// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
// service
import { DialogService } from 'app/core/services/dialog.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { ZeroPiiService } from 'app/core/services/zero-pii.service';
import { PatientsJobService } from 'app/core/services/patients-encrypt-job.service';
import { AuthService } from 'app/core/services/auth.service';

// models
import { CenterContainerTitle } from '../../models';

@Component({
  selector: 'app-zero-pii-prompt',
  templateUrl: './zero-pii-prompt.component.html'
})
export class ZeroPiiPromptComponent implements OnInit {

  form: UntypedFormGroup;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  zeroPiiPasswordHash: string;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private preferencesService: PreferencesService,
    private zeroPiiService: ZeroPiiService,
    private toastService: ToastrService,
    private dialogService: DialogService,
    private patientsJobService: PatientsJobService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.translateService.get('key').subscribe(() => this.setTranslations());
    this.isLoading = true;
    this.preferencesService.get().subscribe(preferences => {
      if (!this.preferencesService.isZeroPiiEnabled)
        this.router.navigate(this.authService.isTechallAdmin ? ['/waiting-sessions'] : ['/patients']);

      this.zeroPiiPasswordHash = preferences.zero_pii_password_hash;
      this.initializeForm();
      this.isLoading = false;
    });
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('key');
    this.title.thin = this.translateService.instant('encryption');
  }

  initializeForm() {
    this.form = this.formBuilder.group({
      zero_pii_password: [''],
    });
  }

  async onSubmitClick(form: UntypedFormGroup) {
    if (!form.value.zero_pii_password)
      return this.openConfirm();

    this.isLoading = true;

    const isValid = await this.zeroPiiService.verifyPassword(form.value.zero_pii_password, this.zeroPiiPasswordHash);

    if (isValid) {
      this.toastService.success(this.translateService.instant('encryptionKeyMatchSuccessMessage'));
      this.zeroPiiService.saveKeyFromPassword(form.value.zero_pii_password).then(() => {
        this.isLoading = false;
        this.router.navigate(this.authService.isTechallAdmin ? ['/waiting-sessions'] : ['/patients']);
        this.patientsJobService.storePatients(null, true);
      });
    } else {
      this.isLoading = false;
      this.openConfirm();
    }
  }

  openConfirm() {
    this.dialogService.openConfirm({
      action: '',
      message: this.translateService.instant('wrongEncryptionKeyMessage'),
      confirmText: this.translateService.instant('tryAgain'),
      cancelText: this.translateService.instant('continue'),
      mediumButtonMode: true,
      showClose: false
    }).then(result => {
      if (result.canceled)
        this.router.navigate(this.authService.isTechallAdmin ? ['/waiting-sessions'] : ['/patients']);
    });
  }
}
