// native
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { TestsRecurringService } from 'app/core/services/tests-recurring.service';
import { PatientsService } from 'app/core/services/patients.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';
import { TabsService } from 'app/core/services/tabs.service';
import { PreferencesService } from 'app/core/services/preferences.service';

// models
import { PaginatedItems, Tab, RecurringTest, ListItemAction, ListItemColumn, BreadcrumbOptions } from '../../models';

// animation
import { routerTransition } from '../../animations/router-animations';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, TEST_TAB_INDEX } from '../../constants';

interface RecurringTestBundleDisplay extends RecurringTest {
  displayed_group_info: string;
}

@Component({
  selector: 'app-bundles-recurring',
  templateUrl: './bundles-recurring.component.html',
  animations: [routerTransition()]
})
export class BundlesRecurringComponent implements OnInit {
  columns: ListItemColumn[] = [
    {
      translationKey: 'testGroups',
      fieldName: 'displayed_group_info',
      fontClass: 'bold'
    },
    {
      translationKey: 'periodInDays',
      fieldName: 'period_in_days',
      fontClass: 'bold'
    },
    {
      translationKey: 'nextTestDate',
      fieldName: 'next_test_date',
      fontClass: 'thin'
    },
    {
      translationKey: 'endDate',
      fieldName: 'end_date',
      fontClass: 'thin'
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'edit',
      execute: (test: RecurringTestBundleDisplay) => this.edit(test),
      visible: () => true
    },
    {
      translationKey: 'delete',
      execute: (test: RecurringTestBundleDisplay) => this.delete(test),
      visible: (test: RecurringTestBundleDisplay) => this.canDelete(test)
    }
  ];

  breadcrumbOptions: BreadcrumbOptions = {
    stepLabels: ['patients'],
    stepActions: [() => { this.router.navigate(['/patients']); }]
  };

  tabs: Tab[] = [];

  patientId: number;

  testBundlesRecurring$: BehaviorSubject<PaginatedItems<RecurringTestBundleDisplay> | null> = new BehaviorSubject(null);

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testsRecurringService: TestsRecurringService,
    private testBundlesService: TestBundlesService,
    private patientsService: PatientsService,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private tabsService: TabsService,
    private preferencesService: PreferencesService
  ) { }

  ngOnInit() {
    this.patientId = this.route.snapshot.params['patientId'];

    this.tabs = this.tabsService.getTestTabs(this.patientId, TEST_TAB_INDEX.RECURRING_BUNDLES);

    this.getTestBundles(this.patientId, this.currentPageSize, this.currentPageIndex, null);

    this.patientsService.getOne(this.patientId).subscribe(patient => {
      this.breadcrumbOptions.stepLabels.push(this.patientsService.getDisplayName(patient));
    });
  }

  private getTestBundles(patientId: number, pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    this.testsRecurringService.getAllBundles(patientId, pageSize, pageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      tests => this.testBundlesRecurring$.next(this.parseTestBundles(tests)),
      error => this.errorService.handleError(error));
  }

  private parseTestBundles(tests: PaginatedItems<RecurringTest>): PaginatedItems<RecurringTestBundleDisplay> {
    const parsedTestBundles = tests.results.map(recurringTestBundle => ({
      ...recurringTestBundle,
      displayed_group_info: this.testBundlesService.getGroupInfo(recurringTestBundle.test_bundle?.test_groups),
      next_test_date: new DatePipe('en-US').transform(recurringTestBundle.next_test_date, this.preferencesService.defaultDateFormat.value),
      end_date: new DatePipe('en-US').transform(recurringTestBundle.end_date, this.preferencesService.defaultDateFormat.value)
    }));

    return {
      results: parsedTestBundles,
      count: tests.count
    };
  }

  private canDelete(test: RecurringTestBundleDisplay) {
    // todo see for permissions
    return true;
  }

  view(test: RecurringTestBundleDisplay) {
    this.router.navigate(['/tests/recurring/view', test.id], { queryParamsHandling: 'merge' });
  }

  edit(test: RecurringTestBundleDisplay) {
    this.router.navigate(['/tests/recurring/edit', test.id], { queryParamsHandling: 'merge' });
  }

  delete(test: RecurringTestBundleDisplay) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('deleteReccuringBundlePrompt'),
      cancelText: this.translateService.instant('back')
    }).then(result => {
      if (result.confirmed)
        this.testsRecurringService.delete(test.id).subscribe(
          res => this.getTestBundles(this.patientId, this.currentPageSize, this.currentPageIndex, this.term),
          error => this.errorService.handleError(error));
    });
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getTestBundles(this.patientId, pageSize, pageIndex, this.term);
  }

  search(term: string) {
    this.currentPageIndex = 0;

    this.testsRecurringService.getAllTests(this.patientId, this.currentPageSize, this.currentPageIndex, term).subscribe(res => {
      this.term = term;
      this.testBundlesRecurring$.next(this.parseTestBundles(res));
    });
  };
}