// native
import { Component, EventEmitter, Input, Output } from '@angular/core';

// models
import { CenterContainerTitle, CenterContainerIcon } from '../../../models';

@Component({
  selector: 'app-center-container',
  templateUrl: './center-container.component.html',
})
export class CenterContainerComponent {
  @Input() title: CenterContainerTitle;
  @Input() showClose: boolean = false;
  @Input() icon: CenterContainerIcon;
  @Input() hasOverlay: boolean = false;
  @Input() isLarge: boolean = false;
  @Input() closeTooltip?: string = null;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor() { }

  closeContainer(event) {
    this.close.emit(event);
  }
}