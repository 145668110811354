// native
import { TitleCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';

// addon
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'grid-dropdown-filter',
  template: `
    <kendo-dropdownlist
        [data]="data"
        (valueChange)="onChange($event)"
        [defaultItem]="defaultItem"
        [value]="selectedValue"
        [valuePrimitive]="true"
        [textField]="textField"
        [valueField]="valueField">
    </kendo-dropdownlist>
  `
})
export class DropDownListFilterComponent extends BaseFilterCellComponent {
  public get selectedValue(): unknown {
    const filter = this.filterByField(this.valueField);
    return filter ? filter.value : null;
  }

  @Input() public filter: CompositeFilterDescriptor;
  @Input() public data: unknown[];
  @Input() public column: string;
  @Input() public textField: string;
  @Input() public valueField: string;

  public get defaultItem(): { [Key: string]: unknown; } {
    return {
      [this.textField]: new TitleCasePipe().transform(this.translateService.instant('select')) + '...',
      [this.valueField]: null
    };
  }

  constructor(
    filterService: FilterService,
    private translateService: TranslateService) {
    super(filterService);
  }

  public onChange(value: unknown): void {
    this.applyFilter(
      value === null ? 
        this.removeFilter(this.column) : 
        this.updateFilter({ 
          field: this.column,
          operator: 'eq',
          value: value
        })
    );
  }
}