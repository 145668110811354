// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { NewBundleComponent } from "./new-bundle/new-bundle.component";
import { BundleFormComponent } from "./bundle-form/bundle-form.component";
import { BundlesRecurringComponent } from "./bundles-recurring/bundles-recurring.component";
import { BundlesComponent } from "./bundles.component";
import { ViewBundleComponent } from "./view-bundle/view-bundle.component";
import { MoveBundleComponent } from './move-bundle/move-bundle.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    BundlesComponent,
    ViewBundleComponent,
    NewBundleComponent,
    BundleFormComponent,
    BundlesRecurringComponent,
    MoveBundleComponent
  ]
})
export class BundlesModule { }