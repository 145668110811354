// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { WebAuthnService } from 'app/core/services/webauthn.service';

// models
import { CenterContainerTitle } from '../../models';

@Component({
  selector: 'app-edit-authenticator',
  templateUrl: './edit-authenticator.component.html'
})
export class EditAuthenticatorComponent implements OnInit {

  form: UntypedFormGroup;
  authenticatorId: number;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public toastService: ToastrService,
    private translateService: TranslateService,
    private webauthnService: WebAuthnService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.authenticatorId = params['id'];

      this.webauthnService.getOne(this.authenticatorId).subscribe(authenticator => {
        this.form = this.formBuilder.group({
          name: [authenticator.label, Validators.required]
        });
      });
    });

    this.translateService.get('authenticator').subscribe(() => this.setTranslations());
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('authenticator');
    this.title.thin = this.translateService.instant('edit');
  }

  onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    const { name } = this.form.value;
    this.webauthnService.update(this.authenticatorId, { label: name }).subscribe(res => {
      this.toastService.success(this.translateService.instant('updateDeviceWebAuthnSuccess'));
      this.closeForm();
    }, err => {
      this.toastService.error(this.translateService.instant('updateDeviceWebAuthnError'));
      this.closeForm();
    });
  }

  closeForm() {
    this.router.navigate(['/preferences'], { queryParams: { tab: 2, authenticator: 1 } });
  }
}

