// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { HelpComponent } from "./help.component";
import { ContactComponent } from "./contact/contact.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    HelpComponent,
    ContactComponent
  ]
})
export class HelpModule { }