
// native
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

// services
import { ApiService } from './api.service';

// models
import { IInvitationRequest, PaginatedItems, IInvitation } from '../../models';

// constants
import {
  API_SSO_PATH, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX,
  API_INVITATIONS_PATH, API_SSO_INVITE_PATH
} from '../../constants';


@Injectable({
  providedIn: 'root'
})
export class SsoService {
  constructor(
    private apiService: ApiService
  ) { }

  ssoLogin(domain: string, invitation?: string) {
    let url = `${API_SSO_PATH}?sso_domain=${domain}&return_url=${encodeURIComponent('/sso-success')}`;

    if (invitation)
      url = url + `&invitation=${invitation}`;

    window.location.href = url;
  }

  ssoInvite(body: IInvitationRequest) {
    return this.apiService.post(`${API_SSO_INVITE_PATH}`, body).pipe(map((response: any) => {
    }));
  }

  getAllInvitations(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<IInvitation>> {
    let path = `${API_INVITATIONS_PATH}?limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    return this.apiService.get(path) as Observable<PaginatedItems<IInvitation>>;
  }

  resendInvitation(id: number) {
    return this.apiService.post(`${API_INVITATIONS_PATH}${id}/resend/`);
  }

  cancelInvitation(id: number) {
    return this.apiService.post(`${API_INVITATIONS_PATH}${id}/cancel/`);
  }
}
