// native
import { map, mergeMap, takeWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, from, of } from 'rxjs';

// services
import { ApiService } from './api.service';
import { PatientsService } from './patients.service';
import { ZeroPiiService } from './zero-pii.service';

// models
import { Patient, PatientRequest, PaginatedItems, PatientJob } from '../../models';

// constants
import { API_PATIENTS_PATH, DEFAULT_PAGE_SIZE, PATIENT_JOB_STATUS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class PatientsJobService {
  constructor(
    private apiService: ApiService,
    private patientsService: PatientsService,
    private zeroPiiService: ZeroPiiService
  ) { }

  encryptJobPageIndex = 0;
  storeJobPageIndex = 0;

  encryptQueue$ = new BehaviorSubject<any[]>([]);
  encryptStart$ = new BehaviorSubject<any[]>([]);
  encryptDone$ = new BehaviorSubject<any[]>([]);

  storeQueue$ = new BehaviorSubject<any[]>([]);
  storeStart$ = new BehaviorSubject<any[]>([]);
  storeDone$ = new BehaviorSubject<any[]>([]);

  protected encryptQueue: any[] = [];
  protected storeQueue: any[] = [];
  protected MAX_CONCURENT_JOBS = 1;

  isEncryptionJobNeeded(): Observable<boolean> {
    const path = `${API_PATIENTS_PATH}?limit=1&is_encrypted=false`;
    return this.apiService.get(path).pipe(
      map((response: PaginatedItems<Patient>) => !!response.results.length)
    );
  }

  private getAll(): Observable<PaginatedItems<Patient>> {
    const path = `${API_PATIENTS_PATH}?limit=${DEFAULT_PAGE_SIZE}&offset=${this.encryptJobPageIndex * DEFAULT_PAGE_SIZE}`;
    return this.apiService.get(path) as Observable<PaginatedItems<Patient>>;
  }

  encryptExistingPatients(isInitial: boolean = false) {
    if (isInitial)
      this.encryptJobPageIndex = 0;
    this.getAll().subscribe(patients => {
      if (!patients?.results?.length)
        return;

      patients.results.forEach(patient => {
        if (!patient.is_encrypted)
          this.addEncryptPatientJob(patient);
      });
      this.startEncrypt();
    });
  }

  private addEncryptPatientJob(patientToEncrypt: Patient): PatientJob {
    const action = (patient: Patient) => {
      const bodyToEncrypt: PatientRequest = {
        first_name: patient.first_name,
        last_name: patient.last_name,
        date_of_birth: patient.date_of_birth,
        email: patient.email,
        phone: patient.phone
      };

      return this.patientsService.update(patient.id, bodyToEncrypt).pipe(
        mergeMap(patient => from(this.zeroPiiService.savePatient(patient)))
      );
    };

    const job = new PatientJob(action, patientToEncrypt);
    this.encryptQueue.push(job);
    return job;
  }

  private startEncrypt() {
    this.continueEncrypt();
    this.encryptStart$.next(this.encryptQueue);
  }

  private continueEncrypt() {
    const running = this.encryptQueue.filter(job => job.snapshot.status === PATIENT_JOB_STATUS.IN_PROGRESS);
    if (running.length >= this.MAX_CONCURENT_JOBS) {
      return;
    }
    const queued = this.encryptQueue.filter(job => job.snapshot.status === PATIENT_JOB_STATUS.QUEUED).reverse();
    if (queued.length === 0) {
      this.encryptDone$.next(this.encryptQueue);
      this.encryptJobPageIndex++;
      this.encryptExistingPatients();
    } else {
      const n = this.MAX_CONCURENT_JOBS - running.length;
      queued.slice(0, n).forEach(job => {
        job.status$.pipe(
          takeWhile((status: string) => ![PATIENT_JOB_STATUS.SUCCEEDED, PATIENT_JOB_STATUS.FAILED].includes(status))
        ).subscribe({
          complete: () => this.continueEncrypt()
        });
        job.run();
      });
    }
    this.encryptQueue$.next(this.encryptQueue);
  }

  async checkPatientsStoreStatus() {
    const lastUpdatedDate = await this.getStorageLastUpdated();
    this.isStorageUpdateJobNeeded(lastUpdatedDate).subscribe(isNeeded => {
      if (isNeeded)
        this.storePatients(lastUpdatedDate, true);
    });
  }

  private async getStorageLastUpdated(): Promise<string> {
    return await this.zeroPiiService.loadLastUpdated();
  }

  private isStorageUpdateJobNeeded(dateString: string): Observable<boolean> {
    if (!dateString)
      return of(true);

    const path = `${API_PATIENTS_PATH}?ordering=-updated&updated__gte=${dateString}&limit=1&is_encrypted=true`;
    return this.apiService.get(path).pipe(
      map((response: PaginatedItems<Patient>) => !!response.results.length)
    );
  }

  private getOutdatedPatients(dateString: string): Observable<PaginatedItems<Patient>> {
    let path = `${API_PATIENTS_PATH}?limit=${DEFAULT_PAGE_SIZE}&offset=${this.storeJobPageIndex * DEFAULT_PAGE_SIZE}&is_encrypted=true`;
    if (dateString)
      path = path + `&ordering=-updated&updated__gte=${dateString}`;
    return this.apiService.get(path) as Observable<PaginatedItems<Patient>>;
  }

  storePatients(dateString: string, isInitial: boolean = false) {
    if (isInitial)
      this.storeJobPageIndex = 0;

    this.getOutdatedPatients(dateString).subscribe(patients => {
      if (!patients?.results?.length)
        return;

      patients.results.forEach(patient => {
        this.addStorePatientJob(patient);
      });
      this.startStore(dateString);
    });
  }

  private addStorePatientJob(patient: Patient): PatientJob {
    const action = (patient: Patient) => {
      return from(this.patientsService.decryptPatient(patient)).pipe(
        mergeMap(decrypted => from(this.zeroPiiService.savePatient(decrypted)))
      );
    };

    const job = new PatientJob(action, patient);
    this.storeQueue.push(job);
    return job;
  }

  private startStore(dateString: string) {
    this.continueStore(dateString);
    this.storeStart$.next(this.storeQueue);
  }

  private continueStore(dateString: string) {
    const running = this.storeQueue.filter(job => job.snapshot.status === PATIENT_JOB_STATUS.IN_PROGRESS);
    if (running.length >= this.MAX_CONCURENT_JOBS) {
      return;
    }
    const queued = this.storeQueue.filter(job => job.snapshot.status === PATIENT_JOB_STATUS.QUEUED).reverse();
    if (queued.length === 0) {
      this.storeDone$.next(this.storeQueue);
      this.storeJobPageIndex++;
      this.storePatients(dateString);
    } else {
      const n = this.MAX_CONCURENT_JOBS - running.length;
      queued.slice(0, n).forEach(job => {
        job.status$.pipe(
          takeWhile((status: string) => ![PATIENT_JOB_STATUS.SUCCEEDED, PATIENT_JOB_STATUS.FAILED].includes(status))
        ).subscribe({
          complete: () => this.continueStore(dateString)
        });
        job.run();
      });
    }
    this.storeQueue$.next(this.storeQueue);
  }
}