// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// services
import { PatientsService } from 'app/core/services/patients.service';

// models
import { Patient } from 'app/models';

@Component({
  selector: 'app-edit-patient',
  templateUrl: './edit-patient.component.html'
})
export class EditPatientComponent implements OnInit {

  patient: Patient;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patientsService: PatientsService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.patientsService.getOne(id).subscribe((patient: Patient) => {
        this.patient = patient;
      }, err => {
        this.closeForm();
      });
    });
  }

  closeForm() {
    this.router.navigate(['/patients']);
  }

}
