// native
import { Component, Input } from '@angular/core';

// model
import { Tab } from '../../../models';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent {

  @Input() tabs: Tab[];

  isSpaceSavingMode() {
    return this.tabs?.filter(tab => tab.translationKey).length > 2;
  }

  isExtraSmall() {
    return this.tabs?.filter(tab => tab.translationKey).length > 3;
  }
}
