// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { CustomBundlesComponent } from './custom-bundles.component';
import { NewCustomBundleComponent } from './new-custom-bundle/new-custom-bundle.component';
import { ViewCustomBundleComponent } from './view-custom-bundle/view-custom-bundle.component';
import { CustomBundleFormComponent } from './custom-bundle-form/custom-bundle-form.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    CustomBundlesComponent,
    NewCustomBundleComponent,
    ViewCustomBundleComponent,
    CustomBundleFormComponent
  ]
})
export class CustomBundlesModule { }