// native
import { NgModule } from "@angular/core";

// addon
import { QRCodeModule } from "angularx-qrcode";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { DeviceFormComponent } from "./device-form/device-form.component";
import { DevicesComponent } from "./devices.component";
import { EditDeviceComponent } from "./edit-device/edit-device.component";
import { NewDeviceComponent } from "./new-device/new-device.component";

@NgModule({
  imports: [
    SharedModule,
    QRCodeModule
  ],
  declarations: [
    DevicesComponent,
    NewDeviceComponent,
    EditDeviceComponent,
    DeviceFormComponent
  ]
})
export class DevicesModule { }