<app-center-container
  [title]="title"
  [showClose]="true"
  (close)="closeForm()">

  <form *ngIf="form">
    <div [formGroup]="step1" [hidden]="currentStep !== 1">
      <app-input-wrapper 
        [control]="step1.get('group')" 
        label="{{ 'testGroup' | translate }}">
        <mat-select 
          class="form_control"
          aria-label="{{ 'testGroup' | translate }}"
          formControlName="group" 
          disableOptionCentering 
          panelClass="dropdown">
          <mat-option *ngFor="let testGroup of displayedTestGroups" [value]="testGroup.value">
            {{ testGroup.name }}
          </mat-option>
          <mat-option *ngIf="displayedCustomBundles?.length" [value]="customBundleGroupValue">{{ 'customBundle' | translate | titlecase }}</mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper
        *ngIf="displayedTestProtocols?.length" 
        [control]="step1.get('protocol')" 
        label="{{ 'testProtocol' | translate }}">
        <mat-select 
          class="form_control"
          aria-label="{{ 'testProtocol' | translate }}"
          formControlName="protocol" 
          disableOptionCentering 
          panelClass="dropdown">
          <mat-option *ngFor="let testProtocol of displayedTestProtocols" [value]="testProtocol.value">
            {{ testProtocol.name }}
          </mat-option>
        </mat-select>
      </app-input-wrapper>

      <app-input-wrapper 
        *ngIf="isOtherGroup || isOtherProtocol"
        [control]="step1.get('other_protocol')" 
        label="{{ 'protocolName' | translate }}"
        identifier="other_protocol">
        <input class="form_control" type="text" formControlName="other_protocol" id="other_protocol">
      </app-input-wrapper>

      <app-input-wrapper 
        [control]="step1.get('test_date')" 
        label="{{ 'testDate' | translate }}"
        identifier="test_date">
        <input 
          class="form_control" 
          [matDatepicker]="picker"
          [datePickerFormat]="preferencesService.defaultDateFormat.serverValue"
          [max]="maxTestDate"  
          formControlName="test_date"
          id="test_date">
        <mat-datepicker-toggle matSuffix [for]="picker" class="form_datepicker-icon">
        </mat-datepicker-toggle>
      </app-input-wrapper>

      <mat-datepicker #picker></mat-datepicker>

      <button 
        class="button button--primary form_button"
        [disabled]="!step1.valid"
        (click)="onNextStep()">
        <span class="bold">{{ lowConfidenceSensitivities?.length ? ('next' | translate) : (isSucceeded ? ('verify' | translate) : ('resolve' | translate)) }}</span>
      </button>
    </div>

    <div [formGroup]="step2" [hidden]="currentStep !== 2" >
      <div formArrayName="sensitivities">
        <div *ngFor="let sensitivityForm of step2.get('sensitivities').controls; let i = index" [formGroupName]="i">
          <div class="row">
            <div class="col-xs-4">
              <div class="form_image-box">
                <img [src]="lowConfidenceSensitivities[i]?.imageSrc" alt="{{ ('sensitivity ' + (i+1)) }}">
              </div>
            </div>
            <div class="col-xs-8">
              <app-input-wrapper 
                [control]="sensitivityForm.get('sensitivityValue')" 
                label="{{ ('sensitivity ' + (i+1)) }}">
                <input class="form_control" type="text" formControlName="sensitivityValue">
              </app-input-wrapper>
            </div>
          </div>
        </div>
      </div>

      <button 
        class="button button--primary form_button"
        [disabled]="!step2.valid"
        (click)="onSubmit()">
        <span class="regular">{{ isSucceeded ? ('verify' | translate) : ('resolve' | translate) }}</span>
      </button>
    </div>
  </form>
</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>
