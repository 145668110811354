<div class="monitor_info-box-simple-container">
  <div class="monitor_info-box">
    <span *ngIf="patient, else testLoadingTemplate">{{patientsService.getDisplayName(patient)}}</span>
  </div>
  <div class="monitor_info-box" [ngClass]="{ 'monitor_info-box--complex' : !!bundle }">
    <ng-container *ngIf="patient, else testLoadingTemplate">
      <span>{{ patient?.date_of_birth | date: preferencesService.defaultDateFormat.value }}&nbsp;&nbsp;{{ patient?.gender?.name | titlecase }}</span>
      <span *ngIf="bundle" id="test-bundle-info">{{ monitorTestService.getBundleInfo(test, bundle) }}</span>
    </ng-container>
  </div>
  <div class="monitor_info-box monitor_info-box--title">
    <ng-container *ngIf="!testLoading, else testLoadingTemplate" >
      <span>{{ monitorTestService.getDisplayTitle(test?.test_group) }}</span>
      <span *ngIf="isRegionShown && (currentRegion !== null)">(&#35;{{ currentRegion + 1 }})</span>
      <span *ngIf="!screen && bundle && (test?.status?.name === 'Pending')">
        <span>&#58;&nbsp;</span>{{ 'pending' | translate }}
      </span>
      <span *ngIf="!isTestDone && screen?.name">&#58;&nbsp;</span>
      <span id="test-screen-name" *ngIf="!isTestDone && screen?.name">{{ getScreenName() }}</span>
    </ng-container>
  </div>
  <div class="monitor_info-box monitor_info-box--controls">
    <span *ngIf="isTestDone" class="text--blink text--uppercase">&nbsp;{{ 'testDone' | translate }}</span>
    <span *ngIf="isTestSyncing" class="text--blink text--uppercase">&nbsp;{{ 'testSyncing' | translate }}</span>
    <app-controls
      *ngIf="!isTestDone && !isTestSyncing && !isTestOffline"
      [screen]="screen"
      [testGroup]="test?.test_group"
      [device]="device"
      (actionClicked)="onActionClicked.emit($event)">
    </app-controls>
  </div>
</div>

<div class="monitor_info-box monitor_info-box--medium monitor_device-box">
  <div class="monitor_device-box-image-container">
    <img src="./assets/images/headset.svg" alt="headset device">
    <div class="monitor_device-title">
      <ng-container *ngIf="!deviceLoading, else testLoadingTemplate">
        {{ device?.name || ('unknownDevice' | translate) }}
      </ng-container>
    </div>
  </div>
  <app-battery
    *ngIf="batteryPercentage"
    [batteryPercentage]="batteryPercentage">
  </app-battery>
  <div 
    *ngIf="streamable && screen && !isTestDone"
    class="monitor_device-box-stream-container"
    [matTooltip]="streamPopupVisible ? ('stopStreaming' | translate | titlecase) : ('streamTest' | translate | titlecase)"
    [ngClass]="{ 'text--blink': streamPopupVisible }">
    <i 
      class="fas fa-stream"
      (click)="onStreamOpen.emit(streamComponent)">
    </i>
  </div>
  <div 
    *ngIf="!isTestDone && !isTestSyncing"
    class="monitor_device-box-cancel-container">
    <i 
      class="fas fa-ban"
      matTooltip="{{ isTestOffline ? ('externallyManagedTest' | translate) : ('cancelTest' | translate | titlecase) }}"
      [ngClass]="{ 'disabled': isTestOffline }"
      (click)="onCancelTest()">
    </i>
  </div>
</div>
<div class="monitor_info-box monitor_info-box--last monitor_image-box">
  <img 
    src="{{utilityService.isWhiteLabelDomain ? './assets/images/logo-white-label.svg' : './assets/images/logo.svg'}}"  
    alt="Olleyes logo">
</div>

<ng-template #testLoadingTemplate>
  <app-load class="text--loader"></app-load>
</ng-template>

<app-stream
  *ngIf="streamable"
  [ngClass]="{ 'hidden': !streamPopupVisible }"
  [isSingleImage]="isSingleImage"
  [streamPending]="streamingService.streamPending"
  (close)="onStreamClose.emit()">
</app-stream>

<app-loader *ngIf="deviceActionLoading" [transparent]="true"></app-loader>
