// native
import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges, isDevMode } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { DevicesService } from 'app/core/services/devices.service';
import { OfficesService } from 'app/core/services/offices.service';
import { ErrorService } from 'app/core/services/error.service';
import { AuthService } from 'app/core/services/auth.service';
import { WorkItemService } from 'app/core/services/work-item.service';

// models
import { CenterContainerTitle, Device, DevicePool, Office, PaginatedItems } from '../../models';

// constants
import { DEVICE_TYPE } from '../../constants';

@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html'
})
export class DeviceFormComponent implements OnInit, OnChanges {
  @Input() device: Device;
  @Input() action: string;

  @Output() close: EventEmitter<Device> = new EventEmitter<Device>();

  public form: UntypedFormGroup;
  offices: Office[] = [];

  isNew: boolean;
  deviceTypeOffice: string = DEVICE_TYPE.OFFICE;
  deviceTypeOfficeChoice: string = DEVICE_TYPE.OFFICE_CHOICE;
  deviceTypeEyeTracking: string = DEVICE_TYPE.EYE_TRACKING;
  deviceTypeHome: string = DEVICE_TYPE.HOME;
  deviceTypeHomeEyeTracking: string = DEVICE_TYPE.HOME_EYE_TRACKING;
  deviceTypeCamera: string = DEVICE_TYPE.CAMERA;

  devicePools: DevicePool[] = [];

  qrDataString: string = null;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public devicesService: DevicesService,
    public officesService: OfficesService,
    private translateService: TranslateService,
    private errorService: ErrorService,
    public authService: AuthService,
    private workItemService: WorkItemService,
  ) { }

  ngOnInit() {
    this.isNew = !this.device;

    if (this.device?.code) {
      const protocol = isDevMode() ? 'http://' : 'https://';
      this.qrDataString = `${protocol}${location.host}/api/cameras/link/?code=${this.device.code}`;
    }

    this.translateService.get('device').subscribe(() => this.setTranslations());

    this.isLoading = true;
    this.officesService.getAll().pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((offices: PaginatedItems<Office>) => {
      this.offices = offices.results;
      this.initializeForm();
    }, error => this.errorService.handleError(error));

    if (this.authService.isPacsIntegrationUser)
      this.workItemService.getConfig().subscribe(config => {
        this.devicePools = config.device_pools;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['action']?.currentValue)
      this.title.thin = changes['action']?.currentValue;
  }

  private initializeForm() {
    const { name, offices, device_type, pacs_integration_device_pool } = this.device || ({} as Device);

    this.form = this.formBuilder.group({
      device_type: [{ value: (this.isNew ? this.deviceTypeOffice : device_type), disabled: !this.isNew }, Validators.required],
      name: [name, Validators.required],
      offices: [offices, Validators.required],
      pacs_integration_device_pool: [pacs_integration_device_pool?.id]
    });
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('device');
  }

  onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    const { name, offices, device_type, pacs_integration_device_pool } = this.form.value;
    const body: Device = { name, offices, device_type, pacs_integration_device_pool };

    const request = this.device?.id ? this.devicesService.update(this.device.id, body) : this.devicesService.create(body);

    this.isLoading = true;
    request.pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      response => this.close.emit(response),
      error => this.errorService.handleDeviceError(error)
    );
  }
}
