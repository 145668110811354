// native
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-percentage-squares',
  templateUrl: './percentage-squares.component.html'
})
export class PercentageSquaresComponent implements OnChanges {

  @Input() percentage: number = 0;
  @Input() color: string = 'red';
  @Input() id: string = '';

  square1: number = 0;
  square2: number = 0;
  square3: number = 0;
  square4: number = 0;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.percentage)
      this.setSquarePercentage(changes.percentage.currentValue);
  }

  setSquarePercentage(percent: number) {
    if (percent === null || percent === undefined)
      return;

    if (percent > 75) {
      this.square1 = this.square2 = this.square3 = 100;
      this.square4 = (percent - 75) * 4;
    } else if (percent <= 75 && percent > 50) {
      this.square1 = this.square2 = 100;
      this.square3 = (percent - 50) * 4;
      this.square4 = 0;
    } else if (percent <= 50 && percent > 25) {
      this.square1 = 100;
      this.square2 = (percent - 25) * 4;
      this.square3 = this.square4 = 0;
    } else if (percent <= 25) {
      this.square1 = (percent - 0) * 4;
      this.square2 = this.square3 = this.square4 = 0;
    }
  }
}
