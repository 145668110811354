// native
import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-percentage-circle',
  templateUrl: './percentage-circle.component.html'
})
export class PercentageCircleComponent implements OnChanges, AfterViewInit {

  @Input() percentage: number = 0;
  @Input() simpleMode?: boolean = false;

  circumference: number;

  @ViewChild('percentageCircle') circleElement: ElementRef;

  constructor() { }

  ngAfterViewInit() {
    this.initCircle();
    this.setProgress(this.percentage);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.percentage && changes.percentage.currentValue) {
      this.setProgress(changes.percentage.currentValue);
    }
  }

  initCircle() {
    const radius = this.circleElement.nativeElement.r.baseVal.value;
    this.circumference = radius * 2 * Math.PI;

    this.circleElement.nativeElement.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
    this.circleElement.nativeElement.style.strokeDashoffset = `${this.circumference}`;
  }

  setProgress(percent: any) {
    if (!this.circumference || !this.circleElement.nativeElement)
      return;
    const offset = this.circumference - parseInt(percent) / 100 * this.circumference;
    this.circleElement.nativeElement.style.strokeDashoffset = offset.toString();

    if (this.circleElement.nativeElement.style.stroke !== '#afcb53')
    // timeout prevents initial flicker
      setTimeout(() => {
        this.circleElement.nativeElement.style.stroke = '#afcb53';
      }, 500);
  }
}
