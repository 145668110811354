<app-center-container    
  [title]="title"
  [showClose]="true"
  (close)="close()">
  <div class="about_container">
    <img 
      src="{{utilityService.isWhiteLabelDomain ? './assets/images/logo-white-label.svg' : './assets/images/logoWhite.svg'}}" 
      alt="Olleyes logo"/>

    <div class="about_footer">
      <div class="text--xl">visuALL</div>
      <div class="text--light">
        {{ 'copyright' | translate }} {{utilityService.isWhiteLabelDomain ? '2017-2024 Forus Health in collaboration with Olleyes, Inc.' : '2017-2024 Olleyes, Inc.'}}
        <br>
        {{ 'allRightsReserved' | translate }}
      </div>
      <small>{{ 'version' | translate }} 1.0</small>
    </div>
  </div>
</app-center-container>
