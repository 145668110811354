import { animate, state, style, transition, trigger } from "@angular/animations";

export function routerTransition() {
  return Fade();
}

export function fadeTransition() {
  return FadeNofixed();
}

export function Fade() {
  return trigger('routerTransition', [
    state('void', style({position: 'fixed', width: '100%', height: '100%'}) ),
    state('*', style({position: 'fixed', width: '100%', height: '100%'}) ),
    transition(':enter', [  // before 2.1: transition('void => *', [
      style({opacity: 0}),
      animate('.3s ease', style({opacity: 1}))
    ]),
    transition(':leave', [  // before 2.1: transition('* => void', [
      style({opacity: 1}),
      animate('.3s ease', style({opacity: 0}))
    ])
  ]);
}

export function FadeNofixed() {
  return trigger('fadeTransition', [
    transition(':enter', [  // before 2.1: transition('void => *', [
      style({opacity: 0}),
      animate('.3s ease', style({opacity: 1}))
    ]),
    transition(':leave', [  // before 2.1: transition('* => void', [
      style({opacity: 1}),
      animate('.3s ease', style({opacity: 0}))
    ])
  ]);
}
