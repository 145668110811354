// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sso-error',
  templateUrl: './sso-error.component.html'
})
export class SsoErrorComponent implements OnInit {

  errorMessage: string;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const errorCode = params['error_code'];
      this.errorMessage = (errorCode === 'auth_failed') ? 'ssoAuthFailedMessage' : 'ssoErrorMessage';
    });
  }
}
