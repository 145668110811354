// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

// addon
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

// service
import { SupportService } from 'app/core/services/support.service';
import { AuthService } from 'app/core/services/auth.service';

// models
import { SupportOffice, Tab, GridItem, ButtonListItem } from '../../models';

// constants
import { DEFAULT_SUPPORT_PAGE_INDEX, DEFAULT_SUPPORT_PAGE_SIZE, DEFAULT_SUPPORT_PAGE_SKIP, DEFAULT_SUPPORT_SORT_ORDER } from '../../constants';

@Component({
  selector: 'app-support-offices',
  templateUrl: './support-offices.component.html'
})
export class SupportOfficesComponent implements OnInit {

  tabs: Tab[] = [
    {
      translationKey: 'devices',
      action: () => this.router.navigate(['/support/devices']),
      isActive: false
    },
    {
      translationKey: 'users',
      action: () => this.router.navigate(['/support/users']),
      isActive: false
    },
    {
      translationKey: 'offices',
      action: () => this.router.navigate(['/support/offices']),
      isActive: true
    }
  ];
  
  buttonList: Array<ButtonListItem> = [
    {
      translationKey: 'newCustomerAccount',
      action: () => this.router.navigate(['/support/accounts/new']),
      disabled: false,
      iconClass: 'fas fa-users'
    }
  ];

  pageSize = DEFAULT_SUPPORT_PAGE_SIZE;
  pageIndex = DEFAULT_SUPPORT_PAGE_INDEX;
  skip = DEFAULT_SUPPORT_PAGE_SKIP;
  sort: SortDescriptor[] = [{
    field: 'name',
    dir: DEFAULT_SUPPORT_SORT_ORDER,
  }];
  filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  gridData$: BehaviorSubject<GridItem<SupportOffice>> = new BehaviorSubject<GridItem<SupportOffice>>({} as GridItem<SupportOffice>);

  isLoading: boolean = true;

  constructor(
    private supportService: SupportService,
    private router: Router,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getOffices();
  }

  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageIndex = this.skip / this.pageSize;
    this.getOffices();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getOffices();
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.getOffices();
  }

  private getOffices() {
    this.isLoading = true;
    this.supportService.getOffices(this.pageSize, this.pageIndex, this.sort, this.filter).subscribe(res => {
      this.gridData$.next(res);
      this.isLoading = false;
    });
  }
}
