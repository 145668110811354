// native
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { OfficesService } from 'app/core/services/offices.service';
import { ErrorService } from 'app/core/services/error.service';

// model
import { CenterContainerTitle, Office, OfficeRequest } from '../../models';

// validator
import * as CustomValidators from '../../shared/validators';

// constants
import { COUNTRIES } from '../../constants';

@Component({
  selector: 'app-office-form',
  templateUrl: './office-form.component.html'
})
export class OfficeFormComponent implements OnInit, OnChanges {

  @Input() office?: Office;
  @Input() action: string;

  @Output() close: EventEmitter<Office> = new EventEmitter<Office>();

  public form: UntypedFormGroup;
  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  allCountries = COUNTRIES;
  countries$: BehaviorSubject<{ name: string, code: string; }[]> = new BehaviorSubject<{ name: string, code: string; }[]>([]);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private officesService: OfficesService,
    private errorService: ErrorService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.translateService.get('office').subscribe(() => {
      this.initializeForm();
      this.setTranslations();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['action']?.currentValue)
      this.title.thin = changes['action']?.currentValue;
  }

  public initializeForm() {
    const { name, street_address, city, state, zip, phone, country } = this.office || ({} as Office);

    this.form = this.formBuilder.group({
      name: [name, Validators.required],
      street_address: [street_address, Validators.required],
      city: [city, Validators.required],
      state: [state, Validators.required],
      zip: [zip, [Validators.required, Validators.pattern(/^[A-Za-z0-9\- ]{2,10}$/)]],
      phone: [phone, Validators.required],
      country: [country, [Validators.required, CustomValidators.country]]
    });

    this.form.controls['country'].valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      map((term: string) => {
        if (!term)
          return [];

        return this.allCountries
          .filter(country => country.name.toLowerCase().startsWith(term.toLowerCase()) || term.toLowerCase() === country.code.toLowerCase())
          .slice(0, 20);
      }),
      tap(response => {
        this.countries$.next(response);
      })
    ).subscribe();
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('office');
  }

  public onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    const { name, street_address, city, state, zip, phone, country } = this.form.value;

    const body: OfficeRequest = { name, street_address, city, state, zip, phone, country };

    const request = this.office ? this.officesService.update(this.office.id, body) : this.officesService.create(body);

    this.isLoading = true;
    request.pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      response => this.close.emit(response),
      error => this.errorService.handleError(error?.error)
    );
  }

}
