<app-center-container
  [title]="title"
  [showClose]="true"
  (close)="closeForm()">

  <form *ngIf="form" [formGroup]="form">
    <app-input-wrapper 
      [control]="form.get('test_report_file')" 
      label="{{ 'testReport' | translate }}"
      [forceMinimized]="true">
      <app-file-uploader 
        class="form_control"
        formControlName="test_report_file"
        [maxFileSize]="100000"
        [maxFileCount]="20"
        [multiple]="true"
        [allowedFileExtensions]="'pdf,jpg,png'"
        (onFileCountError)="form.get('test_report_file').setErrors({ maxFileCount: true })"
        (onFileSizeError)="form.get('test_report_file').setErrors({ maxFileSize: true })"
        (onFileTypeError)="form.get('test_report_file').setErrors({ fileNotAllowed: true })">
      </app-file-uploader>
    </app-input-wrapper>

    <button 
      class="button button--primary form_button"
      [disabled]="!form.valid"
      (click)="onSubmit()">
      <span class="regular">{{ 'upload' | translate }}</span>
    </button>
  </form>
</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>