<div
  #patientList
  *ngIf="reviewPatients.length"
  class="import-list_button-container import-list_button-container--left">
  <div 
    class="import-list_button"
    (click)="isListShown = !isListShown">
    <i 
      class="fas import-list_expand-icon"
      [ngClass]="{
        'fa-caret-right': !isListShown,
        'fa-caret-down': isListShown
      }">
    </i>
    <span class="import-list_button-text">
      {{ 'review patients' | translate }}
    </span>
    <div 
      class="import-list_notification"
      matTooltip="{{ reviewPatients.length + ' ' + (reviewPatients.length > 1 ? ('reviewPatientNotificationMultiple' | translate) : ('reviewPatientNotificationSingle' | translate)) }}"
      matTooltipPosition="above">
      {{ reviewPatients.length }}
    </div>
  </div>

  <div *ngIf="isListShown" class="import-list_container import-list_container--left">
    <div *ngFor="let patient of reviewPatients, trackBy: trackByFn" class="import-list_item">

      <div class="import-list_content">
        <div class="import-list_name">
          {{ patientsService.getDisplayName(patient) }}
        </div>
      </div>

      <div class="import-list_actions">
        <span
          class="import-list_button" 
          (click)="onMerge(patient)">
          {{ 'merge' | translate }}
        </span>
        <span 
          class="import-list_button" 
          (click)="onKeep(patient)">
          {{ 'keep' | translate }}
        </span>
      </div>

    </div>

  </div>

</div>
