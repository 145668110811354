<div 
  #selectionBox 
  *ngIf="translateService.currentLang"
  class="language-switcher_container" 
  (click)="isDropdownVisible = !isDropdownVisible">
  <div class="language-switcher_selector">
    <img 
      src="./assets/images/flags/{{translateService.currentLang}}.svg" 
      alt="{{translateService.currentLang}}">
  </div>
  <div
    class="language-switcher_dropdown" 
    [ngClass]="{ 'hidden': !isDropdownVisible}" >
    <div 
      *ngFor="let language of languageService.appLanguages"
      class="language-switcher_item">
      <img 
        src="./assets/images/flags/{{language.value}}.svg" 
        alt="{{language.value}}" 
        (click)="onLanguageChange(language.value)">
    </div>
  </div>
</div>

