// native
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// service
import { ApiService } from './api.service';

// constants
import { API_BAA_AGREEMENT_PATH } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class BaaAgreementService {
  constructor(public apiService: ApiService) { }

  public sign(): Observable<any> {
    return this.apiService.post(API_BAA_AGREEMENT_PATH).pipe(map(res => res as any));
  }
}
