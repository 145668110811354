// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// model
import { Office } from 'app/models';

// service
import { OfficesService } from 'app/core/services/offices.service';

@Component({
  selector: 'app-edit-office',
  templateUrl: './edit-office.component.html'
})
export class EditOfficeComponent implements OnInit {

  office: Office;

  constructor(
    private router: Router,
    private officesService: OfficesService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.officesService.getOne(id).subscribe((office: Office) => {
        this.office = office;
      });
    });
  }

  closeForm(event: any) {
    this.router.navigate(['/offices']);
  }

}
