// native
import { finalize } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatePipe, TitleCasePipe } from '@angular/common';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { TestsService } from 'app/core/services/tests.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { PatientsService } from 'app/core/services/patients.service';
import { AuthService } from 'app/core/services/auth.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { TestDefaultService } from 'app/core/services/test-default.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';
import { TestFormService } from 'app/core/services/test-form.service';
import { WorkItemService } from 'app/core/services/work-item.service';
import { TabsService } from 'app/core/services/tabs.service';

// models
import {
  Test, PaginatedItems, Tab, TestListItem, ListItemAction,
  ListItemColumn, BreadcrumbOptions, ButtonListItem, ListItemMarker
} from '../models';

// pipe
import { BoldThinPipe } from '../shared/pipes/pipes';

// animation
import { routerTransition } from '../animations/router-animations';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, STRATEGY, TEST_STATUS, TEST_TAB_INDEX, REPORT_TYPES, PROTOCOL } from '../constants';

interface TestDisplay extends TestListItem {
  using_device_display: string;
  date_display: string;
  test_group_info: string;
  status_name: string;
}

@Component({
  selector: 'app-tests',
  templateUrl: './tests.component.html',
  animations: [routerTransition()]
})
export class TestsComponent implements OnInit, OnDestroy {

  columns: ListItemColumn[] = [
    {
      translationKey: 'testDate',
      fieldName: 'date_display',
      fontClass: 'bold'
    },
    {
      translationKey: 'testGroupStrategyProtocol',
      fieldName: 'test_group_info',
      fontClass: 'thin'
    },
    {
      translationKey: 'usingDevice',
      fieldName: 'using_device_display',
      fontClass: 'thin'
    },
    {
      translationKey: 'status',
      fieldName: 'status_name',
      fontClass: 'bold'
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'delete',
      execute: (test: TestListItem) => this.deleteTest(test),
      visible: (test: TestListItem) => this.canDelete(test)
    },
    {
      translationKey: 'monitor',
      execute: (test: TestListItem) => this.monitorTestService.openMonitorScreen(test),
      visible: (test: TestListItem) => this.canMonitor(test)
    },
    {
      translationKey: 'viewReport',
      execute: (test: TestListItem, action: ListItemAction) => this.openHTMLReport(test, action),
      visible: (test: TestListItem) => this.canOpenReport(test)
    },
    {
      translationKey: 'dicom',
      execute: (test: TestListItem, action: ListItemAction) => this.downloadDicomReport(test, action),
      visible: (test: TestListItem) => this.canDownloadReport(test, REPORT_TYPES.DICOM)
    },
    {
      translationKey: 'pdf',
      execute: (test: TestListItem, action: ListItemAction) => this.downloadPDFReport(test, action),
      visible: (test: TestListItem) => this.canDownloadReport(test, REPORT_TYPES.PDF)
    },
    {
      translationKey: 'png',
      execute: (test: TestListItem, action: ListItemAction) => this.downloadImageReport(test, action),
      visible: (test: TestListItem) => this.canDownloadReport(test, REPORT_TYPES.IMAGE)
    },
    {
      translationKey: 'cancel',
      execute: (test: TestListItem) => this.cancelTest(test),
      visible: (test: TestListItem) => this.canCancel(test)
    },
    {
      translationKey: 'move',
      execute: (test: TestListItem) => this.moveTest(test),
      visible: (test: TestListItem) => this.canMove(test)
    }
  ];

  greenMarker: ListItemMarker = {
    visible: (test: Test) => test?.status?.name === TEST_STATUS.STARTED.value
  };

  orangeMarker: ListItemMarker = {
    visible: (test: Test) => [TEST_STATUS.PENDING.value, TEST_STATUS.PAUSED.value].includes(test?.status?.name)
  };

  redMarker: ListItemMarker = {
    visible: (test: Test) => [TEST_STATUS.FAILED.value, TEST_STATUS.EXPIRED.value, TEST_STATUS.STOPPED.value].includes(test?.status?.name)
  };

  breadcrumbOptions: BreadcrumbOptions = {
    stepLabels: ['patients'],
    stepActions: [() => { this.router.navigate(['/patients']); }]
  };

  buttonList: Array<ButtonListItem> = [];

  tabs: Tab[] = [];

  patientId: number;
  patientAge: number;
  isActiveTechallSession: boolean;

  tests$: BehaviorSubject<PaginatedItems<TestDisplay> | null> = new BehaviorSubject(null);
  testsSnapshot: PaginatedItems<TestListItem>;

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  pingInterval: any;

  isLoading: boolean = false;

  languageSubscription: Subscription;

  testSyncProgressMap: { [key: string]: { progress: number; }; } = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testsService: TestsService,
    public tabsService: TabsService,
    private preferencesService: PreferencesService,
    private patientsService: PatientsService,
    private translateService: TranslateService,
    public authService: AuthService,
    private monitorTestService: MonitorTestService,
    private testBundlesService: TestBundlesService,
    private testDefaultService: TestDefaultService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private testFormService: TestFormService,
    public workItemService: WorkItemService
  ) { }

  ngOnInit() {
    this.patientId = this.route.snapshot.params['patientId'];

    this.getTests(this.patientId, this.currentPageSize, this.currentPageIndex, null);

    this.setTestsPing();

    this.tabs = this.tabsService.getTestTabs(this.patientId, TEST_TAB_INDEX.TESTS);
    this.setButtonList();

    this.languageSubscription = this.translateService.onLangChange.subscribe(res => {
      this.getTests(this.patientId, this.currentPageSize, this.currentPageIndex, null);
    });

    this.patientsService.getOne(this.patientId).subscribe(patient => {
      this.isActiveTechallSession = !!patient.active_techall_session;
      if (this.isActiveTechallSession)
        this.breadcrumbOptions.stepLabels[0] = 'VisuALL Tech';

      this.breadcrumbOptions.stepLabels.push(this.patientsService.getDisplayName(patient));
      this.patientAge = patient.age;
    });
  }

  ngOnDestroy() {
    clearInterval(this.pingInterval);
    this.languageSubscription?.unsubscribe();
  }

  private getTests(patientId: number, pageSize: number, pageIndex: number, term: string, showLoader = true) {
    if (showLoader)
      this.isLoading = true;

    this.testsService.getAll(patientId, pageSize, pageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(tests => {
      const syncingTestIds = tests.results
        .filter(test => test.status?.name === TEST_STATUS.SYNCING.value).map(test => test.id);

      if (syncingTestIds?.length) {
        this.testsService.getSyncProgress(syncingTestIds).subscribe(progressItems => {
          progressItems.forEach(item => {
            this.testSyncProgressMap[item.id] = {
              progress: item.progress
            };
          });
          this.tests$.next(this.parseTests(tests));
          this.testsSnapshot = tests;
        });
      } else {
        this.tests$.next(this.parseTests(tests));
        this.testsSnapshot = tests;
      }
    },
      error => this.errorService.handleError(error));
  }

  private canDownloadReport(test: TestListItem, type: string) {
    if (this.authService.isTechallUserOrAdmin)
      return false;

    if (this.preferencesService.isZeroPiiEnabled)
      return false;

    if (test.is_external && (type === REPORT_TYPES.PDF))
      return true;

    if (test.is_external
      && test.has_extracted_report
      && [REPORT_TYPES.DICOM, REPORT_TYPES.IMAGE].includes(type)
      && [STRATEGY.PERIMETRY_DEFAULT].includes(test.test_group?.strategy?.value))
      return true;

    if (test.is_external)
      return false;

    if (test.test_group?.strategy?.value === STRATEGY.BLUE_PUPILLOMETRY)
      return false;

    if (test.test_group?.protocol?.value === PROTOCOL.SUPRA_THRESHOLD_QUADRANT)
      return false;

    if ((test.status?.name === TEST_STATUS.SYNCING.value) && !test.thresholds_received)
      return false;

    return [TEST_STATUS.FINISHED.value, TEST_STATUS.SYNCING.value].includes(test.status?.name);
  }

  private canOpenReport(test: TestListItem) {
    if (test.is_external
      && test.has_extracted_report
      && [STRATEGY.PERIMETRY_DEFAULT].includes(test.test_group?.strategy?.value))
      return true;

    if (test.is_external)
      return false;

    if (test.test_group?.strategy?.value === STRATEGY.BLUE_PUPILLOMETRY)
      return false;

    if (test.test_group?.protocol?.value === PROTOCOL.SUPRA_THRESHOLD_QUADRANT)
      return false;

    if ((test.status?.name === TEST_STATUS.SYNCING.value) && !test.thresholds_received)
      return false;

    return [TEST_STATUS.FINISHED.value, TEST_STATUS.SYNCING.value].includes(test.status?.name);
  }

  private canMonitor(test: TestListItem) {
    return [TEST_STATUS.STARTED.value, TEST_STATUS.PAUSED.value, TEST_STATUS.STOPPED.value, TEST_STATUS.PENDING.value].includes(test.status?.name);
  }

  private canCancel(test: TestListItem) {
    if (this.authService.isTechallUserOrAdmin && !this.isActiveTechallSession)
      return false;
    if (test.offline)
      return false;
    if (test.status?.name === TEST_STATUS.SYNCING.value && test.thresholds_received)
      return false;

    return test.status?.name && ![TEST_STATUS.FINISHED.value, TEST_STATUS.FAILED.value].includes(test.status?.name);
  }

  private canDelete(test: TestListItem) {
    if (this.authService.isTechallUserOrAdmin && !this.isActiveTechallSession)
      return false;

    return [TEST_STATUS.FINISHED.value, TEST_STATUS.FAILED.value].includes(test.status?.name);
  }

  private canMove(test: TestListItem) {
    if (this.authService.isTechallUserOrAdmin)
      return false;
    if (test.test_bundle || test.is_external || test.test_schedule)
      return false;

    if (this.preferencesService.isZeroPiiEnabled)
      return false;

    return [TEST_STATUS.FINISHED.value].includes(test.status?.name);
  }

  create() {
    this.router.navigate(['tests/new', this.patientId]);
  }

  importTest() {
    this.router.navigate(['import/upload', this.patientId]);
  }

  view(test: TestListItem) {
    this.router.navigate(['tests/view', test.id]);
  }

  deleteTest(test: TestListItem) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant('delete')
        + ' ' + this.translateService.instant('test')?.toLowerCase() + '?',
      text: this.translateService.instant('test') + ': ' +
        (test.time_start
          ? new DatePipe('en-US').transform(test.time_start, this.preferencesService.defaultDateTimeFormat)
          : test.id),
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed)
        this.testsService.delete(test.id).subscribe(
          res => this.reload(),
          error => this.errorService.handleError(error)
        );
    });
  }

  cancelTest(test: TestListItem) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('cancel'),
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant('cancel')
        + ' ' + this.translateService.instant('test')?.toLowerCase() + '?',
      text: this.translateService.instant('test') + ': ' +
        (test.time_start
          ? new DatePipe('en-US').transform(test.time_start, this.preferencesService.defaultDateTimeFormat)
          : test.id),
      confirmText: test.test_bundle ? this.translateService.instant('cancelIndividualTest') : this.translateService.instant('yes'),
      altConfirmText: test.test_bundle ? this.translateService.instant('cancelBundle') : null,
      cancelText: test.test_bundle ? this.translateService.instant('dontCancel') : this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed)
        this.testsService.delete(test.id).subscribe(
          res => this.reload(),
          error => this.errorService.handleError(error)
        );
      if (result.altConfirmed)
        this.testBundlesService.cancel(test.test_bundle?.id).subscribe(
          res => this.reload(),
          error => this.errorService.handleError(error)
        );
    });
  }

  moveTest(test: TestListItem) {
    this.router.navigate([`/tests/move/${test.id}`], { queryParams: { 'patient': this.patientId } });
  }

  private reload() {
    this.getTests(this.patientId, this.currentPageSize, this.currentPageIndex, this.term);
    this.setButtonList();
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getTests(this.patientId, pageSize, pageIndex, this.term);
  }

  private parseTests(tests: PaginatedItems<TestListItem>): PaginatedItems<TestDisplay> {
    const parsedTests = tests.results.map(test => {


      return {
        ...test,
        using_device_display: test.using_device
          ? new BoldThinPipe().transform(`${this.translateService.instant('using')} ${test.using_device}`)
          : '',
        date_display: test.time_start
          ? new BoldThinPipe().transform([
            new DatePipe('en-US').transform(test.time_start, this.preferencesService.defaultDateFormat.value),
            new DatePipe('en-US').transform(test.time_start, this.preferencesService.defaultTimeFormat.value)
          ])
          : new TitleCasePipe().transform(this.translateService.instant('notStarted')),
        status_name: this.getTestStatusText(test),
        test_group_info: this.getTestDisplayName(test)
      };
    });

    return {
      results: parsedTests,
      count: tests.count
    };
  }

  private getTestStatusText(test: TestListItem): string {
    const statusTranslationKey = TEST_STATUS[test.status?.name?.toUpperCase()]?.translationKey;
    const translatedStatus = !!statusTranslationKey ? this.translateService.instant(statusTranslationKey) : test.status?.name;

    let text = new TitleCasePipe().transform(translatedStatus || '');

    if ((test.status?.name === TEST_STATUS.SYNCING.value) && test.thresholds_received)
      return new TitleCasePipe().transform(TEST_STATUS.FINISHED.translationKey);

    if (!test.offline || test.status?.name !== TEST_STATUS.SYNCING.value)
      return text;

    return text + ` (${(this.testSyncProgressMap[test.id]?.progress * 100).toFixed()}%)`;
  }

  private getTestDisplayName(test: TestListItem): string {
    let group = test.test_group;

    if (test.is_external) {
      if (group?.protocol)
        return `External Test (${group?.group?.name} (${group?.protocol?.name}))`;

      return `External Test (${group?.group?.name})`;
    }

    if (group?.strategy && group?.protocol)
      return `${group.strategy.name} (${group.protocol.name})`;
    else if (group?.strategy)
      return `${group.group?.name} (${group.strategy.name})`;
    else if (group?.protocol)
      return `${group.group?.name} (${group.protocol.name})`;
    else
      return group?.group.name;
  }

  isBundleTest = (test: TestListItem) => !!test.test_bundle?.id;

  isExternalTest = (test: TestListItem) => !!test.is_external;

  isRecurringTest = (test: TestListItem) => !!test.test_schedule;

  testsTrackBy = (index: number, test: TestListItem) => {
    return test.id + test.status?.name + test.using_device;
  };

  private openHTMLReport(test: TestListItem, action: ListItemAction) {
    const bundle = test.test_bundle;

    if (!bundle || (bundle?.status?.name !== TEST_STATUS.FINISHED.value))
      return this.testsService.openHTMLReport(test.id, this.patientAge);

    if (this.testFormService.isTestPartOfCSuite(test))
      return this.testBundlesService.openHTMLReport(bundle.id, 'singlepage', this.patientAge);

    action.isLoading = true;
    this.testBundlesService.getBundleReportMode(bundle.id).pipe(
      finalize(() => (action.isLoading = false))
    ).subscribe(mode => {
      if (mode.singlepage)
        this.testBundlesService.openHTMLReportWithDialog(bundle.id, this.patientAge);
      else
        this.testBundlesService.openHTMLReport(bundle.id, 'full', this.patientAge);
    }, err => this.testsService.openHTMLReport(test.id, this.patientAge));
  }

  private downloadPDFReport(test: TestListItem, action: ListItemAction) {
    action.isLoading = true;
    const bundle = test.test_bundle;

    if (!bundle || (bundle?.status?.name !== TEST_STATUS.FINISHED.value))
      return this.testsService.downloadPDFReport(test).pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));

    if (this.testFormService.isTestPartOfCSuite(test))
      return this.testBundlesService.downloadPDFReport(bundle.id, 'singlepage').pipe(
        finalize(() => action.isLoading = false))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));;

    this.testBundlesService.getBundleReportMode(bundle.id).subscribe(mode => {
      if (mode.singlepage)
        this.testBundlesService.openReportDialog().then(result => {
          this.isLoading = true;
          this.testBundlesService.downloadPDFReport(bundle.id, result.confirmed ? 'singlepage' : 'full').pipe(
            finalize(() => {
              action.isLoading = false;
              this.isLoading = false;
            }))
            .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
        });
      else {
        this.testBundlesService.downloadPDFReport(bundle.id, 'full').pipe(
          finalize(() => (action.isLoading = false)))
          .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
      }
    }, err => {
      this.testsService.downloadPDFReport(test).pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
    });
  }

  private downloadDicomReport(test: TestListItem, action: ListItemAction) {
    action.isLoading = true;
    const bundle = test.test_bundle;

    if (!bundle || (bundle?.status?.name !== TEST_STATUS.FINISHED.value))
      return this.testsService.downloadDicomReport(test.id).pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));

    if (this.testFormService.isTestPartOfCSuite(test))
      return this.testBundlesService.downloadDicomReport(bundle.id, 'singlepage').pipe(
        finalize(() => action.isLoading = false))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));;

    this.testBundlesService.getBundleReportMode(bundle.id).subscribe(mode => {
      if (mode.singlepage)
        this.testBundlesService.openReportDialog().then(result => {
          this.isLoading = true;
          this.testBundlesService.downloadDicomReport(bundle.id, result.confirmed ? 'singlepage' : 'full').pipe(
            finalize(() => {
              action.isLoading = false;
              this.isLoading = false;
            }))
            .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
        });
      else
        this.testBundlesService.downloadDicomReport(bundle.id, 'full').pipe(
          finalize(() => (action.isLoading = false)))
          .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
    }, err => {
      this.testsService.downloadDicomReport(test.id).pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
    });
  }

  private downloadImageReport(test: TestListItem, action: ListItemAction) {
    action.isLoading = true;
    const bundle = test.test_bundle;

    if (!bundle || (bundle?.status?.name !== TEST_STATUS.FINISHED.value))
      return this.testsService.downloadImageReport(test).pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));

    if (this.testFormService.isTestPartOfCSuite(test))
      return this.testBundlesService.downloadImageReport(bundle.id, 'singlepage').pipe(
        finalize(() => action.isLoading = false))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));;

    this.testBundlesService.getBundleReportMode(bundle.id).subscribe(mode => {
      if (mode.singlepage)
        this.testBundlesService.openReportDialog().then(result => {
          this.isLoading = true;
          this.testBundlesService.downloadImageReport(bundle.id, result.confirmed ? 'singlepage' : 'full').pipe(
            finalize(() => {
              action.isLoading = false;
              this.isLoading = false;
            }))
            .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
        });
      else {
        this.testBundlesService.downloadImageReport(bundle.id, 'full').pipe(
          finalize(() => (action.isLoading = false)))
          .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
      }
    }, err => {
      return this.testsService.downloadImageReport(test).pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
    });
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.isLoading = true;

    this.testsService.getAll(this.patientId, this.currentPageSize, this.currentPageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.term = term;
      this.tests$.next(this.parseTests(res));
    });
  };

  private setTestsPing() {
    this.pingInterval = setInterval(() => {
      const shouldPing = !!this.testsSnapshot?.results.some(test => ![TEST_STATUS.FINISHED.value, TEST_STATUS.FAILED.value].includes(test.status?.name));

      if (shouldPing)
        this.getTests(this.patientId, this.currentPageSize, this.currentPageIndex, this.term, false);
    }, 5000);
  }

  private setButtonList() {
    this.preferencesService.get().subscribe(preferences => {
      const defaultTestDisabled = this.testDefaultService.isDefaultTestDisabled(preferences);

      const deviceTooltipShown = preferences.device
        && (!!preferences.device.administering_test || !preferences.device.available);

      this.buttonList = [{
        translationKey: 'newDefaultTest',
        action: () => {
          this.isLoading = true;
          this.testDefaultService.createDefaultTest(this.patientId, preferences)
            .then(() => this.isLoading = false)
            .catch(() => this.isLoading = false);
        },
        disabled: defaultTestDisabled,
        iconClass: 'fas fa-file',
        tooltipTranslationKey: deviceTooltipShown
          ? 'defaultDeviceInUse'
          : defaultTestDisabled
            ? 'defaultTestDisabledMessage'
            : null
      }, {
        translationKey: 'uploadTextExternalButton',
        action: () => this.importTest(),
        disabled: false,
        iconClass: 'fas fa-upload',
        tooltipTranslationKey: null
      }];
    });
  }
}