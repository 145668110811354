// native
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';

// services
import { PreferencesService } from 'app/core/services/preferences.service';

// models
import { CenterContainerTitle, Tab, WorkItem } from '../../models';

@Component({
  selector: 'app-work-item-details',
  templateUrl: './work-item-details.component.html'
})
export class WorkItemDetailsComponent implements OnInit {

  @Input() item: WorkItem;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  title: CenterContainerTitle = {};
  languageSubscription: Subscription;

  activeTab = 0;
  tabs: Tab[] = [
    {
      translationKey: 'basicInfo',
      action: () => this.setActiveTab(0),
      isActive: true
    },
    {
      translationKey: 'additionalInfo',
      action: () => this.setActiveTab(1),
      isActive: false
    }
  ];

  constructor(
    private translateService: TranslateService,
    public preferencesService: PreferencesService
  ) { }

  ngOnInit(): void {
    this.setTranslations();
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.setTranslations();
    });
  }

  private setTranslations() {
    this.title.thin = this.translateService.instant('view');
    this.title.bold = this.translateService.instant('workItem');
  }

  setActiveTab(index: number) {
    this.activeTab = index;
    this.tabs.forEach((tab: Tab, i: number) => {
      tab.isActive = i === index;
    });
  }
}
