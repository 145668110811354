<app-list-header
  name="controller"
  [canCreate]="authService.isOfficeGroupAdmin"
  (onCreate)="create()"
  (onSearch)="search($event)">
</app-list-header>

<app-item-list
  [columns]="columns"
  [actions]="actions"
  [items$]="controllers$"
  [clickable]="authService.isOfficeGroupAdmin"
  (onItemClick)="edit($event)"
  (onChangePage)="changePage($event.pageIndex, $event.pageSize)">
</app-item-list>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>
