// native
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html'
})
export class ListHeaderComponent implements OnInit, OnDestroy {

  @Input() name: string;
  @Input() canCreate: boolean = true;
  @Input() canSearch: boolean = true;

  @Output() onSearch?: EventEmitter<string> = new EventEmitter<string>();
  @Output() onCreate?: EventEmitter<void> = new EventEmitter<void>();

  term: string;
  typeaheadSearch$: Subject<void> = new Subject<void>();
  cancelTypeahead$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.initTypeahead();
  }

  initTypeahead() {
    this.typeaheadSearch$.pipe(
      switchMap(() => timer(750)),
      takeUntil(this.cancelTypeahead$)
    ).subscribe(() => {
      this.search();
    });
  }

  ngOnDestroy(): void {
    this.cancelTypeahead$.next();
    this.cancelTypeahead$.complete();
  }

  public search() {
    if (!this.term) {
      this.clearSearch();
      return;
    }

    this.onSearch.emit(this.term);
  }

  public onEnter() {
    this.cancelTypeahead$.next();
    this.search();
    this.initTypeahead();
  }

  public clearSearch() {
    this.onSearch.emit(null);
    this.term = null;
  }
}
