// native
import { SafeUrl } from '@angular/platform-browser';
import { Observable, Subscription, of, ReplaySubject } from 'rxjs';
import { startWith, catchError } from 'rxjs/operators';

// addon
import * as uuid from 'uuid';

// constants
import { IMPORT_STATUS } from '../constants';

export interface ImportJob {
  id: string;
  entityId: number;
  name: string;
  status$: Observable<IMPORT_STATUS_FLAG>;
  error$: Observable<string>;
  snapshot: ImportJobSnapshot;
  run(): void;
}

type IMPORT_STATUS_FLAG = 'QUEUED' | 'UPLOADING' | 'UPLOAD_FAILED' | 'NOT_STARTED' | 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED';

interface ImportJobSnapshot {
  status: IMPORT_STATUS_FLAG;
  error: string;
  progress: number;
}

export class ImportFileJob implements ImportJob {
  public id: string;
  public name: string;
  public status$: Observable<IMPORT_STATUS_FLAG>;
  public error$: Observable<string>;
  public snapshot: ImportJobSnapshot;

  protected statusSource = new ReplaySubject<IMPORT_STATUS_FLAG>(1);
  protected errorSource = new ReplaySubject<string>(1);
  protected uploadSubscription: Subscription;

  constructor(
    public formData: FormData,
    public action: Function,
    public file: File,
    public entityId: number
  ) {
    this.id = uuid.v4();
    formData.append('upload_id', this.id);
    this.name = file.name;
    this.snapshot = { error: '', progress: 0, status: <IMPORT_STATUS_FLAG>IMPORT_STATUS.QUEUED.value };
    this.status$ = this.statusSource.pipe(
      startWith(<IMPORT_STATUS_FLAG>IMPORT_STATUS.QUEUED.value)
    );
    this.error$ = this.errorSource.pipe(
      startWith('')
    );
  }

  run(): void {
    if (this.file.size === 0) {
      this.setError('fileEmptyError');
      this.setStatus(<IMPORT_STATUS_FLAG>IMPORT_STATUS.UPLOAD_FAILED.value);
      return;
    }

    this.setStatus(<IMPORT_STATUS_FLAG>IMPORT_STATUS.UPLOADING.value);
    this.uploadSubscription = this.action(this.formData).pipe(
      catchError(err => {
        this.setError('fileUploadError');
        this.setStatus(<IMPORT_STATUS_FLAG>IMPORT_STATUS.UPLOAD_FAILED.value);
        return of(err);
      }),
    ).subscribe(() => {
      // after upload sucess next status in line is set (extraction not started)
      this.setStatus(<IMPORT_STATUS_FLAG>IMPORT_STATUS.NOT_STARTED.value);
    });
  }

  protected setStatus(status: IMPORT_STATUS_FLAG) {
    const finished = [IMPORT_STATUS.NOT_STARTED.value, IMPORT_STATUS.UPLOAD_FAILED.value].includes(status);
    this.snapshot.status = status;
    this.statusSource.next(status);

    if (finished) {
      this.statusSource.complete();
      this.errorSource.complete();
    }
  }

  protected setError(error: string) {
    this.errorSource.next(error);
    this.snapshot.error = error;
  }
}

export interface ImportItem {
  extracted_data: any[];
  extraction_status: string;
  extraction_status_client?: string;
  extraction_verified: boolean;
  error_client?: string;
  id: number;
  test: number;
  test_report: string;
  upload_id: string;
  patient: number;
}

export interface ImportItemEvent {
  data: ImportItem;
  type: string;
}

export interface ImportItemDetails {
  extraction_status: string;
  extraction_verified: boolean;
  group: string;
  patient: number;
  protocol: string;
  test_date: string;
  test_report: string;
  upload_id: string;
  low_confidence_sensitivities?: LowConfidenceSensitivity[];
}

export interface LowConfidenceSensitivity {
  page_id: number;
  index: number;
  value: number;
  imageSrc?: SafeUrl;
}

export interface VerifyImportPayload {
  group: string;
  protocol: string;
  test_date: string;
  updated_sensitivities: LowConfidenceSensitivity[];
}
