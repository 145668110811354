// native
import { Component } from '@angular/core';

// model
import { Tab } from 'app/models';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html'
})
export class HelpComponent {

  activeTab: number = 0;
  tabs: Tab[] = [
    {
      translationKey: 'videos',
      action: () => this.setActiveTab(0),
      isActive: true
    },
    {
      translationKey: 'faq',
      action: () => this.setActiveTab(1),
      isActive: false
    }
  ];

  constructor() { }

  setActiveTab(index: number) {
    this.activeTab = index;
    this.tabs.forEach((tab: Tab, i: number) => {
      this.tabs[i].isActive = i === index;
    });
  }
}
