// native
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// service
import { ApiService } from './api.service';

// models
import { Office, OfficeRequest, PaginatedItems } from '../../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, API_OFFICES_PATH } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class OfficesService {

  constructor(
    public apiService: ApiService
  ) { }

  getAllPaginated(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<Office>> {
    let path = `${API_OFFICES_PATH}?limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    return this.apiService.get(path) as Observable<PaginatedItems<Office>>;
  }

  getAll(): Observable<PaginatedItems<Office>> {
    return this.apiService.get(API_OFFICES_PATH) as Observable<PaginatedItems<Office>>;
  }

  getOne(id: number): Observable<Office> {
    return this.apiService.get(this.getUrl(id)).pipe(map(res => res as Office));
  }

  create(body: OfficeRequest): Observable<Office> {
    return this.apiService.post(API_OFFICES_PATH, body).pipe(map(res => res as Office));
  }

  update(id: number, body: OfficeRequest): Observable<Office> {
    return this.apiService.put(this.getUrl(id), body).pipe(map(res => res as Office));
  }

  delete(id: number): Observable<string> {
    return this.apiService.delete(`${API_OFFICES_PATH}${id}/`).pipe(map(res => res as string));
  }

  setOfficeAdmin(officeId: number, employeeId): Observable<void> {
    return this.apiService.post(`${API_OFFICES_PATH}${officeId}/assign-admin/`, { employee: employeeId }).pipe(map(res => res as any));
  }

  getUrl(id: number) {
    return `${API_OFFICES_PATH}${id}/`;
  }
}