// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { ContactService } from 'app/core/services/contact.service';

//models
import { CenterContainerTitle, ContactBody } from 'app/models';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

  form: UntypedFormGroup;
  title: CenterContainerTitle = {};

  isLoading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private contactService: ContactService,
    private router: Router,
    public toastService: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.translateService.get('contact').subscribe(res => {
      this.initializeForm();
      this.title.thin = this.translateService.instant('contact');
    });
  }

  private initializeForm() {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: [''],
      message: ['', [Validators.required]]
    });
  }

  onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    const { name, email, phone_number, message } = this.form.value;
    const body: ContactBody = { name, email, phone_number, message };

    this.isLoading = true;
    this.contactService.contact(body).subscribe(
      (response: any) => {
        this.isLoading = false;
        this.toastService.success(this.translateService.instant('thanksForContact'));
        this.close();
      }, response => {
        this.isLoading = false;
        response && response.error && this.toastService.error(response.error);
      }
    );
  }

  close() {
    this.router.navigate(['/patients']);
  }
}
