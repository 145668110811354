// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';

// services
import { UtilityService } from '../core/services/utility.service';

// animations
import { fadeTransition } from '../animations/router-animations';

// models
import { CenterContainerTitle } from '../models';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  animations: [fadeTransition()],
  host: { '[@fadeTransition]': '' }
})
export class AboutComponent implements OnInit, OnDestroy {

  title: CenterContainerTitle = {};
  languageSubscription: Subscription;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    public utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.setTranslations();
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.setTranslations();
    });
  }

  private setTranslations() {
    this.title.thin = this.translateService.instant('about');
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  close() {
    this.router.navigate(['/patients']);
  }
}
