// native
import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  host: {
    '(document:click)': 'onOutsideDetailsClick($event)'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploaderComponent),
      multi: true
    }
  ]
})
export class FileUploaderComponent implements OnInit, ControlValueAccessor {

  @Input() allowedFileExtensions: string;
  @Input() maxFileSize: number = 1000;
  @Input() maxFileCount: number = 20;
  @Input() multiple: boolean = false;

  @Output() onFileCountError: EventEmitter<void> = new EventEmitter<void>();
  @Output() onFileSizeError: EventEmitter<void> = new EventEmitter<void>();
  @Output() onFileTypeError: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('defaultFileUploader') fileUploader: ElementRef;
  @ViewChild('detailsEdit') detailsEditElement: ElementRef;

  isUploading: boolean = false;
  isUploaded: boolean = false;
  percentageDone: number = 0;

  allowedFileMimeTypes: string = '';

  files: File[] = [];
  detailsShown = false;

  value: string;
  onChange: (files: File[]) => any;
  onTouched: () => any;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: string) {
    this.value = value;
  }

  ngOnInit() {
    this.calculateAllowedMimeTypes(this.allowedFileExtensions);
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    this.onFileSelect(files);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    event.preventDefault();
  }

  onOutsideDetailsClick(event) {
    if (!this.detailsEditElement?.nativeElement.contains(event.target)) {
      this.detailsShown = false;
    }
  }

  onUploaderClick() {
    this.fileUploader.nativeElement.click();
  }

  onFileSelect(files: FileList) {
    this.onTouched();
    if (files.length && this.areFilesValid(Array.from(files))) {
      this.files = this.files.concat(Array.from(files));
      this.onChange(this.files);
    }
  }

  onFileRemove(event: Event, fileIndex) {
    event.preventDefault();
    this.detailsShown = false;

    this.files.splice(fileIndex, 1);
    this.onChange(this.files);
  }

  onEditDetails(event) {
    event.preventDefault();
    this.detailsShown = true;
  }

  private areFilesValid(files: File[]): boolean {
    if (files.length > this.maxFileCount) {
      this.onFileCountError.emit();
      return false;
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > this.maxFileSize * 1100) {
        this.onFileSizeError.emit();
        return false;
      }

      if (!files[i].type || !this.allowedFileMimeTypes.includes(files[i].type)) {
        this.onFileTypeError.emit();
        return false;
      }
    }

    return true;
  }

  private calculateAllowedMimeTypes(allowedFileExtensions: string): void {
    const extension = allowedFileExtensions.split(',');

    extension.forEach(ext => {
      switch (ext) {
        case 'jpg':
        case 'jpeg':
          this.allowedFileMimeTypes = this.allowedFileMimeTypes.concat(',image/jpeg');
          break;
        case 'gif':
          this.allowedFileMimeTypes = this.allowedFileMimeTypes.concat(',image/gif');
          break;
        case 'png':
          this.allowedFileMimeTypes = this.allowedFileMimeTypes.concat(',image/png');
          break;
        case 'pdf':
          this.allowedFileMimeTypes = this.allowedFileMimeTypes.concat(',application/pdf');
          break;
        case 'xml':
          this.allowedFileMimeTypes = this.allowedFileMimeTypes.concat(',text/xml');
          break;
      }
    });
  }
}
