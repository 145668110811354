<app-list-header
  name="bundle"
  (onCreate)="create()"
  (onSearch)="search($event)">
</app-list-header>

<app-item-list
  [columns]="columns"
  [actions]="actions"
  [items$]="customBundles$"
  (onItemClick)="view($event)"  
  (onChangePage)="changePage($event.pageIndex, $event.pageSize)">
</app-item-list>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>