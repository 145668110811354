import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-direction-arrow',
  templateUrl: './direction-arrow.component.html'
})
export class DirectionArrowComponent {

  @Input() direction: number
  @Input() viewed: boolean

  // added if we want to tweak colors depending on viewed status in the future
  colorViewed = 'black';
  colorUnseen = 'black';
}
