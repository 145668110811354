// native
import { NgModule } from "@angular/core";

// addon
import { GridModule } from "@progress/kendo-angular-grid";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { WorkItemsComponent } from './work-items.component';
import { WorkItemFilterFormComponent } from './work-item-filter-form/work-item-filter-form.component';
import { WorkItemDetailsComponent } from './work-item-details/work-item-details.component';
import { WorkItemsHistoryComponent } from './work-items-history/work-items-history.component';
import { DropDownListFilterComponent } from "./dropdown-list/dropdown-list-filter.component";

@NgModule({
  imports: [
    SharedModule,
    GridModule,
    DropDownsModule
  ],
  declarations: [
    WorkItemsComponent,
    WorkItemFilterFormComponent,
    WorkItemDetailsComponent,
    WorkItemsHistoryComponent,
    DropDownListFilterComponent
  ]
})
export class WorkItemsModule { }