// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// services
import { AuthService } from 'app/core/services/auth.service';
import { UtilityService } from 'app/core/services/utility.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { CenterContainerIcon, CenterContainerTitle, User, UserUpdateRequest } from '../../models';

// constants
import { USER_PASSWORD_REGEX } from '../../constants';

@Component({
  selector: 'app-user-settings-form',
  templateUrl: './user-settings-form.component.html'
})
export class UserSettingsFormComponent implements OnInit {
  title: CenterContainerTitle = {};

  editIcon: CenterContainerIcon = { class: 'edit', action: () => this.onToggleEdit(), active: false };

  user: User;
  form: UntypedFormGroup;
  passwordVisible: boolean = false;
  passwordSuggestMode: boolean = false;

  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private utilityService: UtilityService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.user = this.authService.getCurrentUser();

    this.translateService.get('new').subscribe((res: string) => {
      this.initializeForm();
      this.form.disable();
      this.setTranslations();
    });
  }

  initializeForm() {
    const { username, first_name, last_name, email, phone_number } = this.user;
    this.form = this.formBuilder.group(
      {
        username: [username, [Validators.required]],
        first_name: [first_name, [Validators.required]],
        last_name: [last_name, [Validators.required]],
        email: [email, Validators.compose([Validators.required, Validators.email])],
        phone_number: [phone_number || ''],
        password: [null, [Validators.minLength(15), Validators.pattern(USER_PASSWORD_REGEX)]],
        confirm_password: [null, [Validators.minLength(15)]]
      },
      {
        validator: this.utilityService.checkIfMatchingPasswords(
          'password',
          'confirm_password'
        )
      }
    );
  }

  private setTranslations() {
    this.title.thin = this.translateService.instant('user');
    this.title.bold = this.translateService.instant('settings');
  }

  onPasswordChange() {
    if (this.passwordSuggestMode) {
      this.clearSuggestPassword();
      return;
    }

    if (this.form.value.password || this.form.value.confirm_password) {
      this.form.controls.password.setValidators([Validators.minLength(15), Validators.pattern(USER_PASSWORD_REGEX)]);
      this.form.controls.confirm_password.setValidators(Validators.minLength(15));
    } else {
      this.form.controls.password.setValidators(null);
      this.form.controls.confirm_password.setValidators(null);
    }
  }

  close() {
    this.router.navigate(['/patients']);
  }

  onToggleEdit() {
    this.editIcon.active = !this.editIcon.active;
    this.editIcon.active ? this.form.enable() : this.form.disable();
  }

  onSubmit() {
    if (!this.form.valid)
      return;

    const { email, first_name, last_name, username, phone_number } = this.form.value;

    let body: UserUpdateRequest = {
      id: this.authService.getId(),
      email: email?.toLowerCase ? email.toLowerCase() : null,
      first_name,
      last_name,
      username,
      phone_number: phone_number || null
    };

    const { password, confirm_password } = this.form.value;

    if (!!password) {
      body = {
        ...body,
        password,
        confirm_password: this.passwordSuggestMode ? password : confirm_password
      };
    }

    this.isLoading = true;
    this.authService.updateUserProfile(body).pipe(
      finalize(()=> this.isLoading = false)
    ).subscribe(
      () => this.router.navigate(['/patients']),
      error => this.errorService.handleUserError(this.form, error?.error)
    );
  };

  onSuggestPassword(password: string) {
    this.form.controls['password'].setValue(password);
    this.form.controls['password'].markAsDirty();

    this.form.controls['confirm_password'].setValue(null);
    this.form.controls['confirm_password'].disable();

    this.passwordVisible = true;
    this.passwordSuggestMode = true;
  }

  clearSuggestPassword() {
    this.passwordSuggestMode = false;
    this.form.controls['confirm_password'].enable();
  }
}
