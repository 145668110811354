import { Directive, Inject, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const ORDERED_VALID_INPUTS_MONTHS_FIRST = [
  'MM/DD/YYYY',
  'M/D/YYYY',
  'DD.MM.YYYY',
  'DD/MM/YYYY',
  'D/M/YYYY',
  'DD-MM-YYYY',
  'YYYY/M/D',
  'YYYY/MM/DD',
  'YYYY.MM.DD',
  'YYYY-MM-DD'
];

const ORDERED_VALID_INPUTS_DAYS_FIRST = [
  'DD.MM.YYYY',
  'DD/MM/YYYY',
  'D/M/YYYY',
  'DD-MM-YYYY',
  'MM/DD/YYYY',
  'M/D/YYYY',
  'YYYY/M/D',
  'YYYY/MM/DD',
  'YYYY.MM.DD',
  'YYYY-MM-DD'
];

interface DateParse { dateInput: string[]; }
type DateDisplay = {
  dateInput: string;
  monthYearLabel?: string,
  dateA11yLabel?: string,
  monthYearA11yLabel?: string,
};

class CustomDateFormat {
  private _parse: DateParse = {
    dateInput: ORDERED_VALID_INPUTS_MONTHS_FIRST
  };

  private _display: DateDisplay = {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY'
  };

  set parse(parse: DateParse) {
    this._parse = Object.assign({}, this._parse, parse);
  }

  get parse(): DateParse {
    return this._parse;
  }

  set display(display: DateDisplay) {
    this._display = Object.assign({}, this._display, display);
  }

  get display(): DateDisplay {
    return this._display;
  }

  updateDisplay(displayFormat: string, validInputs: string[]) {
    this.display = { dateInput: displayFormat };
    this.parse = { dateInput: validInputs };
  }
}

@Directive({
  selector: '[datePickerFormat]',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useClass: CustomDateFormat
    }
  ]
})
export class DatePickerFormatDirective {
  @Input() public configDateParse: DateParse;
  @Input() public configDateDisplay: DateDisplay;

  @Input('datePickerFormat')
  set datePickerFormat(displayFormat: string) {
    const daysFirstInputs = [
      'DD.MM.YYYY',
      'DD/MM/YYYY',
      'D/M/YYYY',
      'DD-MM-YYYY'
    ];
    const validInputs = daysFirstInputs.includes(displayFormat) ? ORDERED_VALID_INPUTS_DAYS_FIRST : ORDERED_VALID_INPUTS_MONTHS_FIRST;
    this.matDateFormat.updateDisplay(displayFormat, validInputs);

    // We need this for the first time to tell component about format change
    const value = this.ngControl.value;
    this.ngControl.valueAccessor?.writeValue(value);
  }

  constructor(
    @Inject(MAT_DATE_FORMATS) public matDateFormat: CustomDateFormat,
    @Optional() private ngControl: NgControl
  ) {
  }
}