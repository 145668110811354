// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { NewTestComponent } from "./new-test/new-test.component";
import { TestFormComponent } from "./test-form/test-form.component";
import { TestsComponent } from "./tests.component";
import { ViewTestComponent } from "./view-test/view-test.component";
import { TestsRecurringComponent } from './tests-recurring/tests-recurring.component';
import { ViewTestRecurringComponent } from './view-test-recurring/view-test-recurring.component';
import { EditTestRecurringComponent } from './edit-test-recurring/edit-test-recurring.component';
import { TestRecurringFormComponent } from './test-recurring-form/test-recurring-form.component';
import { MoveTestComponent } from './move-test/move-test.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TestsComponent,
    NewTestComponent,
    ViewTestComponent,
    TestFormComponent,
    TestsRecurringComponent,
    ViewTestRecurringComponent,
    EditTestRecurringComponent,
    TestRecurringFormComponent,
    MoveTestComponent
  ]
})
export class TestsModule { }