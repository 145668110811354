<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="close.emit()">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

    <app-input-wrapper 
      [control]="form.get('name')" 
      label="{{ 'name' | translate }}"
      identifier="name">
      <input 
        class="form_control" 
        type="text" 
        formControlName="name"
        name="name"
        id="name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('device_type')" 
      label="{{ 'deviceType' | translate }}">
      <mat-select 
        class="form_control"
        formControlName="device_type"
        disableOptionCentering 
        panelClass="dropdown"
        name="device_type"
        aria-label="{{ 'deviceType' | translate }}">
        <mat-option [value]="deviceTypeOffice">
          {{ 'officeDevice' | translate }}
        </mat-option>
        <mat-option [value]="deviceTypeEyeTracking">
          {{ 'officeTrackingDevice' | translate }}
        </mat-option>
        <mat-option [value]="deviceTypeOfficeChoice">
          {{ 'officeChoiceDevice' | translate }}
        </mat-option>
        <mat-option [value]="deviceTypeHome">
          {{ 'homeDevice' | translate }}
        </mat-option>
        <mat-option [value]="deviceTypeHomeEyeTracking">
          {{ 'homeTrackingDevice' | translate }}
        </mat-option>
        <mat-option *ngIf="authService.isResearchModeEnabled" [value]="deviceTypeCamera">
          {{ 'cameraDevice' | translate }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('offices')" 
      label="{{ 'office' | translate }}">
      <mat-select 
        multiple
        class="form_control"
        formControlName="offices"
        disableOptionCentering 
        panelClass="dropdown dropdown--multiple"
        name="offices"
        aria-label="{{ 'office' | translate }}">
        <mat-option *ngFor="let office of offices" [value]="office.id">
          {{ office.name }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <app-input-wrapper
      *ngIf="authService.isPacsIntegrationUser && devicePools.length" 
      [control]="form.get('pacs_integration_device_pool')" 
      label="{{ 'devicePool' | translate }}">
      <mat-select 
        class="form_control"
        formControlName="pacs_integration_device_pool" 
        disableOptionCentering 
        panelClass="dropdown"
        name="pacs_integration_device_pool"
        aria-label="{{ 'devicePool' | translate }}">
        <mat-option [value]="null"></mat-option>
        <mat-option *ngFor="let pool of devicePools" [value]="pool.id">
          {{ pool.name }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <div *ngIf="device?.id" class="text--left">
      <br>
      <ng-container *ngIf="device.user || (device.device_type !== deviceTypeCamera), else qrTemplate">
        <label>{{ 'code' | translate }}:&nbsp;</label>
        <span>{{ device.code }}</span>
      </ng-container>
      <ng-template #qrTemplate>
        <label>{{ 'qrCode' | translate }}:&nbsp;</label>
        <br><br>
        <qrcode [qrdata]="qrDataString" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
      </ng-template>
      <br>
      <br>

      <label>{{ 'inUse' | translate }}?:&nbsp;</label>
      <span>{{ device.in_use ? 'yes' : 'no' }}</span>

      <br>
      <br>

      <label>{{ 'claimed' | translate }}?:&nbsp;</label>
      <span>{{ device.user ? 'yes' : 'no' }}</span>
    </div>

    <button 
      class="button button--primary form_button"
      type="submit"
      [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>
    
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>