<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [streamable]="false"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [deviceActionLoading]="deviceActionLoading"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column monitor_container-column--big">
    <div class="monitor_graph-box monitor_graph-box--single">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        class="monitor_circle-box" 
        [percentage]="isTestDone ? 100 : 0">
      </app-percentage-circle>
    </div>
  </div>
</div>

