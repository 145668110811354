import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent {
  constructor() { }

  @Input() transparent = false;
  @Input() blank = false;
}
