// native
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

// service
import { ApiService } from './api.service';
import { UtilityService } from './utility.service';

// models
import { PaginatedItems, SupportDevice, GridItem, SupportUser, SupportOffice, SupportCreateOfficeGroupRequest, SupportCreateOfficeGroupResponse } from '../../models';

// constants
import { API_OFFICE_GROUP_PATH, API_SUPPORT_REPORTS_PATH, API_SUPPORT_USERS_PATH, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  private clientToServerFilterMap = {
    'eq': 'iexact',
    'contains': 'icontains',
    'startswith': 'istartswith',
    'endswith': 'iendswith',
    'gte': 'gte',
    'lte': 'lte'
  };

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private utilityService: UtilityService
  ) { }

  getDevices(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, sort: SortDescriptor[], filter: CompositeFilterDescriptor): Observable<GridItem<SupportDevice>> {
    let path = this.calcPathUrl(`${API_SUPPORT_REPORTS_PATH}active-devices/?limit=${pageSize}`, pageSize, pageIndex, sort, filter);

    return this.apiService.get(path).pipe(map((res: PaginatedItems<SupportDevice>) => {
      return {
        data: res.results,
        total: res.count
      };
    })) as Observable<GridItem<SupportDevice>>;
  }

  getUsers(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, sort: SortDescriptor[], filter: CompositeFilterDescriptor): Observable<GridItem<SupportUser>> {
    let path = this.calcPathUrl(`${API_SUPPORT_REPORTS_PATH}active-users/?limit=${pageSize}`, pageSize, pageIndex, sort, filter);

    return this.apiService.get(path).pipe(map((res: PaginatedItems<SupportUser>) => {
      return {
        data: res.results,
        total: res.count
      };
    })) as Observable<GridItem<SupportUser>>;
  }

  getOffices(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, sort: SortDescriptor[], filter: CompositeFilterDescriptor): Observable<GridItem<SupportOffice>> {
    let path = this.calcPathUrl(`${API_SUPPORT_REPORTS_PATH}active-offices/?limit=${pageSize}`, pageSize, pageIndex, sort, filter);

    return this.apiService.get(path).pipe(map((res: PaginatedItems<SupportOffice>) => {
      return {
        data: res.results,
        total: res.count
      };
    })) as Observable<GridItem<SupportOffice>>;
  }

  private calcPathUrl(basePath: string, pageSize: number, pageIndex: number, sort: SortDescriptor[], filter: any): string {
    let path = basePath;
    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    filter.filters?.forEach((f: FilterDescriptor) => {
      let value = f.value;

      if (f.field === 'date_joined' && !!f.value)
        value = `${this.utilityService.convertClientDateToServerDate(f.value)}${f.operator === 'lte' ? '+23:59:59' : ''}`;

      path = path + `&${f.field}__${this.clientToServerFilterMap[<string>f.operator]}=${value}`;
    });

    if (sort[0]?.field && sort[0]?.dir) {
      const prefix = (sort[0].dir === 'asc') ? '' : '-';
      path = path + `&ordering=${prefix}${sort[0].field}`;
    }

    return path;
  }

  downloadActiveUsersReport() {
    return this.apiService.downloadFile(`${API_SUPPORT_REPORTS_PATH}active-users/xlsx/`);
  }

  createOfficeGroup(body: SupportCreateOfficeGroupRequest): Observable<SupportCreateOfficeGroupResponse> {
    return this.apiService.post(API_OFFICE_GROUP_PATH, body) as Observable<SupportCreateOfficeGroupResponse>;
  }

  setAsAdmin(userId: number): Observable<void> {
    return this.apiService.post(`${API_SUPPORT_USERS_PATH}${userId}/assigntooga/`).pipe(map(() => null));
  }

  changeEmail(userId: number, body: { email: string; }): Observable<void> {
    return this.apiService.post(`${API_SUPPORT_USERS_PATH}${userId}/updateemail/`, body).pipe(map(() => null));
  }
}
