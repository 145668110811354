<div class="controls_container">
  <div 
    class="controls_navigation"
    [ngClass]="{ 'vis-hidden': !isSkipBackwardShown() }">
    <i 
      class="fas fa-step-backward"
      matTooltip="{{ 'skipBackward' | translate | titlecase }}"
      (click)="onBackwardClick()">
    </i>
  </div>

  <div class="controls_play">
    <i 
      class="fas"
      [matTooltip]="isPaused ? ('playTest' | translate | titlecase) : ('pauseTest' | translate | titlecase)"
      [ngClass]="{ 
        'fa-play-circle': isPaused, 
        'fa-pause-circle': !isPaused,
        'hidden': !isPlayPauseShown() }"
      (click)="onPlayPauseClick()">
    </i>
    <i 
      class="fas fa-undo-alt"
      matTooltip="{{ 'restartTest' | translate | titlecase }}"
      [ngClass]="{ 'vis-hidden': !isRestartShown() }"
      (click)="onRestartClick()">
    </i>
  </div>

  <div 
    class="controls_navigation" 
    [ngClass]="{ 'vis-hidden': !isSkipForwardShown() }">
    <i 
      class="fas fa-step-forward"
      matTooltip="{{ 'skipForward' | translate | titlecase }}"
      (click)="onForwardClick()">
    </i>
  </div>
</div>
