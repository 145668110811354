// native
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// services
import { SupportService } from 'app/core/services/support.service';

// models
import { CenterContainerTitle, SupportCreateOfficeGroupRequest } from '../../models';

@Component({
  selector: 'app-support-account-form',
  templateUrl: './support-account-form.component.html'
})
export class SupportAccountFormComponent implements OnInit {

  title: CenterContainerTitle = {};
  form: FormGroup;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private supportService: SupportService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {
    this.translateService.get('account').subscribe(() => this.setTranslations());

    this.initializeForm();
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('account');
    this.title.thin = this.translateService.instant('create');
  }

  private initializeForm() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      first_name: [null, Validators.required],
      last_name: [null, Validators.required],
      username: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      waggoner_module: [false]
    });
  }

  public onSubmit() {
    if (!this.form.valid)
      return;

    let { name, first_name, last_name, username, email, waggoner_module } = this.form.value;

    const body: SupportCreateOfficeGroupRequest = {
      name,
      user: {
        first_name,
        last_name,
        username,
        email: email?.toLowerCase ? email.toLowerCase() : null
      },
      waggoner_module
    };

    this.isLoading = true;

    this.supportService.createOfficeGroup(body).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(response => {
      this.closeForm();

      const successMessage = `<b>${this.escapeHTML(response.name)}</b> 
       ${this.translateService.instant('officeGroupCreateSuccessMessage')}: <br><br> 
       ${this.translateService.instant('username')}: <br> <b>${this.escapeHTML(response.user.username)}</b><br>
       ${this.translateService.instant('password')}: <br> <b>${this.escapeHTML(response.user.password)}</b>`;

      this.toastService.success(successMessage, null, {
        disableTimeOut: true,
        tapToDismiss: false,
        enableHtml: true,
        closeButton: true
      });
    }, error => {
      if (error?.user?.username && error.user.username[0] === 'This username is taken, please use another one.')
        return this.toastService.error(this.translateService.instant('officeGroupDuplicateUsernameMessage'));

      if (error?.user?.email && error.user.email[0] === 'This email address is taken, please use another one.')
        return this.toastService.error(this.translateService.instant('officeGroupDuplicateEmailMessage'));

      if (error?.name && error.name[0] === 'office group with this name already exists.')
        return this.toastService.error(this.translateService.instant('officeGroupDuplicateGroupMessage'));

      this.toastService.error(this.translateService.instant('officeGroupCreateErrorMessage'));
    });
  }

  closeForm() {
    this.router.navigate(['/support/offices']);
  }

  private escapeHTML(htmlStr: string) {
    return htmlStr.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#39;");
  }
}