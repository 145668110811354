// native
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-wrapper',
  templateUrl: './input-wrapper.component.html',
})
export class InputWrapperComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() identifier: string;
  @Input() control: AbstractControl;
  @Input() isIndent: boolean = false;

  @Input() placeholderBold: string = '';
  @Input() placeholderThin: string = '';
  @Input() forceMinimized: boolean = false;
  @Input() useLargeSpacing: boolean = false;
  @Input() hasPlaceholderOffset: boolean = false;
  @Input() infoIconText: string = '';
  @Input() infoIconCheckbox: boolean = false;

  placeholderMinimized: boolean = false;

  constructor(
    private element: ElementRef
  ) { }

  ngOnInit(): void {
    if (!this.placeholderBold && !this.placeholderThin)
      this.calculatePlaceholder();

    if (this.label)
      this.label = this.label.charAt(0).toUpperCase() + this.label.slice(1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.label?.currentValue && !changes.placeholderBold && !changes.placeholderThin)
      this.calculatePlaceholder();
  }

  private calculatePlaceholder() {
    if (!this.label)
      return;

    const placeholderWords = this.label.split(' ');
    if (placeholderWords.length === 1) {
      this.placeholderBold = this.label;
      return;
    }

    const lastIndex = this.label.lastIndexOf(' ');

    this.placeholderBold = this.label.substring(0, lastIndex + 1);

    const thin = this.label.substring(lastIndex + 1, this.label.length);
    this.placeholderThin = thin.charAt(0).toUpperCase() + thin.slice(1);
  }

  onInputClick() {
    this.placeholderMinimized = true;
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (!this.element.nativeElement.contains(event.target))
      this.placeholderMinimized = false;
  }
}
