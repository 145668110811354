// native
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// service
import { ApiService } from './api.service';

// models
import { OfflineController, PaginatedItems } from '../../models';

// constants
import {
  API_OFFLINE_CONTROLLERS_PATH, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE,
} from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class OfflineControllersService {
  constructor(
    private apiService: ApiService
  ) { }

  create(offlineController: OfflineController): Observable<OfflineController> {
    return this.apiService.post(API_OFFLINE_CONTROLLERS_PATH, offlineController) as Observable<OfflineController>;
  }

  delete(id: number): Observable<void> {
    return this.apiService.delete(`${API_OFFLINE_CONTROLLERS_PATH}${id}/`).pipe(map(_ => null));
  }

  getAll(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<OfflineController>> {
    let path = `${API_OFFLINE_CONTROLLERS_PATH}?limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    return this.apiService.get(path) as Observable<PaginatedItems<OfflineController>>;
  }

  getOne(id: number): Observable<OfflineController> {
    return this.apiService.get(`${API_OFFLINE_CONTROLLERS_PATH}${id}/`) as Observable<OfflineController>;
  }

  update(id: number, body: OfflineController): Observable<OfflineController> {
    return this.apiService.put(`${API_OFFLINE_CONTROLLERS_PATH}${id}/`, body) as Observable<OfflineController>;
  }
}
