import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {
  @Input('stepsLabels') stepsLabels;
  @Input('stepsActions') stepsActions;

  constructor() { }

  onClick(breadcrumbIndex: number): void {
    if(breadcrumbIndex < this.stepsActions.length)
      this.stepsActions[breadcrumbIndex]();
  }

  isDisabled(breadcrumbIndex: number) {
    return breadcrumbIndex === this.stepsLabels.length - 1;
  }
}