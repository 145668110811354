// native
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { PerimetryRecord, PlotData, EyeStats, MonitorEvent, PerimetryStimulus } from '../../models';

// constants
import { MONITOR_EVENT_TYPE, MONITOR_INACTIVITY_TIMEOUT } from '../../constants';

@Component({
  selector: 'app-monitor-esterman-test',
  templateUrl: './monitor-esterman.component.html'
})
export class MonitorEstermanComponent extends MonitorBaseComponent {

  eyeStats: EyeStats = new EyeStats();
  eyeData: PlotData[] = [];
  indexList: number[] = [];
  indexSeqNumMap = {};
  count: number = 0;
  layout: any;

  public inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.PERIMETRY * 1000;

  @ViewChild('plotContainer') plotContainer: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    if ((this.device.capabilities?.esterman_algorithm_version > 1) && this.test.monocular)
      return this.router.navigate([`tests/observe/${this.test.id}`]);

    this.monitorTestService.getPerimetryState(this.test.id, true).subscribe(state => {
      this.mergeCurrentState(state.stimuli);
      this.eyeStats.completionPercentage = state.progress.total;
    },
      error => this.toastService.error(error, 'Error'));
  }

  private mergeCurrentState(stimuli: PerimetryStimulus[]) {
    stimuli.forEach(record => this.mergeRecord(record));
  }

  private mergeRecord(record: PerimetryStimulus) {
    const existingRecordIndex = this.indexList.indexOf(record.index);

    if (existingRecordIndex === -1)
      this.addRecord(record);
    else if (!record.seq_num)
      this.updateRecord(existingRecordIndex, record);
    else if (this.indexSeqNumMap[record.index] <= record.seq_num)
      this.updateRecord(existingRecordIndex, record);

    this.count++;

    this.updateStats(record);
  }

  private addRecord(record: PerimetryStimulus) {
    let existingSnapshot: PlotData = { ...this.eyeData[0] };

    if (!existingSnapshot?.marker)
      existingSnapshot = { ...this.monitorTestService.visualTestInitialPlotData };

    let newSnapshot = { ...existingSnapshot };

    const color = this.getMarkerColor(record);

    newSnapshot = {
      ...existingSnapshot,
      marker: {
        ...existingSnapshot.marker,
        color: [...existingSnapshot.marker.color, color]
      },
      x: [...existingSnapshot.x, record.x],
      y: [...existingSnapshot.y, record.y],
      text: [...existingSnapshot.text, '']
    };

    this.indexList.push(record.index);
    this.indexSeqNumMap[record.index] = record.seq_num;
    this.eyeData = [newSnapshot];
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_PERIMETRY_RECORD) {
      this.mergeRecord((<PerimetryRecord>event.data).stimulus);
      this.eyeStats.completionPercentage = (<PerimetryRecord>event.data).progress.total;
    }
  }

  private updateStats(record: PerimetryStimulus) {
    this.eyeStats.totalProcessedCount++;

    if (record.viewed)
      this.eyeStats.totalViewedCount++;

    if (record.viewed && record.response_time < 0.15)
      this.eyeStats.falsePositiveCount++;
  }

  private updateRecord(index: number, record: PerimetryStimulus) {
    const existingSnapshot: PlotData = { ...this.eyeData[0] };
    const color = this.getMarkerColor(record);

    const newShapshot = { ...existingSnapshot };
    newShapshot.marker.color[index] = color;
    newShapshot.x[index] = record.x;
    newShapshot.y[index] = record.y;
    newShapshot.text[index] = '';

    const updatedSnapshot = {
      ...newShapshot,
      marker: {
        ...newShapshot.marker,
        color: [...newShapshot.marker.color]
      },
      x: [...newShapshot.x],
      y: [...newShapshot.y],
      text: [...newShapshot.text]
    };

    this.eyeData = [updatedSnapshot];
    this.indexSeqNumMap[record.index] = record.seq_num;
  }

  public calculateDimensions() {
    const dimensions = this.monitorTestService.getSinglePlotDimensions(<HTMLElement>this.plotContainer.nativeElement);
    this.layout = { ...this.monitorTestService.estermanLayout, ...dimensions };
  }

  public onToggleGrid() {
    this.isGridShown = !this.isGridShown;

    const newXAxis = { ...this.layout.xaxis, gridcolor: this.isGridShown ? 'grey' : 'transparent' };
    const newYAxis = { ...this.layout.yaxis, gridcolor: this.isGridShown ? 'grey' : 'transparent' };
    this.layout = { ...this.layout, xaxis: newXAxis, yaxis: newYAxis };
  }

  public resetStateAfterReconnection(): void {
    this.indexList = [];
    this.eyeStats = {
      ...this.eyeStats,
      blindspotCount: 0,
      falsePositiveCount: 0,
      falseNegativeCount: 0,
      totalFalseNegativeTestsCount: 0,
      totalLocationCount: 0,
      totalViewedCount: 0,
      totalProcessedCount: 0
    };
    this.indexSeqNumMap = {};
    this.count = 0;
  }
}