// native
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// service
import { ApiService } from './api.service';

// models
import { CustomBundle, CustomBundlePostRequest, PaginatedItems } from '../../models';

// constants
import { API_CUSTOM_BUNDLES_PATH, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class CustomBundlesService {

  constructor(
    public apiService: ApiService
  ) { }

  getAll(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null, patientId: number = null): Observable<PaginatedItems<CustomBundle>> {
    let path = `${API_CUSTOM_BUNDLES_PATH}?limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    if (patientId)
      path = path + `&patient_id=${patientId}`;

    return this.apiService.get(path) as Observable<PaginatedItems<CustomBundle>>;
  }

  getOne(id: number, patientId: number = null): Observable<CustomBundle> {
    let path = `${API_CUSTOM_BUNDLES_PATH}${id}/`;
    if (patientId)
      path = path + `?patient_id=${patientId}`;
    return this.apiService.get(path).pipe(map(res => res as CustomBundle));
  }

  create(body: CustomBundlePostRequest): Observable<CustomBundle> {
    return this.apiService.post(API_CUSTOM_BUNDLES_PATH, body).pipe(map(res => res as CustomBundle));
  }

  delete(id: number): Observable<string> {
    return this.apiService.delete(`${API_CUSTOM_BUNDLES_PATH}${id}/`).pipe(map(res => res as string));
  }
}