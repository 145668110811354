<app-center-container [title]="title">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" [hidden]="isDuoShown">
    <app-input-wrapper 
      [control]="form.get('username')" 
      label="{{ 'emailUsernameOrPhone' | translate }}"
      placeholderBold="{{ 'user' | translate }}"
      placeholderThin="{{ 'name' | translate }}"
      [forceMinimized]="forceMinimizedLabel"
      identifier="email">
      <input 
        autocomplete="off"
        autocorrect="off"
        autocapitalize="none"
        #usernameInput 
        type="text" 
        id="email" 
        class="form_control" 
        formControlName="username"
        name="username">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('password')" 
      label="{{ 'password' | translate }}"
      placeholderBold="{{ 'pass' | translate }}"
      placeholderThin="{{ 'word' | translate }}"
      [forceMinimized]="forceMinimizedLabel"
      [useLargeSpacing]="true"
      identifier="password">
      <input 
        autocomplete="off"
        autocorrect="off"
        autocapitalize="none"
        #passwordInput 
        [type]="passwordVisible ? 'text' : 'password'"
        id="password" 
        class="form_control form_control--indent" 
        formControlName="password"
        name="password">
      <i
        (click)="passwordVisible = !passwordVisible"
        class="form_icon fas"
        [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'">
      </i>
    </app-input-wrapper>

    <button class="button button--primary form_button" type="submit" [disabled]="!form.valid">
      <span class="regular">{{ 'sign' | translate }}</span><span class="text--semibold"> {{ 'in' | translate | titlecase }}</span>
    </button>

    <br>
    <br>

    <a class="form_link" routerLink="/forgot">
      {{ 'forgotPassword' | translate }}?
    </a>
  </form>

  <div class="duo__container" *ngIf="isDuoShown">
    <iframe id="duo_iframe" class="duo__frame">
    </iframe>
  </div>

  <div *ngIf="authService.ssoDomain">
    <button class="button button--primary form_button" (click)="onSsoLogin()">{{'ssoLogin' | translate}}</button>
  </div>
</app-center-container>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>