<div
  #importList
  *ngIf="importItems.length"
  class="import-list_button-container">
  <div 
    class="import-list_button import-list_button--primary"
    (click)="isListShown = !isListShown">
    <i 
      class="fas import-list_expand-icon"
      [ngClass]="{
        'fa-caret-right': !isListShown,
        'fa-caret-down': isListShown
      }">
    </i>
    <span class="import-list_button-text import-list_button-text--hidden-mobile">
      {{ 'importStatus' | translate }}
    </span>
    <i class="import-list_button-icon fas fa-file-import"></i>
    <div 
      *ngIf="notificationCount"
      class="import-list_notification"
      matTooltip="{{ notificationCount + ' ' + ('importListNotification' | translate) }}"
      matTooltipPosition="above">
      {{ notificationCount }}
    </div>
  </div>

  <div *ngIf="isListShown" class="import-list_container">
    <div *ngFor="let item of importItems, trackBy: trackByFn" class="import-list_item">

      <div class="import-list_content">
        <div class="import-list_name">
          {{ item?.test_report }}
        </div>
        <div 
          class="import-list_status"
          [ngClass]="{
            'import-list_status--green': isExtractionSucceeded(item),
            'import-list_status--orange text--blink': isProcessing(item),
            'import-list_status--red': isExtractionFailed(item) || isUploadFailed(item)
          }">
          {{ item?.extraction_status_client || item?.extraction_status }}
        </div>
        <div 
          *ngIf="isUploadFailed(item)"
          class="import-list_error">
          {{ item?.error_client | translate }}
        </div>
      </div>

      <div class="import-list_actions">
        <span
          *ngIf="isVerifyShown(item)" 
          class="import-list_button" 
          (click)="onVerify(item)">
          {{ isExtractionSucceeded(item) ? ('verify' | translate) : ('resolve' | translate) }}
        </span>
        <span 
          *ngIf="isCancelShown(item)" 
          class="import-list_button" 
          (click)="onCancel(item)">
          {{ 'cancel' | translate }}
        </span>
      </div>

    </div>

  </div>

</div>