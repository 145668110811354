// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { CustomBundlesService } from 'app/core/services/custom-bundles.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';
import { AuthService } from 'app/core/services/auth.service';
import { TechallSessionService } from 'app/core/services/techall-session.service';

// models
import { PaginatedItems, ListItemAction, ListItemColumn, CustomBundle } from '../models';

// animation
import { routerTransition } from '../animations/router-animations';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../constants';

interface CustomBundleDisplay {
  name: string;
  displayed_group_info: string;
}

@Component({
  selector: 'app-custom-bundles',
  templateUrl: './custom-bundles.component.html',
  animations: [routerTransition()]
})
export class CustomBundlesComponent implements OnInit {

  columns: ListItemColumn[] = [
    {
      translationKey: 'name',
      fieldName: 'name',
      fontClass: 'bold'
    },
    {
      translationKey: 'testGroups',
      fieldName: 'displayed_group_info',
      fontClass: 'bold'
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'delete',
      execute: (bundle: CustomBundle) => this.delete(bundle),
      visible: () => this.canDelete()
    },
  ];

  customBundles$: BehaviorSubject<PaginatedItems<CustomBundle> | null> = new BehaviorSubject(null);

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading: boolean = false;

  constructor(
    private customBundlesService: CustomBundlesService,
    private testBundlesService: TestBundlesService,
    private router: Router,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private authService: AuthService,
    private techallService: TechallSessionService,
  ) { }

  ngOnInit() {
    this.getCustomBundles(this.currentPageSize, this.currentPageIndex, null);
  }

  getCustomBundles(pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    const patientId = this.techallService.currentSession?.patient.id;
    this.customBundlesService.getAll(pageSize, pageIndex, term, patientId).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((bundles: PaginatedItems<CustomBundle>) => {
      this.customBundles$.next(this.parseCustomBundles(bundles));
    }, error => this.errorService.handleError(error));
  }

  private parseCustomBundles(bundles: PaginatedItems<CustomBundle>): PaginatedItems<CustomBundleDisplay> {
    const parsedBundles = bundles.results.map(bundle => ({
      ...bundle,
      displayed_group_info: this.testBundlesService.getGroupInfo(bundle.test_groups)

    }));

    return {
      results: parsedBundles,
      count: bundles.count
    };
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.term = term;

    this.getCustomBundles(this.currentPageSize, this.currentPageIndex, this.term);
  };

  create() {
    this.router.navigate(['/custom-bundles/new']);
  }

  delete(bundle: CustomBundle) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('deleteCustomBundlePrompt')
    }).then(result => {
      if (result.confirmed)
        this.customBundlesService.delete(bundle.id).subscribe(
          res => this.getCustomBundles(this.currentPageSize, this.currentPageIndex, this.term),
          error => this.errorService.handleError(error?.error));
    });
  }

  canDelete() {
    return this.authService.isOfficeGroupAdmin;
  }

  view(bundle: CustomBundle) {
    this.router.navigate(['custom-bundles/view', bundle.id]);
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getCustomBundles(pageSize, pageIndex, this.term);
  }
}