<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" *ngIf="direction === 1">
  <g fill="none" fill-rule="evenodd">
      <path d="M21-8v32h-32V-8z"/>
      <path [attr.fill]="viewed ? colorViewed : colorUnseen" fill-rule="nonzero" d="M5.408 15.449c.632 0 1.143-.512 1.143-1.143V5.734h2.326a.57.57 0 0 0 .404-.975L5.241.719a.57.57 0 0 0-.808 0L.392 4.759a.572.572 0 0 0 .404.975h2.327v8.572c0 .631.511 1.143 1.143 1.143h1.142z"/>
  </g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 13 12" *ngIf="direction === 2">
  <g fill="none" fill-rule="evenodd">
      <path d="M23 22H-9v-32h32z"/>
      <path [attr.fill]="viewed ? colorViewed : colorUnseen" fill-rule="nonzero" d="M2.022 11.556c.446.446 1.17.446 1.616 0L9.7 5.495l1.645 1.645a.57.57 0 0 0 .975-.404V1.022a.57.57 0 0 0-.571-.572H6.033a.572.572 0 0 0-.404.975l1.646 1.646-6.061 6.06a1.143 1.143 0 0 0 0 1.617l.808.808z"/>
  </g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 16 10" *ngIf="direction === 3">
  <g fill="none" fill-rule="evenodd">
      <path d="M24-11H-8v32h32z"/>
      <path [attr.fill]="viewed ? colorViewed : colorUnseen" fill-rule="nonzero" d="M.551 4.592c0-.632.512-1.143 1.143-1.143h8.572V1.123a.57.57 0 0 1 .975-.404l4.04 4.04a.57.57 0 0 1 0 .808l-4.04 4.041a.572.572 0 0 1-.975-.404V6.877H1.694A1.143 1.143 0 0 1 .551 5.734V4.592z"/>
  </g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 13 12" *ngIf="direction === 4">
  <g fill="none" fill-rule="evenodd">
      <path d="M23-10H-9v32h32z"/>
      <path [attr.fill]="viewed ? colorViewed : colorUnseen" fill-rule="nonzero" d="M2.022.444a1.143 1.143 0 0 1 1.616 0L9.7 6.505l1.645-1.645a.57.57 0 0 1 .975.404v5.714a.57.57 0 0 1-.571.572H6.033a.572.572 0 0 1-.404-.975l1.646-1.646-6.061-6.06a1.143 1.143 0 0 1 0-1.617l.808-.808z"/>
  </g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" *ngIf="direction === 5">
  <g fill="none" fill-rule="evenodd">
      <path d="M21 24V-8h-32v32z"/>
      <path [attr.fill]="viewed ? colorViewed : colorUnseen" fill-rule="nonzero" d="M5.408.551c.632 0 1.143.512 1.143 1.143v8.572h2.326a.57.57 0 0 1 .404.975l-4.04 4.04a.57.57 0 0 1-.808 0l-4.041-4.04a.572.572 0 0 1 .404-.975h2.327V1.694c0-.631.511-1.143 1.143-1.143h1.142z"/>
  </g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 13 12" *ngIf="direction === 6">
  <g fill="none" fill-rule="evenodd">
      <path d="M-10-10h32v32h-32z"/>
      <path [attr.fill]="viewed ? colorViewed : colorUnseen" fill-rule="nonzero" d="M10.978.444a1.143 1.143 0 0 0-1.616 0L3.3 6.505 1.656 4.86a.57.57 0 0 0-.975.404v5.714a.57.57 0 0 0 .571.572h5.715a.572.572 0 0 0 .404-.975L5.725 8.929l6.061-6.06a1.143 1.143 0 0 0 0-1.617l-.808-.808z"/>
  </g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 16 10" *ngIf="direction === 7">
  <g fill="none" fill-rule="evenodd">
      <path d="M-8-11h32v32H-8z"/>
      <path [attr.fill]="viewed ? colorViewed : colorUnseen" fill-rule="nonzero" d="M15.449 4.592c0-.632-.512-1.143-1.143-1.143H5.734V1.123A.57.57 0 0 0 4.76.719l-4.04 4.04a.57.57 0 0 0 0 .808l4.04 4.041a.572.572 0 0 0 .975-.404V6.877h8.572c.631 0 1.143-.511 1.143-1.143V4.592z"/>
  </g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 13 12" *ngIf="direction === 8">
  <g fill="none" fill-rule="evenodd">
      <path d="M-10 22h32v-32h-32z"/>
      <path [attr.fill]="viewed ? colorViewed : colorUnseen" fill-rule="nonzero" d="M10.978 11.556a1.143 1.143 0 0 1-1.616 0L3.3 5.495 1.656 7.14a.57.57 0 0 1-.975-.404V1.022A.57.57 0 0 1 1.252.45h5.715a.572.572 0 0 1 .404.975L5.725 3.071l6.061 6.06c.447.447.447 1.17 0 1.617l-.808.808z"/>
  </g>
</svg>
