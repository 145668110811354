<app-center-container 
  [title]="title" 
  [showClose]="true"
  [icon]="editIcon" 
  (close)="close()">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">

    <app-input-wrapper
      [control]="form.get('first_name')"
      label="{{ 'firstName' | translate }}"
      identifier="first_name">
      <input
        class="form_control" 
        type="text" 
        formControlName="first_name"
        name="first_name"
        id="first_name">
    </app-input-wrapper>

    <app-input-wrapper
      [control]="form.get('last_name')"
      label="{{ 'lastName' | translate }}"
      identifier="last_name">
      <input
        class="form_control" 
        type="text" 
        formControlName="last_name"
        name="last_name"
        id="last_name">
    </app-input-wrapper>

    <app-input-wrapper
      [control]="form.get('username')"
      label="{{ 'username' | translate }}"
      identifier="username">
      <input
        autocorrect="off"
        autocapitalize="none"
        class="form_control" 
        type="text" 
        formControlName="username"
        name="username"
        id="username">
    </app-input-wrapper>

    <app-input-wrapper
      [control]="form.get('email')"
      label="{{ 'email' | translate }}"
      identifier="email">
      <input
        autocorrect="off"
        autocapitalize="none"
        class="form_control" 
        type="email" 
        formControlName="email" 
        name="email"
        id="email">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('phone_number')"
      [hasPlaceholderOffset]="true"
      label="{{ 'phone' | translate }}">
      <ngx-mat-intl-tel-input
        class="form_control"
        [ngClass]="{ 'form_control--disabled': form.disabled }"
        [preferredCountries]="['us']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        formControlName="phone_number">
      </ngx-mat-intl-tel-input>
    </app-input-wrapper>

    <ng-container *ngIf="editIcon.active">
      <app-input-wrapper 
        [control]="form.get('password')" 
        label="{{ 'changePassword' | translate }}"
        identifier="password">
        <input
          autocorrect="off"
          autocapitalize="none"
          class="form_control form_control--indent"
          [type]="passwordVisible ? 'text' : 'password'"
          formControlName='password' 
          id="password" 
          name="password"
          (input)="onPasswordChange()">
        <i
          (click)="passwordVisible = !passwordVisible"
          class="form_icon fas"
          [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'">
        </i>
      </app-input-wrapper>

      <app-password-validator 
        [password]="form.get('password').value || ''" 
        (onSuggestPassword)="onSuggestPassword($event)">
      </app-password-validator>

      <app-input-wrapper 
        *ngIf="!passwordSuggestMode"
        [control]="form.get('confirm_password')" 
        label="{{ 'confirmPassword' | translate }}"
        identifier="confirm_password">
        <input
          autocorrect="off"
          autocapitalize="none"
          class="form_control form_control--indent" 
          [type]="passwordVisible ? 'text' : 'password'"
          formControlName='confirm_password' 
          id="confirm_password"
          name="confirm_password"
          (change)="onPasswordChange()">
        <i
          (click)="passwordVisible = !passwordVisible"
          class="form_icon fas"
          [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'">
        </i>
      </app-input-wrapper>

      <br>

      <button
        type="submit"
        class="button button--primary form_button"
        [disabled]="!form.valid || !form.dirty">
        <span class="regular">{{ 'done' | translate }}</span>
      </button>
    </ng-container>
    
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>