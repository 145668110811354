// native
import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { UtilityService } from 'app/core/services/utility.service';
import { PatientsService } from 'app/core/services/patients.service';

// component
import { StreamComponent } from 'app/shared/components/stream/stream.component';

// models
import { Device, DeviceScreen, MonitorAction, Patient, Test, TestBundle, TranslatableValue } from '../../models';

// constants
import { DEVICE_SCREEN, TEST_ACTION } from '../../constants';

@Component({
  selector: 'app-monitor-dashboard',
  templateUrl: './monitor-dashboard.component.html',
  host: {
    class: 'monitor_container-column'
  }
})
export class MonitorDashboardComponent implements OnChanges, OnInit {

  @ViewChild(StreamComponent) streamComponent: StreamComponent;

  @Input() test: Test;
  @Input() testLoading: boolean;
  @Input() patient: Patient;
  @Input() bundle: TestBundle;
  @Input() isRegionShown: boolean = false;
  @Input() currentRegion: number = null;
  @Input() screen: DeviceScreen;
  @Input() isTestDone: boolean;
  @Input() isTestSyncing: boolean;
  @Input() isSingleImage: boolean;
  @Input() streamable: boolean = true;

  @Input() deviceLoading: boolean;
  @Input() device: Device;
  @Input() batteryPercentage: number;

  @Input() streamPopupVisible: boolean;

  @Input() deviceActionLoading: boolean;

  @Output() onActionClicked: EventEmitter<MonitorAction> = new EventEmitter();
  @Output() onStreamOpen: EventEmitter<void> = new EventEmitter();
  @Output() onStreamClose: EventEmitter<void> = new EventEmitter();

  isTestOffline = false;
  screens: TranslatableValue[];

  constructor(
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public utilityService: UtilityService,
    public patientsService: PatientsService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.screens = Object.values(DEVICE_SCREEN);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.test?.currentValue) {
      this.isTestOffline = changes.test.currentValue.offline;
    }
  }

  onCancelTest() {
    if (!this.isTestOffline) {
      this.onActionClicked.emit(TEST_ACTION.CANCEL);
    }
  }

  getScreenName() {
    const targetScreen = this.screens.find(screen => screen.value === this.screen?.name);
    return new TitleCasePipe()
      .transform(this.translateService.instant(targetScreen?.translationKey || ''));
  }
}
