// native 
import { Injectable } from "@angular/core";

// addon
import * as Sentry from "@sentry/angular-ivy";
import { Integrations } from "@sentry/tracing";

// models
import { UserAuthResponse } from "../../models";

// environment
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  initSentry() {
    if (environment.sentry?.dsn) {
      Sentry.init({
        dsn: environment.sentry.dsn,
        integrations: [
          new Integrations.BrowserTracing({
            tracingOrigins: [/^\//],
            routingInstrumentation: Sentry.routingInstrumentation,
          }),
        ],

        tracesSampleRate: environment.sentry?.tracesSampleRate,
        sampleRate: environment.sentry?.errorsSampleRate,

        release: environment.sentry?.release,
        environment: environment.sentry?.environment,
      });
    }
  }

  setUser(authResponse: UserAuthResponse) {
    Sentry.setUser({
      email: authResponse?.user?.email,
      username: authResponse?.user?.username,
      id: authResponse?.user?.id,
      groups: authResponse?.user?.groups,
      demo_mode: authResponse?.office_group?.demo_mode,
      research_mode: authResponse?.office_group?.research_mode,
      pacs_integration: authResponse?.office_group?.pacs_integration,
      enable_sso: authResponse?.office_group?.enable_sso
    });
  }
}

