<ng-container *ngIf="activeDevices?.length">
  <div
    class="device-dashboard_header" 
    (click)="toggleDashboard()"
    [ngClass]="{'device-dashboard_header--collapsed': collapsed}">
    <i 
      class="fas device-dashboard_icon"
      [ngClass]="{
        'fa-caret-right': collapsed,
        'fa-caret-down': !collapsed
      }">
    </i>
    <span class="text--uppercase">{{ 'activeDeviceDashboard' | translate }}</span>
  </div>
  <div 
    class="device-dashboard_container" 
    [ngClass]="{'device-dashboard_container--collapsed': collapsed}">
    <div class="device-dashboard_carousel">
      <div 
        class="device-dashboard_card" 
        *ngFor="let device of activeDevices, trackBy: trackByFn" 
        (click)="monitorTest(device)">
        <div class="device-dashboard_title">{{device?.name}}</div>
        <app-percentage-circle
          [percentage]="device?.test_progress"
          [simpleMode]="true">
        </app-percentage-circle>
        <div class="device-dashboard_content">{{ device?.administering_test }}</div>
      </div>
    </div>
  </div>
</ng-container>