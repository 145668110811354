// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// model
import { IEmployee } from 'app/models';

// service
import { UsersService } from 'app/core/services/users.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html'
})
export class EditUserComponent implements OnInit {

  user: IEmployee

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.usersService.getOne(id).subscribe((user: IEmployee) => {
        this.user = user;
      });
    });
  }

  closeForm(event: any) {
    this.router.navigate(['/users']);
  }

}
