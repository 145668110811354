// native
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { SensorimotorState, PlotData, DeviceScreen, SensorimotorRecord, MonitorEvent } from '../../models';

// constants
import { DEVICE_SCREEN, EYE, MONITOR_EVENT_TYPE, MONITOR_INACTIVITY_TIMEOUT } from '../../constants';

const MAX_SENSORIMOTOR_LOCATION_COUNT = 10; // 1 central location, up to 7 locations done, 1 current source, 1 current target

@Component({
  selector: 'app-monitor-sensorimotor',
  templateUrl: './monitor-sensorimotor.component.html'
})
export class MonitorSensorimotorComponent extends MonitorBaseComponent {

  odData: PlotData[] = [];
  layoutOD;
  osData: PlotData[] = [];
  layoutOS;

  percentage: number = 0;
  currentSeqNum = null;

  @ViewChild('monitorContainer') monitorContainer: ElementRef;
  @ViewChild('plotContainerOS') plotContainerOS: ElementRef;
  @ViewChild('plotContainerOD') plotContainerOD: ElementRef;

  algorithmVersion: 1 | 2 | 3 | 4 = null;
  isWarmup: boolean = false;
  attempt: number = 1;
  location: number = 1;
  isLoading: boolean = true;
  
  inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.SENSORIMOTOR * 1000;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService,
      patientsService, monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.odData = [{ ...this.monitorTestService.getSensorimotorInitialPlotData() }];
    this.osData = [{ ...this.monitorTestService.getSensorimotorInitialPlotData() }];

    this.monitorTestService.getSensorimotorState(this.test.id).subscribe(state => {
      this.algorithmVersion = state?.algorithm_version;
      this.updateState(state);
      this.currentSeqNum = state.seq_num;
      this.calculateDimensions();
      this.isLoading = false;
    }, error => {
      this.toastService.error(error, 'Error');
      this.isLoading = false;
    });
  }

  private updateState(state: SensorimotorState) {
    let existingSnapshot: PlotData = state.eye === EYE.OD ? { ...this.odData[0] } : { ...this.osData[0] };

    const newShapshot = { ...existingSnapshot };

    // render previously tested targets (blue colored, v3 and v4 only)
    if (state.locations_done?.length && [3, 4].includes(this.algorithmVersion)) {
      state.locations_done.forEach(location => {
        newShapshot.marker.color[location.location_index] = 0.5;
        newShapshot.x[location.location_index] = location.x_units;
        newShapshot.y[location.location_index] = location.y_units;

        newShapshot.text[location.location_index] = '';
      });
    }

    // render current target (red colored, v3 and v4 only)
    // forcing this to be second to last member of plot array
    if (state.last_result?.location && [3, 4].includes(this.algorithmVersion)) {
      const index = MAX_SENSORIMOTOR_LOCATION_COUNT - 2;
      newShapshot.marker.color[index] = 1.5;
      newShapshot.x[index] = state.last_result.location.x_units;
      newShapshot.y[index] = state.last_result.location.y_units;
    }

    // render current source (yellow/green, all versions)
    // forcing this to be last member of plot array as we want the text to always be displayed 
    // (fixes a plotly bug with resize and text display)
    if (state.last_result?.location) {
      const index = MAX_SENSORIMOTOR_LOCATION_COUNT - 1;
      newShapshot.marker.color[index] = this.calculateMarkerColor(state.last_result);
      newShapshot.x[index] = state.last_result.x_units;
      newShapshot.y[index] = state.last_result.y_units;

      let text = '';
      if (state.last_result.distance_degrees)
        text = text + `d&nbsp;=&nbsp;${state.last_result.distance_degrees.toFixed(2)}&#176;`;

      newShapshot.text[index] = text;
    }

    const updatedSnapshot = {
      ...newShapshot,
      marker: {
        ...newShapshot.marker,
        color: [...newShapshot.marker.color]
      },
      x: [...newShapshot.x],
      y: [...newShapshot.y],
      text: [...newShapshot.text]
    };

    this.odData = state.eye === EYE.OD ? [updatedSnapshot] : [{ ...this.monitorTestService.getSensorimotorInitialPlotData() }];
    this.osData = state.eye === EYE.OS ? [updatedSnapshot] : [{ ...this.monitorTestService.getSensorimotorInitialPlotData() }];

    this.percentage = state.percentage;
    this.attempt = state.last_result?.attempt_number;
    this.location = state.last_result?.location ? (state.last_result.location.location_index + 1) : null;
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_SENSORIMOTOR_STATE) {
      const eventSeqNum = (<SensorimotorState>event.data).seq_num;
      if (!eventSeqNum || !this.currentSeqNum || (this.currentSeqNum <= eventSeqNum)) {
        this.currentSeqNum = eventSeqNum;
        this.updateState(<SensorimotorState>event.data);
      }
    }
  }

  public calculateDimensions() {
    const dimensions = this.monitorTestService.getSinglePlotDimensions(<HTMLElement>this.plotContainerOS.nativeElement);
    this.layoutOS = { ...this.monitorTestService.getVisualTestLayout(null, true), ...dimensions };
    this.layoutOD = { ...this.monitorTestService.getVisualTestLayout(null, true), ...dimensions };
  }

  public onToggleGrid() { }

  protected updateDeviceScreenStatus(deviceScreen: DeviceScreen) {
    super.updateDeviceScreenStatus(deviceScreen);
    this.isWarmup = deviceScreen?.name === DEVICE_SCREEN.WARM_UP.value;
  }

  private calculateMarkerColor(record: SensorimotorRecord): number {
    if (!record?.distance_degrees)
      return 1;
    if (this.algorithmVersion === 1)
      return 2;
    if (record?.threshold)
      return 2;
    return 0;
  }

  public resetStateAfterReconnection(): void { }
}

