// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// service
import { OfflineControllersService } from 'app/core/services/offline-controllers.service';

// models
import { OfflineController } from 'app/models';

@Component({
  selector: 'app-edit-offline-controller',
  templateUrl: './edit-controller.component.html'
})
export class EditOfflineControllerComponent implements OnInit {

  controller: OfflineController;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private offlineControllersService: OfflineControllersService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.offlineControllersService.getOne(id).subscribe((controller: OfflineController) => {
        this.controller = controller;
      }, err => {
        this.closeForm();
      });
    });
  }

  closeForm() {
    this.router.navigate(['/offline-controllers']);
  }
}
