<app-tabs 
  *ngIf="tabs" 
  [tabs]="tabs">
</app-tabs>

<br>

<app-list-header
  name="session"
  [canCreate]="false"
  (onSearch)="search($event)">
</app-list-header>

<app-item-list 
  [columns]="columns"
  [actions]="actions"
  [items$]="sessions$"
  [clickable]="false"
  (onChangePage)="changePage($event.pageIndex, $event.pageSize)">
</app-item-list>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>
