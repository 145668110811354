// native
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { PlotData, EyeStats, EomsRecord, MonitorEvent } from '../../models';

// constant
import { EYE, MONITOR_EVENT_TYPE, MONITOR_INACTIVITY_TIMEOUT } from '../../constants';

const KINETIC_DIRECTIONS_COUNT = 24;

@Component({
  selector: 'app-monitor-kinetic',
  templateUrl: './monitor-kinetic.component.html'
})
export class MonitorKineticComponent extends MonitorBaseComponent {
  odData: PlotData[] = [];
  layoutRight;
  osData: PlotData[] = [];
  layoutLeft;

  odEyeStats: EyeStats = new EyeStats();
  osEyeStats: EyeStats = new EyeStats();

  odCount = 0;
  osCount = 0;

  directionsCount = KINETIC_DIRECTIONS_COUNT;
  osDirectionsProcessed = new Set();
  odDirectionsProcessed = new Set();

  isVersionTwo: boolean = false;

  inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.KINETIC * 1000;

  @ViewChild('monitorContainer') monitorContainer: ElementRef;
  @ViewChild('plotContainer') plotContainer: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.odData = [{ ...this.monitorTestService.getKineticInitialPlotData() }];
    this.osData = [{ ...this.monitorTestService.getKineticInitialPlotData() }];

    this.isVersionTwo = this.device.capabilities?.kinetic_algorithm_version > 1;

    this.monitorTestService.getEomsTestRecords(this.test.id).subscribe(records => {
      this.mergeCurrentState(records);
      this.calculateCompletionPercentage();
      this.calculateDimensions();
    }, error => this.toastService.error(error, 'Error'));
  }

  private mergeCurrentState(records: EomsRecord[]) {
    records.forEach(record => this.mergeRecord(record));
  }

  private mergeRecord(record: EomsRecord) {
    this.updateRecord(record.direction, record);

    if (record.eye === EYE.OD)
      this.odCount++;
    else
      this.osCount++;

    this.updateStats(record);
  }

  private updateRecord(direction: number, record: EomsRecord) {
    let existingSnapshot: PlotData = record.eye === EYE.OD ? { ...this.odData[0] } : { ...this.osData[0] };

    const color = 2;

    const newShapshot = { ...existingSnapshot };
    newShapshot.marker.color[direction] = color;
    newShapshot.x[direction] = record.x;
    newShapshot.y[direction] = record.y;

    if (direction === 0) {
      newShapshot.marker.color[KINETIC_DIRECTIONS_COUNT] = color;
      newShapshot.x[KINETIC_DIRECTIONS_COUNT] = record.x;
      newShapshot.y[KINETIC_DIRECTIONS_COUNT] = record.y;
    }

    const updatedSnapshot = {
      ...newShapshot,
      marker: {
        ...newShapshot.marker,
        color: [...newShapshot.marker.color]
      },
      x: [...newShapshot.x],
      y: [...newShapshot.y]
    };

    if (record.eye === EYE.OD)
      this.odData = [updatedSnapshot];
    else
      this.osData = [updatedSnapshot];
  }

  private updateStats(record: EomsRecord) {
    let eye = record.eye;
    let eyeStats: EyeStats;

    if (eye === EYE.OD)
      eyeStats = this.odEyeStats;
    else
      eyeStats = this.osEyeStats;

    eyeStats.totalProcessedCount++;

    if (eyeStats.totalLocationCount === 0)
      eyeStats.totalLocationCount = this.directionsCount;

    if (eye === EYE.OD) {
      !record.is_blind_spot && this.odDirectionsProcessed.add(record.direction);
    } else {
      !record.is_blind_spot && this.osDirectionsProcessed.add(record.direction);
    }

    if ((record.is_blind_spot && record.viewed && record.response_time > 0.15) || !record.has_good_fixation)
      eyeStats.blindspotCount++;

    if (record.viewed)
      eyeStats.totalViewedCount++;

    if (record.viewed && record.response_time < 0.15)
      eyeStats.falsePositiveCount++;

    this.calculateCompletionPercentageForEye(eyeStats, eye);
  }

  private calculateCompletionPercentageForEye(eyeStats: EyeStats, eye: string) {
    const locationsDone = (eye === EYE.OD)
      ? this.odDirectionsProcessed.size
      : this.osDirectionsProcessed.size;

    eyeStats.completionPercentage = eyeStats.totalLocationCount > 0
      ? Math.min(locationsDone / eyeStats.totalLocationCount * 100, 100)
      : 0;
  }

  private calculateCompletionPercentage() {
    this.calculateCompletionPercentageForEye(this.odEyeStats, EYE.OD);
    this.calculateCompletionPercentageForEye(this.osEyeStats, EYE.OS);
  }

  public onToggleGrid() { }

  public calculateDimensions() {
    const dimensions = this.monitorTestService.getDoublePlotDimensions(this.monitorContainer.nativeElement, this.plotContainer.nativeElement);

    this.layoutLeft = { ...this.monitorTestService.getKineticLayout(this.isVersionTwo), ...dimensions };
    this.layoutRight = { ...this.monitorTestService.getKineticLayout(this.isVersionTwo), ...dimensions };
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_EOMS_RECORD)
      this.mergeRecord(<EomsRecord>event.data);
  }

  public resetStateAfterReconnection(): void {
    this.odEyeStats = {
      ...this.odEyeStats,
      blindspotCount: 0,
      falsePositiveCount: 0,
      falseNegativeCount: 0,
      totalFalseNegativeTestsCount: 0,
      totalLocationCount: 0,
      totalViewedCount: 0,
      totalProcessedCount: 0
    };
    this.osEyeStats = {
      ...this.osEyeStats,
      blindspotCount: 0,
      falsePositiveCount: 0,
      falseNegativeCount: 0,
      totalFalseNegativeTestsCount: 0,
      totalLocationCount: 0,
      totalViewedCount: 0,
      totalProcessedCount: 0
    };

    this.odCount = 0;
    this.osCount = 0;

    this.osDirectionsProcessed = new Set();
    this.odDirectionsProcessed = new Set();
  }
}
