// native
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { delay, startWith } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { ImportService } from 'app/core/services/import.service';

// models
import { ImportItem, ImportJob } from '../../../models';

// constants
import { IMPORT_STATUS } from '../../../constants';

@Component({
  selector: 'app-import-list',
  templateUrl: './import-list.component.html',
  host: {
    '(document:click)': 'onOutsideListClick($event)'
  },
})
export class ImportListComponent implements OnInit, OnDestroy {
  @Input() patientId: number;

  @ViewChild('importList') importListElement: ElementRef;

  importItems: ImportItem[] = [];
  isListShown: boolean = false;
  notificationCount: number = 0;

  importListSubscription: Subscription;
  importListenerSubscription: Subscription;
  listShownSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private importService: ImportService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.languageSubscription = this.translateService.onLangChange.subscribe(res => {
      this.setTranslations();
    });

    this.listShownSubscription = this.importService.listShown$.pipe(
      delay(1000)
    ).subscribe(isShown => this.isListShown = isShown);

    this.getImportList();
    this.listenImportUpdates();
  }

  ngOnDestroy() {
    this.importListSubscription?.unsubscribe();
    this.importListenerSubscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
    this.listShownSubscription?.unsubscribe();
    this.importService.listShown$.next(false);
  }

  private getImportList() {
    this.importListSubscription = combineLatest([
      this.importService.getAll(this.patientId).pipe(startWith([])),
      this.importService.queue$
    ]).subscribe(([serverImportItems, clientImportJobs]) => {
      const serverUploadIds = serverImportItems.map(item => item.upload_id);
      const pendingJobs: ImportJob[] = [];

      // pick only client stored jobs associated with given patient and remove uploads already stored to server
      clientImportJobs.filter(job => job.entityId === this.patientId).forEach(job => {
        if (!serverUploadIds.includes(job.id))
          pendingJobs.push(job);
      });

      const pendingImportItems: ImportItem[] = pendingJobs.map(job => {
        return {
          upload_id: job.id,
          extracted_data: [],
          extraction_status: IMPORT_STATUS[job.snapshot.status]?.value,
          error_client: job.snapshot.error,
          test: null,
          id: null,
          test_report: job.name,
          extraction_verified: false,
          patient: this.patientId
        };
      });

      this.importItems = serverImportItems.concat(pendingImportItems);
      this.setTranslations();
      this.setNotification();
    }, error => console.error(error));
  }

  private listenImportUpdates() {
    this.importListenerSubscription = this.importService.listenUpdates(this.patientId).subscribe(
      updatedImportItem => {
        this.importItems.forEach((item, index) => {
          if (item.upload_id === updatedImportItem.data.upload_id) {
            this.importItems[index] = {
              ...updatedImportItem.data,
              extraction_status_client: this.translateService.instant(IMPORT_STATUS[updatedImportItem.data.extraction_status]?.translationKey),
            };
            this.setNotification();
          }
        });
      },
      error => console.error(error),
    );
  }

  private setTranslations() {
    this.importItems.forEach(item => {
      item.extraction_status_client = this.translateService.instant(IMPORT_STATUS[item.extraction_status]?.translationKey);
    });
  }

  private setNotification() {
    this.notificationCount = this.importItems.filter(item => [IMPORT_STATUS.SUCCEEDED.value, IMPORT_STATUS.FAILED.value].includes(item?.extraction_status))?.length;
  }

  onOutsideListClick(event) {
    if (!this.importListElement?.nativeElement.contains(event.target))
      this.isListShown = false;
  }

  isExtractionSucceeded(item: ImportItem) {
    return item?.extraction_status === IMPORT_STATUS.SUCCEEDED.value;
  }

  isExtractionFailed(item: ImportItem) {
    return item?.extraction_status === IMPORT_STATUS.FAILED.value;
  }

  isProcessing(item: ImportItem) {
    return item?.extraction_status === IMPORT_STATUS.IN_PROGRESS.value;
  }

  isUploadFailed(item: ImportItem) {
    return item?.extraction_status === IMPORT_STATUS.UPLOAD_FAILED.value;
  }

  isVerifyShown(item: ImportItem) {
    return [IMPORT_STATUS.FAILED.value, IMPORT_STATUS.SUCCEEDED.value].includes(item?.extraction_status);
  }

  isCancelShown(item: ImportItem) {
    return [IMPORT_STATUS.QUEUED.value, IMPORT_STATUS.UPLOAD_FAILED.value].includes(item?.extraction_status);
  }

  onVerify(item: ImportItem) {
    this.router.navigate(['import/verify', item.upload_id]);
  }

  onCancel(item: ImportItem) {
    this.importService.removeFile(item.upload_id);
  }

  trackByFn(index: number, item: ImportItem) {
    return item.upload_id;
  }
}