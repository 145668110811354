<div class="progress-square_container">
  <svg viewBox="0 0 40 190" class="progress-square">
    <defs>
      <linearGradient [attr.id]="'square1' + color + id" y1="100%" y2="0%" x1="0%" x2="0%">
        <stop offset="0%" [attr.stop-color]="color" />
        <stop [attr.offset]="square1 + '%'" [attr.stop-color]="color" />
        <stop [attr.offset]="square1 + '%'" stop-color="grey" />
        <stop offset="100%" stop-color="grey" />
      </linearGradient>
      <linearGradient [attr.id]="'square2' + color + id" y1="100%" y2="0%" x1="0%" x2="0%">
        <stop offset="0%" [attr.stop-color]="color" />
        <stop [attr.offset]="square2 + '%'" [attr.stop-color]="color" />
        <stop [attr.offset]="square2 + '%'" stop-color="grey" />
        <stop offset="100%" stop-color="grey" />
      </linearGradient>  
      <linearGradient [attr.id]="'square3' + color + id" y1="100%" y2="0%" x1="0%" x2="0%">
        <stop offset="0%" [attr.stop-color]="color" />
        <stop [attr.offset]="square3 + '%'" [attr.stop-color]="color" />
        <stop [attr.offset]="square3 + '%'" stop-color="grey" />
        <stop offset="100%" stop-color="grey" />
      </linearGradient>  
      <linearGradient [attr.id]="'square4' + color + id" y1="100%" y2="0%" x1="0%" x2="0%">
        <stop offset="0%" [attr.stop-color]="color" />
        <stop [attr.offset]="square4 + '%'" [attr.stop-color]="color" />
        <stop [attr.offset]="square4 + '%'" stop-color="grey" />
        <stop offset="100%" stop-color="grey" />
      </linearGradient>              
    </defs>
    <g>
      <rect x="0" y="150" width="40" height="40" rx="10" ry="10" [attr.fill]="'url(#square1' + color + id + ')'"/>
      <rect x="0" y="100" width="40" height="40" rx="10" ry="10" [attr.fill]="'url(#square2' + color + id + ')'"/>
      <rect x="0" y="50" width="40" height="40" rx="10" ry="10" [attr.fill]="'url(#square3' + color + id + ')'"/>
      <rect x="0" y="0" width="40" height="40" rx="10" ry="10" [attr.fill]="'url(#square4' + color + id + ')'"/>
    </g>
  </svg>
</div>