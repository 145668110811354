// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { WorkItemService } from 'app/core/services/work-item.service';
import { UtilityService } from 'app/core/services/utility.service';
import { PreferencesService } from 'app/core/services/preferences.service';

// models
import { CenterContainerTitle, UserPreferences, WorkItemDefaultConfig, WorkItemFilterRequest } from '../../models';

// constants
import { WORK_ITEM_FILTER_KEY } from '../../constants';

@Component({
  selector: 'app-work-item-filter-form',
  templateUrl: './work-item-filter-form.component.html'
})
export class WorkItemFilterFormComponent implements OnInit {
  title: CenterContainerTitle = {};
  form: UntypedFormGroup;
  defaultConfig: WorkItemDefaultConfig;
  isLoading: boolean = false;
  storedQuery: WorkItemFilterRequest = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private workItemService: WorkItemService,
    private toastService: ToastrService,
    private router: Router,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    public preferencesService: PreferencesService
  ) { }

  ngOnInit() {
    this.translateService.get('patientFilter').subscribe(() => this.setTranslations());

    this.isLoading = true;
    forkJoin([
      this.workItemService.getConfig(),
      this.preferencesService.get()
    ]).subscribe(([config, preferences]) => {
      this.defaultConfig = config;

      this.isLoading = false;
      this.initializeForm(preferences);
    }, error => {
      this.isLoading = false;
      this.toastService.info(error);
    });
  }

  private initializeForm(preferences: UserPreferences) {
    this.storedQuery = localStorage.getItem(WORK_ITEM_FILTER_KEY) && JSON.parse(localStorage.getItem(WORK_ITEM_FILTER_KEY));

    this.form = this.formBuilder.group({
      pacs_integration_device_pool: [this.storedQuery ? this.storedQuery.pacs_integration_device_pool : preferences?.pacs_integration_device_pool?.id, Validators.compose([Validators.required])],
      patient_first_name: [this.storedQuery ? this.storedQuery.patient_first_name : null, Validators.compose([Validators.maxLength(30)])],
      patient_last_name: [this.storedQuery ? this.storedQuery.patient_last_name : null, Validators.compose([Validators.maxLength(30)])],
      patient_id: [this.storedQuery ? this.storedQuery.patient_id : null, Validators.compose([Validators.maxLength(64)])],
      accession_number: [this.storedQuery ? this.storedQuery.accession_number : null, Validators.compose([Validators.maxLength(16)])],
      requested_procedure_id: [this.storedQuery ? this.storedQuery.requested_procedure_id : null, Validators.compose([Validators.maxLength(16)])],
      scheduled_procedure_step_start_date_from: [this.storedQuery ? this.storedQuery.scheduled_procedure_step_start_date_from : new Date(new Date().setHours(0, 0, 0, 0))],
      scheduled_procedure_step_start_date_to: [this.storedQuery ? this.storedQuery.scheduled_procedure_step_start_date_to : null],
      modality: [this.storedQuery ? this.storedQuery.modality : this.defaultConfig?.mwl_query_modality_default, Validators.compose([Validators.maxLength(3)])],
      scheduled_station_ae_title_auto: [this.storedQuery ? this.storedQuery.scheduled_station_ae_title_auto : true],
      scheduled_station_ae_title: [this.storedQuery ? this.storedQuery.scheduled_station_ae_title : null, Validators.compose([Validators.maxLength(16)])]
    }, {
      validator: this.checkIfMatchingDates(
        'scheduled_procedure_step_start_date_from',
        'scheduled_procedure_step_start_date_to'
      )
    });
  }

  isFieldShown(fieldName: string) {
    return !this.defaultConfig.disabled_mwl_query_filters.includes(fieldName);
  }

  private checkIfMatchingDates(fromKey: string, toKey: string): (group: UntypedFormGroup) => void {
    return (group: UntypedFormGroup) => {
      const fromDate = group.controls[fromKey];
      const toDate = group.controls[toKey];

      if (!fromDate?.value || !toDate?.value)
        return toDate.setErrors(null);
      else if (toDate.value < fromDate.value)
        return toDate.setErrors({ invalidDateRange: true });
      else
        return toDate.setErrors(null);
    };
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('patientFilter');
    this.title.thin = this.translateService.instant('create');
  }

  onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    let {
      patient_first_name,
      patient_last_name,
      patient_id,
      accession_number,
      requested_procedure_id,
      scheduled_procedure_step_start_date_from,
      scheduled_procedure_step_start_date_to,
      modality,
      scheduled_station_ae_title,
      scheduled_station_ae_title_auto,
      pacs_integration_device_pool
    } = this.form.value;

    scheduled_procedure_step_start_date_from = this.utilityService.convertClientDateToServerDate(scheduled_procedure_step_start_date_from);
    scheduled_procedure_step_start_date_to = this.utilityService.convertClientDateToServerDate(scheduled_procedure_step_start_date_to);

    const body: WorkItemFilterRequest = {
      patient_first_name: this.isFieldShown('patient_first_name') ? patient_first_name : null,
      patient_last_name: this.isFieldShown('patient_last_name') ? patient_last_name : null,
      patient_id: this.isFieldShown('patient_id') ? patient_id : null,
      accession_number: this.isFieldShown('accession_number') ? accession_number : null,
      requested_procedure_id: this.isFieldShown('requested_procedure_id') ? requested_procedure_id : null,
      scheduled_procedure_step_start_date_from: this.isFieldShown('scheduled_procedure_step_start_date_from') ? scheduled_procedure_step_start_date_from : null,
      scheduled_procedure_step_start_date_to: this.isFieldShown('scheduled_procedure_step_start_date_to') ? scheduled_procedure_step_start_date_to : null,
      modality: this.isFieldShown('modality') ? modality : null,
      scheduled_station_ae_title,
      scheduled_station_ae_title_auto,
      pacs_integration_device_pool
    };

    this.isLoading = true;

    this.workItemService.filter(body).subscribe(response => {
      localStorage.setItem(WORK_ITEM_FILTER_KEY, JSON.stringify(body));

      this.workItemService.listen(response.mwl_query_id);
      this.isLoading = false;
      this.workItemService.filteringInProgress = true;
      this.closeForm();
    }, err => {
      this.isLoading = false;
      err && err.error && this.toastService.error(err.error);
      this.closeForm();
    });
  }

  onAeTitleAutoChange() {
    this.form.controls['scheduled_station_ae_title'].setValue(null);
    this.form.controls['scheduled_station_ae_title'].updateValueAndValidity();
  }

  closeForm() {
    this.router.navigate(['/work-items']);
  }
}
