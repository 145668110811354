// native
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HammerGestureConfig } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

// addon
import { ToastrModule } from 'ngx-toastr';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GridModule } from "@progress/kendo-angular-grid";

// components
import { AppComponent } from './app.component';

// modules
import { SharedModule } from './shared/shared.module';
import { AboutModule } from './about/about.module';
import { NotFoundModule } from './notfound/notfound.module';
import { HelpModule } from './help/help.module';
import { DemoModule } from './demo/demo.module';
import { UsersModule } from './users/users.module';
import { OfficesModule } from './offices/offices.module';
import { DevicesModule } from './devices/devices.module';
import { PatientsModule } from './patients/patients.module';
import { TestsModule } from './tests/tests.module';
import { BundlesModule } from './bundles/bundles.module';
import { MonitorModule } from './monitor/monitor.module';
import { SettingsModule } from './settings/settings.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { TechallModule } from './techall/techall.module';
import { CustomBundlesModule } from './custom-bundles/custom-bundles.module';
import { CoreModule } from './core/core.module';
import { ImportModule } from './import/import.module';
import { SupportModule } from './support/support.module';
import { WorkItemsModule } from './work-items/work-items.module';
import { OfflineControllersModule } from './offline-controllers/offline-controllers.module';
import { InvitationsModule } from './invitations/invitations.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locales/', '.json?c=1');
}

@Injectable()
class HammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: 6,
    },
    pinch: {
      enable: false,
    },
    rotate: {
      enable: false,
    },
  };
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken',
    }),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'en-us'
    }),
    HammerModule,
    GridModule,

    AboutModule,
    AuthModule,
    CoreModule,
    HelpModule,
    DemoModule,
    NotFoundModule,
    DevicesModule,
    PatientsModule,
    CustomBundlesModule,
    OfficesModule,
    UsersModule,
    TestsModule,
    BundlesModule,
    MonitorModule,
    SettingsModule,
    SharedModule,
    TechallModule,
    ImportModule,
    SupportModule,
    WorkItemsModule,
    OfflineControllersModule,
    InvitationsModule,

    AppRoutingModule
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) { }
}
