// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

// service
import { PatientsService } from 'app/core/services/patients.service';

// models
import { Patient } from '../../models';

// constants
import { CREATE_PATIENT_FROM_MWL } from '../../constants';

@Component({
  selector: 'app-new-test',
  templateUrl: './new-test.component.html'
})
export class NewTestComponent implements OnInit {

  patient: Partial<Patient>;
  firstTest: boolean;
  mwlItemId: string;

  constructor(
    private route: ActivatedRoute,
    private patientsService: PatientsService
  ) { }

  ngOnInit() {
    combineLatest(this.route.params, this.route.queryParams).subscribe(([params, queryParams]) => {
      this.firstTest = queryParams['firstTest'];
      this.mwlItemId = queryParams['mwlId'];

      if (params['patientId'] === CREATE_PATIENT_FROM_MWL) {
        this.patient = {
          monocular: null
        };
        return;
      }

      const patientId = params['patientId'];
      this.patientsService.getOne(patientId).subscribe((patient: Patient) => {
        this.patient = patient;
      });
    });
  }
}
