<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column monitor_container-column--single-or-double">
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="osResponses.length" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <div class="monitor_circle-box">
        {{ 'correctAnswers' | translate }}:
        <br><br>
        <span class="text--xxl">
          <span>{{getCorrect('OS')}}</span>&nbsp;&#47;&nbsp;<span>{{getAnswered('OS')}}</span>
        </span>
      </div>
    </div>
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="odResponses.length" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <div class="monitor_circle-box">
        {{ 'correctAnswers' | translate }}:
        <br><br>
        <span class="text--xxl">
          <span>{{getCorrect('OD')}}</span>&nbsp;&#47;&nbsp;<span>{{getAnswered('OD')}}</span>
        </span>
      </div>
    </div>
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="bothResponses.length" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_circle-box">
        {{ 'correctAnswers' | translate }}:
        <br><br>
        <span class="text--xxl">
          <span>{{getCorrect('both')}}</span>&nbsp;&#47;&nbsp;<span>{{getAnswered('both')}}</span>
        </span>
      </div>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box" *ngIf="osResponses.length">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <app-percentage-circle
        id="test-percentage-left"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone(osResponses)">
      </app-percentage-circle>
    </div>
    <div class="monitor_graph-box" *ngIf="odResponses.length">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <app-percentage-circle
      id="test-percentage-right"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone(odResponses)">
      </app-percentage-circle>
    </div>
    <div class="monitor_graph-box" *ngIf="bothResponses.length">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        id="test-percentage-both"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone(bothResponses)">
      </app-percentage-circle>
    </div>
  </div>
</div>