
// native
import { Injectable } from '@angular/core';

// models
import { ILanguage } from '../../models';

// constants
import { APP_LANGUAGE_KEY, APP_LANGUAGES, REPORT_LANGUAGES } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public appLanguages: ILanguage[] = APP_LANGUAGES;
  private defaultLanguage = 'en-us';

  public reportLanguages: ILanguage[] = REPORT_LANGUAGES;

  get appLanguage() {
    const storedLanguage = localStorage.getItem(APP_LANGUAGE_KEY);
    return this.appLanguages.map(lang => lang.value).includes(storedLanguage) ? storedLanguage : this.defaultLanguage;
  }

  setAppLanguage(value: string) {
    localStorage.setItem(APP_LANGUAGE_KEY, value);
  }

  get appLocale(): string {
    if (navigator.languages != undefined)
      return navigator.languages[0];
    return navigator.language;
  }
}
