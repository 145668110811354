// native
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { PatientsService } from 'app/core/services/patients.service';
import { DialogService } from 'app/core/services/dialog.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { Patient } from '../../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';

@Component({
  selector: 'app-review-patients',
  templateUrl: './review-patients.component.html',
  host: {
    '(document:click)': 'onOutsideListClick($event)'
  },
})
export class ReviewPatientsComponent implements OnInit {
  @Input() patientId: number;

  @ViewChild('patientList') patientListElement: ElementRef;

  reviewPatients: Patient[] = [];
  isListShown: boolean = false;

  constructor(
    public patientsService: PatientsService,
    private router: Router,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getPatientList();
  }

  private getPatientList() {
    this.patientsService.getAll(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, null, true).subscribe(response => {
      this.reviewPatients = response.results;
    }, error => console.error(error));
  }

  onOutsideListClick(event) {
    if (!this.patientListElement?.nativeElement.contains(event.target))
      this.isListShown = false;
  }

  onMerge(patient: Patient) {
    this.router.navigate(['patients/merge', patient.id]);
  }

  onKeep(patient: Patient) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('keep'),
      message: this.translateService.instant('patientKeepPrompt'),
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed) {
        this.patientsService.update(patient.id, { needs_match_review: false }).subscribe(res => {
          this.toastService.success(this.translateService.instant('patientKeepSuccess'));
          this.getPatientList();
        }, err => this.errorService.handleError(this.translateService.instant('patientKeepError')));
      }
    });
  }

  trackByFn(index: number, patient: Patient) {
    return patient.id;
  }
}