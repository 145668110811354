// native
import { Component, Input, Output, EventEmitter } from '@angular/core';

// addon
import * as generator from 'generate-password-browser';

// constants
import { USER_PASSWORD_REGEX } from '../../../constants';

@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html'
})
export class PasswordValidatorComponent {
  @Input() password: string = '';
  @Output() onSuggestPassword: EventEmitter<string> = new EventEmitter<string>();

  public CHAR_REGEX = /(.*[a-zA-Z]){8,}/;
  public NUMBER_REGEX = /(.*\d){2}/;
  public SPECIAL_REGEX = /(.*[*.=!@$%^&(){}:;<>,.?/~_+-]){2}/;
  public TOTAL_REGEX = /^(.*[a-zA-Z\d*.=!@$%^&(){}:;<>,.?/~_+-\s]){15,}$/;
  public LEADING_TRAILING_SPACES_REGEX = /^(?! )[a-zA-Z\d*.=!@$%^&(){}:;<>,.?/~_+\-]+(?<! )$/

  private PASSWORD_LENGTH = 15;

  onSuggestClick(event) {
    event.preventDefault();
    this.generatePassword();
  }

  private generatePassword() {
    let password = generator.generate({
      length: this.PASSWORD_LENGTH - 2,
      numbers: true,
      uppercase: false,
      strict: true,
      excludeSimilarCharacters: true
    });

    password = this.addSymbols(password);

    if (!password.match(USER_PASSWORD_REGEX))
      return this.generatePassword();

    this.onSuggestPassword.emit(password);
  }

  private addSymbols(term: string): string {
    let randomPosition = Math.floor(Math.random() * (term.length - 1)) + 1;
    term = term.slice(0, randomPosition) + '-' + term.slice(randomPosition);

    randomPosition = Math.floor(Math.random() * (term.length - 1)) + 1;
    term = term.slice(0, randomPosition) + '-' + term.slice(randomPosition);

    return term;
  }
}
