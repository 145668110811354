<div 
  class="progress-ring_container"
  [ngClass]="{'progress-ring_container--simple': simpleMode}">
  <svg
    class="progress-ring"
    viewBox="0 0 120 120">
    <circle
      stroke="grey"
      stroke-width="4"
      fill="transparent"
      r="52"
      cx="60"
      cy="60"/>

    <circle
      #percentageCircle
      class="progress-ring_circle"
      stroke="transparent"
      stroke-width="10"
      stroke-linecap="round"
      fill="transparent"
      r="52"
      cx="60"
      cy="60"/>

    <circle
      fill="#afcb53"
      [attr.r]="simpleMode ? '35' : '30'"
      cx="60"
      cy="60"/>
  </svg>

  <div *ngIf="percentage !== undefined" class="progress-ring_title">
    <strong>{{ percentage }}</strong><small>&#37;</small>
  </div>
</div>