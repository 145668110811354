<div class="button-list_container">
  <div 
    *ngFor="let button of list" 
    matTooltip="{{ (button.tooltipTranslationKey | translate | titlecase) || (button.translationKey | translate | titlecase) }}" 
    matTooltipPosition="above">
    <i 
      class="button-list_icon {{ button.iconClass }}" 
      [ngClass]="{ 'button-list_icon--disabled faded-more pointer-events-none': button.disabled }" 
      (click)="button.action()">
    </i>
  </div>
</div>
