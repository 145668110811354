// antive
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// service
import { TestsService } from 'app/core/services/tests.service';

// models
import { Test } from 'app/models';

@Component({
  selector: 'app-view-test',
  templateUrl: './view-test.component.html'
})
export class ViewTestComponent implements OnInit {

  test: Test;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testsService: TestsService
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      const id = params['id'];

      this.testsService.getOne(id).subscribe((test: Test) => {
        this.test = test;
      }, err => {
        this.router.navigate(['/tests']);
      });
    });
  }
}
