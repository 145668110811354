// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, shareReplay, startWith } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DateAdapter } from '@angular/material/core';

// service
import { AuthService } from './core/services/auth.service';
import { DemoService } from './core/services/demo.service';
import { TrackingService } from './core/services/tracking.service';
import { TechallSessionService } from './core/services/techall-session.service';
import { LanguageService } from './core/services/language.service';
import { NavigationService } from './core/services/navigation.service';
import { DialogService } from './core/services/dialog.service';
import { PreferencesService } from './core/services/preferences.service';
import { PatientsJobService } from './core/services/patients-encrypt-job.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  protected onlineSubject = new Subject<boolean>();

  navExpanded = false;
  headerShown = true;
  headerTransparentMode = false;

  footerShown = true;

  navigationHiddenSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private demoService: DemoService,
    private toastService: ToastrService,
    private trackingService: TrackingService,
    public techallService: TechallSessionService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private navigationService: NavigationService,
    private dateAdapter: DateAdapter<any>,
    private dialogService: DialogService,
    private preferencesService: PreferencesService,
    private patientsJobService: PatientsJobService
  ) { }

  ngOnInit() {
    this.trackingService.initSentry();

    this.initializeRouteSubscription();
    this.navigationHiddenSubscription = this.navigationService.navigationHidden$.subscribe(hidden => {
      this.headerShown = !hidden;
      this.navExpanded = !hidden;
    });

    this.setOfflineNotification();

    this.translateService.use(this.languageService.appLanguage);
    this.translateService.setDefaultLang(this.languageService.appLanguage);
    this.dateAdapter.setLocale(this.languageService.appLocale);

    if (this.authService.isLoggedIn)
      this.preferencesService.get().subscribe(() => {
          this.demoService.checkAndRedirect();
      });

    this.checkPatientEncryptionStatus();
  }

  ngOnDestroy(): void {
    this.navigationHiddenSubscription?.unsubscribe();
  }

  setOfflineNotification() {
    window.addEventListener('online', () => this.onlineSubject.next(navigator.onLine));
    window.addEventListener('offline', () => this.onlineSubject.next(navigator.onLine));

    this.onlineSubject.pipe(
      distinctUntilChanged(),
      startWith(navigator.onLine),
      shareReplay({ refCount: true, bufferSize: 1 })
    ).subscribe(online => {
      if (!online) {
        this.toastService.error(this.translateService.instant('internetAccessRequired'));
      }
    });
  }

  isVideoHidden() {
    if (this.techallService.inCall || this.techallService.isWaiting)
      return false;
    if (this.authService.isTechallUser && this.techallService.currentSession) {
      return false;
    }
    return true;
  }

  initializeRouteSubscription() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.navigationService.getChildRoute(this.route)),
      filter((route: ActivatedRoute) => route.outlet === 'primary'),
      mergeMap((route: ActivatedRoute) => route.data)
    ).subscribe(routeData => {
      this.headerShown = !!routeData?.hasNavigation;
      this.headerTransparentMode = this.router.url.includes('techall-mode') || this.router.url.includes('demo-mode');
      this.navExpanded = false;

      this.footerShown = !!routeData?.hasFooter;

      this.dialogService.visible$.next(false);
    });
  }

  checkPatientEncryptionStatus() {
    // if user is OGA continue the "encrypt existing patients" job if needed
    // the job will automatically store patients to local db
    if (this.preferencesService.isZeroPiiEnabled && this.authService.isOfficeGroupAdmin)
      this.patientsJobService.isEncryptionJobNeeded().subscribe(isNeeded => {
        if (isNeeded)
          this.patientsJobService.encryptExistingPatients(true);
      });
  }
}
