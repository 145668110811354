// native
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

// models
import { UserPreferences, UserPreferencesPutRequest, TestSystemUnit, TestDateFormat, TestTimeFormat, UserInactivityLimit } from '../../models';

// constants
import { API_GET_PREFERENCES_PATH, API_UPDATE_PREFERENCES_PATH, ENABLE_ZERO_PII_KEY } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  // todo refactor demo mode flag handling
  isDemoModeEnabled = false;
  disablePi = false;

  SYSTEM_UNITS: TestSystemUnit[] = [
    {
      name: 'imperial',
      value: 'IMP'
    }, {
      name: 'international',
      value: 'INT'
    }
  ];

  USER_INACTIVITY_LIMITS: UserInactivityLimit[] = [
    {
      name: 'inactivityLimits.noLimit',
      value: 0
    }, {
      name: 'inactivityLimits.15mins',
      value: 15 * 60
    }, {
      name: 'inactivityLimits.30mins',
      value: 30 * 60
    }, {
      name: 'inactivityLimits.1hour',
      value: 1 * 60 * 60
    }, {
      name: 'inactivityLimits.2hours',
      value: 2 * 60 * 60
    }, {
      name: 'inactivityLimits.4hours',
      value: 4 * 60 * 60
    }, {
      name: 'inactivityLimits.8hours',
      value: 8 * 60 * 60
    }
  ];

  DATE_FORMATS: TestDateFormat[] = [
    {
      serverValue: 'DD.MM.YYYY',
      value: 'dd.MM.yyyy',
      exampleDisplay: '01.06.2023'
    },
    {
      serverValue: 'DD/MM/YYYY',
      value: 'dd/MM/yyyy',
      exampleDisplay: '01/06/2023'
    }, {
      serverValue: 'D/M/YYYY',
      value: 'd/M/yyyy',
      exampleDisplay: '1/6/2023'
    },
    {
      serverValue: 'DD-MM-YYYY',
      value: 'dd-MM-yyyy',
      exampleDisplay: '01-06-2023'
    },
    {
      serverValue: 'M/D/YYYY',
      value: 'M/d/yyyy',
      exampleDisplay: '6/1/2023'
    },
    {
      serverValue: 'MM/DD/YYYY',
      value: 'MM/dd/yyyy',
      exampleDisplay: '06/01/2023'
    },
    {
      serverValue: 'YYYY/M/D',
      value: 'yyyy/M/d',
      exampleDisplay: '2023/6/1'
    },
    {
      serverValue: 'YYYY/MM/DD',
      value: 'yyyy/MM/dd',
      exampleDisplay: '2023/06/01'
    },
    {
      serverValue: 'YYYY.MM.DD',
      value: 'yyyy.MM.dd',
      exampleDisplay: '2023.06.01'
    },
    {
      serverValue: 'YYYY-MM-DD',
      value: 'yyyy-MM-dd',
      exampleDisplay: '2023-06-01'
    }
  ];

  TIME_FORMATS: TestTimeFormat[] = [
    {
      serverValue: '12H',
      value: 'h:mm a',
      exampleDisplay: '3:12 PM'
    },
    {
      serverValue: '24H',
      value: 'HH:mm',
      exampleDisplay: '15:12'
    }
  ];

  constructor(
    private httpClient: HttpClient
  ) { }

  defaultTimeFormat: TestTimeFormat = {
    serverValue: '12H',
    value: 'h:mm a'
  };

  defaultDateFormat: TestDateFormat = {
    serverValue: 'MM/DD/YYYY',
    value: 'MM/dd/yyyy'
  };

  get defaultDateTimeFormat(): string {
    return this.defaultDateFormat.value + ' ' + this.defaultTimeFormat.value;
  }

  get isZeroPiiEnabled(): boolean {
    return !!JSON.parse(localStorage.getItem(ENABLE_ZERO_PII_KEY));
  }

  get(): Observable<UserPreferences> {
    return <Observable<UserPreferences>>this.httpClient.get(API_GET_PREFERENCES_PATH).pipe(
      tap((preferences: UserPreferences) => {
        this.isDemoModeEnabled = preferences.enable_demo_mode;
        this.disablePi = preferences.disable_pi;
        this.defaultTimeFormat = this.TIME_FORMATS.find(format => format.serverValue === preferences.time_format);
        this.defaultDateFormat = this.DATE_FORMATS.find(format => format.serverValue === preferences.date_format);
        if (preferences.enable_zero_pii !== null)
          localStorage.setItem(ENABLE_ZERO_PII_KEY, preferences.enable_zero_pii.toString());
      })
    );
  }

  update(body: Partial<UserPreferencesPutRequest>): Observable<UserPreferences> {
    return <Observable<UserPreferences>>this.httpClient.put(API_UPDATE_PREFERENCES_PATH, body).pipe(
      tap((preferences: UserPreferences) => {
        this.isDemoModeEnabled = preferences.enable_demo_mode;
        this.disablePi = preferences.disable_pi;
        this.defaultTimeFormat = this.TIME_FORMATS.find(format => format.serverValue === preferences.time_format);
        this.defaultDateFormat = this.DATE_FORMATS.find(format => format.serverValue === preferences.date_format);
        if (preferences.enable_zero_pii !== null)
          localStorage.setItem(ENABLE_ZERO_PII_KEY, preferences.enable_zero_pii.toString());
      })
    );
  }
}