<div 
  #container
  class="color_container">
  <ng-container *ngIf="height">
    <div 
    *ngFor="let color of colors" 
    class="color_circle"
    [style]="'backgroundColor:#' + (color ? color.color : 'fff')">
  </div>
  </ng-container>
</div>
