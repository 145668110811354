<app-breadcrumbs 
  [stepsLabels]="breadcrumbOptions.stepLabels"
  [stepsActions]="breadcrumbOptions.stepActions">
</app-breadcrumbs>

<div class="flex-space-between">
  <app-tabs 
    *ngIf="tabs?.length" 
    [tabs]="tabs">
  </app-tabs>

  <div class="action-header_container">
    <app-button-list
      *ngIf="buttonList?.length"
      [list]="buttonList">
    </app-button-list>

    <app-import-list 
      [patientId]="patientId">
    </app-import-list>
  </div>
</div>

<br>

<app-list-header
  name="test"
  [canCreate]="!authService.isTechallUserOrAdmin || (authService.isTechallAdmin && isActiveTechallSession)"
  (onCreate)="create()"
  (onSearch)="search($event)">
</app-list-header>

<app-item-list 
  [columns]="columns"
  [actions]="actions"
  [items$]="tests$"
  [icons]="[
    { name: 'layer-group', visible: isBundleTest }, 
    { name: 'file-import', visible: isExternalTest }, 
    { name: 'clock', visible: isRecurringTest },
    { name: 'spinner orange', visible: workItemService.isPendingPacsTest, getTooltip: workItemService.getPacsDetails },
    { name: 'exclamation-circle red', visible: workItemService.isFailedPacsTest, getTooltip: workItemService.getPacsDetails },
    { name: 'check color-primary', visible: workItemService.isSuccessPacsTest, getTooltip: workItemService.getPacsDetails }
    ]"
  [orangeMarker]="orangeMarker"
  [greenMarker]="greenMarker"
  [redMarker]="redMarker"
  [itemTrackBy]="testsTrackBy"
  (onItemClick)="view($event)"  
  (onChangePage)="changePage($event.pageIndex, $event.pageSize)">
</app-item-list>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>
