import { Directive, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appSwipeItem]'
})
export class SwipeItemDirective {
  @HostBinding(`class.swiped`) swiped;
  @HostBinding(`class.not-swiped`) notSwiped;

  constructor() {}

  @HostListener('swipeleft')
  public Swipe() {
    this.swiped = true;
    this.notSwiped = false;
  }

  @HostListener('swiperight')
  public SwipeBack() {
    this.swiped = false;
    this.notSwiped = true;
  }
}
