// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { OfficesService } from 'app/core/services/offices.service';
import { UsersService } from 'app/core/services/users.service';

// model
import { IEmployee, IEmployeeRequest, Office, PaginatedItems, CenterContainerTitle } from '../../models';

@Component({
  selector: 'app-select-office',
  templateUrl: './select-office.component.html'
})
export class SelectOfficeComponent implements OnInit {

  form: UntypedFormGroup;
  offices: Office[];
  user: IEmployee;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private officesService: OfficesService,
    private usersService: UsersService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    const userId = this.route.snapshot.params['userId'];

    if (!userId)
      this.close();

    this.translateService.get('offices').subscribe(() => this.setTranslations());

    this.usersService.getOne(userId).subscribe((user: IEmployee) => {
      if (!user)
        this.close();

      this.user = user;

      this.officesService.getAll().subscribe((offices: PaginatedItems<Office>) => {
        this.offices = offices.results;
        this.initializeForm();
      });

    }, err => {
      this.close();
    });
  }

  public initializeForm() {
    const { offices } = this.user;

    this.form = this.formBuilder.group({
      offices: [offices, Validators.required],
    });
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('offices');
    this.title.thin = this.translateService.instant('manage');
  }

  public onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    let { offices } = this.form.value;

    const body: IEmployeeRequest = { offices };

    this.isLoading = true;
    this.usersService.update(this.user.id, body).subscribe(
      (response: IEmployee) => {
        this.isLoading = false;
        this.close();
      }, response => {
        this.isLoading = false;
        response && response.error && this.toastService.error(response.error);
      }
    );
  }

  close() {
    this.router.navigate(['/users']);
  }
}


