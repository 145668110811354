// native
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// model
import { OfflineController } from '../../models';

@Component({
  selector: 'app-new-offline-controller',
  templateUrl: './new-controller.component.html'
})
export class NewOfflineControllerComponent {

  constructor(
    private router: Router
  ) { }

  closeForm(controller: OfflineController) {
    if (!!controller)
      this.router.navigate(['offline-controllers/edit', controller.id]);
    else
      this.router.navigate(['offline-controllers']);
  }
}
