// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// services
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { TestsService } from 'app/core/services/tests.service';
import { DialogService } from 'app/core/services/dialog.service';
import { UtilityService } from 'app/core/services/utility.service';

// models
import { Device, PaginatedItems, ListItemColumn, ListItemAction, ListItemMarker } from '../models/';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEVICE_TYPE } from '../constants';

interface DeviceDisplay extends Device {
  administering: string;
  claimed: string;
}

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html'
})
export class DevicesComponent implements OnInit, OnDestroy {

  columns: ListItemColumn[] = [
    {
      translationKey: 'deviceName',
      fieldName: 'name',
      fontClass: 'bold'
    },
    {
      translationKey: 'deviceType',
      fieldName: 'device_type',
      fontClass: 'bold'
    },
    {
      translationKey: 'runningTest',
      fieldName: 'administering',
      fontClass: 'bold',
      hoverOnly: false
    },
    {
      translationKey: 'deviceClaimed',
      fieldName: 'claimed',
      fontClass: 'thin',
    },
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'edit',
      execute: (device: Device) => this.edit(device),
      visible: (device: Device) => this.canEdit(device)
    },
    {
      translationKey: 'cancelTest',
      execute: (device: Device) => this.cancelTest(device),
      visible: (device: Device) => !!device.administering_test
    }
  ];

  orangeMarker: ListItemMarker = {
    visible: (device: Device) => !!device.administering_test
  };

  devices$: BehaviorSubject<PaginatedItems<DeviceDisplay> | null> = new BehaviorSubject(null);

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading: boolean = false;

  languageSubscription: Subscription;

  constructor(
    public router: Router,
    public devicesService: DevicesService,
    public authService: AuthService,
    private testsService: TestsService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.getDevices(this.currentPageSize, this.currentPageIndex, null);

    this.languageSubscription = this.translateService.onLangChange.subscribe(res => {
      this.getDevices(this.currentPageSize, this.currentPageIndex, null);
    });
  }

  private getDevices(pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    this.devicesService.getAll(pageSize, pageIndex, term).subscribe(res => {
      this.isLoading = false;

      this.devices$.next(this.parseDevices(res));
    }, err => { this.isLoading = false; });
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  canEdit(device: Device): boolean {
    if (this.authService.isOfficeGroupAdmin)
      return true;

    if (!this.authService.isOfficeAdmin)
      return false;

    const deviceEditable = this.utilityService.hasCommonElement(
      this.authService.getUserAdministeredOffices(),
      device.offices);
    return deviceEditable;
  }

  edit(device: Device) {
    this.router.navigate(['/devices/edit', device.id]);
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.isLoading = true;

    this.devicesService.getAll(this.currentPageSize, this.currentPageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.term = term;
      this.devices$.next(this.parseDevices(res));
    });
  };

  create() {
    this.router.navigate(['/devices/new']);
  }

  cancelTest(device: Device) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant('cancelTest') +
        ' (' + this.translateService.instant('patient') + ' ' + device?.administering_test + ') ?',
      cancelText: this.translateService.instant('back')
    }).then(result => {
      if (result.confirmed)
        this.testsService.delete(device.test).subscribe(
          res => this.getDevices(this.currentPageSize, this.currentPageIndex, this.term));
    });
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getDevices(pageSize, pageIndex, this.term);
  }

  private parseDevices(devices: PaginatedItems<Device>): PaginatedItems<DeviceDisplay> {
    const parsedDevices = devices.results.map(device => {
      const { device_type, administering_test, user } = device;
      const claimed = user ? this.translateService.instant('claimed') : this.translateService.instant('unclaimed');
      const administering = administering_test
        ? administering_test
        : '';

      let deviceTypeFormatted = '';

      if (device_type === DEVICE_TYPE.HOME)
        deviceTypeFormatted = this.translateService.instant('homeDevice');
      else if (device_type === DEVICE_TYPE.OFFICE_CHOICE)
        deviceTypeFormatted = this.translateService.instant('officeChoiceDevice');
      else if (device_type === DEVICE_TYPE.EYE_TRACKING)
        deviceTypeFormatted = this.translateService.instant('officeTrackingDevice');
      else if (device_type === DEVICE_TYPE.CAMERA)
        deviceTypeFormatted = this.translateService.instant('cameraDevice');
      else
        deviceTypeFormatted = this.translateService.instant('officeDevice');

      return {
        ...device,
        claimed,
        administering,
        device_type: deviceTypeFormatted,
      };
    });

    return {
      results: parsedDevices,
      count: devices.count
    };
  }
}
