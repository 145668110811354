// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { PreferencesComponent } from "./preferences/preferences.component";
import { UserSettingsFormComponent } from "./user-settings/user-settings-form.component";
import { EditAuthenticatorComponent } from './edit-authenticator/edit-authenticator.component';
import { ZeroPiiFormComponent } from './zero-pii-form/zero-pii-form.component';
import { ZeroPiiPromptComponent } from './zero-pii-prompt/zero-pii-prompt.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PreferencesComponent,
    UserSettingsFormComponent,
    EditAuthenticatorComponent,
    ZeroPiiFormComponent,
    ZeroPiiPromptComponent
  ]
})
export class SettingsModule { }