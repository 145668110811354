import { animate, animateChild, query, stagger, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ height: 0, opacity: 0 }), animate('100ms', style({ height: '48px',opacity: 1 })),
    ]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('100ms', style({ opacity: 0 }))]
  )
]);

export const startList = trigger('startList', [
  transition(':enter', [
    query('@items', stagger(300, animateChild()),{ optional: true })
  ]),
])
