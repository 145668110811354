<div 
  class="dialog_overlay" 
  [ngClass]="{'dialog_overlay--visible': visible}">
  <div class="dialog_container">
    <app-center-container [title]="title" [showClose]="showClose" (close)="onClose()">

      <div class="dialog_body">
        {{message}}
        <ng-container *ngIf="text">
          <br>{{text}}<br>
        </ng-container>
      </div>

      <div 
        class="dialog_footer" 
        [ngClass]="{
          'dialog_footer--centered': !showCancel,
          'dialog_footer--vertical': !!altConfirmText
        }">
        <button 
          *ngIf="altConfirmText"
          type="button" 
          class="button button--primary"
          aria-label="confirm"
          [ngClass]="{ 'button--medium': mediumButtonMode }" 
          (click)="onAltConfirm()">
          <span class="regular">{{altConfirmText || action}}</span>
        </button>
        <button 
          type="button" 
          class="button button--primary"
          aria-label="confirm"
          [ngClass]="{ 'button--medium': mediumButtonMode }" 
          (click)="onConfirm()">
          <span class="regular">{{confirmText || action}}</span>
        </button>
        <button 
          *ngIf="showCancel" 
          type="button" 
          class="button button--secondary"
          aria-label="cancel"
          [ngClass]="{ 'button--medium': mediumButtonMode }" 
          (click)="onCancel()">
          <span class="regular">{{cancelText || ('cancel' | translate)}}</span>
        </button>
      </div>

    </app-center-container>
  </div>
</div>

