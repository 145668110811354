<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="closeForm()">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <app-input-wrapper 
      [control]="form.get('doctor')" 
      label="{{ 'doctorsName' | translate }}">
      <mat-select 
        class="form_control"
        formControlName="doctor" 
        disableOptionCentering 
        panelClass="dropdown"
        name="doctor"
        aria-label="{{ 'doctorsName' | translate }}">
        <mat-option *ngFor="let doctor of doctors" [value]="doctor.id">
          {{ doctor.first_name + ' ' + doctor.last_name }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <app-input-wrapper 
      *ngIf="!preferences?.disable_pi"
      [control]="form.get('first_name')" 
      label="{{ 'firstName' | translate }}"
      identifier="first_name">
      <input class="form_control" type="text" formControlName="first_name" name="first_name" id="first_name">
    </app-input-wrapper>

    <app-input-wrapper 
      *ngIf="!preferences?.disable_pi"
      [control]="form.get('last_name')" 
      label="{{ 'lastName' | translate }}"
      identifier="last_name">
      <input class="form_control" type="text" formControlName="last_name" name="last_name" id="last_name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('date_of_birth')" 
      label="{{ 'dateOfBirth' | translate }}"
      identifier="date_of_birth">
      <input 
        class="form_control" 
        [matDatepicker]="picker"
        [datePickerFormat]="preferencesService.defaultDateFormat.serverValue"
        [max]="maxDobDate" 
        formControlName="date_of_birth"
        name="date_of_birth"
        id="date_of_birth">
      <mat-datepicker-toggle 
        *ngIf="!patientLoaded"
        matSuffix
        [for]="picker" 
        class="form_datepicker-icon">
      </mat-datepicker-toggle>
    </app-input-wrapper>

    <mat-datepicker #picker></mat-datepicker>

    <app-input-wrapper 
      [control]="form.get('gender')" 
      label="{{ 'gender' | translate }}">
      <mat-select 
        class="form_control"
        formControlName="gender"
        disableOptionCentering 
        panelClass="dropdown"
        name="gender"
        aria-label="{{ 'gender' | translate }}">
        <mat-option *ngFor="let gender of genders" [value]="gender.id">
          {{ gender.name | translate }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('ethnicity')" 
      label="{{ 'ethnicity' | translate }}">
      <mat-select 
        class="form_control"
        formControlName="ethnicity" 
        disableOptionCentering 
        panelClass="dropdown"
        name="ethnicity"
        aria-label="{{ 'ethnicity' | translate }}">
        <mat-option *ngFor="let ethnicity of ethnicities" [value]="ethnicity.id">
          {{ ethnicity.name | translate }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <ng-container *ngIf="showContactFields">
      <app-input-wrapper 
        [control]="form.get('email')" 
        label="{{ 'email' | translate }}">
        <input
          autocorrect="off"
          autocapitalize="none"
          class="form_control" 
          type="email" 
          formControlName="email">
      </app-input-wrapper>

      <app-input-wrapper 
        [control]="form.get('phone')"
        [hasPlaceholderOffset]="true"
        label="{{ 'phone' | translate }}">
        <ngx-mat-intl-tel-input
          class="form_control"
          [ngClass]="{ 'form_control--disabled': disabled }"
          [preferredCountries]="['us']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          formControlName="phone">
        </ngx-mat-intl-tel-input>
      </app-input-wrapper>
    </ng-container>

    <app-input-wrapper 
      [control]="form.get('patient_id_number')" 
      label="{{ 'patientIdNumber' | translate }}"
      identifier="patient_id_number">
      <input class="form_control" type="text" formControlName="patient_id_number" name="patient_id_number" id="patient_id_number">
    </app-input-wrapper>

    <app-input-wrapper 
      *ngIf="!isNew" 
      [control]="form.get('monocular')" 
      label="{{ 'convergenceTestingResults' | translate }}"
      [forceMinimized]="true">
      
      <div class="radio_group radio_group--vertical-mobile">
        <input 
          id="monocular-on"
          class="radio_input" 
          type="radio" 
          formControlName="monocular" 
          [value]="true">
        <label class="radio_label" for="monocular-on">
          <div class="radio_button"></div>
          <div class="radio_text">{{ 'monocularOn' | translate }}</div>
        </label>

        <input 
          id="monocular-off"
          class="radio_input" 
          type="radio" 
          formControlName="monocular" 
          [value]="false">
        <label class="radio_label" for="monocular-off">
          <div class="radio_button"></div>
          <div class="radio_text">{{ 'monocularOff' | translate }}</div>
        </label>
      </div>
      
    </app-input-wrapper>

    <app-input-wrapper
      *ngIf="!isNew && !preferences?.always_use_subtitles"
      [control]="form.get('subtitles')">
      <input
        type="checkbox"
        class="checkbox_input"
        formControlName="subtitles"
        id="subtitles">
      <label 
        for="subtitles"
        class="checkbox_label"
        [ngClass]="{ 'checkbox_label--error': form.get('subtitles').errors && form.get('subtitles').touched }">
        {{ 'useSubtitles' | translate | titlecase }}
      </label>
    </app-input-wrapper>

    <button *ngIf="!disabled" class="button button--primary form_button" type="submit" [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>

    <button *ngFor="let action of buttonActions" class="button button--primary form_button" type="button" (click)="action.action()">
      <span class="regular">{{ action.nameBold | translate }}</span>
      <span class="text--semibold">{{ action.nameThin | translate }}</span>
    </button>
  </form>
</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>