// native
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { PerimetryRecord, PlotData, EyeStats, MonitorEvent, PerimetryStimulus, PerimetryState, DeviceScreen } from '../../models';

// constant
import {
  EYE, REGION_STEP, MONITOR_EVENT_TYPE, STRATEGY, PROTOCOL, TEST_ACTION,
  TAPED_PROMPT_TIMEOUT, DEVICE_SCREEN, TAPED_PROMPT_ID, MONITOR_INACTIVITY_TIMEOUT
} from '../../constants';

@Component({
  selector: 'app-monitor-perimetry',
  templateUrl: './monitor-perimetry.component.html'
})
export class MonitorPerimetryComponent extends MonitorBaseComponent {
  odData: PlotData[] = [];
  layoutRight;
  osData: PlotData[] = [];
  layoutLeft;

  odIndexList: number[] = [];
  osIndexList: number[] = [];

  odEyeStats: EyeStats = new EyeStats();
  osEyeStats: EyeStats = new EyeStats();

  odCount = 0;
  osCount = 0;

  odIndexSeqNumMap = {};
  osIndexSeqNumMap = {};

  currentRegion: number = null;
  isPtosisTapedPromptShown: boolean = false;

  public inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.PERIMETRY * 1000;

  @ViewChild('monitorContainer') monitorContainer: ElementRef;
  @ViewChild('plotContainer') plotContainer: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService,
      patientsService, monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    const nonThresholdStrategies = [STRATEGY.SUPRA_THRESHOLD, STRATEGY.SUPRA_FAST,
    STRATEGY.CONFRONTATION];

    forkJoin([
      nonThresholdStrategies.includes(this.test.test_group?.strategy?.value)
        ? of({})
        : this.monitorTestService.getPerimetryState(this.test.id, false),
      this.monitorTestService.getPerimetryState(this.test.id, true)
    ]).subscribe(([nonThresholdState, thresholdState]) => {
      this.mergeCurrentState((<PerimetryState>nonThresholdState)?.stimuli);
      this.mergeCurrentState(thresholdState.stimuli);

      this.checkTapedModalShowing(thresholdState.progress?.total, thresholdState.stimuli[thresholdState.stimuli.length - 1]);

      this.osEyeStats.completionPercentage = thresholdState.progress?.os;
      this.odEyeStats.completionPercentage = thresholdState.progress?.od;
    }, error => this.toastService.error(error, 'Error'));
  }

  private mergeRecord(record: PerimetryStimulus) {
    if (this.isRegionShown)
      this.setRegion(record);

    const existingRecordIndex =
      record.eye === EYE.OD
        ? this.odIndexList.indexOf(record.index)
        : this.osIndexList.indexOf(record.index);

    if (existingRecordIndex === -1)
      this.addRecord(record);
    else if (!record.seq_num)
      this.updateRecord(existingRecordIndex, record);
    else if (record.eye === EYE.OD && (this.odIndexSeqNumMap[record.index] <= record.seq_num))
      this.updateRecord(existingRecordIndex, record);
    else if (record.eye === EYE.OS && (this.osIndexSeqNumMap[record.index] <= record.seq_num))
      this.updateRecord(existingRecordIndex, record);

    if (record.eye === EYE.OD) {
      this.odCount++;
    } else {
      this.osCount++;
    }

    this.updateStats(record);
  }

  private mergeCurrentState(stimuli: PerimetryStimulus[]) {
    if (!stimuli?.length)
      return;

    stimuli.forEach(record => this.mergeRecord(record));
  }

  public addRecord(record: PerimetryStimulus) {
    let existingSnapshot: PlotData = record.eye === EYE.OD ? { ...this.odData[0] } : { ...this.osData[0] };

    if (!existingSnapshot?.marker)
      existingSnapshot = { ...this.monitorTestService.visualTestInitialPlotData };

    let newSnapshot = { ...existingSnapshot };

    const color = this.getMarkerColor(record);

    newSnapshot = {
      ...existingSnapshot,
      marker: {
        ...existingSnapshot.marker,
        color: [...existingSnapshot.marker.color, color]
      },
      x: [...existingSnapshot.x, record.x],
      y: [...existingSnapshot.y, record.y],
      text: [...existingSnapshot.text, '']
    };

    if (record.eye === EYE.OD) {
      this.odIndexList.push(record.index);
      this.odIndexSeqNumMap[record.index] = record.seq_num;
      this.odData = [newSnapshot];
    } else {
      this.osIndexList.push(record.index);
      this.osIndexSeqNumMap[record.index] = record.seq_num;
      this.osData = [newSnapshot];
    }
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_PERIMETRY_RECORD) {
      this.mergeRecord((<PerimetryRecord>event.data).stimulus);

      this.checkTapedModalShowing((<PerimetryRecord>event.data).progress?.total, (<PerimetryRecord>event.data).stimulus);

      this.osEyeStats.completionPercentage = (<PerimetryRecord>event.data).progress?.os;
      this.odEyeStats.completionPercentage = (<PerimetryRecord>event.data).progress?.od;
    }
  }

  public onToggleGrid() {
    this.isGridShown = !this.isGridShown;

    const newXAxis = { ...this.layoutLeft.xaxis, gridcolor: this.isGridShown ? 'grey' : 'transparent' };
    const newYAxis = { ...this.layoutLeft.yaxis, gridcolor: this.isGridShown ? 'grey' : 'transparent' };
    this.layoutLeft = { ...this.layoutLeft, xaxis: newXAxis, yaxis: newYAxis };
    this.layoutRight = { ...this.layoutRight, xaxis: newXAxis, yaxis: newYAxis };
  }

  private updateRecord(index: number, record: PerimetryStimulus) {
    let existingSnapshot: PlotData = record.eye === EYE.OD ? { ...this.odData[0] } : { ...this.osData[0] };
    const color = this.getMarkerColor(record);

    const newShapshot = { ...existingSnapshot };
    newShapshot.marker.color[index] = color;
    newShapshot.x[index] = record.x;
    newShapshot.y[index] = record.y;
    newShapshot.text[index] = '';

    // need to re-construct data objects in order to plotly re-draw it
    const updatedSnapshot = {
      ...newShapshot,
      marker: {
        ...newShapshot.marker,
        color: [...newShapshot.marker.color]
      },
      x: [...newShapshot.x],
      y: [...newShapshot.y],
      text: [...newShapshot.text]
    };

    if (record.eye === EYE.OD) {
      this.odData = [updatedSnapshot];
      this.odIndexSeqNumMap[record.index] = record.seq_num;
    }
    else {
      this.osData = [updatedSnapshot];
      this.osIndexSeqNumMap[record.index] = record.seq_num;
    }
  }

  private updateStats(record: PerimetryStimulus) {
    let eye = record.eye;
    let eyeStats: EyeStats;

    if (eye === EYE.OD) {
      eyeStats = this.odEyeStats;
    }
    else {
      eyeStats = this.osEyeStats;
    }

    eyeStats.totalProcessedCount++;

    if ((record.is_blind_spot && record.viewed && record.threshold && record.response_time > 0.15) || !record.has_good_fixation)
      eyeStats.blindspotCount++;

    if (record.viewed)
      eyeStats.totalViewedCount++;

    if (record.viewed && ((record.response_time < 0.15) || record.false_positive))
      eyeStats.falsePositiveCount++;

    if (record.false_negative !== null) {
      eyeStats.totalFalseNegativeTestsCount++;
      if (record.false_negative === true)
        eyeStats.falseNegativeCount++;
    }
  }

  public calculateDimensions() {
    if (!this.isPlotShown || this.isSimpleLayoutShown)
      return;

    const dimensions = this.monitorTestService.getDoublePlotDimensions(this.monitorContainer.nativeElement, this.plotContainer.nativeElement);
    this.layoutLeft = { ...this.monitorTestService.getVisualTestLayout(this.test?.test_group?.strategy), ...dimensions };
    this.layoutRight = { ...this.monitorTestService.getVisualTestLayout(this.test?.test_group?.strategy), ...dimensions };
  }

  protected updateDeviceScreenStatus(deviceScreen: DeviceScreen) {
    super.updateDeviceScreenStatus(deviceScreen);
    if (deviceScreen?.name !== DEVICE_SCREEN.PAUSED.value)
      this.hidePtosisTapedPrompt();
  }

  private setRegion(record: PerimetryStimulus) {
    if (record.region_no !== this.currentRegion && this.currentRegion !== null)
      this.resetRegionState();

    if ([REGION_STEP.ONE, REGION_STEP.TWO].includes(record.region_no))
      this.currentRegion = record.region_no;
  }

  private checkTapedModalShowing(totalProgress: number, record: PerimetryStimulus) {
    if (this.isPtosisTapedPromptShown)
      return;
    if (this.test.test_group?.protocol?.value !== PROTOCOL.PTOSIS_30_2_TRADITIONAL)
      return;
    if (record?.region_no === REGION_STEP.TWO)
      return;
    if (totalProgress === 50)
      this.showPtosisTapedPrompt();
  }

  private showPtosisTapedPrompt() {
    this.isPtosisTapedPromptShown = true;
    this.dialogService.openConfirm({
      message: this.translateService.instant('ptosisPromptMessage'),
      action: null,
      confirmText: this.translateService.instant('continue'),
      showClose: false,
      showCancel: false,
      id: TAPED_PROMPT_ID
    }).then(result => {
      if (result.confirmed)
        this.triggerAction(TEST_ACTION.PLAY);
    });
    setTimeout(() => this.hidePtosisTapedPrompt(), TAPED_PROMPT_TIMEOUT);
  }

  private hidePtosisTapedPrompt() {
    this.dialogService.closeConfirm({ closed: true }, TAPED_PROMPT_ID);
  }

  private resetRegionState() {
    this.odData = [];
    this.osData = [];

    this.odIndexList = [];
    this.osIndexList = [];

    this.odEyeStats = new EyeStats();
    this.osEyeStats = new EyeStats();

    this.odCount = 0;
    this.osCount = 0;
  }

  public resetStateAfterReconnection() {
    this.odIndexList = [];
    this.osIndexList = [];

    this.odEyeStats = {
      ...this.odEyeStats,
      blindspotCount: 0,
      falsePositiveCount: 0,
      falseNegativeCount: 0,
      totalFalseNegativeTestsCount: 0,
      totalLocationCount: 0,
      totalViewedCount: 0,
      totalProcessedCount: 0
    };
    this.osEyeStats = {
      ...this.osEyeStats,
      blindspotCount: 0,
      falsePositiveCount: 0,
      falseNegativeCount: 0,
      totalFalseNegativeTestsCount: 0,
      totalLocationCount: 0,
      totalViewedCount: 0,
      totalProcessedCount: 0
    };

    this.odCount = 0;
    this.osCount = 0;

    this.odIndexSeqNumMap = {};
    this.osIndexSeqNumMap = {};
  }
}
