// native
import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

//addon
import { TranslateService } from '@ngx-translate/core';

// services
import { PatientsService } from '../../core/services/patients.service';

// models
import { Patient, PatientButtonActions } from '../../models';

@Component({
  selector: 'app-view-patient',
  templateUrl: './view-patient.component.html'
})
export class ViewPatientComponent implements OnInit {
  patient: Patient;

  buttonActions: Array<PatientButtonActions> = [
    {
      nameThin: 'test',
      nameBold: 'new',
      action: () => this.openNewTest()
    },
    {
      nameThin: 'tests',
      nameBold: 'previous',
      action: () => this.openExistingTests()
    }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patientsService: PatientsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.patientsService.getOne(id).subscribe((patient: Patient) => {
        this.patient = patient;
      }, err => {
        this.closeForm();
      });
    });
    this.translateService.get('test').subscribe(() => this.setTranslations());
  }

  private setTranslations() {
    const titleCasePipe = new TitleCasePipe();
    this.buttonActions[0].nameThin = titleCasePipe.transform(this.translateService.instant('test'));
    this.buttonActions[0].nameBold = titleCasePipe.transform(this.translateService.instant('new'));
    this.buttonActions[1].nameThin = titleCasePipe.transform(this.translateService.instant('tests'));
    this.buttonActions[1].nameBold = titleCasePipe.transform(this.translateService.instant('previous'));
  }

  closeForm() {
    this.router.navigate(['/patients']);
  }

  openExistingTests() {
    this.router.navigate(['/tests', this.patient.id]);
  }

  openNewTest() {
    this.router.navigate(['/tests/new', this.patient.id]);
  }
}