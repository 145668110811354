// native
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

// service
import { ApiService } from './api.service';

// models
import { Device } from '../../models';

// constants
import { API_DEVICES_PATH } from '../../constants';

interface UrlPayload {
  token_url: string;
}

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  streamSocket: WebSocket;
  streamPending: boolean = false;

  dataId = 0;
  dataLength = 0;
  dataLeftImageLength = 0;
  receivedLength = 0;
  rawImageData = new Uint8Array(0);

  streamingStopped$: Subject<void> = new Subject<void>();
  private streamLastUpdated?: Date;
  private streamCheckInterval?: number;
  private STREAM_MAX_DELAY_MS = 30 * 1000;
  private STREAM_CHECK_INTERVAL_MS = 10 * 1000;

  constructor(
    private apiService: ApiService
  ) { }

  getStreamUrl(deviceId: number): Observable<UrlPayload> {
    let path = `${API_DEVICES_PATH}${deviceId}/monitor/`;
    return this.apiService.get(path).pipe(map(res => res as UrlPayload));
  }

  isSingleImageStream(device: Device): boolean {
    if (device?.capabilities?.streaming_camera_count === 1)
      return true;
    if (device?.capabilities?.streaming_camera_count === 2)
      return false;
    return false;
  }

  streamDevice(url: string, deviceId: string, isSingleImage: boolean, leftImageEl: HTMLImageElement, rightImageEl: HTMLImageElement): void {
    // url = 'ws://localhost:9898/'; //local test override
    this.streamSocket?.close();

    this.streamSocket = new WebSocket(url, 'json.webpubsub.azure.v1');

    this.streamSocket.onopen = () => {
      this.streamSocket.send(JSON.stringify({
        "type": "joinGroup",
        "group": deviceId
      }));
    };

    this.streamSocket.onmessage = event => {
      const message = JSON.parse(event.data);
      if (message.data) {
        this.setStreamUpdateTime();
        this.readImage(message.data, isSingleImage, leftImageEl, rightImageEl);
      }
    };

    this.setStreamUpdateTime();
    this.setStreamActivityPing();
  }

  private readImage(rawData: any, isSingleImage: boolean, leftImageEl: HTMLImageElement, rightImageEl: HTMLImageElement) {
    if (!rawData)
      return;

    let parsedRawData;

    try {
      parsedRawData = JSON.parse(rawData);
    } catch {
      return;
    }

    if (!parsedRawData.rawImage)
      return;

    let byteData = new Uint8Array(parsedRawData.rawImage);
    let dataId = this.byteToInt32(byteData, 0);

    const unityMetaAmount = isSingleImage ? 8 : 12;

    // only if new image different then the previous image we will process and render the new image
    if (dataId != this.dataId) {
      this.receivedLength = 0;
      this.rawImageData = new Uint8Array(0);
      this.dataId = dataId;
      this.dataLength = this.byteToInt32(byteData, 4);
      this.dataLeftImageLength = this.byteToInt32(byteData, 8);
    }

    this.receivedLength += byteData.length - unityMetaAmount;
    this.rawImageData = this.combineInt8Array(this.rawImageData, byteData.slice(unityMetaAmount, byteData.length)) as any;

    if (this.receivedLength == this.dataLength)
      this.processImageData(this.rawImageData, isSingleImage, leftImageEl, rightImageEl);
  }

  private processImageData(byte: any, isSingleImage: boolean, leftImageEl: HTMLImageElement, rightImageEl: HTMLImageElement) {
    let leftBinary = '';
    let rightBinary = '';

    let bytes = new Uint8Array(byte);
    //----conver byte[] to Base64 string----
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      if (isSingleImage) {
        leftBinary += String.fromCharCode(bytes[i]);
        continue;
      }

      if (i < this.dataLeftImageLength)
        leftBinary += String.fromCharCode(bytes[i]);
      else
        rightBinary += String.fromCharCode(bytes[i]);
    }

    leftImageEl.src = 'data:image/jpeg;base64,' + btoa(leftBinary);
    rightImageEl.src = 'data:image/jpeg;base64,' + btoa(rightBinary);

    if (this.streamPending)
      this.streamPending = false;
  }

  private combineInt8Array(a, b) {
    let c = new Int8Array(a.length + b.length);
    c.set(a);
    c.set(b, a.length);
    return c;
  }

  private byteToInt32(byte, offset) {
    return (byte[offset] & 255) + ((byte[offset + 1] & 255) << 8) + ((byte[offset + 2] & 255) << 16) + ((byte[offset + 3] & 255) << 24);
  }

  closeStream() {
    this.streamPending = false;
    this.dataId = 0;
    this.streamSocket?.close();
    window.clearInterval(this.streamCheckInterval);
  }

  private setStreamUpdateTime() {
    this.streamLastUpdated = new Date();
  }

  // stream stop will be initiated if no new message is recieved for given amount of time
  private setStreamActivityPing() {
    if (this.streamCheckInterval)
      window.clearInterval(this.streamCheckInterval);

    this.streamCheckInterval = window.setInterval(() => {
      const now = new Date().getTime();
      const timeDifference = now - this.streamLastUpdated.getTime();
      if (timeDifference > this.STREAM_MAX_DELAY_MS) {
        this.streamingStopped$.next();
        window.clearInterval(this.streamCheckInterval);
      }
    }, this.STREAM_CHECK_INTERVAL_MS);
  }
}
