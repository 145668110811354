// native
import { NgModule } from "@angular/core";

// addon
import { GridModule } from "@progress/kendo-angular-grid";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { SupportUsersComponent } from './support-users/support-users.component';
import { SupportOfficesComponent } from './support-offices/support-offices.component';
import { SupportDevicesComponent } from './support-devices/support-devices.component';
import { SupportAccountFormComponent } from './support-account-form/support-account-form.component';
import { ChangeEmailComponent } from './change-email/change-email.component';

@NgModule({
  imports: [
    SharedModule,
    GridModule
  ],
  declarations: [
    SupportUsersComponent,
    SupportOfficesComponent,
    SupportDevicesComponent,
    SupportAccountFormComponent,
    ChangeEmailComponent
  ]
})
export class SupportModule { }