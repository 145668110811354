// native
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { PupillometryState } from '../../models';

// constants
import { MONITOR_EVENT_TYPE, MONITOR_INACTIVITY_TIMEOUT, PUPIL_PERIOD_TYPES, STRATEGY } from '../../constants';

@Component({
  selector: 'app-monitor-pupillometry',
  templateUrl: './monitor-pupillometry.component.html'
})
export class MonitorPupillometryComponent extends MonitorBaseComponent {

  state: PupillometryState = null;
  percentageDone: string = null;

  osOffset: number = 0;
  odOffset: number = 0;

  TIMER_ANIMATION_FRAME_COUNT = 10;

  isODAdaptation: boolean;
  isODStimulation: boolean;
  isODAPD: boolean;
  isODReact: boolean;
  isODPhaseL: boolean;
  isODPhaseD: boolean;

  isOSAdaptation: boolean;
  isOSStimulation: boolean;
  isOSAPD: boolean;
  isOSReact: boolean;
  isOSPhaseL: boolean;
  isOSPhaseD: boolean;

  isBluePupil: boolean;
  isVersionTwo: boolean;

  public inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.PUPIL * 1000;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.isBluePupil = this.test.test_group?.strategy?.value === STRATEGY.BLUE_PUPILLOMETRY;

    const isPupilVersionTwo = (this.device?.capabilities?.pupil_standard_algorithm_version === 2) && (this.test?.test_group?.strategy?.value === STRATEGY.PUPILLOMETRY);
    const isPerrlVersionTwo = (this.device?.capabilities?.perrl_algorithm_version === 2) && (this.test?.test_group?.strategy?.value === STRATEGY.PERRL);
    this.isVersionTwo = isPupilVersionTwo && isPerrlVersionTwo;

    this.monitorTestService.getPupillometryState(this.test.id).subscribe(state => {
      this.state = state;
      this.setPercentageDone();
      this.setPeriod();

      if (state?.os_state && state?.od_state)
        this.updateTimerDisplay(false);
    });
  }

  private setPeriod() {
    this.isODAdaptation = (this.state?.od_state?.period_type === PUPIL_PERIOD_TYPES.ADAPTATION);
    this.isODAPD = (this.state?.od_state?.period_type === PUPIL_PERIOD_TYPES.APD);
    this.isODStimulation = (this.state?.od_state?.period_type === PUPIL_PERIOD_TYPES.STIMULATION);
    this.isODReact = (this.state?.od_state?.period_type === PUPIL_PERIOD_TYPES.REACT);
    this.isODPhaseL = (this.state?.od_state?.period_type === PUPIL_PERIOD_TYPES.PHASE_L);
    this.isODPhaseD = (this.state?.od_state?.period_type === PUPIL_PERIOD_TYPES.PHASE_D);

    this.isOSAdaptation = (this.state?.os_state?.period_type === PUPIL_PERIOD_TYPES.ADAPTATION);
    this.isOSAPD = (this.state?.os_state?.period_type === PUPIL_PERIOD_TYPES.APD);
    this.isOSStimulation = (this.state?.os_state?.period_type === PUPIL_PERIOD_TYPES.STIMULATION);
    this.isOSReact = (this.state?.os_state?.period_type === PUPIL_PERIOD_TYPES.REACT);
    this.isOSPhaseL = (this.state?.os_state?.period_type === PUPIL_PERIOD_TYPES.PHASE_L);
    this.isOSPhaseD = (this.state?.os_state?.period_type === PUPIL_PERIOD_TYPES.PHASE_D);
  }

  private setPercentageDone() {
    this.percentageDone = Math.min((this.state.test_percentage_done), 100).toFixed(0);
  }

  private updateTimerDisplay(showAnimation: boolean = true) {
    const oldOsOffset = this.osOffset;
    const oldOdOffset = this.odOffset;

    const newOsOffset = this.state.os_state.period_completion_percentage;
    const newOdOffset = this.state.od_state.period_completion_percentage;

    if (!showAnimation) {
      this.osOffset = newOsOffset;
      this.odOffset = newOdOffset;
      return;
    }

    const osFrameOffset = (newOsOffset - oldOsOffset) / this.TIMER_ANIMATION_FRAME_COUNT;
    const odFrameOffset = (newOdOffset - oldOdOffset) / this.TIMER_ANIMATION_FRAME_COUNT;

    let counter = 0;
    const intervalID = setInterval(() => {
      this.osOffset = this.osOffset + osFrameOffset;
      this.odOffset = this.odOffset + odFrameOffset;
      counter++;

      if (counter >= this.TIMER_ANIMATION_FRAME_COUNT) {
        window.clearInterval(intervalID);
      }
    }, 50);
  }

  public handleNewRecordEvent(event: any) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_PUPILLOMETRY_STATE) {
      const eventSeqNum = (<PupillometryState>event.data).seq_num;
      if (!eventSeqNum || !this.state?.seq_num || (this.state.seq_num <= eventSeqNum)) {
        this.state = <PupillometryState>event.data;
        this.setPercentageDone();
        this.setPeriod();

        if (this.state.os_state && this.state.od_state)
          this.updateTimerDisplay();
      }
    }
  }

  public resetStateAfterReconnection(): void { }

  public calculateDimensions() { }

  public onToggleGrid() { }
}
