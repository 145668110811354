// native
import { TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

// services
import { WorkItemService } from 'app/core/services/work-item.service';
import { AuthService } from 'app/core/services/auth.service';

// models
import { Tab, GridItem, PacsIntegrationRecord } from '../../models';

// constants
import { DEFAULT_SUPPORT_PAGE_INDEX, DEFAULT_SUPPORT_PAGE_SIZE, DEFAULT_SUPPORT_PAGE_SKIP, DEFAULT_SUPPORT_SORT_ORDER, PACS_STATUS } from '../../constants';

@Component({
  selector: 'app-work-items-history',
  templateUrl: './work-items-history.component.html'
})
export class WorkItemsHistoryComponent implements OnInit {

  tabs: Tab[] = [
    {
      translationKey: 'workItems',
      action: () => this.router.navigate([`/work-items`]),
      isActive: false
    },
    {
      translationKey: 'pacsRecords',
      action: null,
      isActive: true
    }
  ];

  pageSize = DEFAULT_SUPPORT_PAGE_SIZE;
  pageIndex = DEFAULT_SUPPORT_PAGE_INDEX;
  skip = DEFAULT_SUPPORT_PAGE_SKIP;
  sort: SortDescriptor[] = [{
    field: 'patient_name',
    dir: DEFAULT_SUPPORT_SORT_ORDER,
  }];
  filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  gridData$: BehaviorSubject<GridItem<any>> = new BehaviorSubject<GridItem<any>>({} as GridItem<PacsIntegrationRecord>);

  isLoading: boolean = true;
  languageSubscription: Subscription;

  statusCategories: { name: string, value: string; }[] = [];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private workItemsService: WorkItemService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setStatusCategories();

    this.getWorkItemsHistoryRecords();

    this.languageSubscription = this.translateService.onLangChange.subscribe(res => {
      this.setStatusCategories();
    });
  }

  private setStatusCategories() {
    this.statusCategories = [
      {
        name: new TitleCasePipe().transform(this.translateService.instant(PACS_STATUS.INITIALIZED.translationKey)),
        value: PACS_STATUS.INITIALIZED.value
      },
      {
        name: new TitleCasePipe().transform(this.translateService.instant(PACS_STATUS.MESSAGE_SENT.translationKey)),
        value: PACS_STATUS.MESSAGE_SENT.value
      },
      {
        name: new TitleCasePipe().transform(this.translateService.instant(PACS_STATUS.MESSAGE_FAILED.translationKey)),
        value: PACS_STATUS.MESSAGE_FAILED.value
      },
      {
        name: new TitleCasePipe().transform(this.translateService.instant(PACS_STATUS.AGENT_FAILED.translationKey)),
        value: PACS_STATUS.AGENT_FAILED.value
      },
      {
        name: new TitleCasePipe().transform(this.translateService.instant(PACS_STATUS.AGENT_SUCCESS.translationKey)),
        value: PACS_STATUS.AGENT_SUCCESS.value
      }
    ];
  }

  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageIndex = this.skip / this.pageSize;
    this.getWorkItemsHistoryRecords();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getWorkItemsHistoryRecords();
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.getWorkItemsHistoryRecords();
  }

  private getWorkItemsHistoryRecords() {
    this.isLoading = true;
    this.workItemsService.getRecords(this.pageSize, this.pageIndex, this.sort, this.filter).subscribe(res => {
      this.gridData$.next(res);
      this.isLoading = false;
    });
  }

  getStatusTranslation(status: string): string {
    if (!status || !PACS_STATUS[status])
      return '';
    return new TitleCasePipe().transform(this.translateService.instant(PACS_STATUS[status].translationKey));
  }

  restart(record: PacsIntegrationRecord) {
    this.workItemsService.restartPacsForRecord(record.id).subscribe(() => {
      this.getWorkItemsHistoryRecords();
    });
  }
}
