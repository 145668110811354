<app-center-container
  [title]="title"
  [showClose]="true"
  (close)="close()">

  <br>
  <div *ngIf="office">
    {{ 'selectAdminToAssign' | translate }} {{ office.name }}:
  </div>
  <br>

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <div class="text--left">
      <small>
        {{ 'usersNotShown' | translate }}
      </small>
    </div>

    <br>

    <app-input-wrapper 
      [control]="form.get('user')" 
      label="{{ 'user' | translate }}">
      <mat-select 
        class="form_control"
        name="user"
        aria-label="{{ 'user' | translate }}"
        formControlName="user"
        disableOptionCentering 
        panelClass="dropdown">
        <mat-option *ngFor="let user of users" [value]="user">
          {{ user.first_name + ' ' + user.last_name }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <br>

    <button class="button button--primary form_button" type="submit" [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>