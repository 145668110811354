// native
import { Injectable } from '@angular/core';

import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

// service
import { AuthService } from '../services/auth.service';
import { TechallSessionService } from '../services/techall-session.service';

@Injectable({
  providedIn: 'root'
})
export class CustomBundleGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private techallSessionService: TechallSessionService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isOfficeGroupAdmin || (this.authService.isTechallAdmin && !!this.techallSessionService.currentSession))
      return true;
    else {
      this.router.navigate(['/patients']);
      return false;
    }
  }
}
