<app-center-container
  [title]="title"
  [showClose]="true"
  [hasOverlay]="true"
  (close)="onClose.emit()">

  <br>
  <div>
    {{ 'changeEmailMessage' | translate }} {{ user?.first_name + ' ' + user?.last_name }}:
  </div>
  <br>

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmitClick(form)">
    <app-input-wrapper 
      [control]="form.get('email')" 
      label="{{ 'email' | translate }}"
      identifier="email">

      <input 
        type="text"
        id="email"
        class="form_control"
        formControlName="email">
    </app-input-wrapper>
    <br>

    <button class="button button--primary form_button" type="submit" [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>

