<app-device-dashboard
  (onUpdate)="onDeviceDashboardUpdated($event)">
</app-device-dashboard>

<div class="action-header_container action-header_container--single">
  <app-review-patients>
  </app-review-patients>
</div>

<app-list-header
  name="patient"
  (onCreate)="create()"
  (onSearch)="search($event)">
</app-list-header>

<app-item-list 
  [columns]="columns" 
  [actions]="actions"
  [orangeMarker]="orangeMarker" 
  [items$]="patients$" 
  [pageSize]="currentPageSize"
  (onItemClick)="view($event)"   
  (onChangePage)="changePage($event.pageIndex, $event.pageSize)">
</app-item-list>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>