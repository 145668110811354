
<div class="validator_container">
  <div class="validator_item">
    <i [ngClass]="password.match(CHAR_REGEX) ? 'fas fa-check color-primary' : 'fas fa-times orange'"></i>
    <span>{{ 'passwordValidationChar' | translate }}</span>
  </div>
  <div class="validator_item">
    <i [ngClass]="password.match(NUMBER_REGEX) ? 'fas fa-check color-primary' : 'fas fa-times orange'"></i>
    <span>{{ 'passwordValidationNumber' | translate }}</span>
  </div>
  <div class="validator_item">
    <i [ngClass]="password.match(SPECIAL_REGEX) ? 'fas fa-check color-primary' : 'fas fa-times orange'"></i>
    <span>{{ 'passwordValidationSpecial' | translate }}</span>
  </div>
  <div class="validator_item">
    <i [ngClass]="password.match(TOTAL_REGEX) ? 'fas fa-check color-primary' : 'fas fa-times orange'"></i>
    <span>{{ 'passwordValidationTotal' | translate }}</span>
  </div>
  <div class="validator_item">
    <i [ngClass]="password.match(LEADING_TRAILING_SPACES_REGEX) ? 'fas fa-check color-primary' : 'fas fa-times orange'"></i>
    <span>{{ 'passwordValidationSpaces' | translate }}</span>
  </div>

  <div class="validator_icon">
    <i 
      class="fas fa-key"
      matTooltip="{{ 'generatePassword' | translate }}"
      (click)="onSuggestClick($event)">
    </i>
  </div>
</div>
