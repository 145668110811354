<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column monitor_container-column--single-or-double" (window:resize)="windowChanged.emit(getEvent())" #plotContainer>
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="osColors.length" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <app-color-circles 
        [colors]="osColors"
        [height]="circleHeight">
      </app-color-circles>
    </div>
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="odColors.length" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <app-color-circles 
        [colors]="odColors"
        [height]="circleHeight">
      </app-color-circles>
    </div>
    <div class="monitor_graph-box monitor_graph-box--single" *ngIf="bothColors.length" >
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-color-circles 
        [colors]="bothColors"
        [height]="circleHeight">
      </app-color-circles>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box" *ngIf="osColors.length">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <app-percentage-circle
        id="test-percentage-left"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone(osColors)">
      </app-percentage-circle>
    </div>
    <div class="monitor_graph-box" *ngIf="odColors.length">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <app-percentage-circle
        id="test-percentage-right"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone(odColors)">
      </app-percentage-circle>
    </div>
    <div class="monitor_graph-box" *ngIf="bothColors.length">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        id="test-percentage-both"
        class="monitor_circle-box" 
        [percentage]="getPercentageDone(bothColors)">
      </app-percentage-circle>
    </div>
  </div>
</div>