// native
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// service
import { ApiService } from 'app/core/services/api.service';
import { UtilityService } from './utility.service';

// models
import { Operator } from '../../models';

// constants
import { API_OPERATORS_PATH } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class OperatorsService {

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
  ) { }

  public getAll(patientId?: number): Observable<Operator[]> {
    const url = this.utilityService.appendQueryParam(API_OPERATORS_PATH, 'patient_id', patientId);
    return this.apiService.get(url).pipe(map(obj => obj as Operator[]));
  }

}
