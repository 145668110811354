// native
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

// service
import { ApiService } from 'app/core/services/api.service';

// models
import { PaginatedItems, RecurringTestPostRequest, RecurringTest, RecurringTestPutRequest } from '../../models';

// constant
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, API_TESTS_RECURRING_PATH } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class TestsRecurringService {

  constructor(
    public apiService: ApiService
  ) { }

  public create(body: RecurringTestPostRequest): Observable<RecurringTest> {
    return this.apiService.post(`${API_TESTS_RECURRING_PATH}`, body).pipe(map(obj => obj as RecurringTest));
  }

  public getAllTests(patientId: number, pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<RecurringTest>> {
    let path = this.calculateGetAllPath(patientId, pageSize, pageIndex, term);

    path = path + `&scheduled_entity=T`;

    return this.apiService.get(path) as Observable<PaginatedItems<RecurringTest>>;
  }

  public getAllBundles(patientId: number, pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<RecurringTest>> {
    let path = this.calculateGetAllPath(patientId, pageSize, pageIndex, term);

    path = path + `&scheduled_entity=B`;

    return this.apiService.get(path) as Observable<PaginatedItems<RecurringTest>>;
  }

  private calculateGetAllPath(patientId: number, pageSize: number, pageIndex: number, term: string): string {
    let path = `${API_TESTS_RECURRING_PATH}?patient=${patientId}&limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    return path;
  }

  public delete(id: number): Observable<any> {
    return this.apiService.delete(`${API_TESTS_RECURRING_PATH}${id}/`);
  }

  public getOne(id: number): Observable<RecurringTest> {
    return this.apiService.get(`${API_TESTS_RECURRING_PATH}${id}`).pipe(map(obj => obj as RecurringTest));
  }

  public update(id: number, body: RecurringTestPutRequest): Observable<RecurringTest> {
    return this.apiService.put(`${API_TESTS_RECURRING_PATH}${id}/`, body).pipe(map(obj => obj as RecurringTest));
  }
}