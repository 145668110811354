<div class="video_container" [ngClass]="{
    'video_container--centered': !!isUser && !full && !minimized,
    'video_container--portrait': isPortrait,
    'video_container--minimized': !!minimized,
    'video_container--full': !!full
   }">
  <div class="video_panel" *ngIf="!isWaiting && !isEnding && !isUser">
    <span (click)="onFullToggle()" class="stream_full-icon" *ngIf="!minimized">
      <i 
        *ngIf="!full, else compressTemplate" 
        class="fas fa-expand-arrows-alt"
        matTooltip="{{ 'fullScreen' | translate | titlecase }}">
      </i>
    </span>
    <span (click)="onMinimizeToggle()" *ngIf="!full">
      <i 
        *ngIf="!minimized, else maximizeTemplate" 
        class="far fa-window-minimize" 
        matTooltip="{{ 'minimize' | translate | titlecase }}">
      </i>
    </span>
  </div>

  <div *ngIf="isWaiting || (isUser && !inCall), else endingTemplate" class="video_message">
    <div>
      <div>
        {{ isUser ? ('waitingForTechnician' | translate) : ('callWillStart' | translate) }}
      </div>
      <br>
      <small>{{ isUser ? ('micCameraReminder' | translate) : ('callWaitingInfoMessage' | translate) }}</small>
      <br>
      <br>
      <app-load [isLight]="true" class="text--xxs">
      </app-load>
    </div>
  </div>

  <ng-template #endingTemplate>
    <div *ngIf="isEnding" class="video_message">
      <div>
        <div>
          {{ 'callEnded' | translate }}
        </div>
        <app-load [isLight]="true" class="text--xxs">
        </app-load>
      </div>
    </div>
  </ng-template>

  <div 
    id="video_renderer" 
    class="video_renderer" 
    [ngClass]="{
      'video_renderer--minimized': !!minimized, 
      'video_renderer--full': !!full
    }">
    <div class="video_controls" *ngIf="isControlsShown()">
      <div 
        *ngIf="!isMuted" 
        (click)="mute.emit()" 
        matTooltip="{{ 'muteMicrophone' | translate | titlecase }}">
        <i class="fas fa-microphone-slash"></i>
      </div>
      <div 
        *ngIf="isMuted" 
        (click)="unmute.emit()" 
        matTooltip="{{ 'unmuteMicrophone' | translate | titlecase }}">
        <i class="fas fa-microphone-slash red"></i>
      </div>
      <div 
        *ngIf="!isVideo" 
        (click)="startVideo.emit()" 
        matTooltip="{{ 'showVideo' | translate | titlecase }}">
        <i class="fas fa-video-slash red"></i>
      </div>
      <div 
        *ngIf="isVideo" 
        (click)="stopVideo.emit()"
        matTooltip="{{ 'hideVideo' | translate | titlecase }}">
        <i class="fas fa-video-slash"></i>
      </div>
      <!-- hide screen share for now -->
      <div 
        *ngIf="!isSharing && false" 
        (click)="startSharing.emit()" 
        matTooltip="{{ 'shareScreen' | translate | titlecase }}">
        <i class="fas fa-desktop"></i>
      </div>
      <div 
        *ngIf="isSharing && false" 
        (click)="stopSharing.emit()" 
        matTooltip="{{ 'stopSharing' | translate | titlecase }}">
        <i class="fas fa-desktop red"></i>
      </div>
      <div (click)="endCall.emit()" matTooltip="{{ 'endCall' | translate | titlecase }}">
        <i class="fas fa-phone red"></i>
      </div>
    </div>
  </div>

</div>

<ng-template #maximizeTemplate>
  <i class="far fa-window-maximize" matTooltip="{{ 'maximize' | translate | titlecase }}"></i>
</ng-template>

<ng-template #compressTemplate>
  <i class="fas fa-compress-arrows-alt" matTooltip="{{ 'exitFullScreen' | translate | titlecase }}"></i>
</ng-template>