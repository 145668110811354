// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// service
import { DevicesService } from 'app/core/services/devices.service';

// models
import { Device } from 'app/models';

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html'
})
export class EditDeviceComponent implements OnInit {

  device: Device;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private devicesService: DevicesService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.devicesService.getOne(id).subscribe((device: Device) => {
        this.device = device;
      }, err => {
        this.closeForm();
      });
    });
  }

  closeForm() {
    this.router.navigate(['/devices']);
  }
}
