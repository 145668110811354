// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// service
import { SsoService } from 'app/core/services/sso.service';

@Component({
  selector: 'app-sso-onboarding',
  templateUrl: './sso-onboarding.component.html'
})
export class SsoOnboardingComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private ssoService: SsoService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const ssoDomain = params['sso_domain'];
      const invitation = params['invitation'];

      this.ssoService.ssoLogin(ssoDomain, invitation);
    });
  }
}
