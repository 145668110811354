// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { EditOfficeComponent } from "./edit-office/edit-office.component";
import { NewOfficeComponent } from "./new-office/new-office.component";
import { OfficeFormComponent } from "./office-form/office-form.component";
import { OfficesComponent } from "./offices.component";
import { SelectOfficeComponent } from "./select-office/select-office.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    OfficesComponent,
    NewOfficeComponent,
    EditOfficeComponent,
    OfficeFormComponent,
    SelectOfficeComponent
  ]
})
export class OfficesModule { }