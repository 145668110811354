// native
import { Component } from '@angular/core';

// service
import { UtilityService } from '../core/services/utility.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html'
})
export class NotFoundComponent {
  constructor(
    public utilityService: UtilityService
  ) { }
}