<div class="breadcrumb_container">
  <button 
    (click)="onClick(i)"
    *ngFor="let stepLabel of stepsLabels; let i = index;"
    class="breadcrumb_item bold"
    type="button" 
    [title]="stepLabel">
    {{ (i === 0) ? (stepLabel | translate) : stepLabel }}
  </button>
</div>
