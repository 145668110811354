<app-tabs 
  *ngIf="tabs?.length"
  [tabs]="tabs">
</app-tabs>

<br>

<app-list-header
  name="filter"
  [canSearch]="false"
  (onCreate)="createFilter()">
</app-list-header>

<app-item-list
  [columns]="columns"
  [actions]="actions"
  [greenMarker]="greenMarker"
  [redMarker]="redMarker"
  [icons]="[
    { name: 'user', visible: isPatientItem },
    { name: 'exclamation-circle red', visible: isWarning, getTooltip: getWarningDetails }
    ]"
  [items$]="workItems$"
  [pageable]="false"
  (onItemClick)="view($event)">
</app-item-list>

<app-work-item-details
  *ngIf="selectedItem"
  [item]="selectedItem"
  (close)="selectedItem = null">
</app-work-item-details>

<app-loader *ngIf="isLoading || (workItemsService.workItemsLoading$ | async)" [transparent]="true"></app-loader>
