// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { InvitationsComponent } from './invitations.component';
import { InvitationFormComponent } from "./invitation-form/invitation-form.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    InvitationsComponent,
    InvitationFormComponent
  ]
})
export class InvitationsModule { }