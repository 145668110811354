// native
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { TestsRecurringService } from 'app/core/services/tests-recurring.service';
import { UtilityService } from 'app/core/services/utility.service';
import { PreferencesService } from 'app/core/services/preferences.service';

// models
import { CenterContainerTitle, RecurringTest, RecurringTestPutRequest } from '../../models';

@Component({
  selector: 'app-test-recurring-form',
  templateUrl: './test-recurring-form.component.html'
})
export class TestRecurringFormComponent implements OnInit, OnChanges {
  @Input() test: RecurringTest;
  @Input() action: string;
  @Input() isEdit: boolean = false;

  @Output() close: EventEmitter<RecurringTest> = new EventEmitter<RecurringTest>();

  public form: UntypedFormGroup;
  title: CenterContainerTitle = {};
  minEndDate = new Date();

  constructor(
    public formBuilder: UntypedFormBuilder,
    public testsRecurringService: TestsRecurringService,
    public toastService: ToastrService,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    public preferencesService: PreferencesService
  ) { }

  ngOnInit() {
    this.translateService.get('recurringTest').subscribe(() => {
      this.initializeForm();
      this.setTranslations();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['action']?.currentValue)
      this.title.thin = changes['action']?.currentValue;
  }

  private initializeForm() {
    const { period_in_days, end_date } = this.test || ({} as RecurringTest);

    this.form = this.formBuilder.group({
      period_in_days: [period_in_days, Validators.required],
      end_date: [this.utilityService.convertServerDateToClientDate(end_date), Validators.required]
    });

    !this.isEdit && this.form.disable();
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('recurringTest');
  }

  onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    const body: RecurringTestPutRequest = {
      period_in_days: this.form.value.period_in_days,
      end_date: this.utilityService.convertClientDateToServerDate(this.form.value.end_date)
    };

    this.testsRecurringService.update(this.test.id, body).subscribe(
      (response: RecurringTest) => this.close.emit(response),
      response => { response && response.error && this.toastService.error(response.error); }
    );
  }
}
