// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { TestsService } from 'app/core/services/tests.service';
import { PatientsService } from 'app/core/services/patients.service';
import { DialogService } from 'app/core/services/dialog.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { PaginatedItems, CenterContainerTitle, Patient, Test, TestMoveRequest } from '../../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';


@Component({
  selector: 'app-move-test',
  templateUrl: './move-test.component.html'
})
export class MoveTestComponent implements OnInit {

  form: UntypedFormGroup;
  patients$: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
  test: Test;

  currentPatientId: string;
  selectedPatient: Patient;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private patientsService: PatientsService,
    private testsService: TestsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private preferencesService: PreferencesService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    const testId = this.route.snapshot.params['id'];
    this.currentPatientId = this.route.snapshot.queryParams['patient'];

    this.translateService.get('user').subscribe(() => this.setTranslations());

    this.isLoading = true;
    this.testsService.getOne(testId).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((test: Test) => {
      if (!test)
        this.close();

      this.test = test;
    }, err => this.close());

    this.initializeForm();

    this.patientsService.getAll().subscribe((response: PaginatedItems<Patient>) => {
      this.patients$.next(this.filterCurrentPatient(response.results));
    });
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('test');
    this.title.thin = this.translateService.instant('move');
  }

  public initializeForm() {
    this.form = this.formBuilder.group({
      patient: ['', Validators.required],
    });

    this.form.controls['patient'].valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      tap((value) => {
        if (this.patientsService.getDisplayName(this.selectedPatient) !== value)
          this.selectedPatient = null;
      }),
      switchMap(term => {
        return this.patientsService.getAll(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, term.toLowerCase());
      }),
      tap(response => {
        this.patients$.next(this.filterCurrentPatient(response.results));
      })
    ).subscribe();
  }

  private filterCurrentPatient(patients: Patient[]): Patient[] {
    const filtered = patients?.filter(patient => patient.id !== parseInt(this.currentPatientId)) || [];
    return filtered;
  }

  onSelectPatient(patient: Patient) {
    this.selectedPatient = patient;
  }

  public onSubmit(form: UntypedFormGroup) {
    if (!form.valid || !this.selectedPatient)
      return;

    this.dialogService.openConfirm({
      action: this.translateService.instant('move'),
      message: this.translateService.instant('testMovePrompt')
        + ' ' + this.patientsService.getDisplayName(this.selectedPatient) + '?',
      text: this.translateService.instant('test') + ': ' +
        (this.test.time_start ? new DatePipe('en-US').transform(this.test.time_start, this.preferencesService.defaultDateTimeFormat) : this.test.id),
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed) {
        this.isLoading = true;;
        const body: TestMoveRequest = {
          patient: this.selectedPatient.id
        };

        this.testsService.move(this.test.id, body).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(res => {
          this.toastService.success(this.translateService.instant('testMoveSuccess'));
          this.router.navigate([`tests/${res.patient?.id}`]);
        }, err => this.errorService.handleError(this.translateService.instant('testMoveError')));
      }
    });
  }

  close() {
    this.router.navigate([`tests/${this.currentPatientId}`]);
  }
}