// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { PatientsService } from 'app/core/services/patients.service';
import { DialogService } from 'app/core/services/dialog.service';
import { LanguageService } from 'app/core/services/language.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { PaginatedItems, CenterContainerTitle, Patient } from '../../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';

@Component({
  selector: 'app-merge-patient',
  templateUrl: './merge-patient.component.html'
})
export class MergePatientComponent implements OnInit {

  form: UntypedFormGroup;
  patients$: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
  sourcePatientId: number;

  selectedPatient: Patient;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public patientsService: PatientsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.sourcePatientId = parseInt(this.route.snapshot.params['id']);

    this.translateService.get('patient').subscribe(() => this.setTranslations());

    this.initializeForm();

    this.patientsService.getAll(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, null, false).subscribe((response: PaginatedItems<Patient>) => {
      this.patients$.next(response.results);
    });
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('patient');
    this.title.thin = this.translateService.instant('merge');
  }

  public initializeForm() {
    this.form = this.formBuilder.group({
      patient: ['', Validators.required],
    });

    this.form.controls['patient'].valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      tap((value) => {
        if (this.patientsService.getDisplayName(this.selectedPatient) !== value)
          this.selectedPatient = null;
      }),
      switchMap(term => {
        return this.patientsService.getAll(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, term.toLowerCase(), false);
      }),
      tap(response => {
        this.patients$.next(response.results);
      })
    ).subscribe();
  }

  onSelectPatient(patient: Patient) {
    this.selectedPatient = patient;
  }

  public onSubmit(form: UntypedFormGroup) {
    if (!form.valid || !this.selectedPatient)
      return;

    this.dialogService.openConfirm({
      action: this.translateService.instant('merge'),
      message: this.translateService.instant('patientMergePrompt'),
      text: this.patientsService.getDisplayName(this.selectedPatient) + ' (' + this.translateService.instant('dateOfBirth').toLowerCase() + ': ' +
        new DatePipe('en-US').transform(this.selectedPatient.date_of_birth, this.languageService.appLocale === 'en-US'
          ? 'MM/dd/yyy' : 'dd/MM/yyyy') + ')?',
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed) {
        this.isLoading = true;;
        this.patientsService.merge(this.sourcePatientId, this.selectedPatient.id).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(() => {
          this.toastService.success(this.translateService.instant('patientMergeSuccess'));
          this.router.navigate([`patients/`]);
        }, err => this.errorService.handleError(this.translateService.instant('patientMergeError')));
      }
    });
  }

  close() {
    this.router.navigate([`patients`]);
  }
}
