<span class="battery__container">
  <ng-container *ngIf="batteryPercentage > 80, else midBatteryTemplate">
    <i class="battery__icon fas fa-battery-full green"></i>
    <span class="battery__text">{{ batteryPercentage }}&#37;</span>
  </ng-container>
  <ng-template #midBatteryTemplate>
    <ng-container *ngIf="batteryPercentage > 20, else lowBatteryTemplate">
      <i 
        class="battery__icon fas gold"
        [ngClass]="{ 'fa-battery-three-quarters': batteryPercentage > 60,
                    'fa-battery-half': batteryPercentage > 30 && batteryPercentage <= 60,
                    'fa-battery-quarter': batteryPercentage <= 30  }">
      </i>
      <span class="battery__text">{{ batteryPercentage }}<small>&#37;</small></span>
    </ng-container>
  </ng-template>
  <ng-template #lowBatteryTemplate>
    <ng-container>
      <i 
        matTooltip="{{ 'headsetBatteryTooltip' | translate }}"
        class="battery__icon fas red"
        [ngClass]="{ 'fa-battery-quarter': batteryPercentage > 5,
                    'fa-battery-empty': batteryPercentage <= 5 }">
      </i>
      <span class="battery__text">{{ batteryPercentage }}<small>&#37;</small></span>
    </ng-container>
  </ng-template>
</span>

