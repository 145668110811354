<div class="uploader_container">
  <input 
    type="file"
    [attr.multiple]="multiple"
    [attr.accept]="allowedFileMimeTypes"
    #defaultFileUploader
    (change)="onFileSelect($event.target.files)"
    class="uploader_input">

  <div 
    *ngIf="files.length"
    class="uploader_content"
    #detailsEdit>

    <span *ngIf="files.length === 1">{{files[0].name}}</span>
    <span *ngIf="files.length > 1">{{files.length}} attached files</span>

    <span 
      class="uploader_icon uploader_icon--indent" 
      (click)="onUploaderClick()">
      <i class="fas fa-upload"></i>
    </span>
    <span 
      class="uploader_icon" 
      (click)="detailsShown = !detailsShown">
      <i class="fas fa-edit"></i>
    </span>

    <div *ngIf="detailsShown" class="uploader_popover">
      <div *ngFor="let file of files, let i=index" class="uploader_list-item">
        <span >{{file.name}}</span>
        <span 
          (click)="onFileRemove($event, i)">
          <i class="fas fa-times"></i>
        </span>
      </div>
    </div>
  </div>

  <div 
    *ngIf="!files?.length"
    class="uploader_placeholder"
    (click)="onUploaderClick()">    
    <i class="fas fa-upload"></i>
    <span>{{ 'fileUploadPlaceholder' | translate }}</span>
  </div>

</div>