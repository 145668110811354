// native
import { Injectable } from '@angular/core';

// addon
import { openDB } from 'idb';

// service
import { UtilityService } from './utility.service';

// model
import { Patient } from 'app/models';

// These should not be changed for backward compatibility
const SEPARATOR = '$';
const DB_NAME = 'ZeroPIIDatabase';
const STORE_NAME = 'ZeroPIIStore';
const PASSWORD_KEY_NAME = 'ZeroPIIKey';
const PATIENTS_KEY_NAME = 'ZeroPIIPatients';
const LAST_UPDATED_KEY_NAME = 'ZeroPIILastUpdated';

// These should not be changed for backward compatibility
const DERIVATION_ALGO = 'PBKDF2';
const GENERATION_ALGO = 'AES-GCM';
const KEY_LENGTH = 256;

// PII iterations and PII digest algorithm can be changed (stored in hashed value)
const PII_ITERATIONS = 50000;
const PII_DIGEST_ALGO = 'SHA-256';

// Password iterations and password digest algorithm can be changed (stored in hashed value)
const PASSWORD_ITERATIONS = 600000;
const PASSWORD_DIGEST_ALGO = 'SHA-512';

// Salt and iv length can be changed (stored in hashed value)
const SALT_LENGTH = 16;
const IV_LENGTH = 12;

const deriveKey = (salt, iterations, hashAlgo, baseKey, keyUsage) =>
  window.crypto.subtle.deriveKey(
    { name: DERIVATION_ALGO, salt, iterations, hash: hashAlgo },
    baseKey, { name: GENERATION_ALGO, length: KEY_LENGTH }, false, keyUsage);

const buff_to_base64 = (buff) =>
  btoa(new Uint8Array(buff).reduce((data, byte) => data + String.fromCharCode(byte), ''));

const base64_to_buf = (b64) => Uint8Array.from(atob(b64), (c) => c.charCodeAt(null));

@Injectable({
  providedIn: 'root'
})
export class ZeroPiiService {
  private encoder = new TextEncoder();
  private decoder = new TextDecoder();

  constructor(
    private utilityService: UtilityService
  ) { }

  async saveKeyFromPassword(password: string) {
    const encodedPassword = this.encoder.encode(password);
    const baseKey = await window.crypto.subtle.importKey(
      'raw', encodedPassword, DERIVATION_ALGO, false, ['deriveKey']);

    const transaction = await this.openKeyDBTransaction('readwrite');
    await transaction.store.put(baseKey, PASSWORD_KEY_NAME);
    await transaction.done;
  };

  async encrypt(value: string): Promise<string> {
    const baseKey = await this.loadKey();
    const salt = window.crypto.getRandomValues(new Uint8Array(SALT_LENGTH));
    // Deriving unique key for each value. This will make encryption more secure.
    const derivedKey = await deriveKey(salt, PII_ITERATIONS, PII_DIGEST_ALGO, baseKey, ['encrypt']);
    const iv = window.crypto.getRandomValues(new Uint8Array(IV_LENGTH));

    const encoded = this.encoder.encode(value);
    const encrypted = await window.crypto.subtle.encrypt(
      { name: GENERATION_ALGO, iv }, derivedKey, encoded);

    const encryptedContentArr = new Uint8Array(encrypted);

    return this.combineBuffers(
      salt, this.encoder.encode(`${PII_ITERATIONS}`),
      this.encoder.encode(PII_DIGEST_ALGO), iv, encryptedContentArr);
  }

  async decrypt(value: string): Promise<string> {
    if (!value)
      return Promise.resolve(null);

    try {
      const [salt, iterations, digestAlgo, iv, data] = this.splitBuffers(value);
      const baseKey = await this.loadKey();
      const derivedKey = await deriveKey(
        salt, parseInt(this.decoder.decode(iterations)),
        this.decoder.decode(digestAlgo), baseKey, ['decrypt']);
      const decryptedContent = await window.crypto.subtle.decrypt(
        { name: GENERATION_ALGO, iv }, derivedKey, data);

      return this.decoder.decode(decryptedContent);
    } catch {
      return Promise.resolve(value);
    }
  }

  async loadKey(): Promise<CryptoKey> {
    const transaction = await this.openKeyDBTransaction('readonly');
    return await transaction.store.get(PASSWORD_KEY_NAME);
  };

  private async openKeyDBTransaction(mode: IDBTransactionMode) {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) { db.createObjectStore(STORE_NAME); },
    });
    return db.transaction(STORE_NAME, mode);
  }

  async hashPassword(password: string): Promise<string> {
    const salt = window.crypto.getRandomValues(new Uint8Array(SALT_LENGTH));
    return await this.getHashWithSalt(password, salt, PASSWORD_ITERATIONS, PASSWORD_DIGEST_ALGO);
  }

  async verifyPassword(password: string, hash: string): Promise<boolean> {
    const [salt, iterations, digestAlgo] = this.splitBuffers(hash);
    const expectedHash = await this.getHashWithSalt(
      password, salt, parseInt(this.decoder.decode(iterations)), this.decoder.decode(digestAlgo));
    return hash === expectedHash;
  }

  private async getHashWithSalt(
    password: string, salt: Uint8Array, iterations: number, digestAlgo: string,
  ): Promise<string> {
    const encodedPassword = this.encoder.encode(password);
    const key = await window.crypto.subtle.importKey(
      'raw', encodedPassword, DERIVATION_ALGO, false, ['deriveBits']);
    const derivedBits = await crypto.subtle.deriveBits(
      { name: DERIVATION_ALGO, hash: digestAlgo, salt, iterations }, key, KEY_LENGTH);
    return this.combineBuffers(
      salt, this.encoder.encode(`${iterations}`),
      this.encoder.encode(digestAlgo), new Uint8Array(derivedBits));
  }

  private combineBuffers(...parts: Uint8Array[]): string {
    return parts.map(buff_to_base64).join(SEPARATOR);
  }

  private splitBuffers(value: string): Uint8Array[] {
    return value.split(SEPARATOR).map((value) => base64_to_buf(value));
  }

  private async savePatients(patients: Patient[]): Promise<void> {
    const transaction = await this.openKeyDBTransaction('readwrite');
    await transaction.store.put(patients, PATIENTS_KEY_NAME);
    await transaction.done;
  };

  async savePatient(patient: Patient): Promise<void> {
    let storedPatients = await this.loadPatients();
    if (!storedPatients)
      storedPatients = [];

    let patientToUpdateIndex = storedPatients.findIndex(storedPatient => storedPatient.id === patient.id);
    if (patientToUpdateIndex > -1) {
      storedPatients[patientToUpdateIndex] = { ...patient };
    } else {
      storedPatients.push(patient);
    }
    await this.savePatients(storedPatients);
    await this.saveLastUpdated(this.utilityService.convertClientDateToServerTimezoneDate(new Date()));
  }

  async deletePatient(id: number): Promise<void> {
    let storedPatients = await this.loadPatients();
    if (!storedPatients)
      return;

    let indexToDelete = storedPatients.findIndex(storedPatient => storedPatient.id === id);
    if (indexToDelete > -1) {
      storedPatients.splice(indexToDelete, 1);
    }
    await this.savePatients(storedPatients);
    await this.saveLastUpdated(this.utilityService.convertClientDateToServerTimezoneDate(new Date()));
  }

  async loadPatients(): Promise<Patient[]> {
    const transaction = await this.openKeyDBTransaction('readonly');
    return await transaction.store.get(PATIENTS_KEY_NAME);
  };

  private async saveLastUpdated(dateString: string): Promise<void> {
    const transaction = await this.openKeyDBTransaction('readwrite');
    await transaction.store.put(dateString, LAST_UPDATED_KEY_NAME);
  };

  async loadLastUpdated(): Promise<string> {
    const transaction = await this.openKeyDBTransaction('readonly');
    return await transaction.store.get(LAST_UPDATED_KEY_NAME);
  };
}
