// native
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

// service
import { ErrorService } from 'app/core/services/error.service';
import { SupportService } from 'app/core/services/support.service';

// models
import { CenterContainerTitle, SupportUser } from '../../models';


@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html'
})
export class ChangeEmailComponent implements OnInit {

  @Input() user: SupportUser

  @Output() onClose: EventEmitter<void> = new EventEmitter();
  @Output() onSubmit: EventEmitter<void> = new EventEmitter();

  form: UntypedFormGroup;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private supportService: SupportService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.translateService.get('user').subscribe(() => this.setTranslations());
    this.initializeForm();
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('email');
    this.title.thin = this.translateService.instant('change');
  }

  public initializeForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.compose([Validators.required, Validators.email])]],
    });
  }

  public onSubmitClick(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    this.isLoading = true;

    this.supportService.changeEmail(this.user.id, { email: this.form.value.email })
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(res => {
        this.toastService.success(this.translateService.instant('changeEmailSuccess'));
        this.onSubmit.emit();
        this.onClose.emit();
      }, err => {
        this.errorService.handleSupportUserError(err?.code);
      });
  }

  close() {
    this.onClose.emit();
  }
}
