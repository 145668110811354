
// native
import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';

// models
import { Device, DeviceScreen, MonitorAction, TestGroupInfo } from '../../../models';

// const
import { DEVICE_SCREEN, TEST_ACTION, GROUP, PROTOCOL } from '../../../constants';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html'
})
export class ControlsComponent implements OnChanges {

  isScreenOrderV2: boolean;

  isTutorial: boolean;
  isConvergence: boolean;
  isDoingTest: boolean;
  isPaused: boolean;
  isCalibrationV2: boolean;
  isTutorialFoveal: boolean;

  isPupillometry: boolean;
  isPerimetryWithoutQuadrant: boolean;

  @Input() screen: DeviceScreen;
  @Input() testGroup: TestGroupInfo;
  @Input() device: Device;

  @Output() actionClicked: EventEmitter<MonitorAction> = new EventEmitter<MonitorAction>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.screen && changes.screen.currentValue) {
      this.isTutorial = changes.screen.currentValue.name === DEVICE_SCREEN.TUTORIAL.value;
      this.isConvergence = changes.screen.currentValue.name === DEVICE_SCREEN.CONVERGENCE.value;
      this.isDoingTest = changes.screen.currentValue.name === DEVICE_SCREEN.DOING_TEST.value;
      this.isPaused = changes.screen.currentValue.name === DEVICE_SCREEN.PAUSED.value;
      this.isCalibrationV2 = changes.screen.currentValue.name === DEVICE_SCREEN.CALIBRATION_V2.value;
      this.isTutorialFoveal = changes.screen.currentValue.name === DEVICE_SCREEN.TURORIAL_FOVEAL.value;
    }

    if (changes && changes.testGroup && changes.testGroup.currentValue) {
      this.isPupillometry = this.testGroup.group.value === GROUP.PUPIL;
      this.isPerimetryWithoutQuadrant = (this.testGroup.group?.value === GROUP.PERIMETRY)
        && (this.testGroup.protocol?.value !== PROTOCOL.SUPRA_THRESHOLD_QUADRANT);
    }

    if (changes && changes.device && changes.device.currentValue) {
      this.isScreenOrderV2 = this.device?.capabilities?.screen_order_version > 1;
    }
  }

  isSkipBackwardShown() {
    if (this.isScreenOrderV2)
      return false;

    return this.isConvergence;
  }

  isPlayPauseShown() {
    if (!this.isPaused && !this.isDoingTest)
      return false;
    if (this.isPupillometry && this.isDoingTest)
      return false;
    return this.isPupillometry || this.isPerimetryWithoutQuadrant;
  }

  isRestartShown() {
    return this.isDoingTest || this.isPaused;
  }

  isSkipForwardShown() {
    if (this.isScreenOrderV2) {
      return this.isConvergence
        || this.isTutorialFoveal
        || this.isTutorial
        || this.isCalibrationV2;
    }

    return this.isTutorial
      || this.isConvergence
      || this.isCalibrationV2;
  }

  onPlayPauseClick() {
    this.isPaused
      ? this.actionClicked.emit(TEST_ACTION.PLAY)
      : this.actionClicked.emit(TEST_ACTION.PAUSE);
  }

  onForwardClick() {
    this.actionClicked.emit(TEST_ACTION.FORWARD);
  }

  onBackwardClick() {
    this.actionClicked.emit(TEST_ACTION.BACKWARD);
  }

  onRestartClick() {
    if (!this.screen)
      return;

    this.actionClicked.emit(TEST_ACTION.RESTART_TEST);
  }
}
