// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';

// services
import { AuthService } from 'app/core/services/auth.service';
import { OfflineControllersService } from 'app/core/services/offline-controllers.service';
import { DialogService } from 'app/core/services/dialog.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { OfflineController, PaginatedItems, ListItemColumn, ListItemAction } from '../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../constants';

@Component({
  selector: 'app-offline-controllers',
  templateUrl: './offline-controllers.component.html'
})
export class OfflineControllersComponent implements OnInit {

  columns: ListItemColumn[] = [
    {
      translationKey: 'deviceName',
      fieldName: 'name',
      fontClass: 'bold'
    },
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'edit',
      execute: (controller: OfflineController) => this.edit(controller),
      visible: () => this.authService.isOfficeGroupAdmin
    },
    {
      translationKey: 'delete',
      execute: (controller: OfflineController) => this.delete(controller),
      visible: () => this.authService.isOfficeGroupAdmin
    }
  ];

  controllers$: BehaviorSubject<PaginatedItems<OfflineController> | null> = new BehaviorSubject(null);

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading: boolean = false;

  constructor(
    public router: Router,
    public authService: AuthService,
    public offlineControllersService: OfflineControllersService,
    public dialogService: DialogService,
    public translateService: TranslateService,
    public errorService: ErrorService
  ) { }

  ngOnInit() {
    this.getControllers(this.currentPageSize, this.currentPageIndex, null);
  }

  private getControllers(pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    this.offlineControllersService.getAll(pageSize, pageIndex, term).subscribe(res => {
      this.isLoading = false;
      this.controllers$.next(res);
    }, err => { this.isLoading = false; });
  }

  edit(controller: OfflineController) {
    this.router.navigate(['/offline-controllers/edit', controller.id]);
  };

  delete(controller: OfflineController) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant('delete')
        + ' ' + this.translateService.instant('offlineController')?.toLowerCase() + '?',
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed)
        this.offlineControllersService.delete(controller.id).subscribe(() => {
          this.getControllers(this.currentPageIndex, this.currentPageIndex, this.term);
        }), err => this.errorService.handleError(err);
    });
  };

  search(term: string) {
    this.currentPageIndex = 0;

    this.offlineControllersService.getAll(this.currentPageSize, this.currentPageIndex, term).subscribe(res => {
      this.term = term;
      this.controllers$.next(res);
    });
  };

  create() {
    this.router.navigate(['/offline-controllers/new']);
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getControllers(pageSize, pageIndex, this.term);
  }
}
