<div class="notfound_container">
  <img 
    src="{{utilityService.isWhiteLabelDomain ? './assets/images/logo-white-label.svg' : './assets/images/logoWhite.svg'}}" 
    class="notfound_image" 
    alt="Olleyes logo" />
  <span class="notfound_content">404 <small>{{ 'notFound' | translate | titlecase }}</small></span>
  <br>
  <a class="form_link" routerLink="/">
    {{ 'goToHomepage' | translate }}
  </a>
</div>