// native
import { BehaviorSubject, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { finalize, tap } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { PatientsService } from 'app/core/services/patients.service';
import { TestsService } from 'app/core/services/tests.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { PatientsJobService } from 'app/core/services/patients-encrypt-job.service';

// models
import { PaginatedItems, Patient, ListItemColumn, ListItemAction, ListItemMarker } from '../models';

// pipe
import { BoldThinPipe } from 'app/shared/pipes/pipes';

// animation
import { routerTransition } from 'app/animations/router-animations';

// constants
import { DEFAULT_ENCRYPTED_PAGE_SIZE, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../constants';

interface PatientDisplay extends Patient {
  display_name: string;
  age_in_years: string;
  gender_name: string;
  last_test_display: string;
}

@Component({
  selector: 'app-patient',
  templateUrl: './patients.component.html',
  animations: [routerTransition()]
})
export class PatientsComponent implements OnInit, OnDestroy {
  columns: ListItemColumn[] = [
    {
      translationKey: 'fullName',
      fieldName: 'display_name',
    },
    {
      translationKey: 'age',
      fieldName: 'age_in_years',
      hoverOnly: true
    },
    {
      translationKey: 'gender',
      fieldName: 'gender_name',
      fontClass: 'thin',
      hoverOnly: true
    },
    {
      translationKey: 'lastTest',
      fieldName: 'last_test_display',
      fontClass: 'thin',
      hoverOnly: true
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'edit',
      execute: (patient: Patient) => this.edit(patient),
      visible: () => true
    },
    {
      translationKey: 'trend',
      execute: (patient: Patient) => this.testsService.openTrendAnalysisReport(patient.id, patient.age),
      visible: (patient: Patient) => patient.trend_analysis_available
    },
    {
      translationKey: 'showTests',
      execute: (patient: Patient) => this.openExistingTests(patient),
      visible: () => true
    },
    {
      translationKey: 'newTest',
      execute: (patient: Patient) => this.openNewTest(patient),
      visible: () => true
    },
    {
      translationKey: 'delete',
      execute: (patient: Patient) => this.delete(patient),
      visible: () => true
    }
  ];

  orangeMarker: ListItemMarker = {
    visible: (patient: Patient) => this.patientsWithRunningTest.includes(patient.id)
  };

  patients$: BehaviorSubject<PaginatedItems<PatientDisplay> | null> = new BehaviorSubject(null);
  patientsWithRunningTest: number[] = [];

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading: boolean = false;

  languageSubscription: Subscription;

  constructor(
    private testsService: TestsService,
    public patientsService: PatientsService,
    private router: Router,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    public preferencesService: PreferencesService,
    private toastService: ToastrService,
    private patientJobService: PatientsJobService
  ) { }

  ngOnInit() {
    this.currentPageSize = this.preferencesService.isZeroPiiEnabled ? DEFAULT_ENCRYPTED_PAGE_SIZE : DEFAULT_PAGE_SIZE;

    this.getPatients(this.currentPageSize, this.currentPageIndex, null);

    this.languageSubscription = this.translateService.onLangChange.subscribe(res => {
      this.getPatients(this.currentPageSize, this.currentPageIndex, null);
    });
  }

  getPatients(pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    this.patientsService.getAll(pageSize, pageIndex, term).pipe(
      tap(() => {
        if (this.preferencesService.isZeroPiiEnabled)
          this.patientJobService.checkPatientsStoreStatus();
      }),
      finalize(() => this.isLoading = false)
    ).subscribe(res => this.patients$.next(this.parsePatients(res)),
      error => this.errorService.handleError(error));
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.isLoading = true;
    // use local patient store for search in case of zero pii enabled
    if (this.preferencesService.isZeroPiiEnabled && term) {
      if (term?.length < 3) {
        this.isLoading = false;
        return;
      }
      return this.patientsService.searchPatientStore(term).pipe(
        finalize(() => this.isLoading = false),
        tap(() => {
          if (this.preferencesService.isZeroPiiEnabled)
            this.patientJobService.checkPatientsStoreStatus();
        }),
      ).subscribe(res => {
        this.patients$.next(this.parsePatients(res));
      });
    }

    this.patientsService.getAll(this.currentPageSize, this.currentPageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.term = term;
      this.patients$.next(this.parsePatients(res));
    });
  };

  create() {
    this.router.navigate(['/patients/new']);
  }

  edit(patient: Patient) {
    this.router.navigate(['/patients/edit', patient.id]);
  }

  view(patient: Patient) {
    this.router.navigate(['/patients', patient.id]);
  }

  openNewTest(patient: Patient) {
    this.router.navigate(['/tests/new', patient.id]);
  }

  openExistingTests(patient: Patient) {
    this.router.navigate(['/tests', patient.id]);
  }

  delete(patient: Patient) {
    this.testsService.getFinishedTestsForPatient(patient.id).subscribe(finishedTestsCount => {
      if (finishedTestsCount > 0)
        this.toastService.error(
          this.translateService.instant('deletePatientTestsMessage'),
          this.translateService.instant('associatedTests') + ' ' + finishedTestsCount,
          { timeOut: 8000 }
        );
      else
        this.confirmDelete(patient);
    }, err => this.confirmDelete(patient));
  }

  private confirmDelete(patient: Patient) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant('delete') + ' ' + this.translateService.instant('patient')
        + ' ' + this.patientsService.getDisplayName(patient) + '?'
    }).then(result => {
      if (result.confirmed)
        this.patientsService.delete(patient.id).subscribe(
          res => this.getPatients(this.currentPageSize, this.currentPageIndex, this.term),
          error => this.errorService.handleError(error)
        );
    });
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getPatients(pageSize, pageIndex, this.term);
  }

  private parsePatients(patients: PaginatedItems<Patient>): PaginatedItems<PatientDisplay> {
    const parsedPatients = patients.results.map(patient => ({
      ...patient,
      display_name: new BoldThinPipe().transform(this.patientsService.getDisplayName(patient)),
      age_in_years: patient?.age
        ? new BoldThinPipe().transform([
          patient.age?.toString(),
          this.translateService.instant('years')
        ])
        : '',
      gender_name: patient?.gender?.name ? this.translateService.instant(patient.gender.name) : undefined,
      last_test_display: patient.last_test
        ? new DatePipe('en-US').transform(patient.last_test, this.preferencesService.defaultDateFormat.value)
        : this.translateService.instant('noTests')
    }));

    return {
      results: parsedPatients,
      count: patients.count
    };
  }

  onDeviceDashboardUpdated(patients: number[]) {
    this.patientsWithRunningTest = patients;
  }
}