// native
import { AbstractControl } from '@angular/forms';

// constant
import { COUNTRIES } from '../../constants';

export function country(control: AbstractControl) {
  const value = control.value;
  const allCountries = COUNTRIES;

  if (!value) {
    return;
  }

  if (!allCountries.find(country => country.name.toLowerCase() === value.toLowerCase())) {
    return {
      countryInvalid: true
    };
  }
}