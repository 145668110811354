// native
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// model
import { Device } from '../../models';

@Component({
  selector: 'app-new-device',
  templateUrl: './new-device.component.html'
})
export class NewDeviceComponent {

  constructor(
    private router: Router
  ) { }

  closeForm(device: Device) {
    if (!!device)
      this.router.navigate(['devices/edit', device.id]);
    else
      this.router.navigate(['devices']);
  }
}
