// native
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { DoctorsService } from 'app/core/services/doctors.service';
import { PatientsService } from 'app/core/services/patients.service';
import { UtilityService } from 'app/core/services/utility.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';
import { AuthService } from 'app/core/services/auth.service';

// models
import { Doctor, Ethnicity, Gender, Patient, PatientButtonActions, PatientRequest, CenterContainerTitle, UserPreferences } from '../../models';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html'
})
export class PatientFormComponent implements OnInit, OnChanges {

  @Input() patient: Patient;
  @Input() action: string;
  @Input() disabled?: boolean;
  @Input() buttonActions?: PatientButtonActions[] = [];

  title: CenterContainerTitle = {};

  @Output() close: EventEmitter<Patient> = new EventEmitter<Patient>();

  form: UntypedFormGroup;
  maxDobDate = new Date();
  doctors: Doctor[];
  ethnicities: Ethnicity[];
  genders: Gender[];
  preferences: UserPreferences;

  isNew: boolean;
  showContactFields: boolean = false;

  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private patientsService: PatientsService,
    private doctorsService: DoctorsService,
    private errorService: ErrorService,
    private router: Router,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    public preferencesService: PreferencesService,
    private dialogService: DialogService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.isNew = !this.patient;
    this.translateService.get('patient').subscribe(() => this.setTranslations());

    this.isLoading = true;
    forkJoin([
      this.patientsService.getEthnicities(),
      this.patientsService.getGenders(),
      this.doctorsService.getAll(),
      this.preferencesService.get()
    ]).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(responseList => {
      const [ethnicities, genders, doctors, preferences] = responseList;
      this.ethnicities = ethnicities;
      this.genders = genders;
      this.doctors = doctors;
      this.preferences = preferences;
      this.initializeForm();
    }, error => this.errorService.handleError(error));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['action']?.currentValue)
      this.title.thin = changes['action']?.currentValue;
  }

  private initializeForm() {
    const { first_name, last_name, email, date_of_birth, gender,
      ethnicity, doctor_id, monocular, patient_id_number, phone, subtitles } = this.patient || ({} as Patient);

    this.showContactFields = !!phone || !!email;

    this.form = this.formBuilder.group({
      first_name: [first_name, this.preferences.disable_pi ? null : [Validators.required]],
      last_name: [last_name, this.preferences.disable_pi ? null : [Validators.required]],
      email: [email, Validators.compose([Validators.email])],
      date_of_birth: [this.utilityService.convertServerDateToClientDate(date_of_birth), Validators.required],
      gender: [gender ? gender.id : null],
      ethnicity: [ethnicity ? ethnicity.id : null],
      doctor: [doctor_id, Validators.compose([Validators.required])],
      monocular: [monocular],
      phone: [phone],
      patient_id_number: [patient_id_number, this.preferences.disable_pi ? [Validators.required] : null],
      subtitles: [subtitles],
    });

    this.disabled && this.form.disable();
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('patient');
  }

  async onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    let { first_name, last_name, email, date_of_birth,
      gender, ethnicity, doctor, monocular, patient_id_number, phone, subtitles } = this.form.value;

    date_of_birth = this.utilityService.convertClientDateToServerDate(date_of_birth);

    const body: PatientRequest = {
      first_name,
      last_name,
      email,
      date_of_birth,
      gender_id: gender,
      ethnicity_id: ethnicity,
      doctor_id: doctor,
      patient_id_number,
      phone: phone || null
    };

    if (!this.isNew) {
      body.monocular = monocular;
      body.subtitles = subtitles;
    }

    let duplicate = null;
    const shouldCheckDuplicates = !this.authService.isTechallUserOrAdmin;
    if (shouldCheckDuplicates)
      duplicate = await this.patientsService.getDuplicate(body);

    if (duplicate && (this.patient?.id !== duplicate.id)) {
      this.dialogService.openConfirm({
        action: this.isNew ? this.translateService.instant('create') : this.translateService.instant('edit'),
        message: this.isNew ? this.translateService.instant('patientExistsMessage') : this.translateService.instant('patientExistsEditMessage'),
        confirmText: this.isNew ? this.translateService.instant('useExistingPatient') : this.translateService.instant('reviewChanges'),
        cancelText: this.isNew ? this.translateService.instant('createNewPatient') : this.translateService.instant('changeAnyway'),
        mediumButtonMode: true
      }).then(result => {
        if (result.confirmed && this.isNew)
          this.router.navigate(['/tests/new', duplicate.id]);
        if (result.canceled)
          this.submitPatient(body);
      });
    } else {
      this.submitPatient(body);
    }
  }

  private submitPatient(body: PatientRequest) {
    this.isLoading = true;
    const request = this.isNew ? this.patientsService.create(body) : this.patientsService.update(this.patient.id, body);
    request.pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      response => this.close.emit(response),
      error => this.errorService.handleError(error?.error)
    );
  }

  closeForm() {
    this.router.navigate(['/patients']);
  }
}
