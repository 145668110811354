// core
import { Component, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';

// services
import { DevicesService } from '../../core/services/devices.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { ZeroPiiService } from 'app/core/services/zero-pii.service';

// models
import { ActiveDevice } from '../../models';

// constants
import { DASHBOARD_COLLAPSED_KEY } from '../../constants';

@Component({
  selector: 'app-device-dashboard',
  templateUrl: './device-dashboard.component.html'
})
export class DeviceDashboardComponent implements OnInit, OnDestroy {
  activeDevices: ActiveDevice[] = [];
  pingInterval: any;
  collapsed = !!JSON.parse(localStorage.getItem(DASHBOARD_COLLAPSED_KEY));

  @Output() onUpdate: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor(
    private devicesService: DevicesService,
    private monitorTestService: MonitorTestService,
    private zeroPiiService: ZeroPiiService
  ) { }

  ngOnInit(): void {
    this.getActiveDevices();
    this.setActiveDevicePing();
  }

  ngOnDestroy() {
    clearInterval(this.pingInterval);
  }

  toggleDashboard() {
    this.collapsed = !this.collapsed;
    localStorage.setItem(DASHBOARD_COLLAPSED_KEY, this.collapsed.toString());
  }

  private setActiveDevicePing() {
    this.pingInterval = setInterval(() => {
      this.getActiveDevices();
    }, 5000);
  }

  private getActiveDevices() {
    this.devicesService.getActiveDevices().subscribe(devices => {
      Promise.all(
        devices.map(async device => {
          if (!device?.is_patient_encrypted)
            return device;
          return await this.decryptActiveDevice(device);
        })
      ).then(devices => {
        this.activeDevices = devices.sort((a, b) => {
          if (a.test < b.test)
            return -1;
          else if (a.test > b.test)
            return 1;
          else
            return 0;
        });

        const activePatients = devices.map(device => device.patient_id);
        this.onUpdate.emit(activePatients);
      });
    }, err => this.activeDevices = []);
  }

  private async decryptActiveDevice(device: ActiveDevice): Promise<ActiveDevice> {
    const firstName = device.administering_test?.split(' ')[0];
    const lastName = device.administering_test?.split(' ')[1];

    return Promise.all([
      this.zeroPiiService.decrypt(firstName),
      this.zeroPiiService.decrypt(lastName),
    ]).then(([firstName, lastName]) => {
      const decryptedPatient: ActiveDevice = {
        ...device,
        administering_test: firstName + ' ' + lastName
      };
      return decryptedPatient;
    });
  }

  trackByFn(index: number, device: ActiveDevice) {
    return device.test;
  }

  monitorTest(activeTest: ActiveDevice) {
    this.monitorTestService.openMonitorScreen(activeTest, false, { queryParams: { from: 'home' } });
  }
}