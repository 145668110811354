// native
import { NgModule } from '@angular/core';

// module
import { SharedModule } from 'app/shared/shared.module';

// component
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    NavigationComponent,
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
})
export class CoreModule { }
