// native
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html'
})
export class VideoCallComponent {
  minimized: boolean;
  full: boolean;

  @Input() isUser: boolean = false;
  @Input() isVideo: boolean;
  @Input() isSharing: boolean;
  @Input() isMuted: boolean;
  @Input() isEnding: boolean;
  @Input() isWaiting: boolean;
  @Input() inCall: boolean;
  @Input() isPortrait: boolean;

  @Output() startVideo: EventEmitter<void> = new EventEmitter<void>();
  @Output() stopVideo: EventEmitter<void> = new EventEmitter<void>();
  @Output() startSharing: EventEmitter<void> = new EventEmitter<void>();
  @Output() stopSharing: EventEmitter<void> = new EventEmitter<void>();
  @Output() mute: EventEmitter<void> = new EventEmitter<void>();
  @Output() unmute: EventEmitter<void> = new EventEmitter<void>();
  @Output() endCall: EventEmitter<void> = new EventEmitter<void>();

  onMinimizeToggle() {
    this.minimized = !this.minimized;
  }

  onFullToggle() {
    this.full = !this.full;
  }

  isControlsShown() {
    if (this.minimized)
      return false;
    if (this.isEnding)
      return false;
    if (!this.inCall && this.isUser)
      return false;
    if (this.isWaiting && this.isUser)
      return false;
    if (this.isWaiting)
      return true;
    return true;
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.full = false;
    this.minimized = false;
  }
}
