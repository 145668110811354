<div class="demo_container">
  <div class="demo_side" *ngIf="currentStep === 1">
    <div class="demo_header-wrapper">
      <div>{{ 'selfPerimetry' | translate }}…</div>
      <div>{{ 'thatSimple' | translate }}!</div>
    </div>

    <div class="demo_round-button-wrapper">
      <div class="demo_round-button" (click)="onStepOneComplete()">
        <div class="demo_round-button-text">
          {{ 'startExperience' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="demo_side demo_side--overlay" *ngIf="currentStep === 2">
    <form 
      *ngIf="testForm" 
      class="demo_content" 
      [formGroup]="testForm" 
      (ngSubmit)="onStepTwoComplete()">
      <app-input-wrapper 
        [control]="testForm.get('name')" 
        label="{{ 'name' | translate }}"
        identifier="name">
        <input 
          class="form_control demo_control"
          name="demo-input-name"
          id="name" 
          type="text" 
          formControlName="name">
      </app-input-wrapper>

      <div class="relative">
        <app-input-wrapper 
          [control]="testForm.get('email')" 
          label="{{ 'email' | translate }}"
          identifier="email">
          <input
            autocorrect="off"
            autocapitalize="none"
            class="form_control demo_control"
            name="demo-input-email"
            id="email"
            type="email" 
            formControlName="email">
        </app-input-wrapper>
        <div class="demo_message">
          {{ 'resultsWillBeSent' | translate }}
        </div>
      </div>

      <button class="demo_button" type="submit" [disabled]="!testForm.valid || !testForm.dirty">
        <span class="regular">{{ 'start' | translate }}</span>
      </button>
    </form>
  </div> 

  <div class="demo_side demo_side--overlay" *ngIf="currentStep === 3">
    <div class="demo_content">
      <button *ngFor="let group of displayedTestGroups" class="demo_button" (click)="onTestSelect(group)">
        <span class="regular">{{group.name}}</span>
      </button>
    </div>
  </div> 

  <div class="demo_side demo_side--end">
    <div class="demo_logo-wrapper">
      <img src="./assets/images/logo-horizontal-white.png" alt="Olleyes logo"/>
    </div>
    <div *ngIf="preferences?.device?.name" class="demo_device-wrapper">
      <span class="text--uppercase">{{ 'device' | translate }}:&nbsp;</span>
      {{ preferences.device.name }} {{ (preferences.device.administering_test || !preferences.device.available) ? ('(' + ('inUse' | translate) + ')') : '' }}
    </div>
  </div>

  <div *ngIf="isPinPromptVisible" class="demo_pin-container">
    <form
      *ngIf="pinForm" 
      [formGroup]="pinForm" 
      (ngSubmit)="onSubmitPin()">
      <app-input-wrapper 
        [control]="pinForm.get('pin')" 
        label="{{ 'pin' | translate }}"
        identifier="pin">
        <input type="text" id="pin" class="form_control demo_control" formControlName="pin" name="pin" id="pin">
      </app-input-wrapper>
  
      <br>
  
      <button class="demo_button" type="submit" [disabled]="!pinForm.valid || !pinForm.dirty">
        <span class="regular">{{ 'proceed' | translate }}</span>
      </button>
      <br>
      <br>
      <span class="form_link" (click)="closePinPrompt()">{{ 'goBack' | translate }}</span>
    </form>
  </div>

</div>
