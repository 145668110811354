// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { ImportUploadComponent } from "./upload/import-upload.component";
import { ImportVerifyComponent } from "./verify/import-verify.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ImportVerifyComponent,
    ImportUploadComponent
  ]
})
export class ImportModule { }