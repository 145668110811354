<div class="flex-space-between">
  <app-tabs
    *ngIf="tabs?.length"
    [tabs]="tabs">
  </app-tabs>

  <div class="action-header_container" *ngIf="authService.isBasicSupportUser">
    <app-button-list
      *ngIf="buttonList?.length"
      [list]="buttonList">
    </app-button-list>
  </div>
</div>

<br>

<kendo-grid
  [data]="gridData$ | async"
  [loading]="isLoading"
  [filterable]="true"
  [filter]="filter"
  (filterChange)="filterChange($event)"
  [sortable]="true"
  [sort]="sort"
  (sortChange)="sortChange($event)"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="skip"
  (pageChange)="pageChange($event)"
  [height]="700">
  <kendo-grid-column 
    field="last_name" 
    title="{{ 'lastName' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="first_name" 
    title="{{ 'firstName' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="email"
    title="{{ 'email' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="groups" 
    title="{{ 'group' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="office_group" 
    title="{{ 'officeGroup' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="date_joined" 
    title="{{ 'dateJoined' | translate | titlecase }}"
    filter="date">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-gte-operator></kendo-filter-gte-operator>
        <kendo-filter-lte-operator></kendo-filter-lte-operator>
      </kendo-grid-date-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.date_joined | date: 'MM/dd/yyyy'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column *ngIf="authService.isBasicSupportUser" [width]="260">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button
        kendoGridEditCommand 
        class="k-primary" 
        (click)="onChangeEmailClick(dataItem)">
        {{ 'changeEmail' | translate | titlecase }}
      </button>
      <button
        *ngIf="!isOfficeGroupAdmin(dataItem)" 
        kendoGridEditCommand 
        class="k-primary" 
        (click)="setAsAdmin(dataItem)">
        {{ 'setAsAdmin' | translate | titlecase }}
      </button>
   </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<app-change-email
  *ngIf="!!userToChangeEmail"
  [user]="userToChangeEmail"
  (onSubmit)="getUsers()"
  (onClose)="userToChangeEmail = null">
</app-change-email>
