<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column monitor_container-column--single" (window:resize)="windowChanged.emit(getEvent())">
    <div class="monitor_graph-box monitor_graph-box--single" #plotContainer>
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title monitor_graph-title--right" *ngIf="authService.isOneHundredBetaLevel">
        <i 
          class="fas fa-border-all"
          [ngClass]="{ 'grey': !isGridShown }"
          (click)="onToggleGrid()">
        </i>
      </div>
      <plotly-plot [data]="eyeData" *ngIf="layout" [layout]="layout" [config]="monitorTestService.plotOptions"
        [revision]="count" [debug]="true" class="monitor_plot-box">
      </plotly-plot>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        class="monitor_circle-box" 
        [percentage]="eyeStats.completionPercentage.toFixed(0)">
      </app-percentage-circle>
    </div>

    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_stat-container">
        <div class="monitor_stat-box">
          <app-percentage-squares
            [percentage]="eyeStats.totalViewedCount > 3 ? ((eyeStats.falsePositiveCount / eyeStats.totalProcessedCount) * 100).toFixed(0) : 0"
            [color]="'#54b17c'"
            [id]="'od'">
          </app-percentage-squares>
          <span class="monitor_stat-title">FP</span> 
          <span class="monitor_stat-title">
            {{ eyeStats.totalViewedCount > 3 ? ((eyeStats.falsePositiveCount / eyeStats.totalProcessedCount) * 100).toFixed(0) : 0 }}<span class="text--sm">&#37;</span>
          </span>
        </div>
        <div class="monitor_stat-box">
          <app-percentage-squares
            [percentage]="0"
            [color]="'#afcb53'"
            [id]="'od'">
          </app-percentage-squares>
          <span class="monitor_stat-title">FN</span> 
          <span class="monitor_stat-title">
            {{ 0 }}<span class="text--sm">&#37;</span>
          </span>
        </div>
        <div class="monitor_stat-box">
          <app-percentage-squares
            [percentage]="eyeStats.blindspotCount"
            [color]="'#f6c042'"
            [id]="'od'">
          </app-percentage-squares>
          <span class="monitor_stat-title">FL</span> 
          <span class="monitor_stat-title">
            {{ eyeStats.blindspotCount }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>