// native
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { TestsService } from './tests.service';
import { PatientsService } from './patients.service';
import { MonitorTestService } from './monitor-test.service';
import { TestBundlesService } from './test-bundles.service';
import { DevicesService } from './devices.service';
import { TestFormService } from './test-form.service';

// models
import { TestPostRequest, UserPreferences, TestGroup, CustomBundleTestCreationRequest, Patient } from '../../models';

// constants
import { GOLDMAN_SIZES, SUPRA_T_INTENSITIES, GROUP, DEVICE_TYPE, TEST_STATUS_STARTED_ID, CONTROL_TYPES, DEFAULT_OCCLUSION_TIME, TESTING_POSITIONS, DEFAULT_APD_STIMULATION_TIME } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class TestDefaultService {

  testGroups: TestGroup[] = [];

  constructor(
    private patientsService: PatientsService,
    private testsService: TestsService,
    private toastService: ToastrService,
    private monitorTestService: MonitorTestService,
    private translateService: TranslateService,
    private testBundlesService: TestBundlesService,
    private devicesService: DevicesService,
    private testFormService: TestFormService
  ) { }

  isDefaultTestDisabled(preferences: UserPreferences): boolean {
    if (!preferences)
      return true;
    if (!preferences.device || !!preferences.device.administering_test || !preferences.device.available || !preferences.device.active)
      return true;
    if (!preferences.operator?.active)
      return true;

    if (!preferences.test_group && !preferences.custom_test_bundle_type)
      return true;

    if (preferences.test_group) {
      const isTestWithStrategy = this.testsService.isGroupWithStrategy(preferences.test_group?.group);
      if (isTestWithStrategy && !preferences.test_group?.strategy?.value)
        return true;

      const isTestWithProtocol = this.testsService.isGroupWithProtocol(preferences.test_group?.group)
        || this.testsService.isStrategyWithProtocol(preferences.test_group.strategy);
      if (isTestWithProtocol && !preferences.test_group?.protocol?.value)
        return true;
    }

    return false;
  }

  createDefaultTest(patientId: number, preferences: UserPreferences): Promise<void> {
    if (!patientId || !preferences) {
      this.toastService.error(this.translateService.instant('defaultTestErrorGeneric'));
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      forkJoin([
        this.patientsService.getOne(patientId),
        this.testsService.getControlTypes()
      ]).subscribe(responseList => {
        const [patient, controlTypes] = responseList;

        const { device, operator, test_group, language, skip_convergence_testing, skip_tutorial,
          use_short_tutorial, disable_pause, custom_test_bundle_type, always_use_subtitles } = preferences;

        if (!!custom_test_bundle_type) {
          const customBundleBody = this.getDefaultCustomBundleBody(preferences, patient);
          this.testBundlesService.create(customBundleBody).pipe(
            finalize(() => resolve())
          ).subscribe(
            bundle => this.monitorTestService.openMonitorScreen(bundle.current_test),
            error => this.toastService.error(error, 'Error:', { closeButton: true })
          );
          return;
        }

        const body: TestPostRequest = {
          patient: patient.id,
          status: TEST_STATUS_STARTED_ID,
          device: device?.id,
          doctor: patient.doctor_id,
          operator: operator?.id,
          test_group: {
            group: test_group?.group?.value || null,
            strategy: test_group?.strategy?.value || null,
            protocol: test_group?.protocol?.value || null
          },
          with_correction: false,
          eye: [GROUP.SENSORIMOTOR_SHAPES, GROUP.SENSORIMOTOR_CARDINAL].includes(test_group?.group?.value) ? 'OD' : '',
          supra_t_intensity: this.testFormService.calculateIntensityPayload(test_group?.strategy, test_group?.protocol, device, SUPRA_T_INTENSITIES.STANDARD.value),
          goldman_size: this.testFormService.calculateGoldmanSizePayload(test_group?.strategy, GOLDMAN_SIZES.THREE.value),
          control_type: this.testFormService.isTestWithControlType(test_group?.group, test_group?.strategy, test_group?.protocol)
            ? controlTypes.find(type => type.name === CONTROL_TYPES.CONTROLLER)?.id
            : null,
          language,
          monocular: this.testFormService.calculateMonocularPayload(skip_convergence_testing, false, test_group?.group, test_group?.strategy, '', device),
          skip_convergence_testing: this.testFormService.isTestWithConvergence(test_group?.strategy) ? skip_convergence_testing : true,
          skip_tutorial: this.testFormService.isTestWithTutorial(test_group?.group, test_group?.strategy, test_group?.protocol) ? skip_tutorial : true,
          use_short_tutorial: this.testFormService.isMainLanguage(language) ? use_short_tutorial : true,
          disable_pause: this.testFormService.isTestWithDisablePause(device, test_group?.strategy) ? disable_pause : true,
          test_foveal_sensitivity: false,
          stimulus_type: null,
          apd_stimulation_time: this.testFormService.isTestWithApdStimulationTime(test_group?.strategy) ? DEFAULT_APD_STIMULATION_TIME : null,
          skip_calibration: false,
          skip_eye_tracking_calibration: false,
          subtitles: this.testFormService.isTestWithSubtitles(device, language, test_group?.group, test_group?.strategy)
            ? always_use_subtitles
            : false,
          occlusion_time: this.testFormService.calculateOcclusionTimePayload(test_group?.group, test_group?.strategy, DEFAULT_OCCLUSION_TIME),
          cover_directions: this.testFormService.isTestWithPositions(test_group?.strategy) ? TESTING_POSITIONS.PRIMARY_ONLY.value : null,
          cover_digitally_occluded: !!this.testFormService.isTestWithDefaultDigitalOcclusion(test_group?.strategy),
          subtitle_increase_text_size: false,
        };

        if (test_group?.group?.value === GROUP.CSUITE) {
          const deviceVersion = this.devicesService.getSemanticVersion(device?.app_version);
          const cSuiteBody = this.testBundlesService.getBundleBodyForCSuite(body, deviceVersion, controlTypes);

          this.testBundlesService.create(cSuiteBody).pipe(
            finalize(() => resolve())
          ).subscribe(
            bundle => this.monitorTestService.openMonitorScreen(bundle.current_test),
            error => this.toastService.error(error, 'Error:', { closeButton: true })
          );
          return;
        }

        this.testsService.create(body).pipe(
          finalize(() => resolve())
        ).subscribe(
          test => this.monitorTestService.openMonitorScreen(test),
          error => this.toastService.error(error, 'Error:', { closeButton: true })
        );
      });
    });
  }

  private getDefaultCustomBundleBody(preferences: UserPreferences, patient: Patient): CustomBundleTestCreationRequest {
    const { device, operator, language, skip_convergence_testing, skip_tutorial,
      use_short_tutorial, disable_pause, custom_test_bundle_type, always_use_subtitles } = preferences;

    const body: CustomBundleTestCreationRequest = {
      patient: patient.id,
      device: device?.id,
      doctor: patient.doctor_id,
      operator: operator?.id,
      custom_test_bundle_type,
      custom_test_bundle_parameters: {
        monocular: skip_convergence_testing ? false : null,
        skip_tutorial,
        disable_pause,
        skip_convergence_testing,
        use_short_tutorial,
        language,
        subtitles: this.testFormService.isTestWithSubtitles(device, language, { name: '', value: GROUP.CUSTOM_BUNDLE }) ? always_use_subtitles : false,
        subtitle_increase_text_size: false,
      },
      test_descriptions: []
    };

    return body;
  }
}