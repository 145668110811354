<div 
  class="form_field-wrapper"
  [ngClass]="{
    'form_field-wrapper--error': !control.valid && control.touched,
    'form_field-wrapper--indent': isIndent,
    'form_field-wrapper--large-spacing': useLargeSpacing
  }"
  (click)="onInputClick()">
  <label 
    [for]="identifier"
    *ngIf="(placeholderBold || placeholderThin)"
    class="form_placeholder"
    [ngClass]="{
      'form_placeholder--disabled': control.disabled, 
      'form_placeholder--minimized': forceMinimized || placeholderMinimized || control.value || control.disabled || control.errors?.validatePhoneNumber,
      'form_placeholder--offset': hasPlaceholderOffset
    }">
    <strong>{{ placeholderBold }}</strong><span class="faded">{{ placeholderThin }}</span>
  </label>
  <i 
    *ngIf="infoIconText" 
    class="form_info-icon fa fa-info-circle"
    [ngClass]="{'form_info-icon--checkbox': infoIconCheckbox }"
    [matTooltip]="infoIconText">
  </i>
  <ng-content></ng-content>

  <small aria-label="required_error" *ngIf="control.errors?.required && !control.errors?.validatePhoneNumber && control.touched" class="form_error"
    name="required_error" role="alert">
    {{ label }} {{ 'isRequired' | translate }}.
  </small>

  <small aria-label="email_error" *ngIf="control.errors?.email && control.touched" class="form_error" name="email_error"
    role="alert">
    {{ 'notValidEmail' | translate }}
  </small>

  <small aria-label="pattern_error" *ngIf="control.errors?.pattern && !control.errors?.minlength && control.touched" class="form_error"
    name="pattern_error" role="alert">
    {{ 'notValidValue' | translate }}
  </small>

  <small aria-label="not_match_error" *ngIf="control.errors?.notEquivalent && control.touched" class="form_error"
    name="not_match_error" role="alert">
    {{ 'passwordsDontMatch' | translate }}
  </small>

  <small aria-label="not_match_error" *ngIf="control.errors?.usernameTaken && control.touched" class="form_error"
    name="username_taken_error" role="alert">
    {{ 'usernameInUse' | translate }}
  </small>

  <small aria-label="not_match_error" *ngIf="control.errors?.emailTaken && control.touched" class="form_error"
    name="email_taken_error" role="alert">
    {{ 'emailInUse' | translate }}
  </small>

  <small aria-label="not_match_error" *ngIf="control.errors?.phoneTaken && control.touched" class="form_error"
    name="phone_taken_error" role="alert">
    {{ 'phoneInUse' | translate }}
  </small>

  <small aria-label="max_file_count" *ngIf="control.errors?.maxFileCount && control.touched" class="form_error"
    name="max_file_size" role="alert">
    {{ 'maxFileCount' | translate }}
  </small>

  <small aria-label="max_file_size" *ngIf="control.errors?.maxFileSize && control.touched" class="form_error"
    name="max_file_size" role="alert">
    {{ 'maxFileSize' | translate }}
  </small>

  <small aria-label="file_not_allowed" *ngIf="control.errors?.fileNotAllowed && control.touched" class="form_error"
    name="file_not_allowed" role="alert">
    {{ 'fileTypeNotAllowed' | translate }}
  </small>

  <small aria-label="maximum_number_value" *ngIf="control.errors?.max && control.touched" class="form_error form_error--large"
    name="maximum_number_value" role="alert">
    {{ 'maximumFieldNumberMessage' | translate }} {{control.errors.max?.max}}.
  </small>

  <small aria-label="minimum_number_value" *ngIf="control.errors?.min && control.touched" class="form_error form_error--large"
    name="minimum_number_value" role="alert">
    {{ 'minimumFieldNumberMessage' | translate }} {{control.errors.min?.min}}.
  </small>

  <small aria-label="maximum_input_length" *ngIf="control.errors?.maxlength && control.touched" class="form_error form_error--large"
    name="maximum_input_length" role="alert">
    {{ 'maximumInputLengthMessage' | translate }} {{control.errors.maxlength?.requiredLength}}.
  </small>

  <small aria-label="minimum_input_length" *ngIf="control.errors?.minlength && control.touched" class="form_error form_error--large"
    name="minimum_input_length" role="alert">
    {{ 'minimumInputLengthMessage' | translate }} {{control.errors.minlength?.requiredLength}}.
  </small>

  <small aria-label="invalid_date_range" *ngIf="control.errors?.invalidDateRange && control.touched" class="form_error form_error--large"
    name="invalid_date_range" role="alert">
    {{ 'invalidDateRange' | translate }}
  </small>

  <small aria-label="invalid_phone_number" *ngIf="control.errors?.validatePhoneNumber && control.touched" class="form_error"
    name="invalid_phone_number" role="alert">
    {{ 'invalidPhoneNumber' | translate }}
  </small>

  <small aria-label="invalid_country" *ngIf="control.errors?.countryInvalid && control.touched" class="form_error"
    name="invalid_cuntry" role="alert">
    {{ 'invalidCountry' | translate }}
  </small>

  <small aria-label="invalid_minimum_date" *ngIf="control.errors?.matDatepickerMin && control.touched" class="form_error"
    name="invalid_minimum_date" role="alert">
    {{ 'minimumDateMessage' | translate }}
  </small>

  <small aria-label="invalid_maximum_date" *ngIf="control.errors?.matDatepickerMax && control.touched" class="form_error"
    name="invalid_maximum_date" role="alert">
    {{ 'maximumDateMessage' | translate }}
</small>
</div>