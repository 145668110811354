<app-tabs 
  *ngIf="tabs" 
  [tabs]="tabs">
</app-tabs>

<br>

<app-list-header
  name="user"
  [canCreate]="!authService.isSsoEnabled"
  (onCreate)="create()"
  (onSearch)="search($event)">
</app-list-header>

<app-item-list
  [columns]="columns"
  [actions]="actions"
  [items$]="users$"
  [showWarning]="true"
  (onItemClick)="edit($event)"
  (onChangePage)="changePage($event.pageIndex, $event.pageSize)">
</app-item-list>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>