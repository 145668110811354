<div class="demo_container">
  <div *ngIf="!session" class="demo_side">
    <div class="demo_round-button-wrapper">
      <div class="demo_round-button" (click)="onJoin()">
        <div class="demo_round-button-text">
          {{ 'readyToJoin' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
