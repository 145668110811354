// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-custom-bundle',
  templateUrl: './new-custom-bundle.component.html'
})
export class NewCustomBundleComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  close(event: any) {
    this.router.navigate(['/custom-bundles']);
  }

}
