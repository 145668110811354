// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { OfflineControllerFormComponent } from "./controller-form/controller-form.component";
import { OfflineControllersComponent } from "./offline-controllers.component";
import { EditOfflineControllerComponent } from "./edit-controller/edit-controller.component";
import { NewOfflineControllerComponent } from "./new-controller/new-controller.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    OfflineControllersComponent,
    NewOfflineControllerComponent,
    EditOfflineControllerComponent,
    OfflineControllerFormComponent,
  ]
})
export class OfflineControllersModule { }
