// native
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// service
import { ApiService } from './api.service';

// constants
import { API_START_RESET_PASSWORD_PROCESS_PATH, API_VALIDATE_RESET_PASSWORD_REQUEST_PATH, API_RESET_PASSWORD_PATH } from '../../constants';

// models
import { ResetPasswordBody, StartResetPasswordProcessBody, ValidateResetPasswordRequestBody } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  constructor(private apiService: ApiService) { }

  startResetPasswordProcess(body: StartResetPasswordProcessBody): Observable<{ message: string; }> {
    return this.apiService.post(API_START_RESET_PASSWORD_PROCESS_PATH, body).pipe(map(res => res as { message: string; }));
  }

  validateResetPasswordRequest(body: ValidateResetPasswordRequestBody): Observable<{ message: string; }> {
    return this.apiService.post(API_VALIDATE_RESET_PASSWORD_REQUEST_PATH, body).pipe(map(res => res as { message: string; }));
  }

  resetPassword(body: ResetPasswordBody): Observable<{ message: string; }> {
    return this.apiService.post(API_RESET_PASSWORD_PATH, body).pipe(map(res => res as { message: string; }));
  }
}
