// native
import { Injectable } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { from, Observable, Subject } from 'rxjs';

// addon
import {
  Call,
  CallAgent,
  CallClient,
  DeviceManager,
  LocalVideoStream,
  VideoStreamRenderer
} from '@azure/communication-calling';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { setLogLevel } from '@azure/logger';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { ApiService } from './api.service';

// models
import { PaginatedItems, TechallSession, TechallSessionPutRequest } from '../../models';

// constants
import { API_TECHALL_SESSION_PATH, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class TechallSessionService {

  currentSession: TechallSession = null;

  call: Call;
  callAgent: CallAgent;
  deviceManager: DeviceManager;
  localVideoStream: LocalVideoStream;

  inCall: boolean = false;

  isMuted: boolean = false;
  isVideo: boolean = true;
  isSharing: boolean = false;
  isEnding: boolean = false;
  isWaiting: boolean = false;

  isPortraitOrientation: boolean = false;

  callEnded$: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ApiService,
    private toastService: ToastrService,
    private translateService: TranslateService
  ) {
    // uncomment to see verbose logs for ACS
    // setLogLevel('verbose');
  }

  public getSessions(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null, fetchFinished: boolean = false): Observable<PaginatedItems<TechallSession>> {
    let path = `${API_TECHALL_SESSION_PATH}?limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    if (fetchFinished)
      path = path + `&past`;

    return this.apiService.get(path).pipe(
      map(res => res as PaginatedItems<TechallSession>)
    );
  }

  public updateSession(id: number, body: TechallSessionPutRequest): Observable<TechallSession> {
    let path = `${API_TECHALL_SESSION_PATH}${id}/`;

    return this.apiService.put(path, body)
      .pipe(
        tap(() => {
          if (!body.active)
            this.endCall();
        }),
        map(res => res as TechallSession)
      );
  }

  joinSession(session: TechallSession): Observable<TechallSession> {
    return this.updateSession(session.id, { active: true }).pipe(
      mergeMap(session => from(this.initializeCallAgent(session))),
      mergeMap(session => from(this.startCall(session)))
    );
  }

  public async initializeCallAgent(session: TechallSession, isAdmin: boolean = true): Promise<TechallSession> {
    this.isWaiting = true;
    const token = new AzureCommunicationTokenCredential(isAdmin ? session.acs_admin_token : session.acs_user_token);

    const callClient = new CallClient();
    this.callAgent = await callClient.createCallAgent(
      token,
      { displayName: '<ignored>' }
    );

    this.deviceManager = await callClient.getDeviceManager();
    const permissions = await this.deviceManager.askDevicePermission({ video: true, audio: true });

    if (!permissions.video)
      this.toastService.error(this.translateService.instant('allowVideoError'), null, { disableTimeOut: true, closeButton: false, tapToDismiss: false });

    this.callAgent.on('incomingCall', async (args) => {
      const callOptions = await this.getCallOptions();
      await args.incomingCall.accept(callOptions);
    });

    this.callAgent.on('callsUpdated', async (event) => {
      // the call is ended only if a removed call (call that the other side wants to end) is the same as the current call
      // there are some cases where other side ends the call that is currenlty not active 
      // (e.g. if the other side reloads the app, and then ends the call being active in the previous session)
      // that situation we want to ignore and not close the video box because there is still 
      // an active call in the new browser session of the other side
      if (event.removed.length > 0 && this.call.id && event.removed[0].id === this.call.id) {
        this.isEnding = true;
        setTimeout(() => {
          this.inCall = false;
          this.isWaiting = false;
          this.callEnded$.next();
          this.isEnding = false;
        }, 2000);
        return;
      }

      if (event.added.length === 0)
        return;

      this.call = event.added[0];

      const participant = this.call.remoteParticipants[0];
      const remoteStream = participant.videoStreams[0];
      const renderer = new VideoStreamRenderer(remoteStream);
      const view = await renderer.createView();

      // extract size to calc video orientation
      const size = view['videoStream']?.size;
      if (size && size.height && size.width)
        this.isPortraitOrientation = size.height > size.width;

      const targetContainer = document.getElementById('video_renderer');

      // remove existing stream element (to avoid duplicates when other side refreshes inside existing call)
      const streamElementId = 'techall-video-stream-element';
      if (document.getElementById(streamElementId))
        document.getElementById(streamElementId).remove();
      view.target.setAttribute('id', streamElementId);

      targetContainer && targetContainer.appendChild(view.target);

      this.isWaiting = false;
      this.inCall = true;
    });

    return session;
  }

  public async startCall(session: TechallSession, isAdmin: boolean = true): Promise<TechallSession> {
    this.isWaiting = true;
    const userId = { communicationUserId: isAdmin ? session.acs_user : session.acs_admin };
    const callOptions = await this.getCallOptions();
    this.callAgent.startCall([userId], callOptions);
    return session;
  }

  startVideo() {
    this.call?.startVideo(this.localVideoStream).then(() => {
      this.isVideo = true;
    });
  }

  stopVideo() {
    this.call?.stopVideo(this.localVideoStream).then(() => {
      this.isVideo = false;
    });
  }

  startSharing() {
    this.call?.startScreenSharing().then(() => {
      this.isSharing = true;
    });
  }

  stopSharing() {
    this.call?.stopScreenSharing().then(() => {
      this.isSharing = false;
    });
  }

  mute() {
    this.call?.mute().then(() => {
      this.isMuted = true;
    });
  }

  unmute() {
    this.call?.unmute().then(() => {
      this.isMuted = false;
    });
  }

  endCall() {
    if (!this.call || this.call.state === 'Disconnected')
      return;

    this.isWaiting = false;
    this.isEnding = true;
    this.call.hangUp().then(() => {
      this.isEnding = false;
      this.inCall = false;
    }).catch(err => {
      this.isEnding = false;
      this.inCall = false;
    });
  }

  private async getCallOptions(): Promise<any> {
    const cameras = await this.deviceManager.getCameras();
    this.localVideoStream = new LocalVideoStream(cameras[0]);
    return {
      videoOptions: {
        localVideoStreams: [this.localVideoStream]
      },
      audioOptions: {
        muted: false
      }
    };
  }
}
