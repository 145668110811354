<div class="flex-space-between">
  <app-tabs 
    *ngIf="tabs?.length"
    [tabs]="tabs">
  </app-tabs>

  <div class="action-header_container">
    <button class="button button--small button--has-icon">
      <i class="button_icon fas fa-download"></i>
      <a href="https://visuall-videos.azureedge.net/manuals/VisuALL S User Manual 2.1.pdf"
        download="VisuALL S User Manual 2.1.pdf">{{ 'userManual' | translate | titlecase }} S</a>
    </button>
    <button class="button button--small button--has-icon">
      <i class="button_icon fas fa-download"></i>
      <a href="https://visuall-videos.azureedge.net/manuals/VisuALL ETS Manual Rev 2.1.pdf"
        download="VisuALL ETS Manual 2.1.pdf">{{ 'userManual' | translate | titlecase }} ETS</a>
    </button>
  </div>
</div>

<br>

<div
  *ngIf="activeTab === 0"
  class="row">
  <div class="col-lg-4 col-sm-6 col-xs-12">
    <div class="video-box">
      <p class="text--center">{{ 'videoTitle1' | translate }}</p>
      <video 
        controls
        preload="auto" 
        width="100%" 
        height="auto">
        <source src="https://visuall-videos.azureedge.net/tutorials/wifi.mp4" type="video/mp4">
      </video>
    </div>
  </div>
  <div class="col-lg-4 col-sm-6 col-xs-12">
    <div class="video-box">
      <p class="text--center">{{ 'videoTitle2' | translate }}</p>
      <video 
        controls
        preload="auto" 
        width="100%" 
        height="auto">
        <source src="https://visuall-videos.azureedge.net/tutorials/new-patient.mp4" type="video/mp4">
      </video>
    </div>
  </div>
  <div class="col-lg-4 col-sm-6 col-xs-12">
    <div class="video-box">
      <p class="text--center">{{ 'videoTitle3' | translate }}</p>
      <div class="video-box_container">
        <iframe 
          class="video-box_frame"
          src="https://www.youtube.com/embed/ldjOmKjbw6c" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-sm-6 col-xs-12">
    <div class="video-box">
      <p class="text--center">{{ 'videoTitle4' | translate }}</p>
      <div class="video-box_container">
        <iframe 
          class="video-box_frame"
          src="https://www.youtube.com/embed/4OnSZcLb1H8" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-sm-6 col-xs-12">
    <div class="video-box">
      <p class="text--center">{{ 'videoTitle5' | translate }}</p>
      <div class="video-box_container">
        <iframe 
          class="video-box_frame"
          src="https://www.youtube.com/embed/KC5INo2cFbo" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
  <div class="col-lg-4 col-sm-6 col-xs-12">
    <div class="video-box">
      <p class="text--center">{{ 'videoTitle6' | translate }}</p>
      <div class="video-box_container">
        <iframe 
          class="video-box_frame"
          src="https://www.youtube.com/embed/V2wPZ9sBoEU" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="activeTab === 1"
  class="row">
  <div class="col-xs-12">

    <h2 class="text--title-bold">
      {{ 'faqQuestion1' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer1' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion2' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer2' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion3' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer3' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion4' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer4' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion5' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer5' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion6' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer6' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion7' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer7' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion8' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer8' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion9' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer9' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion10' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer10' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion11' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer11' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion12' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer12' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion13' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer13' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion14' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer14' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion15' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer15' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion16' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer16' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion17' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer17' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion18' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer18' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion19' | translate }}
    </h2>
    <p class="text--light">
      a) {{ 'faqAnswer19a' | translate }}
    </p>
    <p class="text--light">
      b) {{ 'faqAnswer19b' | translate }}
    </p>
    <p class="text--light">
      c) {{ 'faqAnswer19c' | translate }}
    </p>

    <br>

    <h2 class="text--title-bold">
      {{ 'faqQuestion20' | translate }}
    </h2>
    <p class="text--light">
      {{ 'faqAnswer20' | translate }}
      <br>
      <br>
      1. {{ 'faqAnswer20-1' | translate }}
      <br>
      <br>
      <img src="./assets/images/faq-step-1.png">
      <br>
      2. {{ 'faqAnswer20-2' | translate }}
      <br>
      <br>
      <img src="./assets/images/faq-step-2.png">
      <br>
      3. {{ 'faqAnswer20-3' | translate }}
      <br>
      <br>
      <img src="./assets/images/faq-step-3.png">
      <br>
      <br>
      4. {{ 'faqAnswer20-4-1' | translate }} 
      <a href="https://support.apple.com/en-us/HT204023">{{ 'faqAnswer20-4-2' | translate }}</a> {{ 'or' | translate }} 
      <a href="https://support.google.com/android/answer/9059108?hl=en">{{ 'faqAnswer20-4-3' | translate }}</a>.
      {{ 'faqAnswer20-4-4' | translate }} 
      <a href="https://visuall-videos.azureedge.net/tutorials/wifi.mp4">{{ 'faqAnswer20-4-5' | translate }} </a>.
      <br>
      <br>
      <br>
    </p>

  </div>

</div>