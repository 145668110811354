<app-tabs 
  *ngIf="tabs" 
  [tabs]="tabs">
</app-tabs>

<br>

<kendo-grid
  [data]="gridData$ | async"
  [loading]="isLoading"
  [filterable]="true"
  [filter]="filter"
  (filterChange)="filterChange($event)"
  [sortable]="true"
  [sort]="sort"
  (sortChange)="sortChange($event)"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="skip"
  (pageChange)="pageChange($event)"
  [height]="700">
  <kendo-grid-column 
    field="name" 
    title="{{ 'name' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column 
    field="device_type" 
    title="{{ 'deviceType' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="app_version"
    title="{{ 'appVersion' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="serial_number" 
    title="{{ 'serialNumber' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="office_group" 
    title="{{ 'officeGroup' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column *ngIf="authService.isBasicSupportUser">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button
        *ngIf="dataItem?.claimed" 
        kendoGridEditCommand 
        class="k-primary" 
        (click)="unlink(dataItem)">
        {{ 'unlink' | translate | titlecase }}
      </button>
   </ng-template>
  </kendo-grid-command-column>
</kendo-grid>