import { Directive,  HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appHoverItem]'
})
export class HoverItemDirective {
  @HostBinding(`class.hover`) hovered;

  constructor() {}

  @HostListener('mouseleave')
  public OnExit() {
    this.hovered = false;
  }

  @HostListener('mouseenter')
  public OnEnter() {
    this.hovered = true;
  }
}
