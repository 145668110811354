// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TitleCasePipe } from '@angular/common';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// services
import { SsoService } from 'app/core/services/sso.service';
import { TabsService } from 'app/core/services/tabs.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { PaginatedItems, Tab, ListItemColumn, ListItemAction, IInvitation } from '../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, OFFICE_TAB_INDEX, INVITATION_STATUS } from '../constants';

interface IInvitationDisplay extends IInvitation {
  status_name: string;
}

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html'
})
export class InvitationsComponent implements OnInit, OnDestroy {

  invitations$: BehaviorSubject<PaginatedItems<IInvitationDisplay> | null> = new BehaviorSubject(null);

  tabs: Tab[] = [];

  columns: ListItemColumn[] = [
    {
      translationKey: 'firstName',
      fieldName: 'first_name'
    },
    {
      translationKey: 'lastName',
      fieldName: 'last_name'
    },
    {
      translationKey: 'email',
      fieldName: 'email',
      fontClass: 'bold'
    },
    {
      translationKey: 'status',
      fieldName: 'status_name'
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'resend',
      execute: (invitation: IInvitation) => this.resend(invitation.id),
      visible: (invitation: IInvitation) => this.canResend(invitation)
    },
    {
      translationKey: 'cancel',
      execute: (invitation: IInvitation) => this.cancel(invitation.id),
      visible: (invitation: IInvitation) => this.canCancel(invitation)
    }
  ];

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading: boolean = false;

  languageSubscription: Subscription;

  constructor(
    private router: Router,
    private ssoService: SsoService,
    private toastService: ToastrService,
    private errorService: ErrorService,
    private translateService: TranslateService,
    private tabsService: TabsService
  ) { }

  ngOnInit() {
    this.getInvitations(this.currentPageSize, this.currentPageIndex, null);

    this.languageSubscription = this.translateService.onLangChange.subscribe(res => {
      this.getInvitations(this.currentPageSize, this.currentPageIndex, null);
    });

    this.tabs = this.tabsService.getOfficeTabs(OFFICE_TAB_INDEX.INVITATIONS);
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  getInvitations(pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    this.ssoService.getAllInvitations(pageSize, pageIndex, term).subscribe((invitations: PaginatedItems<IInvitation>) => {
      this.isLoading = false;
      this.invitations$.next(this.parseInvitations(invitations));
    }, err => { this.isLoading = false; });
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.term = term;

    this.getInvitations(this.currentPageSize, this.currentPageIndex, this.term);
  };

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getInvitations(pageSize, pageIndex, this.term);
  }

  private parseInvitations(invitations: PaginatedItems<IInvitation>): PaginatedItems<IInvitationDisplay> {
    const parsedInvitations = invitations.results.map(invitation => {
      return {
        ...invitation,
        status_name: new TitleCasePipe().transform(this.translateService.instant(INVITATION_STATUS[invitation.status].translationKey))
      };
    });

    return {
      results: parsedInvitations,
      count: invitations.count
    };
  }

  create() {
    this.router.navigate(['/invitations/new']);
  }

  resend(id: number) {
    this.ssoService.resendInvitation(id).subscribe(() => {
      this.toastService.success(this.translateService.instant('invitationResendSuccessMessage'));
      this.getInvitations(this.currentPageSize, this.currentPageIndex, null);
    },
      err => this.errorService.handleError()
    );
  }

  cancel(id: number) {
    this.ssoService.cancelInvitation(id).subscribe(() => {
      this.toastService.success(this.translateService.instant('invitationCancelSuccessMessage'));
      this.getInvitations(this.currentPageSize, this.currentPageIndex, null);
    },
      err => this.errorService.handleError()
    );
  }

  canResend(invitation: IInvitation) {
    return true;
  }

  canCancel(invitation: IInvitation) {
    return [INVITATION_STATUS.INITIALIZED.value, INVITATION_STATUS.EMAIL_SENT.value].includes(invitation.status);
  }
}
