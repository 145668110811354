// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// services
import { SsoService } from 'app/core/services/sso.service';
import { OfficesService } from 'app/core/services/offices.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { CenterContainerTitle, IInvitationRequest, Office, PaginatedItems } from '../../models';

// constants
import { EMPLOYEE_GROUPS } from '../../constants';

@Component({
  selector: 'app-invitation-form',
  templateUrl: './invitation-form.component.html'
})
export class InvitationFormComponent implements OnInit {

  form: UntypedFormGroup;
  offices: Office[];
  employeeGroups = EMPLOYEE_GROUPS;

  title: CenterContainerTitle = {};

  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private ssoService: SsoService,
    private officesService: OfficesService,
    private errorService: ErrorService,
    private toastService: ToastrService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.isLoading = true;

    this.translateService.get('user').subscribe(() => this.setTranslations());

    this.officesService.getAll().pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((offices: PaginatedItems<Office>) => {
      this.offices = offices.results;
      this.initializeForm();
    }, error => this.errorService.handleError(error));
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('user');
    this.title.thin = this.translateService.instant('invite');
  }

  public initializeForm() {

    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      offices: [null, Validators.required],
      employeeGroup: [null, Validators.required]
    });
  }

  public onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    let { first_name, last_name, email, offices, employeeGroup } = this.form.value;

    const groups = employeeGroup ? [employeeGroup] : employeeGroup;

    const body: IInvitationRequest = {
      first_name,
      last_name,
      email: email?.toLowerCase ? email.toLowerCase() : null,
      offices,
      groups
    };

    this.isLoading = true;
    this.ssoService.ssoInvite(body).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      response => {
        this.toastService.success(this.translateService.instant('invitationSentSuccessMessage'));
        this.close();
      },
      error => this.errorService.handleInvitationError(this.form, error)
    );
  }
  public close() {
    this.router.navigate(['/invitations']);
  }
}
