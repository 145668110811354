// native
import { Component, ElementRef, ViewChild } from '@angular/core';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { LanguageService } from 'app/core/services/language.service';
import { PreferencesService } from 'app/core/services/preferences.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  host: {
    '(document:click)': 'onOutsideDropdownClick($event)'
  }
})
export class LanguageSwitcherComponent {

  isDropdownVisible: boolean = false;
  @ViewChild('selectionBox') selectionBoxElement: ElementRef;

  constructor(
    public translateService: TranslateService,
    public languageService: LanguageService,
    private preferencesService: PreferencesService
  ) { }

  onLanguageChange(language: string) {
    this.translateService.use(language);
    this.preferencesService.update({ app_language: language }).subscribe(() => {
      this.languageService.setAppLanguage(language);
    });
  }

  onOutsideDropdownClick(event) {
    if (!this.selectionBoxElement?.nativeElement.contains(event.target)) {
      this.isDropdownVisible = false;
    }
  }
}
