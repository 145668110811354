// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// service
import { CustomBundlesService } from 'app/core/services/custom-bundles.service';
import { TechallSessionService } from 'app/core/services/techall-session.service';

// model
import { CustomBundle } from '../../models';

@Component({
  selector: 'app-view-custom-bundle',
  templateUrl: './view-custom-bundle.component.html'
})
export class ViewCustomBundleComponent implements OnInit {

  customBundle: CustomBundle

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customBundlesService: CustomBundlesService,
    private techallService: TechallSessionService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];
      const patientId = this.techallService.currentSession?.patient.id;

      this.customBundlesService.getOne(id, patientId).subscribe((bundle: CustomBundle) => {
        this.customBundle = bundle;
      });
    });
  }

  close(event: any) {
    this.router.navigate(['/custom-bundles']);
  }
}
