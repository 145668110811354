// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// service
import { UtilityService } from '../../../core/services/utility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
    public utilityService: UtilityService
  ) { }

  ngOnInit() {
  }

  onContactClick() {
    this.router.navigate(['/contact']);
  }
}
