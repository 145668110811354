<div class="item-list_container">
  <div 
    *ngIf="items && !items.length" 
    class="item-list_row item-list_row--empty">
    {{ 'noResultsFound' | translate }}
  </div>

  <div 
    *ngFor="let item of items; trackBy: itemTrackBy"
    class="item-list_row"
    [ngClass]="{
      'pointer': clickable,
      'item-list_row--green': greenMarker?.visible(item),
      'item-list_row--orange': orangeMarker?.visible(item),
      'item-list_row--red': redMarker?.visible(item)
    }"
    appHoverItem 
    appSwipeItem 
    (click)="clickable && onItemClick.emit(item)">

    <div class="item-list_icon">
      <ng-container *ngFor="let icon of icons">
        <div
          *ngIf="icon?.visible(item)" 
          matTooltip="{{ icon?.getTooltip && icon.getTooltip(item) }}" 
          matTooltipPosition="above">
          <i class="fas fa-{{icon.name}}"></i>
        </div>
      </ng-container>
    </div>

    <div 
      *ngFor="let column of columns"
      class="item-list_cell {{column.fontClass}} col-xs-1 {{column.isSmall ? 'col-md-1' : 'col-md-2'}}"
      [ngClass]="{'item-list_cell--hover-only': column.hoverOnly, 'hidden-mobile': column.isHiddenOnMobile}" >

      <span 
        *ngIf="showWarning && !item[column.fieldName]"
        matTooltip="{{ (column.translationKey | translate | titlecase) + ' ' + ('isMissing' | translate) + '!' }}" 
        matTooltipPosition="above">
        <i class="fa-exclamation-circle fas red"></i>
      </span>

      <span 
        matTooltip="{{ column.translationKey | translate | titlecase }}" 
        [matTooltipPosition]="column.repositionTooltip ? 'left' : 'above'" 
        [innerHTML]="item[column.fieldName]">
      </span>
    </div>

    <div
      class="item-list_button-container end-xs col-xs-offset-{{ 4 - columns?.length }} col-md-offset-{{ (4 - columns?.length) * 2}}"
      [ngClass]="{
          'col-xs-8 col-md-4': !reducedActionsMode, 
          'col-md-3 col-xs-7 col-md-offset-0 col-xs-offset-0': reducedActionsMode 
        }">
      <button 
        *ngFor="let action of actions"
        click-stop-propagation 
        class="item-list_button"
        [ngClass]="{'item-list_button--disabled': action.isLoading}"
        type="button"
        [hidden]="!action.visible(item)"
        (click)="!action.isLoading && action.execute(item, action)">
        <span 
          [ngClass]="{'vis-hidden': action.isLoading}">
          {{ action.translationKey | translate }}
        </span>
        <app-load 
          *ngIf="action.isLoading" 
          class="item-list_loader"
          [isLight]="true">
        </app-load>
      </button>
    </div>
    
  </div>

  <mat-paginator 
    *ngIf="pageable"
    [hidden]="!showPaginator"
    #paginator 
    [length]="count" 
    [pageIndex]="pageIndex" 
    [pageSize]="pageSize"
    [pageSizeOptions]="[10, 20, 50, 100]"
    (page)="changePage($event)">
  </mat-paginator>
</div>