<app-center-container
  [title]="title"
  [showClose]="true"
  (close)="close()">

  <br>
  <div>
    {{ 'selectPatientToMoveTest' | translate }}:
  </div>
  <br>

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <app-input-wrapper 
      [control]="form.get('patient')" 
      label="{{ 'patient' | translate }}"
      identifier="patient">

      <input 
        type="text"
        id="patient"
        class="form_control"
        formControlName="patient"
        [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" class="dropdown dropdown--autocomplete">
          <mat-option 
            *ngFor="let patient of patients$ | async" 
            [value]="patientsService.getDisplayName(patient)"
            (onSelectionChange)="onSelectPatient(patient)">
            {{ patientsService.getDisplayName(patient) }}
          </mat-option>
        </mat-autocomplete>

    </app-input-wrapper>
    <br>

    <button class="button button--primary form_button" type="submit" [disabled]="!form.valid || !form.dirty || !selectedPatient">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>
