// native
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// service
import { ApiService } from './api.service';

// models
import { ContactBody } from '../../models';

// constants
import { API_CONTACT_PATH } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(public apiService: ApiService) { }

  public contact(body: ContactBody): Observable<any> {
    return this.apiService.post(API_CONTACT_PATH, body).pipe(map(res => res as any));
  }
}
