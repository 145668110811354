<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="close.emit()">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

    <app-input-wrapper 
      [control]="form.get('name')" 
      label="{{ 'name' | translate }}"
      identifier="name">
      <input class="form_control" type="text" formControlName="name" name="name" id="name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('street_address')" 
      label="{{ 'streetAddress' | translate }}"
      identifier="street_address">
      <input class="form_control" type="text" formControlName="street_address" name="street_address" id="street_address">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('city')" 
      label="{{ 'city' | translate }}"
      identifier="city">
      <input class="form_control" type="text" formControlName="city" name="city" id="city">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('state')" 
      label="{{ 'state' | translate }}"
      identifier="state">
      <input class="form_control" type="text" formControlName="state" name="state" id="state">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('zip')" 
      label="{{ 'zipCode' | translate }}"
      identifier="zip">
      <input class="form_control" type="text" formControlName="zip" name="zip" id="zip">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('country')" 
      label="{{ 'country' | translate }}"
      identifier="country">

      <input 
        type="text"
        class="form_control"
        formControlName="country"
        [matAutocomplete]="auto"
        name="country"
        id="country">
        <mat-autocomplete #auto="matAutocomplete" class="dropdown dropdown--autocomplete">
          <mat-option 
            *ngFor="let country of countries$ | async" 
            [value]="country.name">
            <span class="flex-centered">
              <img src="./assets/images/svg-country-flags/{{country.code.toLowerCase()}}.svg">
              <span>&nbsp; {{ country.name }}</span>
            </span>
          </mat-option>
        </mat-autocomplete>
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('phone')"
      [hasPlaceholderOffset]="true" 
      label="{{ 'phone' | translate }}">
      <ngx-mat-intl-tel-input
        class="form_control"
        [ngClass]="{ 'form_control--disabled': disabled }"
        [preferredCountries]="['us']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        formControlName="phone"
        name="phone">
      </ngx-mat-intl-tel-input>
    </app-input-wrapper>

    <br>

    <button class="button button--primary form_button" type="submit" [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>

  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>