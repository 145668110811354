<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="closeForm()">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
    
    <app-input-wrapper 
      [control]="form.get('name')" 
      label="{{ 'officeGroupName' | translate }}"
      identifier="name">
      <input class="form_control" type="text" formControlName="name" id="name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('first_name')" 
      label="{{ 'adminFirstName' | translate }}"
      identifier="first_name">
      <input class="form_control" type="text" formControlName="first_name" id="first_name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('last_name')" 
      label="{{ 'adminLastName' | translate }}"
      identifier="last_name">
      <input class="form_control" type="text" formControlName="last_name" id="last_name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('email')" 
      label="{{ 'adminEmail' | translate }}"
      identifier="email">
      <input
        autocorrect="off"
        autocapitalize="none"
        class="form_control" 
        type="email"
        id="email"
        formControlName="email">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('username')" 
      label="{{ 'adminUsername' | translate }}"
      identifier="username">
      <input
        autocorrect="off"
        autocapitalize="none"
        class="form_control" 
        type="text"
        id="username"
        formControlName="username">
    </app-input-wrapper>

    <app-input-wrapper
      [control]="form.get('waggoner_module')">
      <input
        type="checkbox"
        class="checkbox_input"
        formControlName="waggoner_module"
        id="waggoner_module">
      <label 
        for="waggoner_module"
        class="checkbox_label"
        [ngClass]="{ 'checkbox_label--error': form.get('waggoner_module').errors && form.get('waggoner_module').touched }">
        {{ 'enableWaggoner' | translate | titlecase }}
      </label>
    </app-input-wrapper>

    <br>

    <button class="button button--primary form_button" type="submit" [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>

  </form>
  
</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>