// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';

// service
import { DialogService } from 'app/core/services/dialog.service';
import { AuthService } from 'app/core/services/auth.service';
import { ErrorService } from 'app/core/services/error.service';
import { SupportService } from 'app/core/services/support.service';

// models
import { Tab, GridItem, SupportUser, ButtonListItem } from '../../models';

// constants
import { DEFAULT_SUPPORT_PAGE_INDEX, DEFAULT_SUPPORT_PAGE_SIZE, DEFAULT_SUPPORT_PAGE_SKIP, DEFAULT_SUPPORT_SORT_ORDER, USER_GROUP } from '../../constants';

@Component({
  selector: 'app-support-users',
  templateUrl: './support-users.component.html'
})
export class SupportUsersComponent implements OnInit, OnDestroy {

  tabs: Tab[] = [
    {
      translationKey: 'devices',
      action: () => this.router.navigate(['/support/devices']),
      isActive: false
    },
    {
      translationKey: 'users',
      action: null,
      isActive: true
    },
    {
      translationKey: 'offices',
      action: () => this.router.navigate(['/support/offices']),
      isActive: false
    }
  ];

  buttonList: Array<ButtonListItem> = [
    {
      translationKey: 'support.downloadUsersReport',
      action: () => this.downloadActiveUsersReport(),
      disabled: false,
      iconClass: 'fas fa-download'
    }
  ];

  pageSize = DEFAULT_SUPPORT_PAGE_SIZE;
  pageIndex = DEFAULT_SUPPORT_PAGE_INDEX;
  skip = DEFAULT_SUPPORT_PAGE_SKIP;
  sort: SortDescriptor[] = [{
    field: 'last_name',
    dir: DEFAULT_SUPPORT_SORT_ORDER
  }];
  filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };

  gridData$: BehaviorSubject<GridItem<SupportUser>> = new BehaviorSubject<GridItem<SupportUser>>({} as GridItem<SupportUser>);

  isLoading: boolean = true;

  userToChangeEmail: SupportUser = null;

  constructor(
    private supportService: SupportService,
    private router: Router,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private toastService: ToastrService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.userToChangeEmail = null;
  }

  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageIndex = this.skip / this.pageSize;
    this.getUsers();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getUsers();
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.getUsers();
  }

  private getUsers() {
    this.isLoading = true;
    this.supportService.getUsers(this.pageSize, this.pageIndex, this.sort, this.filter).subscribe(res => {
      this.gridData$.next(res);
      this.isLoading = false;
    });
  }

  private downloadActiveUsersReport() {
    this.isLoading = true;
    this.supportService.downloadActiveUsersReport().pipe(
      finalize(() => (this.isLoading = false))
    ).subscribe(() => { },
      () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric'))
    );
  }

  isOfficeGroupAdmin(user: SupportUser): boolean {
    return user.groups?.includes(USER_GROUP.OFFICE_GROUP_ADMIN.value);
  }

  setAsAdmin(user: SupportUser): void {
    this.dialogService.openConfirm({
      action: '',
      message: this.translateService.instant('setAsAdminPrompt'),
      text: this.translateService.instant('user') + ': ' + user.first_name + ' ' + user.last_name,
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed) {
        this.isLoading = true;
        this.supportService.setAsAdmin(user.id).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(
          res => {
            this.toastService.success(this.translateService.instant('setAdminSuccess'));
            this.getUsers();
          },
          error => this.errorService.handleSupportUserError(error?.code)
        );
      }
    });
  }

  onChangeEmailClick(user: SupportUser): void {
    this.userToChangeEmail = user;
  }
}
