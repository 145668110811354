// native
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// service
import { PreferencesService } from './preferences.service';

@Injectable({
  providedIn: 'root'
})
export class DemoService {
  constructor(
    private preferencesService: PreferencesService,
    private router: Router
  ) { }

  checkAndRedirect(forceRedirect = false) {
    if (this.preferencesService.isDemoModeEnabled && !window?.location?.href?.includes('observe'))
      this.router.navigate(['demo-mode']);
    else if (forceRedirect)
      this.router.navigate(['patients']);
  }
}

