// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

// service
import { TechallSessionService } from 'app/core/services/techall-session.service';

// models
import { TechallSession } from '../models';

@Component({
  selector: 'app-techall-user',
  templateUrl: './techall-user.component.html'
})
export class TechallUserComponent implements OnInit, OnDestroy {
  session: TechallSession;
  callEndedSubscription: Subscription;
  pingInterval: any;

  constructor(
    private router: Router,
    public techallService: TechallSessionService
  ) { }

  ngOnInit(): void {
    this.getSessions(true);

    this.setSessionsPing();

    this.callEndedSubscription = this.techallService.callEnded$.subscribe(() => {
      this.getSessions();
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.pingInterval);
    this.callEndedSubscription?.unsubscribe();
  }

  private getSessions(initialLoad: boolean = false) {
    this.techallService.getSessions().subscribe(async sessions => {
      // checking if session still exists just for display purposes (to show video box)
      this.session = sessions?.results[0];
      this.techallService.currentSession = this.session;

      // following acs related logic is triggered only once on first load
      if (initialLoad && this.session)
        this.techallService.initializeCallAgent(this.session, false);
    });
  }

  private setSessionsPing() {
    this.pingInterval = setInterval(() => {
      this.getSessions();
    }, 5000);
  }

  onJoin() {
    this.router.navigate(['/patients/new']);
  }
}
