<div class="tabs__container">
  <span 
    *ngFor="let tab of tabs"
    class="tabs__button"
    [ngClass]="{
      'tabs__button--active': tab.isActive, 
      'tabs__button--hidden': !tab.translationKey,
      'tabs__button--small': isSpaceSavingMode(),
      'tabs__button--extra-small': isExtraSmall()
    }"
    (click)="tab.action && tab.action()">
    <span class="tabs__text">
      <i *ngIf="tab.iconClass" class="{{tab.iconClass}}"></i>&nbsp;{{ tab.translationKey | translate | titlecase }}
    </span>
  </span>
</div>