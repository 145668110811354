<app-center-container
  [title]="title"
  [showClose]="true"
  (close)="close.emit()">

  <form *ngIf="form" [formGroup]="form">
    <app-input-wrapper 
      [control]="form.get('period_in_days')" 
      label="{{ 'periodInDays' | translate }}"
      identifier="period_in_days">
      <input class="form_control" type="number" min="1" max="999" formControlName="period_in_days" name="period_in_days" id="period_in_days">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('end_date')" 
      label="{{ 'endDate' | translate }}"
      identifier="end_date">
      <input 
        class="form_control" 
        [matDatepicker]="picker"
        [datePickerFormat]="preferencesService.defaultDateFormat.serverValue"
        [min]="minEndDate"  
        formControlName="end_date"
        name="end_date"
        id="end_date">
      <mat-datepicker-toggle matSuffix [for]="picker" class="form_datepicker-icon">
      </mat-datepicker-toggle>
    </app-input-wrapper>

    <mat-datepicker #picker></mat-datepicker>

    <button 
      *ngIf="isEdit" 
      (click)="onSubmit(form, true)" 
      class="button button--primary form_button" 
      [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>
  </form>

</app-center-container>