<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="closeForm()">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

    <app-input-wrapper 
      [control]="form.get('name')" 
      label="{{ 'name' | translate }}"
      identifier="name">
      <input 
        class="form_control" 
        type="text" 
        formControlName="name"
        id="name">
    </app-input-wrapper>

    <button 
      *ngIf="!isDisabled"
      class="button button--primary form_button"
      type="submit"
      [disabled]="!form.valid">
      <span class="bold">{{ 'done' | translate }}</span>
    </button>
    
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>
