// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// services
import { WorkItemService } from 'app/core/services/work-item.service';
import { UtilityService } from 'app/core/services/utility.service';
import { ErrorService } from 'app/core/services/error.service';
import { PreferencesService } from 'app/core/services/preferences.service';

// models
import { PaginatedItems, ListItemColumn, ListItemAction, WorkItem, WorkItemFilterRequest, ListItemMarker, Tab } from '../models/';

// constants
import { CREATE_PATIENT_FROM_MWL, MWL_WORK_ITEM_STATUS, WORK_ITEM_FILTER_KEY } from '../constants';

@Component({
  selector: 'app-work-items',
  templateUrl: './work-items.component.html'
})
export class WorkItemsComponent implements OnInit, OnDestroy {

  tabs: Tab[] = [
    {
      translationKey: 'workItems',
      action: () => null,
      isActive: true
    },
    {
      translationKey: 'pacsRecords',
      action: () => this.router.navigate([`/work-items/history`]),
      isActive: false
    }
  ];

  columns: ListItemColumn[] = [
    {
      translationKey: 'pacs.patientName',
      fieldName: 'patient_name',
      fontClass: 'bold'
    },
    {
      translationKey: 'pacs.patientId',
      fieldName: 'patient_id',
      fontClass: 'bold',
      isSmall: true,
    },
    {
      translationKey: 'pacs.patientBirthDate',
      fieldName: 'patient_birth_date',
      fontClass: 'thin',
      isSmall: true,
      isHiddenOnMobile: true
    },
    {
      translationKey: 'pacs.accessionNumber',
      fieldName: 'accession_number',
      fontClass: 'bold',
      isSmall: true
    },
    {
      translationKey: 'pacs.scheduledProcedureStepStartDate',
      fieldName: 'scheduled_procedure_step_start_datetime',
      fontClass: 'thin'
    },
    {
      translationKey: 'pacs.scheduledProcedureStepDescription',
      fieldName: 'scheduled_procedure_step_description',
      fontClass: 'thin',
      repositionTooltip: true
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'newTest',
      execute: (workItem: WorkItem) => this.newTest(workItem),
      visible: (workItem: WorkItem) => workItem.can_create_patient
    },
    {
      translationKey: 'newBundle',
      execute: (workItem: WorkItem) => this.newBundle(workItem),
      visible: (workItem: WorkItem) => workItem.can_create_patient
    },
    {
      translationKey: 'viewPatient',
      execute: (workItem: WorkItem) => this.router.navigate(['/tests', workItem.visuall_patient_id]),
      visible: (workItem: WorkItem) => !!workItem.visuall_patient_id
    }
  ];

  greenMarker: ListItemMarker = {
    visible: (item: WorkItem) => item.status === MWL_WORK_ITEM_STATUS.SUCCESS
  };

  redMarker: ListItemMarker = {
    visible: (item: WorkItem) => item.status === MWL_WORK_ITEM_STATUS.WARNING
  };

  workItems$: BehaviorSubject<PaginatedItems<WorkItem>> = new BehaviorSubject(null);
  selectedItem: WorkItem;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    public workItemsService: WorkItemService,
    private utilityService: UtilityService,
    private errorService: ErrorService,
    private preferencesService: PreferencesService,
    private toastService: ToastrService
  ) { }

  ngOnInit() {
    if (!this.workItemsService.filteringInProgress)
      this.setDefaultQuery();

    this.workItemsService.workItems$.subscribe(items => {
      if (items) {
        this.workItems$.next(this.parseWorkItems(items));
        this.workItemsService.filteringInProgress = false;
      }
    });
  }

  private setDefaultQuery() {
    this.preferencesService.get().subscribe(preferences => {
      if (!preferences?.pacs_integration_device_pool?.id) {
        this.workItems$.next({ results: [], count: 0 });
        return this.toastService.info(this.translateService.instant('pacs.noDefaultDevicePoolMessage'));
      }

      const storedQuery: WorkItemFilterRequest = localStorage.getItem(WORK_ITEM_FILTER_KEY) && JSON.parse(localStorage.getItem(WORK_ITEM_FILTER_KEY));
      this.isLoading = true;

      this.workItemsService.getConfig().subscribe(config => {
        const body: WorkItemFilterRequest = {
          patient_first_name: storedQuery ? storedQuery.patient_first_name : null,
          patient_last_name: storedQuery ? storedQuery.patient_last_name : null,
          patient_id: storedQuery ? storedQuery.patient_id : null,
          accession_number: storedQuery ? storedQuery.accession_number : null,
          requested_procedure_id: storedQuery ? storedQuery.requested_procedure_id : null,
          scheduled_procedure_step_start_date_from: storedQuery ? storedQuery.scheduled_procedure_step_start_date_from : this.utilityService.convertClientDateToServerDate(new Date(new Date().setHours(0, 0, 0, 0))),
          scheduled_procedure_step_start_date_to: storedQuery ? storedQuery.scheduled_procedure_step_start_date_to : null,
          modality: storedQuery ? storedQuery.modality : config.mwl_query_modality_default,
          scheduled_station_ae_title: storedQuery ? storedQuery.scheduled_station_ae_title : null,
          scheduled_station_ae_title_auto: (storedQuery?.scheduled_station_ae_title_auto === false) ? storedQuery.scheduled_station_ae_title_auto : true,
          pacs_integration_device_pool: storedQuery ? storedQuery.pacs_integration_device_pool : preferences.pacs_integration_device_pool?.id
        };

        this.workItemsService.filter(body).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(response => {
          this.workItemsService.listen(response.mwl_query_id);
        }, err => this.errorService.handleError(err?.error));
      }, err => this.errorService.handleError(err?.error));
    });
  }

  ngOnDestroy(): void {
    this.workItemsService.workItems$.next(null);
  }

  newTest(workItem: WorkItem) {
    this.router.navigate(['/tests/new', workItem.visuall_patient_id || CREATE_PATIENT_FROM_MWL], { queryParams: { mwlId: workItem.id } });
  };

  newBundle(workItem: WorkItem) {
    this.router.navigate(['/tests/bundles/new', workItem.visuall_patient_id || CREATE_PATIENT_FROM_MWL], { queryParams: { mwlId: workItem.id } });
  };

  view(workItem: WorkItem) {
    this.selectedItem = workItem;
  }

  createFilter() {
    this.router.navigate(['/work-items/filter']);
  }

  isWarning = (workItem: WorkItem) => workItem.status === MWL_WORK_ITEM_STATUS.WARNING;
  getWarningDetails = (workItem: WorkItem) => workItem.status_details || '';

  isPatientItem = (workItem: WorkItem) => !!workItem.visuall_patient_id;

  private parseWorkItems(workItems: WorkItem[]): PaginatedItems<WorkItem> {
    const parsedWorkitems = workItems.map(item => {
      return {
        ...item,
        scheduled_procedure_step_start_datetime: new DatePipe('en-US').transform(item.scheduled_procedure_step_start_datetime, this.preferencesService.defaultDateTimeFormat),
        patient_birth_date: new DatePipe('en-US').transform(item.patient_birth_date, this.preferencesService.defaultDateFormat.value)
      };
    });

    return {
      results: parsedWorkitems,
      count: workItems.length
    };
  }
}