// native
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html'
})
export class StreamComponent {
  minimized: boolean;
  full: boolean;

  @Input() streamPending: boolean;
  @Input() isSingleImage: boolean;

  @ViewChild('leftImage') leftImage: ElementRef;
  @ViewChild('rightImage') rightImage: ElementRef;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }

  onMinimizeToggle() {
    this.minimized = !this.minimized;
  }

  onFullToggle() {
    this.full = !this.full;
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    this.full = false;
    this.minimized = false;
  }
}
