<app-tabs 
  *ngIf="tabs?.length"
  [tabs]="tabs">
</app-tabs>

<br>

<kendo-grid
  [data]="gridData$ | async"
  [loading]="isLoading"
  [filterable]="true"
  [filter]="filter"
  (filterChange)="filterChange($event)"
  [sortable]="true"
  [sort]="sort"
  (sortChange)="sortChange($event)"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="skip"
  (pageChange)="pageChange($event)"
  [height]="700">

  <kendo-grid-column 
    field="patient_name" 
    title="{{ 'patient' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  
  <kendo-grid-column 
    field="status" 
    title="{{ 'status' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <grid-dropdown-filter
        [filter]="filter"
        [data]="statusCategories"
        column="status"
        textField="name"
        valueField="value"
      ></grid-dropdown-filter>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{getStatusTranslation(dataItem.status)}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column 
    field="test_group" 
    title="{{ 'test' | translate | titlecase }}"
    [sortable]="false"
    [filterable]="false">
  </kendo-grid-column>

  <kendo-grid-column 
    field="status_changed" 
    title="{{ 'statusChanged' | translate | titlecase }}"
    filter="date">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-gte-operator></kendo-filter-gte-operator>
        <kendo-filter-lte-operator></kendo-filter-lte-operator>
      </kendo-grid-date-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.status_changed | date: 'MM/dd/yyyy HH:mm:ss'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column *ngIf="authService.isOfficeGroupAdmin" [width]="100">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button
        kendoGridEditCommand 
        class="k-primary" 
        (click)="restart(dataItem)">
        {{ 'restart' | translate | titlecase }}
      </button>
   </ng-template>
  </kendo-grid-command-column>

</kendo-grid>