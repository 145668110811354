// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PatientsService } from 'app/core/services/patients.service';
import { DialogService } from 'app/core/services/dialog.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { PaginatedItems, CenterContainerTitle, Patient, TestMoveRequest, TestBundle } from '../../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';

@Component({
  selector: 'app-move-bundle',
  templateUrl: './move-bundle.component.html'
})
export class MoveBundleComponent implements OnInit {

  form: UntypedFormGroup;
  patients$: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
  bundle: TestBundle;

  currentPatientId: string;
  selectedPatient: Patient;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public patientsService: PatientsService,
    private testBundleService: TestBundlesService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    const bundleId = this.route.snapshot.params['id'];
    this.currentPatientId = this.route.snapshot.queryParams['patient'];

    this.translateService.get('user').subscribe(() => this.setTranslations());

    this.isLoading = true;
    this.testBundleService.getOne(bundleId).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((bundle: TestBundle) => {
      if (!bundle)
        this.close();

      this.bundle = bundle;
    }, err => this.close());

    this.initializeForm();

    this.patientsService.getAll().subscribe((response: PaginatedItems<Patient>) => {
      this.patients$.next(this.filterCurrentPatient(response.results));
    });
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('bundle');
    this.title.thin = this.translateService.instant('move');
  }

  public initializeForm() {
    this.form = this.formBuilder.group({
      patient: ['', Validators.required],
    });

    this.form.controls['patient'].valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      tap((value) => {
        if (this.patientsService.getDisplayName(this.selectedPatient) !== value)
          this.selectedPatient = null;
      }),
      switchMap(term => {
        return this.patientsService.getAll(DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, term.toLowerCase());
      }),
      tap(response => {
        this.patients$.next(this.filterCurrentPatient(response.results));
      })
    ).subscribe();
  }

  private filterCurrentPatient(patients: Patient[]): Patient[] {
    const filtered = patients?.filter(patient => patient.id !== parseInt(this.currentPatientId)) || [];
    return filtered;
  }

  onSelectPatient(patient: Patient) {
    this.selectedPatient = patient;
  }

  public onSubmit(form: UntypedFormGroup) {
    if (!form.valid || !this.selectedPatient)
      return;

    this.dialogService.openConfirm({
      action: this.translateService.instant('move'),
      message: this.translateService.instant('bundleMovePrompt')
        + ' ' + this.patientsService.getDisplayName(this.selectedPatient) + '?',
      text: new TitleCasePipe().transform(this.translateService.instant('bundle'))
        + ': ' + this.getBundleInfo(this.bundle.test_descriptions.map((desc: any) => desc.test_group.group.name)),
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed) {
        this.isLoading = true;;
        const body: TestMoveRequest = {
          patient: this.selectedPatient.id
        };

        this.testBundleService.move(this.bundle.id, body).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(res => {
          this.toastService.success(this.translateService.instant('bundleMoveSuccess'));
          this.router.navigate([`tests/bundles/${this.selectedPatient.id}`]);
        }, err => this.errorService.handleError(this.translateService.instant('bundleMoveError')));
      }
    });
  }

  close() {
    this.router.navigate([`tests/bundles/${this.currentPatientId}`]);
  }

  private getBundleInfo(names: string[]) {
    if (names.length > 3)
      return names.slice(0, 3).join(', ') + '...';
    else
      return names.join(', ');
  }
}
