// native
import { Component, OnInit } from '@angular/core';

// services
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'app-sso-success',
  template: ''
})
export class SsoSuccessComponent implements OnInit {

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.handleLoginSuccess();
  }
}
