// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html'
})
export class NewUserComponent implements OnInit {

  officeId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const officeId = this.route.snapshot.params['officeId'];

    if (officeId)
      this.officeId = parseInt(officeId);
  }

  closeForm(event: any) {
    this.router.navigate(['/users']);
  }
}
