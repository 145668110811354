// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// service
import { TestsService } from 'app/core/services/tests.service';
import { ImportService } from 'app/core/services/import.service';
import { UtilityService } from 'app/core/services/utility.service';
import { ErrorService } from 'app/core/services/error.service';
import { PreferencesService } from 'app/core/services/preferences.service';

// models
import { CenterContainerTitle, ImportItemDetails, LowConfidenceSensitivity, TestGroup, TestProtocol, VerifyImportPayload } from '../../models';

// constants
import { GROUP, OTHER_PROTOCOL, IMPORT_STATUS } from '../../constants';

interface PrepopulatedFields {
  group: string;
  protocol: string;
  test_date: string;
  low_confidence_sensitivities?: LowConfidenceSensitivity[];
}

@Component({
  selector: 'app-import-verify',
  templateUrl: './import-verify.component.html'
})
export class ImportVerifyComponent implements OnInit {
  title: CenterContainerTitle;
  uploadId: string;
  patientId: number;
  isSucceeded: boolean;

  form: UntypedFormGroup;
  currentStep: number = 1;
  step1: UntypedFormGroup;
  step2: UntypedFormGroup;
  maxTestDate = new Date();

  displayedTestGroups: TestGroup[] = [];
  displayedTestProtocols: TestProtocol[] = [];

  isLoading: boolean = false;
  isOtherGroup: boolean = false;
  isOtherProtocol: boolean = false;

  lowConfidenceSensitivities: LowConfidenceSensitivity[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private importService: ImportService,
    private testsService: TestsService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private utilityService: UtilityService,
    private errorService: ErrorService,
    public preferencesService: PreferencesService
  ) { }

  ngOnInit() {
    this.title = {
      bold: this.translateService.instant('externalTest')
    };

    this.route.params.subscribe(params => {
      this.isLoading = true;
      this.uploadId = params['uploadId'];
      this.getFormData(this.uploadId)
        .pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(
          ([testGroups, upload]) => {
            this.displayedTestGroups = testGroups;
            this.patientId = (<ImportItemDetails>upload).patient;
            this.isSucceeded = (<ImportItemDetails>upload).extraction_status === IMPORT_STATUS.SUCCEEDED.value;

            this.title.thin = this.isSucceeded ? this.translateService.instant('verify') : this.translateService.instant('resolve');

            const prepopulatedFields: PrepopulatedFields = {
              group: upload.group,
              protocol: upload.protocol,
              test_date: upload.test_date,
              low_confidence_sensitivities: upload.low_confidence_sensitivities
            };
            this.initForm(prepopulatedFields);

            this.lowConfidenceSensitivities = [...upload.low_confidence_sensitivities];
            this.loadSensitivityImages();
          },
          error => this.errorService.handleError());
    });
  }

  public getFormData(uploadId: string): Observable<[TestGroup[], ImportItemDetails]> {
    return forkJoin([
      this.testsService.getImportTestGroups(),
      this.importService.getItemDetails(uploadId)
    ]);
  }

  initForm(fields: PrepopulatedFields) {
    const test_date = fields.test_date ? this.utilityService.convertServerDateToClientTimezoneDate(fields.test_date) : null;

    this.step1 = this.formBuilder.group({
      group: [fields.group || null, [Validators.required]],
      test_date: [test_date, [Validators.required]],
      protocol: [fields.protocol || null, [Validators.required]],
      other_protocol: null
    });

    const sensitivities = [];
    fields.low_confidence_sensitivities.forEach(sensitivity => {
      sensitivities.push(this.formBuilder.group({
        sensitivityValue: [sensitivity.value, Validators.required]
      }));
    });

    this.step2 = this.formBuilder.group({
      sensitivities: this.formBuilder.array(sensitivities)
    });

    this.form = this.formBuilder.group({
      step1: this.step1,
      step2: this.step2
    });

    if (fields.group)
      this.onGroupChange(fields.group, true);

    this.setGroupChangeListener();
    this.setProtocolChangeListener();
  }

  loadSensitivityImages() {
    this.lowConfidenceSensitivities.forEach(sensitivity => {
      this.importService.loadSensitivityImage(this.uploadId, sensitivity.page_id.toString(), sensitivity.index.toString()).subscribe(blob => {
        const objectURL = URL.createObjectURL(<Blob>blob);
        sensitivity.imageSrc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      });
    });
  }

  onNextStep() {
    if (this.lowConfidenceSensitivities.length) {
      this.currentStep = 2;
      return;
    }
    this.onSubmit();
  }

  protected setGroupChangeListener() {
    this.step1.get('group').valueChanges.subscribe(group => {
      this.onGroupChange(group);
    });
  }

  private setProtocolChangeListener() {
    this.step1.get('protocol').valueChanges.subscribe(protocol => {
      this.isOtherProtocol = protocol === OTHER_PROTOCOL;
      this.step1.controls['other_protocol'].setValue(null);
      if (this.isOtherProtocol || this.isOtherGroup) {
        this.step1.controls['other_protocol'].setValidators(Validators.required);
        this.step1.controls['other_protocol'].updateValueAndValidity();
      } else {
        this.step1.controls['other_protocol'].setValidators(null);
        this.step1.controls['other_protocol'].updateValueAndValidity();
      }
    });
  }

  private onGroupChange(group, isInitialSet = false) {
    if (!isInitialSet)
      this.step1.controls['protocol'].setValue(null);

    this.step1.controls['other_protocol'].setValue(null);

    this.isOtherGroup = group === GROUP.OTHER;
    if (this.isOtherGroup) {
      this.step1.controls['other_protocol'].setValidators(Validators.required);
      this.step1.controls['other_protocol'].updateValueAndValidity();
      this.displayedTestProtocols = [];
    } else {
      this.step1.controls['other_protocol'].setValidators(null);
      this.step1.controls['other_protocol'].updateValueAndValidity();
      if (this.selectedTestGroup.strategies.length > 0)
        this.displayedTestProtocols = this.selectedTestGroup.strategies[0].protocols || [];
      else
        this.displayedTestProtocols = this.selectedTestGroup.protocols || [];
    }

    if (this.displayedTestProtocols.length == 0) {
      this.step1.controls['protocol'].setValidators(null);
      this.step1.controls['protocol'].updateValueAndValidity();
    } else {
      this.step1.controls['protocol'].setValidators(Validators.required);
      this.step1.controls['protocol'].updateValueAndValidity();
    }
  }

  get selectedTestGroup(): TestGroup {
    const selectedGroupValue = this.step1.controls['group'].value;
    return this.displayedTestGroups.find(group => group.value === selectedGroupValue);
  }

  onSubmit() {
    if (!this.form.valid)
      return;

    const { group, other_protocol, protocol, test_date } = this.step1.value;

    const parsedProtocol = (this.isOtherGroup || this.isOtherProtocol) ? other_protocol : protocol;
    const parsedDate = this.utilityService.convertClientDateToServerTimezoneDate(test_date);

    const body: VerifyImportPayload = {
      group,
      protocol: parsedProtocol || '',
      test_date: parsedDate,
      updated_sensitivities: this.getUpdatedSensitivities()
    };

    this.importService.updateImportItem(body, this.uploadId)
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(res => {
        this.importService.removeFile(this.uploadId);
        this.closeForm();
      }, err => {
        this.errorService.handleError(this.translateService.instant('verifyImportErrorMessage'));
      });
  }

  private getUpdatedSensitivities(): LowConfidenceSensitivity[] {
    return this.lowConfidenceSensitivities.map((sensitivity, index) => {
      return {
        index: sensitivity.index,
        page_id: sensitivity.page_id,
        value: parseInt(this.form.value.step2.sensitivities[index]?.sensitivityValue)
      };
    });
  }

  closeForm() {
    this.router.navigate(['/tests', this.patientId]);
  }
}