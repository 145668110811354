<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="closeForm()">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

    <div
      *ngIf="!!storedQuery"
      class="form_icon-container">
      <i
        class="pointer fa fa-info-circle"
        matTooltip="{{'patientFilterQueryMessage' | translate}}">
      </i>
    </div>

    <app-input-wrapper
      [control]="form.get('pacs_integration_device_pool')" 
      label="{{ 'devicePool' | translate }}">
      <mat-select 
        class="form_control"
        formControlName="pacs_integration_device_pool" 
        disableOptionCentering 
        panelClass="dropdown"
        name="pacs_integration_device_pool"
        aria-label="{{ 'devicePool' | translate }}">
        <mat-option *ngFor="let pool of defaultConfig.device_pools" [value]="pool.id">
          {{ pool.name }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <app-input-wrapper 
      *ngIf="isFieldShown('patient_first_name')"
      [control]="form.get('patient_first_name')" 
      label="{{ 'firstName' | translate }}"
      identifier="patient_first_name">
      <input class="form_control" type="text" formControlName="patient_first_name" id="patient_first_name">
    </app-input-wrapper>

    <app-input-wrapper 
      *ngIf="isFieldShown('patient_last_name')"
      [control]="form.get('patient_last_name')" 
      label="{{ 'lastName' | translate }}"
      identifier="patient_last_name">
      <input class="form_control" type="text" formControlName="patient_last_name" id="patient_last_name">
    </app-input-wrapper>

    <app-input-wrapper 
      *ngIf="isFieldShown('patient_id')"
      [control]="form.get('patient_id')" 
      label="{{ 'pacs.patientId' | translate }}"
      identifier="patient_id">
      <input class="form_control" type="text" formControlName="patient_id" id="patient_id">
    </app-input-wrapper>

    <app-input-wrapper 
      *ngIf="isFieldShown('accession_number')"
      [control]="form.get('accession_number')" 
      label="{{ 'pacs.accessionNumber' | translate }}"
      identifier="accession_number">
      <input class="form_control" type="text" formControlName="accession_number" id="accession_number">
    </app-input-wrapper>

    <app-input-wrapper
      *ngIf="isFieldShown('requested_procedure_id')" 
      [control]="form.get('requested_procedure_id')" 
      label="{{ 'pacs.requestedProcedureId' | translate }}"
      identifier="requested_procedure_id">
      <input class="form_control" type="text" formControlName="requested_procedure_id" id="requested_procedure_id">
    </app-input-wrapper>

    <app-input-wrapper
      *ngIf="isFieldShown('scheduled_procedure_step_start_date_from')" 
      [control]="form.get('scheduled_procedure_step_start_date_from')" 
      label="{{ 'filterDateFrom' | translate }}"
      identifier="scheduled_procedure_step_start_date_from">
      <input 
        class="form_control" 
        [matDatepicker]="pickerFrom"
        [datePickerFormat]="preferencesService.defaultDateFormat.serverValue"
        formControlName="scheduled_procedure_step_start_date_from"
        id="scheduled_procedure_step_start_date_from">
      <mat-datepicker-toggle matSuffix [for]="pickerFrom" class="form_datepicker-icon">
      </mat-datepicker-toggle>
    </app-input-wrapper>

    <mat-datepicker #pickerFrom></mat-datepicker>

    <app-input-wrapper
      *ngIf="isFieldShown('scheduled_procedure_step_start_date_to')"  
      [control]="form.get('scheduled_procedure_step_start_date_to')" 
      label="{{ 'filterDateTo' | translate }}"
      identifier="scheduled_procedure_step_start_date_to">
      <input 
        class="form_control" 
        [matDatepicker]="pickerTo"
        [datePickerFormat]="preferencesService.defaultDateFormat.serverValue"
        formControlName="scheduled_procedure_step_start_date_to"
        id="scheduled_procedure_step_start_date_to">
      <mat-datepicker-toggle matSuffix [for]="pickerTo" class="form_datepicker-icon">
      </mat-datepicker-toggle>
    </app-input-wrapper>

    <mat-datepicker #pickerTo></mat-datepicker>

    <app-input-wrapper 
      *ngIf="isFieldShown('modality')"
      [control]="form.get('modality')" 
      label="{{ 'pacs.modality' | translate }}"
      identifier="modality">
      <input class="form_control" type="text" formControlName="modality" id="modality">
    </app-input-wrapper>

    <app-input-wrapper
      [control]="form.get('scheduled_station_ae_title_auto')">
      <input
        type="checkbox"
        class="checkbox_input"
        formControlName="scheduled_station_ae_title_auto"
        (change)="onAeTitleAutoChange()"
        id="scheduled_station_ae_title_auto">
      <label 
        for="scheduled_station_ae_title_auto"
        class="checkbox_label"
        [ngClass]="{ 'checkbox_label--error': form.get('scheduled_station_ae_title_auto').errors && form.get('scheduled_station_ae_title_auto').touched }">
        {{ 'pacs.scheduledStationAeTitleAuto' | translate }}
      </label>
    </app-input-wrapper>

    <app-input-wrapper 
      *ngIf="!form.get('scheduled_station_ae_title_auto').value"
      [control]="form.get('scheduled_station_ae_title')" 
      label="{{ 'pacs.scheduledStationAeTitle' | translate }}"
      identifier="scheduled_station_ae_title">
      <input class="form_control" type="text" formControlName="scheduled_station_ae_title" id="scheduled_station_ae_title">
    </app-input-wrapper>

    <button *ngIf="!disabled" class="button button--primary form_button" type="submit" [disabled]="!form.valid">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>
  </form>
</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>