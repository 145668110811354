// native
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { ImportService } from 'app/core/services/import.service';

// models
import { CenterContainerTitle } from '../../models';

@Component({
  selector: 'app-import-upload',
  templateUrl: './import-upload.component.html'
})
export class ImportUploadComponent implements OnInit {

  patientId: number;
  title: CenterContainerTitle;
  form: UntypedFormGroup;
  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private importService: ImportService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.patientId = params['patientId'];
    });

    this.title = {
      bold: this.translateService.instant('externalTest'),
      thin: this.translateService.instant('upload')
    };

    this.form = this.formBuilder.group({
      test_report_file: [null, [Validators.required]]
    });
  }

  onSubmit() {
    if (!this.form.valid)
      return;

    const files = this.form.value.test_report_file;
    files.forEach(file => this.startImport(file));
    this.closeForm();
    this.importService.listShown$.next(true);
  }

  startImport(file: File) {
    const formData = new FormData();
    formData.append('patient', this.patientId.toString());
    formData.append('test_report', file);

    this.importService.addFile(formData, (payload) => this.importService.createImportItem(payload), file, this.patientId);
    this.importService.start();
  }

  closeForm() {
    this.router.navigate(['/tests', this.patientId]);
  }
}