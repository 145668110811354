<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="close()">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

    <app-input-wrapper 
      [control]="form.get('name')" 
      label="{{ 'name' | translate }}"
      identifier="name">
      <input 
        class="form_control"
        name="name" 
        type="text" 
        formControlName="name"
        id="name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('email')" 
      label="{{ 'email' | translate }}"
      identifier="email">
      <input
        autocorrect="off"
        autocapitalize="none"
        class="form_control" 
        name="email"
        type="email" 
        formControlName="email"
        id="email">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('phone_number')"
      [hasPlaceholderOffset]="true"
      label="{{ 'phone' | translate }}">
      <ngx-mat-intl-tel-input
        class="form_control"
        [ngClass]="{ 'form_control--disabled': disabled }"
        [preferredCountries]="['us']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        formControlName="phone_number">
      </ngx-mat-intl-tel-input>
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('message')" 
      label="{{ 'message' | translate }}"
      identifier="message">
      <textarea 
        class="form_control form_textarea"
        name="message"
        type="text"
        rows="7"
        maxlength="1000"
        formControlName="message"
        id="message">
      </textarea>
    </app-input-wrapper>

    <br>

    <button 
      class="button button--primary form_button"
      type="submit"
      [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'sendMessage' | translate }}</span>
    </button>
    
  </form>

</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>