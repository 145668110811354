// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// service
import { TestsRecurringService } from 'app/core/services/tests-recurring.service';

// models
import { RecurringTest } from 'app/models';

@Component({
  selector: 'app-view-test-recurring',
  templateUrl: './view-test-recurring.component.html'
})
export class ViewTestRecurringComponent implements OnInit {

  recurringTest: RecurringTest;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testsRecurringService: TestsRecurringService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.testsRecurringService.getOne(id).subscribe((recurringTest: RecurringTest) => {
        this.recurringTest = recurringTest;
      }, err => {
        this.closeForm();
      });
    });
  }

  closeForm() {
    this.recurringTest.test_bundle
      ? this.router.navigate(['/tests/bundles/recurring', this.recurringTest.patient], { queryParamsHandling: 'merge' })
      : this.router.navigate(['/tests/recurring', this.recurringTest.patient], { queryParamsHandling: 'merge' });
  }
}
