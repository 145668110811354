import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-hourglass',
  templateUrl: './hourglass.component.html'
})
export class HourglassComponent implements OnChanges {

  @Input() offset: number[] = [];

  offsetTop: number = 0;
  offsetBottom: number = 100;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.offset?.currentValue !== null && changes?.offset?.currentValue !== undefined) {
      this.offsetTop = changes?.offset?.currentValue;
      this.offsetBottom = 100 - this.offsetTop;
    }
  }

}
