// native
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

// models
import { ColorTestRecord } from 'app/models';

@Component({
  selector: 'app-color-circles',
  templateUrl: './color-circles.component.html'
})
export class ColorCirclesComponent implements OnChanges {

  @Input() colors: ColorTestRecord[] = [];
  @Input() height: number;
  @ViewChild('container') containerEl: ElementRef;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.height?.currentValue) {
      this.containerEl && (this.containerEl.nativeElement.style.height = `${changes.height.currentValue}px`);
    }
  }
}
