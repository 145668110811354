// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-office',
  templateUrl: './new-office.component.html'
})
export class NewOfficeComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  closeForm(event: any) {
    this.router.navigate(['/offices']);
  }
}
