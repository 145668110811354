// native
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

// service
import { TestsService } from '../../core/services/tests.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { ContrastRecord, MonitorEvent } from '../../models';

//animation
import { fadeAnimation, startList } from '../../animations/animations';

// constant
import { MONITOR_EVENT_TYPE, EYE, MONITOR_INACTIVITY_TIMEOUT } from '../../constants';

@Component({
  selector: 'app-monitor-contrast-test',
  templateUrl: './monitor-contrast.component.html',
  animations: [fadeAnimation, startList]
})
export class MonitorContrastComponent extends MonitorBaseComponent {
  odData: ContrastRecord[] = [];
  osData: ContrastRecord[] = [];
  ouData: ContrastRecord[] = [];

  isSingleOsEye: boolean = false;
  isSingleOdEye: boolean = false;
  isMonocular: boolean = null;

  question: string[] = [];
  answer: string[] = [];
  variants: string[] = [];
  currentEye: string = '';
  currentSeqNum: number = null;

  percentageChunk = 12.5;

  isLoading: boolean = true;

  inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.VISUAL_ACUITY * 1000;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.isSingleOsEye = this.test.eye === EYE.OS;
    this.isSingleOdEye = this.test.eye === EYE.OD;
    this.isMonocular = !!this.test.monocular;

    this.monitorTestService.getContrastStandardThresholdState(this.test.id)
      .pipe(
        finalize(() => this.isLoading = false),
      ).subscribe(records => {
        const mappedRecords: ContrastRecord[] = records.map(record => {
          return {
            ...record,
            correct: !!this.isCorrect(record)
          };
        });
        this.renderThresholdRecords(mappedRecords);
      });
    this.monitorTestService.getContrastStandardOngoingState(this.test.id)
      .pipe(
        finalize(() => this.isLoading = false)
      ).subscribe(record => {
        record && this.renderOngoingState(record);
      });
  }

  private renderThresholdRecords(records: ContrastRecord[]) {
    records.filter(record => record.eye === EYE.OD && record.phase !== 3).forEach(record => {
      this.odData[record.index - 1] = record;
    });
    records.filter(record => record.eye === EYE.OS && record.phase !== 3).forEach(record => {
      this.osData[record.index - 1] = record;
    });
    records.filter(record => [EYE.OU, EYE.BOTH_SERVER, null].includes(record.eye) && record.phase !== 3).forEach(record => {
      this.ouData[record.index - 1] = record;
    });
  }

  private renderOngoingState(record: ContrastRecord) {
    this.question = record.question.split('');
    this.answer = record.answer.split('');
    this.variants = record.variants.split('');
    this.currentEye = record.eye || EYE.OU;
    this.currentSeqNum = record.seq_num;
  }

  public getPercentageDone() {
    if (!this.isMonocular)
      return this.hasPhaseTwo(this.ouData) ? 100 : this.ouData?.filter(item => !!item).length * this.percentageChunk;
    else if (this.isSingleOdEye)
      return this.hasPhaseTwo(this.odData) ? 100 : this.odData?.filter(item => !!item).length * this.percentageChunk;
    else if (this.isSingleOsEye)
      return this.hasPhaseTwo(this.osData) ? 100 : this.osData?.filter(item => !!item).length * this.percentageChunk;
    else
      return (this.hasPhaseTwo(this.osData) ? 50 : this.osData?.filter(item => !!item).length * this.percentageChunk / 2)
        + (this.hasPhaseTwo(this.odData) ? 50 : this.odData?.filter(item => !!item).length * this.percentageChunk / 2);
  }

  private hasPhaseTwo(records: ContrastRecord[]): boolean {
    return !!records.find(record => record?.phase === 2);
  }

  public calculateDimensions() { }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_CONTRAST_RECORD)
      this.updateRecords(<ContrastRecord>event.data);
  }

  private updateRecords(record: ContrastRecord) {
    record.correct = this.isCorrect(record);

    switch (record.eye) {
      case EYE.OD:
        this.updateEyeRecord(record, this.odData);
        break;
      case EYE.OS:
        this.updateEyeRecord(record, this.osData);
        break;
      case EYE.OU:
      case EYE.BOTH_SERVER:
      case null:
        this.updateEyeRecord(record, this.ouData);
        break;
    }
    this.updateOngoingState(record);
  }

  private updateEyeRecord(record: ContrastRecord, eyeData: ContrastRecord[]) {
    if (!record.threshold || (record.phase === 3))
      return;

    if (!eyeData[record.index - 1] || (record.seq_num > eyeData[record.index - 1].seq_num))
      eyeData[record.index - 1] = record;
  }

  private updateOngoingState(record: ContrastRecord) {
    if ((this.currentSeqNum >= record.seq_num) && (record.phase !== 3))
      return;
    this.renderOngoingState(record);
  }

  public resetStateAfterReconnection(): void {
    this.odData = [];
    this.osData = [];
    this.ouData = [];
  }

  public onToggleGrid() { }

  private isCorrect(record: ContrastRecord): boolean {
    const answers = record.answer.split('');
    for (let i = 0; i < answers.length; i++) {
      if (record.question.split('').includes(answers[i]))
        return true;
    }
  }
}
