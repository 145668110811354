// native
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

// addon
import { MatPaginator, PageEvent } from '@angular/material/paginator';

// models
import { ListItemAction, ListItemColumn, ListItemIcon, ListItemMarker, PaginatedItems } from '../../../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../../constants';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html'
})
export class ItemListComponent implements OnInit {
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;

  @Input() items$: BehaviorSubject<PaginatedItems<any>>;
  @Input() columns: Array<ListItemColumn>;
  @Input() actions: Array<ListItemAction>;
  @Input() icons?: ListItemIcon[] = [];
  @Input() greenMarker?: ListItemMarker;
  @Input() orangeMarker?: ListItemMarker;
  @Input() redMarker?: ListItemMarker;
  @Input() showWarning?: boolean;
  @Input() clickable?: boolean = true;
  @Input() pageable?: boolean = true;
  @Input() pageSize?: number = DEFAULT_PAGE_SIZE;
  @Input() itemTrackBy?: Function = null;

  @Output() onItemClick?: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangePage?: EventEmitter<{ pageIndex: number, pageSize: number; }> = new EventEmitter<{ pageIndex: number, pageSize: number; }>();

  items: any[];
  reducedActionsMode: boolean = false;
  pageIndex: number = DEFAULT_PAGE_INDEX;
  showPaginator: boolean;
  count: number;
  previousSearchCount: number;

  ngOnInit() {
    this.items$
      .pipe(filter((res) => res !== null))
      .subscribe((items: PaginatedItems<any>) => {
        this.showPaginator = items.count > this.pageSize;

        // todo revisit if isolating paginator to its own component
        if (this.previousSearchCount && this.previousSearchCount !== items.count)
          this.paginator && (this.paginator.pageIndex = DEFAULT_PAGE_INDEX);
        this.previousSearchCount = items.count;

        if (this.paginator) {
          const paginatorIntl = this.paginator._intl;
          paginatorIntl.nextPageLabel = '';
          paginatorIntl.previousPageLabel = '';
        }

        this.items = items.results;
        this.count = items.count;
      });

    if (this.columns?.length >= 5)
      this.reducedActionsMode = true;
  }

  public changePage(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.onChangePage.emit({ pageIndex: event.pageIndex, pageSize: event.pageSize });
  }
}