<div class="list-header_container">
  <button
    *ngIf="canCreate"   
    class="list-header_button cy-new-button" 
    [ngClass]="{ 'list-header_button--single': !canSearch }"
    type="button"
    (click)="onCreate.emit()">
    <span class="bold">{{ 'new' | translate }}</span>
    <span class="thin">{{ name | translate | titlecase }}</span>
  </button>

  <div class="list-header_searchbar" *ngIf="canSearch">
    <input
      type="text"
      placeholder="{{ 'searchFor' | translate }} {{ name | translate | lowercase }}" 
      [(ngModel)]="term"
      (input)="typeaheadSearch$.next()" 
      (keyup.enter)="onEnter()" >
    <div 
      *ngIf="!!term"
      class="list-header_icon-close"
      (click)="clearSearch()">
      <i class="fas fa-times"></i>
    </div>
    <div 
      class="list-header_icon-search"
      (click)="search()">
      <img src="./assets/images/search.svg" alt="search">
    </div>
  </div>
</div>