
<div class="baa_container">
  <div class="baa_title">{{ 'baa.title' | translate }}</div>

  <div class="baa_item baa_item--large">
    <div>1.</div>
    <div>{{ 'baa.1.header' | translate }}</div>
  </div>

  <div class="baa_item baa_item--large">
    <div>2.</div>
    <div>
      <div>
        {{ 'baa.2.header' | translate }}
      </div>
      <div class="baa_item">    
        <div>2.1</div>
        <div>{{ 'baa.2.1' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.2</div>
        <div>{{ 'baa.2.2' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.3</div>
        <div>{{ 'baa.2.3' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.4</div>
        <div>{{ 'baa.2.4' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.5</div>
        <div>{{ 'baa.2.5' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.6</div>
        <div>{{ 'baa.2.6' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.7</div>
        <div>{{ 'baa.2.7' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.8</div>
        <div>{{ 'baa.2.8' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.9</div>
        <div>{{ 'baa.2.9' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.10</div>
        <div>{{ 'baa.2.10' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.11</div>
        <div>{{ 'baa.2.11' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.12</div>
        <div>{{ 'baa.2.12' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.13</div>
        <div>{{ 'baa.2.13' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.14</div>
        <div>{{ 'baa.2.14' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.15</div>
        <div>{{ 'baa.2.15' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.16</div>
        <div>{{ 'baa.2.16' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.17</div>
        <div>{{ 'baa.2.17' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.18</div>
        <div>{{ 'baa.2.18' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.19</div>
        <div>{{ 'baa.2.19' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>2.20</div>
        <div>{{ 'baa.2.20' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="baa_item baa_item--large">
    <div>3.</div>
    <div>
      <div>
        {{ 'baa.3.header' | translate }}
      </div>
      <div class="baa_item">    
        <div>3.1</div>
        <div>{{ 'baa.3.1' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.2</div>
        <div>{{ 'baa.3.2' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.3</div>
        <div>{{ 'baa.3.3' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.4</div>
        <div>{{ 'baa.3.4' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.5</div>
        <div>{{ 'baa.3.5' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.6</div>
        <div>
          <div>{{ 'baa.3.6' | translate }}</div>
          <div class="baa_item">    
            <div>3.6.1</div>
            <div>{{ 'baa.3.6.1' | translate }}</div>
          </div>
          <div class="baa_item">    
            <div>3.6.2</div>
            <div>{{ 'baa.3.6.2' | translate }}</div>
          </div>
          <div class="baa_item">    
            <div>3.6.3</div>
            <div>{{ 'baa.3.6.3' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="baa_item">    
        <div>3.7</div>
        <div>{{ 'baa.3.7' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.8</div>
        <div>{{ 'baa.3.8' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.9</div>
        <div>{{ 'baa.3.9' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.10</div>
        <div>{{ 'baa.3.10' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.11</div>
        <div>{{ 'baa.3.11' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.12</div>
        <div>{{ 'baa.3.12' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.13</div>
        <div>{{ 'baa.3.13' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.14</div>
        <div>{{ 'baa.3.14' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.15</div>
        <div>{{ 'baa.3.15' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>3.16</div>
        <div>{{ 'baa.3.16' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="baa_item baa_item--large">
    <div>4.</div>
    <div>{{ 'baa.4.header' | translate }}</div>
  </div>

  <div class="baa_item baa_item--large">
    <div>5.</div>
    <div>
      <div>
        {{ 'baa.5.header' | translate }}
      </div>
      <div class="baa_item">    
        <div>5.1</div>
        <div>{{ 'baa.5.1' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>5.2</div>
        <div>
          <div>{{ 'baa.5.2' | translate }}</div>
          <div class="baa_item">    
            <div>5.2.1</div>
            <div>{{ 'baa.5.2.1' | translate }}</div>
          </div>
          <div class="baa_item">    
            <div>5.2.2</div>
            <div>{{ 'baa.5.2.2' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="baa_item baa_item--large">
    <div>6.</div>
    <div>
      <div>
        {{ 'baa.6.header' | translate }}
      </div>
      <div class="baa_item">    
        <div>6.1</div>
        <div>{{ 'baa.6.1' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>6.2</div>
        <div>{{ 'baa.6.2' | translate }}</div>
      </div>
    </div>
  </div>

  <div class="baa_item baa_item--large">
    <div>7.</div>
    <div>
      <div>
        {{ 'baa.7.header' | translate }}
      </div>
      <div class="baa_item">    
        <div>7.1</div>
        <div>{{ 'baa.7.1' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>7.2</div>
        <div>{{ 'baa.7.2' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>7.3</div>
        <div>{{ 'baa.7.3' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>7.4</div>
        <div class="text--pre-wrap">{{ 'baa.7.4' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>7.5</div>
        <div>{{ 'baa.7.5' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>7.6</div>
        <div>{{ 'baa.7.6' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>7.7</div>
        <div>{{ 'baa.7.7' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>7.8</div>
        <div>{{ 'baa.7.8' | translate }}</div>
      </div>
      <div class="baa_item">    
        <div>7.9</div>
        <div>{{ 'baa.7.9' | translate }}</div>
      </div>
    </div>
  </div>

  <br><br>

  <div class="flex-space-around">
    <button class="button button--primary button--border" (click)="onAgreementAccept()">
      <span class="regular">{{ 'accept' | translate }}</span>
    </button>
    <button class="button button--secondary button--border" (click)="onAgreementCancel()">
      <span class="regular">{{ 'cancel' | translate }}</span>
    </button>
  </div>

  <br>

</div>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>
