// native
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { ColorTestRecord, ColorTestEyeState, MonitorEvent, ColorTestState } from '../../models';

// constants
import { MONITOR_EVENT_TYPE, EYE, MONITOR_INACTIVITY_TIMEOUT } from '../../constants';

@Component({
  selector: 'app-monitor-color',
  templateUrl: './monitor-color.component.html'
})
export class MonitorColorComponent extends MonitorBaseComponent {

  circleHeight: number;

  odColors: ColorTestRecord[] = [];
  osColors: ColorTestRecord[] = [];
  bothColors: ColorTestRecord[] = [];

  public currentSeqNum = null;

  public inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.COLOR_VISION * 1000;

  @ViewChild('plotContainer') plotContainer: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.monitorTestService.getColorState(this.test.id).subscribe(res => {
      setTimeout(() => this.calculateDimensions());

      this.updateColors(res.eye_states);
      this.currentSeqNum = res.seq_num;
    });
  }

  public getPercentageDone(records: ColorTestRecord[]) {
    if (!records.length)
      return 0;

    const recordsWithValues = records.filter(color => !!color);
    return (Math.min((recordsWithValues.length / records.length) * 100, 100)).toFixed(0);
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_D15_STATE) {
      const eventSeqNum = (<ColorTestState>event.data).seq_num;
      if (!eventSeqNum || !this.currentSeqNum || (this.currentSeqNum <= eventSeqNum)) {
        this.currentSeqNum = eventSeqNum;
        this.updateColors((<ColorTestState>event.data).eye_states);
      }
    }
  }

  private updateColors(items: ColorTestEyeState[]) {
    items.forEach(item => {
      switch (item.eye) {
        case EYE.OS:
          this.osColors = item.target_row;
          break;
        case EYE.OD:
          this.odColors = item.target_row;
          break;
        case null:
          this.bothColors = item.target_row;
          break;
      }
    });
  }

  public calculateDimensions() {
    const el = this.plotContainer.nativeElement;
    this.circleHeight = el.clientWidth / 17;
  }

  public resetStateAfterReconnection(): void { }

  public onToggleGrid() { }
}