<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <div class="monitor_container-column">
    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'right' | translate | titlecase }}</div>
      <div class="monitor_eye-box" *ngIf="state && !isBluePupil">
        <ng-container *ngIf="isODStimulation || isODAPD || isODReact, else odTimerTemplate">
          <div>
            <i 
              class="fas fa-eye fa-4x"
              [ngClass]="{ 'text--blink': state.od_state?.is_currently_tested || isODAPD, 
                            'faded-more': !state.od_state?.is_currently_tested && !isODAPD }">
            </i>
          </div>
          <span [ngClass]="{ 'faded-more': !state.od_state?.is_currently_tested }">
            <ng-container *ngIf="isODStimulation">{{ 'stimulation' | translate }}</ng-container>
            <ng-container *ngIf="isODAPD">{{ 'APD' | translate }}</ng-container>
            <ng-container *ngIf="isODReact">{{ 'react' | translate }}</ng-container>
          </span> 
          <ng-container *ngIf="state.od_state?.is_retesting">
            <ng-container *ngTemplateOutlet="repeatingTemplate"></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #odTimerTemplate>
          <ng-container *ngIf="isODAdaptation || isODPhaseL || isODPhaseD, else noPeriodTemplate">
            <app-hourglass [offset]="odOffset"></app-hourglass>
            <i class="fas fa-moon"></i>&nbsp;
            <ng-container *ngIf="isODAdaptation">{{ 'darkAdaptation' | translate }}</ng-container>
            <ng-container *ngIf="isODPhaseL">{{ 'phaseL' | translate }}</ng-container>
            <ng-container *ngIf="isODPhaseD">{{ 'phaseD' | translate }}</ng-container>
            <ng-container *ngIf="state.od_state?.is_retesting">
              <ng-container *ngTemplateOutlet="repeatingTemplate"></ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </div>
      <div class="monitor_eye-box" *ngIf="isBluePupil">
        <span class="text--blink">{{isTestDone ? ('testDone' | translate | titlecase) : (('inProgress' | translate | titlecase) + '...') }}</span>
      </div>
    </div>

    <div class="monitor_graph-box">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title">{{ 'left' | translate | titlecase }}</div>
      <div class="monitor_eye-box" *ngIf="state && !isBluePupil">
        <ng-container *ngIf="isOSStimulation || isOSAPD || isOSReact, else osTimerTemplate">
          <div>
            <i 
              class="fas fa-eye fa-4x"
              [ngClass]="{ 'text--blink': state.os_state?.is_currently_tested || isOSAPD, 
                            'faded-more': !state.os_state?.is_currently_tested && !isOSAPD}">
            </i>
          </div>
          <span [ngClass]="{ 'faded-more': !state.os_state?.is_currently_tested }">
            <ng-container *ngIf="isOSStimulation">{{ 'stimulation' | translate }}</ng-container>
            <ng-container *ngIf="isOSAPD">{{ 'APD' | translate }}</ng-container>
            <ng-container *ngIf="isOSReact">{{ 'react' | translate }}</ng-container>
          </span> 
          <ng-container *ngIf="state.os_state?.is_retesting">
            <ng-container *ngTemplateOutlet="repeatingTemplate"></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #osTimerTemplate>
          <ng-container *ngIf="isOSAdaptation || isOSPhaseL || isOSPhaseD, else noPeriodTemplate">
            <app-hourglass [offset]="osOffset"></app-hourglass>
            <i class="fas fa-moon"></i>&nbsp;
            <ng-container *ngIf="isOSAdaptation">{{ 'darkAdaptation' | translate }}</ng-container>
            <ng-container *ngIf="isOSPhaseL">{{ 'phaseL' | translate }}</ng-container>
            <ng-container *ngIf="isOSPhaseD">{{ 'phaseD' | translate }}</ng-container>
            <ng-container *ngIf="state.os_state?.is_retesting">
              <ng-container *ngTemplateOutlet="repeatingTemplate"></ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </div>
      <div class="monitor_eye-box" *ngIf="isBluePupil">
        <span class="text--blink">{{isTestDone ? ('testDone' | translate | titlecase) : (('inProgress' | translate | titlecase) + '...') }}</span>
      </div>
    </div>
  </div>

  <ng-template #noPeriodTemplate>
    <small>{{ 'noTestingPeriods' | translate }}</small>
  </ng-template>

  <ng-template #repeatingTemplate>
    <br>
    <small class="faded-more">{{ (isVersionTwo ? 'repeating' : 'repeatingPupil') | translate }}</small>
  </ng-template>

  <div class="monitor_container-column">
    <div class="monitor_graph-box monitor_graph-box--single">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <app-percentage-circle
        id="test-percentage"
        class="monitor_circle-box" 
        [percentage]="(isTestDone && isBluePupil) ? 100 : percentageDone">
      </app-percentage-circle>
    </div>
  </div>

</div>
