// native
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

// addon
import { TranslateService } from '@ngx-translate/core';

// models
import { CenterContainerTitle } from '../../models';


@Component({
  selector: 'app-zero-pii-form',
  templateUrl: './zero-pii-form.component.html'
})
export class ZeroPiiFormComponent implements OnInit {

  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onSubmit: EventEmitter<string> = new EventEmitter();

  form: UntypedFormGroup;

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.translateService.get('key').subscribe(() => this.setTranslations());
    this.initializeForm();
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('key');
    this.title.thin = this.translateService.instant('encryption');
  }

  public initializeForm() {
    this.form = this.formBuilder.group({
      zero_pii_password: ['', [Validators.compose([Validators.required, Validators.minLength(5)])]],
    });
  }

  public onSubmitClick(form: UntypedFormGroup) {
    if (!form.valid)
      return;
    this.onSubmit.emit(form.value?.zero_pii_password);
  }
}

