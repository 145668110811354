// native
import { Component, Input } from '@angular/core';

// models
import { ButtonListItem } from '../../../models';

@Component({
  selector: 'app-button-list',
  templateUrl: './button-list.component.html'
})
export class ButtonListComponent {

  @Input() list: ButtonListItem[];
}
