// native
import { Injectable, isDevMode } from '@angular/core';
import { Observable, timer, BehaviorSubject, Subject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { delayWhen, map, retryWhen, takeWhile } from 'rxjs/operators';

// service
import { ApiService } from './api.service';

// models
import { ImportItem, ImportItemEvent, ImportJob, ImportFileJob, ImportItemDetails, VerifyImportPayload } from '../../models';

// constants
import { API_GET_ALL_IMPORTS_PATH, API_UPLOAD_REPORT_PATH, WEBSOCKET_IMPORT_STATUS, IMPORT_STATUS } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  queue$ = new BehaviorSubject<ImportJob[]>([]);
  start$ = new BehaviorSubject<ImportJob[]>([]);
  done$ = new Subject<ImportJob[]>();

  protected queue: ImportJob[] = [];
  protected MAX_CONCURENT_JOBS = 1;

  listShown$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  
  public constructor(
    private apiService: ApiService
  ) { }

  getAll(patientId: number): Observable<ImportItem[]> {
    return this.apiService.get(`${API_GET_ALL_IMPORTS_PATH}?patient=${patientId}`).pipe(map(res => res as ImportItem[]));
  }

  getItemDetails(uploadId: string): Observable<ImportItemDetails> {
    return this.apiService.get(`${API_UPLOAD_REPORT_PATH}${uploadId}/`).pipe(map(res => res as ImportItemDetails));
  }

  createImportItem(body: FormData): Observable<ImportItem> {
    return this.apiService.post(API_UPLOAD_REPORT_PATH, body).pipe(map(res => res as ImportItem));
  }

  updateImportItem(body: VerifyImportPayload, uploadId: string): Observable<VerifyImportPayload> {
    return this.apiService.put(`${API_UPLOAD_REPORT_PATH}${uploadId}/`, body).pipe(map(res => res as VerifyImportPayload));
  }

  listenUpdates(patientId): Observable<ImportItemEvent> {
    const protocol = isDevMode() ? 'ws' : 'wss';
    const relativeUrl = `${WEBSOCKET_IMPORT_STATUS}${patientId}`;

    return webSocket(`${protocol}://${window.location.host}${relativeUrl}`).pipe(
      map(obj => obj as ImportItemEvent),
      retryWhen((errors) => errors.pipe(
        delayWhen(response => timer(2000))
      ))
    );
  }

  start() {
    this.continue();
    this.start$.next(this.queue);
  }

  addFile(formData: FormData, action: Function, file: File, entityId: number = null): ImportJob {
    const job = new ImportFileJob(formData, action, file, entityId);
    this.queue.push(job);
    return job;
  }

  removeFile(uploadId: string) {
    this.queue = this.queue.filter(job => job.id !== uploadId);
    this.queue$.next(this.queue);
  }

  protected continue() {
    const running = this.queue.filter(job => job.snapshot.status === IMPORT_STATUS.UPLOADING.value);
    if (running.length >= this.MAX_CONCURENT_JOBS) {
      return;
    }
    const queued = this.queue.filter(job => [IMPORT_STATUS.QUEUED.value].includes(job.snapshot.status)).reverse();
    if (queued.length === 0) {
      this.done$.next(this.queue);
    } else {
      const n = this.MAX_CONCURENT_JOBS - running.length;
      queued.slice(0, n).forEach(job => {
        job.status$.pipe(
          takeWhile(status => ![IMPORT_STATUS.NOT_STARTED.value, IMPORT_STATUS.UPLOAD_FAILED.value].includes(status))
        ).subscribe({
          complete: () => this.continue()
        });
        job.run();
      });
    }
    this.queue$.next(this.queue);
  }

  // currently not used
  clearFailedUploads() {
    this.queue = this.queue.filter(job => ![IMPORT_STATUS.UPLOAD_FAILED.value].includes(job.snapshot.status));
    this.queue$.next(this.queue);
  }

  loadSensitivityImage(uploadId: string, pageId: string, sensitivityId: string): Observable<string | Blob> {
    const url = `${API_UPLOAD_REPORT_PATH}${uploadId}/page-sensitivities/${pageId}/sensitivity/${sensitivityId}/`;
    return this.apiService.getBlob(url);
  }
}