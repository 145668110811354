// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { TechallSessionService } from 'app/core/services/techall-session.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { PatientsService } from 'app/core/services/patients.service';

// models
import { PaginatedItems, TechallSession, Tab, TechallSessionDisplay, ListItemAction, ListItemColumn } from '../../models';

// animation
import { routerTransition } from '../../animations/router-animations';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';

// pipe
import { BoldThinPipe } from '../../shared/pipes/pipes';

@Component({
  selector: 'app-waiting-sessions',
  templateUrl: './waiting-sessions.component.html',
  animations: [routerTransition()]
})
export class WaitingSessionsComponent implements OnInit, OnDestroy {

  tabs: Tab[] = [
    {
      translationKey: 'waitingTechallSessions',
      action: null,
      isActive: true
    },
    {
      translationKey: 'finishedTechallSessions',
      action: () => this.router.navigate([`/finished-sessions`]),
      isActive: false
    }
  ];

  columns: ListItemColumn[] = [
    {
      translationKey: 'patient',
      fieldName: 'patient_display',
      fontClass: 'bold'
    },
    {
      translationKey: 'techallUser',
      fieldName: 'user_display',
      fontClass: 'bold'
    },
    {
      translationKey: 'createdAt',
      fieldName: 'created_display',
      fontClass: 'thin'
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'join',
      execute: session => this.joinSession(session),
      visible: (session: TechallSession) => this.canJoin()
    },
    {
      translationKey: 'callAgain',
      execute: session => this.callAgain(),
      visible: (session: TechallSession) => this.canCallAgain(session)
    },
    {
      translationKey: 'endSession',
      execute: session => this.endSession(session),
      visible: (session: TechallSession) => this.canEndSession(session),
    },
    {
      translationKey: 'newTest',
      execute: session => this.createTest(session),
      visible: (session: TechallSession) => this.canCreateTest(session)
    },
    {
      translationKey: 'showTests',
      execute: session => this.showTests(session),
      visible: (session: TechallSession) => this.canShowTests(session)
    },
  ];

  sessions$: BehaviorSubject<PaginatedItems<TechallSessionDisplay> | null> = new BehaviorSubject(null);
  sessionsSnapshot: PaginatedItems<TechallSession>;

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  pingInterval: any;

  isLoading: boolean = false;

  callEndedSubscription: Subscription;

  constructor(
    private router: Router,
    private techallService: TechallSessionService,
    private translateService: TranslateService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private preferencesService: PreferencesService,
    private patientsService: PatientsService
  ) { }

  ngOnInit() {
    this.getSessions(this.currentPageSize, this.currentPageIndex, null, true);

    this.setSessionsPing();

    this.callEndedSubscription = this.techallService.callEnded$.subscribe(() => {
      this.getSessions(this.currentPageSize, this.currentPageIndex, null);
    });
  }

  ngOnDestroy() {
    clearInterval(this.pingInterval);
    this.callEndedSubscription?.unsubscribe();
  }

  private getSessions(pageSize: number, pageIndex: number, term: string, showLoader = true) {
    if (showLoader)
      this.isLoading = true;

    this.techallService.getSessions(pageSize, pageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      sessions => {
        this.techallService.currentSession = sessions?.results.find(session => !!session.admin);

        this.sessions$.next(this.parseSessions(sessions));
        this.sessionsSnapshot = sessions;
      },
      error => this.errorService.handleError(error));
  }

  private canJoin() {
    return !this.techallService.currentSession;
  }

  private canCallAgain(session: TechallSession) {
    return ((this.techallService.currentSession?.id === session.id) && !this.techallService.inCall);
  }

  private canEndSession(session: TechallSession) {
    return this.techallService.currentSession?.id === session.id;
  }

  private canCreateTest(session: TechallSession) {
    return this.techallService.currentSession?.id === session.id;
  }

  private canShowTests(session: TechallSession) {
    return this.techallService.currentSession?.id === session.id;
  }

  private createTest(session: TechallSession) {
    this.router.navigate(['tests/new', session.patient.id]);
  }

  private showTests(session: TechallSession) {
    this.router.navigate(['tests/', session.patient.id]);
  }

  private joinSession(session: TechallSession) {
    this.isLoading = true;
    this.techallService.joinSession(session).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(joinedSession => {
      this.techallService.currentSession = { ...joinedSession };
    });
  }

  private callAgain() {
    if (this.techallService.callAgent)
      this.techallService.startCall(this.techallService.currentSession);
    else
      this.techallService.initializeCallAgent(this.techallService.currentSession)
        .then(session => this.techallService.startCall(session));
  }

  private endSession(session: TechallSession) {

    this.dialogService.openConfirm({
      action: this.translateService.instant('end'),
      message: this.translateService.instant('endSessionPrompt'),
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed) {
        this.isLoading = true;
        this.techallService.updateSession(session.id, { active: false }).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(res => {
          this.getSessions(this.currentPageSize, this.currentPageIndex, this.term);
        });
      }
    });
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getSessions(pageSize, pageIndex, this.term);
  }

  isCurrentSession = (session: TechallSession) => session.id === this.techallService.currentSession?.id;

  private parseSessions(sessions: PaginatedItems<TechallSession>): PaginatedItems<TechallSessionDisplay> {
    const parsedSessions = sessions.results.map(session => ({
      ...session,
      patient_display: new BoldThinPipe().transform(
        this.patientsService.getDisplayName(session.patient)
      ),
      user_display: new BoldThinPipe().transform(
        `${session.user.last_name} ${session.user.first_name}`
      ),
      created_display: new BoldThinPipe().transform([
        new DatePipe('en-US').transform(session.created, this.preferencesService.defaultDateFormat.value),
        new DatePipe('en-US').transform(session.created, this.preferencesService.defaultTimeFormat.value)
      ])
    }));

    return {
      results: parsedSessions,
      count: sessions.count
    };
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.isLoading = true;

    this.techallService.getSessions(this.currentPageSize, this.currentPageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.term = term;
      this.sessions$.next(this.parseSessions(res));
    });
  };

  private setSessionsPing() {
    this.pingInterval = setInterval(() => {
      this.getSessions(this.currentPageSize, this.currentPageIndex, this.term, false);
    }, 3000);
  }
}