// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { AuthService } from 'app/core/services/auth.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { ErrorService } from 'app/core/services/error.service';
import { DialogService } from 'app/core/services/dialog.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { WorkItemService } from 'app/core/services/work-item.service';
import { TabsService } from 'app/core/services/tabs.service';

// models
import {
  PaginatedItems, Tab, TestBundle, ListItemAction,
  ListItemColumn, BreadcrumbOptions, ListItemMarker, TestBundleListItem
} from '../models';

// pipe
import { BoldThinPipe } from 'app/shared/pipes/pipes';

// animation
import { routerTransition } from '../animations/router-animations';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, TEST_STATUS, TEST_TAB_INDEX } from '../constants';

interface TestBundleDisplay extends TestBundleListItem {
  date_display: string;
  displayed_group_info: string;
  using_device_display: string;
  status_name: string;
}

@Component({
  selector: 'app-bundles',
  templateUrl: './bundles.component.html',
  animations: [routerTransition()]
})
export class BundlesComponent implements OnInit, OnDestroy {

  columns: ListItemColumn[] = [
    {
      translationKey: 'createdAt',
      fieldName: 'date_display',
      fontClass: 'bold'
    },
    {
      translationKey: 'testGroups',
      fieldName: 'displayed_group_info',
      fontClass: 'bold'
    },
    {
      translationKey: 'usingDevice',
      fieldName: 'using_device_display',
      fontClass: 'thin'
    },
    {
      translationKey: 'status',
      fieldName: 'status_name',
      fontClass: 'bold',
      hoverOnly: true
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'delete',
      execute: (bundle: TestBundle) => this.deleteBundle(bundle),
      visible: (bundle: TestBundle) => this.canDelete(bundle)
    },
    {
      translationKey:'monitor',
      execute: (bundle: TestBundle) => this.monitorTestService.openMonitorScreen(bundle.current_test),
      visible: (bundle: TestBundle) => this.canMonitor(bundle)
    },
    {
      translationKey: 'cancel',
      execute: (bundle: TestBundle) => this.cancelBundle(bundle),
      visible: (bundle: TestBundle) => this.canCancel(bundle)
    },
    {
      translationKey: 'viewReport',
      execute: (bundle: TestBundle, action: ListItemAction) => this.openHTMLReport(bundle, action),
      visible: (bundle: TestBundle) => this.canOpenReport(bundle)
    },
    {
      translationKey: 'dicom',
      execute: (bundle: TestBundle, action: ListItemAction) => this.downloadDicomReport(bundle, action),
      visible: (bundle: TestBundle) => this.canOpenReport(bundle) && !this.preferencesService.isZeroPiiEnabled
    },
    {
      translationKey: 'pdf',
      execute: (bundle: TestBundle, action: ListItemAction) => this.downloadPDFReport(bundle, action),
      visible: (bundle: TestBundle) => this.canOpenReport(bundle) && !this.preferencesService.isZeroPiiEnabled
    },
    {
      translationKey: 'png',
      execute: (bundle: TestBundle, action: ListItemAction) => this.downloadImageReport(bundle, action),
      visible: (bundle: TestBundle) => this.canOpenReport(bundle) && !this.preferencesService.isZeroPiiEnabled
    },
    {
      translationKey: 'move',
      execute: (test: TestBundle) => this.moveBundle(test),
      visible: (test: TestBundle) => this.canMove(test)
    }
  ];

  greenMarker: ListItemMarker = {
    visible: (test: TestBundle) => test?.status?.name === TEST_STATUS.STARTED.value
  };

  orangeMarker: ListItemMarker = {
    visible: (test: TestBundle) => [TEST_STATUS.PENDING.value, TEST_STATUS.PAUSED.value].includes(test?.status?.name)
  };

  redMarker: ListItemMarker = {
    visible: (test: TestBundle) => [TEST_STATUS.FAILED.value, TEST_STATUS.EXPIRED.value, TEST_STATUS.STOPPED.value].includes(test?.status?.name)
  };

  breadcrumbOptions: BreadcrumbOptions = {
    stepLabels: ['patients'],
    stepActions: [() => { this.router.navigate(['/patients']); }]
  };

  tabs: Tab[] = [];

  patientId: number;
  patientAge: number;
  isActiveTechallSession: boolean;

  testBundles$: BehaviorSubject<PaginatedItems<TestBundleDisplay> | null> = new BehaviorSubject(null);

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading = false;

  languageSubscription: Subscription;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private testBundlesService: TestBundlesService,
    private tabsService: TabsService,
    private patientsService: PatientsService,
    private translateService: TranslateService,
    private monitorTestService: MonitorTestService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    private preferencesService: PreferencesService,
    public workItemService: WorkItemService
  ) { }

  ngOnInit() {
    this.patientId = this.route.snapshot.params['patientId'];

    this.getTestBundles(this.patientId, this.currentPageSize, this.currentPageIndex, null);

    this.tabs = this.tabsService.getTestTabs(this.patientId, TEST_TAB_INDEX.BUNDLES);

    this.patientsService.getOne(this.patientId).subscribe(patient => {
      this.isActiveTechallSession = !!patient.active_techall_session;
      if (this.isActiveTechallSession)
        this.breadcrumbOptions.stepLabels[0] = 'VisuALL Tech';
      this.breadcrumbOptions.stepLabels.push(this.patientsService.getDisplayName(patient));
      this.patientAge = patient.age;
    });

    this.languageSubscription = this.translateService.onLangChange.subscribe(res => {
      this.getTestBundles(this.patientId, this.currentPageSize, this.currentPageIndex, null);
    });
  }

  private getTestBundles(patientId: number, pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    this.testBundlesService.getAll(patientId, pageSize, pageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      bundles => {
        this.testBundles$.next(this.parseTestBundles(bundles));
      },
      error => {
        this.errorService.handleError(error);
      }
    );
  }

  ngOnDestroy(): void {
    this.languageSubscription?.unsubscribe();
  }

  private canCancel(bundle: TestBundle) {
    if (this.authService.isTechallUserOrAdmin && !this.isActiveTechallSession)
      return false;
    return bundle.status?.name && ![TEST_STATUS.FINISHED.value, TEST_STATUS.FAILED.value].includes(bundle.status?.name);
  }

  private canDelete(bundle: TestBundle) {
    if (this.authService.isTechallUserOrAdmin && !this.isActiveTechallSession)
      return false;
    return [TEST_STATUS.FINISHED.value, TEST_STATUS.FAILED.value].includes(bundle.status?.name);
  }

  private canMove(bundle: TestBundle) {
    if (this.authService.isTechallUserOrAdmin)
      return false;

    if (this.preferencesService.isZeroPiiEnabled)
      return false;

    return [TEST_STATUS.FINISHED.value].includes(bundle.status?.name);
  }

  private canMonitor(bundle: TestBundle) {
    return [TEST_STATUS.STARTED.value, TEST_STATUS.PAUSED.value, TEST_STATUS.STOPPED.value, TEST_STATUS.PENDING.value].includes(bundle.status?.name);
  }

  private canOpenReport(bundle: TestBundle) {
    return bundle.status?.name === TEST_STATUS.FINISHED.value;
  }

  private openHTMLReport(bundle: TestBundle, action: ListItemAction) {
    if (this.testBundlesService.isCSuiteBundle(bundle))
      return this.testBundlesService.openHTMLReport(bundle.id, 'singlepage', this.patientAge);

    action.isLoading = true;
    this.testBundlesService.getBundleReportMode(bundle.id).pipe(
      finalize(() => (action.isLoading = false))
    ).subscribe(mode => {
      if (mode.singlepage)
        this.testBundlesService.openHTMLReportWithDialog(bundle.id, this.patientAge);
      else
        this.testBundlesService.openHTMLReport(bundle.id, 'full', this.patientAge);
    }, err => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
  }

  create() {
    this.router.navigate(['tests/bundles/new', this.patientId]);
  }

  view(bundle: TestBundle) {
    this.router.navigate(['tests/bundles/view', bundle.id]);
  }

  private downloadPDFReport(bundle: TestBundle, action: ListItemAction) {
    action.isLoading = true;

    if (this.testBundlesService.isCSuiteBundle(bundle))
      return this.testBundlesService.downloadPDFReport(bundle.id, 'singlepage').pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));

    this.testBundlesService.getBundleReportMode(bundle.id).subscribe(mode => {
      if (mode.singlepage)
        this.testBundlesService.openReportDialog().then(result => {
          this.isLoading = true;
          this.testBundlesService.downloadPDFReport(bundle.id, result.confirmed ? 'singlepage' : 'full').pipe(
            finalize(() => {
              action.isLoading = false;
              this.isLoading = false;
            }))
            .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
        });
      else
        this.testBundlesService.downloadPDFReport(bundle.id, 'full').pipe(
          finalize(() => (action.isLoading = false)))
          .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
    }, err => {
      action.isLoading = false;
      this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric'));
    });
  }

  private downloadDicomReport(bundle: TestBundle, action: ListItemAction) {
    action.isLoading = true;

    if (this.testBundlesService.isCSuiteBundle(bundle))
      return this.testBundlesService.downloadDicomReport(bundle.id, 'singlepage').pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));

    this.testBundlesService.getBundleReportMode(bundle.id).subscribe(mode => {
      if (mode.singlepage)
        this.testBundlesService.openReportDialog().then(result => {
          this.isLoading = true;
          this.testBundlesService.downloadDicomReport(bundle.id, result.confirmed ? 'singlepage' : 'full').pipe(
            finalize(() => {
              action.isLoading = false;
              this.isLoading = false;
            }))
            .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
        });
      else
        this.testBundlesService.downloadDicomReport(bundle.id, 'full').pipe(
          finalize(() => (action.isLoading = false)))
          .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
    }, err => {
      action.isLoading = false;
      this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric'));
    });
  }

  private downloadImageReport(bundle: TestBundle, action: ListItemAction) {
    action.isLoading = true;

    if (this.testBundlesService.isCSuiteBundle(bundle))
      return this.testBundlesService.downloadImageReport(bundle.id, 'singlepage').pipe(
        finalize(() => (action.isLoading = false)))
        .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));

    this.testBundlesService.getBundleReportMode(bundle.id).subscribe(mode => {
      if (mode.singlepage)
        this.testBundlesService.openReportDialog().then(result => {
          this.isLoading = true;
          this.testBundlesService.downloadImageReport(bundle.id, result.confirmed ? 'singlepage' : 'full').pipe(
            finalize(() => {
              action.isLoading = false;
              this.isLoading = false;
            }))
            .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
        });
      else
        this.testBundlesService.downloadImageReport(bundle.id, 'full').pipe(
          finalize(() => (action.isLoading = false)))
          .subscribe(() => { }, () => this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric')));
    }, err => {
      action.isLoading = false;
      this.errorService.handleError(this.translateService.instant('reportErrorMessageGeneric'));
    });
  }

  cancelBundle(bundle: TestBundle) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('cancel'),
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant('cancel') + ' '
        + this.translateService.instant('testBundle') + ' ?',
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed)
        this.testBundlesService.cancel(bundle.id).subscribe(
          res => this.getTestBundles(this.patientId, this.currentPageSize, this.currentPageIndex, this.term),
          error => this.errorService.handleError(error));
    });
  }

  deleteBundle(bundle: TestBundle) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant('delete') + ' '
        + this.translateService.instant('testBundle') + ' ?',
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed)
        this.testBundlesService.delete(bundle.id).subscribe(
          res => this.getTestBundles(this.patientId, this.currentPageSize, this.currentPageIndex, this.term),
          error => this.errorService.handleError(error));
    });
  }

  moveBundle(bundle: TestBundle) {
    this.router.navigate([`/tests/bundles/move/${bundle.id}`], { queryParams: { 'patient': this.patientId } });
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getTestBundles(this.patientId, pageSize, pageIndex, this.term);
  }

  private parseTestBundles(bundles: PaginatedItems<TestBundleListItem>): PaginatedItems<TestBundleDisplay> {
    const parsedBundles = bundles.results.map(bundle => {

      const statusTranslationKey = TEST_STATUS[bundle.status?.name?.toUpperCase()]?.translationKey;
      const translatedStatus = !!statusTranslationKey ? this.translateService.instant(statusTranslationKey) : bundle.status?.name;

      return {
        ...bundle,
        date_display: new BoldThinPipe().transform([
          new DatePipe('en-US').transform(bundle.created, this.preferencesService.defaultDateTimeFormat)
        ]),
        displayed_group_info: this.testBundlesService.getGroupInfo(bundle.test_groups),
        using_device_display: bundle.current_test?.using_device || '',
        status_name: new TitleCasePipe().transform(translatedStatus || ''),
      };
    });

    return {
      results: parsedBundles,
      count: bundles.count
    };
  }

  isCustomBundle = (bundle: TestBundle) => !!bundle.custom_test_bundle_type;

  search(term: string) {
    this.currentPageIndex = 0;
    this.isLoading = true;

    this.testBundlesService.getAll(this.patientId, this.currentPageSize, this.currentPageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.term = term;
      this.testBundles$.next(this.parseTestBundles(res));
    });
  };
}