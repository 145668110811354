// native
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html'
})
export class InfoBoxComponent {

  @Input() message: string;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

}
