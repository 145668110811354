<app-center-container    
  [title]="title"
  [showClose]="true"
  [hasOverlay]="true"
  [isLarge]="true"
  (close)="close.emit()">

  <div class="flex-end">
    <app-tabs [tabs]="tabs"></app-tabs>
  </div>

  <br>

  <div [hidden]="activeTab !== 0">
    <div class="work-item_container">
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.patientName' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.patient_name | truncate }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.patientId' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.patient_id }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.patientBirthDate' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.patient_birth_date | date: preferencesService.defaultDateFormat.value }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.patientSex' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.patient_sex }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.accessionNumber' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.accession_number }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.scheduledProcedureStepStartDate' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.scheduled_procedure_step_start_datetime | date: preferencesService.defaultDateTimeFormat }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.scheduledProcedureStepDescription' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.scheduled_procedure_step_description | truncate }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.scheduledProtocolCode' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.scheduled_protocol_code }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.modality' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.modality }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.scheduledStationAeTitle' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.scheduled_station_ae_title }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.referringPhysicianName' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.referring_physician_name | truncate }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.requestingPhysician' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.requesting_physician | truncate }}
        </div>
      </div>
  
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.scheduledPerformingPhysicianName' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.scheduled_performing_physician_name | truncate }}
        </div>
      </div>
    </div>
  </div>

  <div [hidden]="activeTab !== 1">
    <div class="work-item_container">
      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.admissionId' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.admission_id }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.allergies' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.allergies | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.currentPatientLocation' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.current_patient_location | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.medicalAlerts' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.medical_alerts | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.patientComments' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.patient_comments | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.ethnicGroup' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.ethnic_group }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.patientWeight' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.patient_weight }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.patientState' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.patient_state | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.pregnancyStatus' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.pregnancy_status | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.specialNeeds' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.special_needs | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.confidentialityConstraintOnPatientDataDescription' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.confidentiality_constraint_on_patient_data_description | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.fillerOrderNumberImagingServiceRequest' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.filler_order_number_imaging_service_request }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.placerOrderNumberImagingServiceRequest' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.placer_order_number_imaging_service_request }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.reasonForRequestedProcedureCode' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.reason_for_requested_procedure_code }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.reasonForRequestedProcedure' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.reason_for_the_requested_procedure }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.requestedProcedureCode' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.requested_procedure_code }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.requestedProcedureComments' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.requested_procedure_comments | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.requestedProcedureDescription' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.requested_procedure_description | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.requestedProcedureId' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.requested_procedure_id }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.scheduledProcedureStepId' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.scheduled_procedure_step_id }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.commentsOnTheScheduledProcedureStep' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.comments_on_the_scheduled_procedure_step | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.institutionName' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.institution_name | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.issuerOfPatientId' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.issuer_of_patient_id | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.referencedStudy' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.referenced_study | truncate }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.studyInstanceUid' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.study_instance_uid }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.studyId' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.study_id }}
        </div>
      </div>

      <div class="work-item_record">
        <div class="work-item_record-label">
          {{ 'pacs.studyDateTime' | translate }}
        </div>
        <div class="work-item_record-value">
          {{ item.study_datetime | date: preferencesService.defaultDateTimeFormat }}
        </div>
      </div>
    </div>
  </div>
</app-center-container>