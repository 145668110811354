<app-list-header
  name="device"
  [canCreate]="authService.isOfficeGroupAdmin || authService.isOfficeAdmin"
  (onCreate)="create()"
  (onSearch)="search($event)">
</app-list-header>

<app-item-list
  [columns]="columns"
  [actions]="actions"
  [orangeMarker]="orangeMarker"
  [items$]="devices$"
  [clickable]="authService.isOfficeGroupAdmin"
  (onItemClick)="edit($event)"  
  (onChangePage)="changePage($event.pageIndex, $event.pageSize)">
</app-item-list>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>
