// native
import { Injectable } from '@angular/core';

import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

// service
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PacsGuard  {

  constructor(
    private router: Router,
    private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isPacsIntegrationUser)
      return true;
      
    this.router.navigate(['/patients']);
    return false;
  }
}