import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'boldThin',
  pure: true
})
export class BoldThinPipe implements PipeTransform {
  transform(display: string | Array<string>): string {
    const words =
      typeof display === 'string'
        ? display.match(/([\S]+)/g)
        : (display as Array<string>);

    if (!words || words.length === 0) {
      return '';
    }

    const [first, ...rest] = words;

    let result = `<span class='bold'>${first}</span>`;

    rest.forEach(remaining => {
      result += ` <span class='thin'>${remaining}</span>`;
    });

    return result;
  }
}

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, truncateCount: number = 300): string {
    if (!value) { return ''; }

    if (value.length && value.length <= truncateCount)
      return value;

    return value.slice(0, truncateCount) + '...';
  }
}