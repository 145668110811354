import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-battery',
  templateUrl: './battery.component.html'
})
export class BatteryComponent implements OnInit {

  @Input() batteryPercentage: number;

  constructor() { }

  ngOnInit() {
  }

}
