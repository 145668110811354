// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

// service
import { TechallSessionService } from 'app/core/services/techall-session.service';
import { ErrorService } from 'app/core/services/error.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { PatientsService } from 'app/core/services/patients.service';

// models
import { PaginatedItems, TechallSession, Tab, TechallSessionDisplay, ListItemAction, ListItemColumn } from '../../models';

// animation
import { routerTransition } from '../../animations/router-animations';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../../constants';

// pipe
import { BoldThinPipe } from '../../shared/pipes/pipes';

@Component({
  selector: 'app-finished-sessions',
  templateUrl: './finished-sessions.component.html',
  animations: [routerTransition()]
})
export class FinishedSessionsComponent implements OnInit, OnDestroy {

  tabs: Tab[] = [
    {
      translationKey: 'waitingTechallSessions',
      action: () => this.router.navigate([`/waiting-sessions`]),
      isActive: false
    },
    {
      translationKey: 'finishedTechallSessions',
      action: null,
      isActive: true
    }
  ];

  columns: ListItemColumn[] = [
    {
      translationKey: 'patient',
      fieldName: 'patient_display',
      fontClass: 'bold'
    },
    {
      translationKey: 'techallUser',
      fieldName: 'user_display',
      fontClass: 'bold'
    },
    {
      translationKey: 'createdAt',
      fieldName: 'created_display',
      fontClass: 'thin'
    }
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'showTests',
      execute: session => this.showTests(session),
      visible: () => true
    },
  ];

  sessions$: BehaviorSubject<PaginatedItems<TechallSessionDisplay> | null> = new BehaviorSubject(null);
  sessionsSnapshot: PaginatedItems<TechallSession>;

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  pingInterval: any;

  isLoading: boolean = false;

  constructor(
    private router: Router,
    private techallService: TechallSessionService,
    private errorService: ErrorService,
    private preferencesService: PreferencesService,
    private patientsService: PatientsService
  ) { }

  ngOnInit() {
    this.getSessions(this.currentPageSize, this.currentPageIndex, null);

    this.setSessionsPing();
  }

  ngOnDestroy() {
    clearInterval(this.pingInterval);
  }

  private getSessions(pageSize: number, pageIndex: number, term: string, showLoader = true) {
    if (showLoader)
      this.isLoading = true;

    this.techallService.getSessions(pageSize, pageIndex, term, true).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      sessions => {
        this.sessions$.next(this.parseSessions(sessions));
        this.sessionsSnapshot = sessions;
      },
      error => this.errorService.handleError()
    );
  }

  private showTests(session: TechallSession) {
    this.router.navigate(['tests/', session.patient.id]);
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getSessions(pageSize, pageIndex, this.term);
  }

  private parseSessions(sessions: PaginatedItems<TechallSession>): PaginatedItems<TechallSessionDisplay> {
    const parsedSessions = sessions.results.map(session => ({
      ...session,
      patient_display: new BoldThinPipe().transform(
        this.patientsService.getDisplayName(session.patient)
      ),
      user_display: new BoldThinPipe().transform(
        `${session.user.last_name} ${session.user.first_name}`
      ),
      created_display: new BoldThinPipe().transform([
        new DatePipe('en-US').transform(session.created, this.preferencesService.defaultDateFormat.value),
        new DatePipe('en-US').transform(session.created, this.preferencesService.defaultTimeFormat.value)
      ])
    }));

    return {
      results: parsedSessions,
      count: sessions.count
    };
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.isLoading = true;

    this.techallService.getSessions(this.currentPageSize, this.currentPageIndex, term).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.term = term;
      this.sessions$.next(this.parseSessions(res));
    });
  };

  private setSessionsPing() {
    this.pingInterval = setInterval(() => {
      this.getSessions(this.currentPageSize, this.currentPageIndex, this.term, false);
    }, 3000);
  }
}
