// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "app/shared/shared.module";

// component
import { TechallUserComponent } from "./techall-user.component";
import { WaitingSessionsComponent } from './waiting-sessions/waiting-sessions.component';
import { FinishedSessionsComponent } from './finished-sessions/finished-sessions.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    TechallUserComponent,
    WaitingSessionsComponent,
    FinishedSessionsComponent
  ]
})
export class TechallModule { }
