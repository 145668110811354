// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

// services
import { OfficesService } from 'app/core/services/offices.service';
import { DialogService } from 'app/core/services/dialog.service';
import { TabsService } from 'app/core/services/tabs.service';

// models
import { Office, PaginatedItems, Tab, ListItemColumn, ListItemAction } from '../models';

// constant
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, OFFICE_TAB_INDEX } from '../constants';

@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html'
})
export class OfficesComponent implements OnInit {

  offices$: BehaviorSubject<PaginatedItems<Office> | null> = new BehaviorSubject(null);

  tabs: Tab[] = [];

  columns: ListItemColumn[] = [
    {
      translationKey: 'officeName',
      fieldName: 'name',
      fontClass: 'bold'
    },
    {
      translationKey: 'streetAddress',
      fieldName: 'street_address',
      fontClass: 'bold'
    },
    {
      translationKey: 'city',
      fieldName: 'city',
      fontClass: 'bold'
    },
    {
      translationKey: 'phone',
      fieldName: 'phone',
      fontClass: 'bold'
    },
  ];

  actions: ListItemAction[] = [
    {
      translationKey: 'edit',
      execute: (office: Office) => this.edit(office),
      visible: () => true
    },
    {
      translationKey: 'delete',
      execute: (office: Office) => this.delete(office),
      visible: () => true
    },
    {
      translationKey: 'assignAdmin',
      execute: (office: Office) => this.assignAdmin(office),
      visible: () => true
    },
    {
      translationKey: 'newUser',
      execute: (office: Office) => this.router.navigate(['users/new', office.id]),
      visible: () => true
    }
  ];

  currentPageSize: number = DEFAULT_PAGE_SIZE;
  currentPageIndex: number = DEFAULT_PAGE_INDEX;
  term: string;

  isLoading: boolean = false;

  constructor(
    private officesService: OfficesService,
    private router: Router,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private tabsService: TabsService
  ) { }

  ngOnInit() {
    this.getOffices(this.currentPageSize, this.currentPageIndex, null);

    this.tabs = this.tabsService.getOfficeTabs(OFFICE_TAB_INDEX.OFFICES);
  }

  getOffices(pageSize: number, pageIndex: number, term: string) {
    this.isLoading = true;
    this.officesService.getAllPaginated(pageSize, pageIndex, term).subscribe((offices: PaginatedItems<Office>) => {
      this.isLoading = false;
      this.offices$.next(offices);
    }, err => { this.isLoading = false; });
  }

  search(term: string) {
    this.currentPageIndex = 0;
    this.term = term;

    this.getOffices(this.currentPageSize, this.currentPageIndex, this.term);
  };

  edit(office: Office) {
    this.router.navigate(['/offices/edit', office.id]);
  }

  create() {
    this.router.navigate(['/offices/new']);
  }

  delete(office: Office) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('delete'),
      message: this.translateService.instant('deleteOfficePrompt')
    }).then(result => {
      if (result.confirmed)
        this.officesService.delete(office.id).subscribe(
          res => this.getOffices(this.currentPageSize, this.currentPageIndex, this.term),
          error => this.toastService.error(error?.error));
    });
  }

  assignAdmin(office: Office) {
    this.router.navigate(['/users/select', office.id]);
  }

  changePage(pageIndex: number, pageSize: number) {
    this.currentPageIndex = pageIndex;
    this.currentPageSize = pageSize;
    this.getOffices(pageSize, pageIndex, this.term);
  }
}
