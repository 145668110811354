// native
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// services
import { ApiService } from 'app/core/services/api.service';

// models
import { IEmployee, IEmployeeRequest, PaginatedItems } from '../../models';

// constants
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, API_USERS_PATH } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private apiService: ApiService
  ) { }

  getAllPaginated(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<IEmployee>> {
    let path = `${API_USERS_PATH}?limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    return this.apiService.get(path) as Observable<PaginatedItems<IEmployee>>;
  }

  getAll(): Observable<PaginatedItems<IEmployee>> {
    return this.apiService.get(API_USERS_PATH) as Observable<PaginatedItems<IEmployee>>;
  }

  getOne(id: number): Observable<IEmployee> {
    return this.apiService.get(this.getIEmployeeUrl(id)).pipe(map(res => res as IEmployee));
  }

  create(body: IEmployeeRequest): Observable<IEmployee> {
    return this.apiService.post(API_USERS_PATH, body).pipe(map(res => res as IEmployee));
  }

  delete(id: number): Observable<void> {
    return this.apiService.delete(this.getIEmployeeUrl(id)).pipe(map(res => null));
  }

  update(id: number, body: IEmployeeRequest): Observable<IEmployee> {
    return this.apiService.put(this.getIEmployeeUrl(id), body).pipe(map(res => res as IEmployee));
  }

  resetPassword(id: number): Observable<any> {
    const employeePath = this.getIEmployeeUrl(id);
    return this.apiService.put(`${employeePath}reset_user_password/`);
  }

  private getIEmployeeUrl(id: number) {
    return `${API_USERS_PATH}${id}/`;
  }
}