// native
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// service
import { ApiService } from './api.service';
import { UtilityService } from './utility.service';

// models
import { Device, SemanticDeviceVersion, PaginatedItems, ActiveDevice } from '../../models';

// constants
import {
  API_ACTIVE_DEVICES_PATH, API_DEVICES_PATH, API_DEVICES_TEST_CANDIDATES_PATH,
  DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEVICE_LAST_USED_SORT_MIN_LENGTH
} from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService,
  ) { }

  create(device: Device): Observable<Device> {
    return this.apiService.post(API_DEVICES_PATH, device).pipe(map(res => res as Device));
  }

  delete(id: number): Observable<void> {
    return this.apiService.delete(`${API_DEVICES_PATH}${id}/`).pipe(map(_ => null));
  }

  unlink(id: number): Observable<void> {
    return this.apiService.post(`${API_DEVICES_PATH}${id}/unlink/`).pipe(map(_ => null));
  }

  getAll(pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<Device>> {
    let path = `${API_DEVICES_PATH}?limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    return this.apiService.get(path) as Observable<PaginatedItems<Device>>;
  }

  getAllWithAvailableTests(patientId?: number): Observable<Device[]> {
    const url = this.utilityService.appendQueryParam(API_DEVICES_TEST_CANDIDATES_PATH, 'patient_id', patientId);
    return this.apiService.get(url).pipe(
      map((devices: Device[]) => {
        if (devices.length < DEVICE_LAST_USED_SORT_MIN_LENGTH)
          return devices.sort((a, b) => {
            if (a.name > b.name) return 1;
            return -1;
          });
        else
          return devices.sort((a, b) => new Date(b.last_used).getTime() - new Date(a.last_used).getTime());
      })
    );
  }

  getOne(id: number): Observable<Device> {
    return this.apiService.get(`${API_DEVICES_PATH}${id}/`).pipe(map(res => res as Device));
  }

  update(id: number, body: Device): Observable<Device> {
    return this.apiService.put(`${API_DEVICES_PATH}${id}/`, body).pipe(map(res => res as Device));
  }

  getActiveDevices(): Observable<ActiveDevice[]> {
    return this.apiService.get(API_ACTIVE_DEVICES_PATH).pipe(map((res) => res as ActiveDevice[]));
  }

  getSemanticVersion(version: string): SemanticDeviceVersion {
    if (!version)
      return null;

    const versionAsNumberedList: number[] = version.split(".", 3).map(versionSegment => parseInt(versionSegment));

    return {
      major: versionAsNumberedList[0],
      minor: versionAsNumberedList[1],
      patch: versionAsNumberedList[2],
    };
  }
}
