<div class="flex-space-between">
  <app-tabs 
    *ngIf="tabs?.length" 
    [tabs]="tabs">
  </app-tabs>

  <div class="action-header_container" *ngIf="authService.isBasicSupportUser">
    <app-button-list
      *ngIf="buttonList?.length"
      [list]="buttonList">
    </app-button-list>
  </div>
</div>

<br>

<kendo-grid
  [data]="gridData$ | async"
  [loading]="isLoading"
  [filterable]="true"
  [filter]="filter"
  (filterChange)="filterChange($event)"
  [sortable]="true"
  [sort]="sort"
  (sortChange)="sortChange($event)"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="skip"
  (pageChange)="pageChange($event)"
  [height]="700">
  <kendo-grid-column 
    field="name" 
    title="{{ 'name' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="office_group" 
    title="{{ 'officeGroup' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="street_address" 
    title="{{ 'streetAddress' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="city" 
    title="{{ 'city' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="zip" 
    title="{{ 'zipCode' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="state"
    title="{{ 'state' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="phone" 
    title="{{ 'phone' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column 
    field="country" 
    title="{{ 'country' | translate | titlecase }}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter">
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>