<app-center-container [title]="title">
  <div *ngIf="currentStep === 1">
    <form 
      *ngIf="!isPhoneResetMode"
      [formGroup]="startEmailResetForm" 
      (ngSubmit)="startResetPasswordProcess()">

      <app-input-wrapper
        [control]="startEmailResetForm.get('emailOrUsername')"
        label="{{ 'emailOrUsername' | translate }}"
        identifier="email">
        <input
          autocorrect="off"
          autocapitalize="none"
          class="form_control" 
          type="text" 
          formControlName="emailOrUsername"
          name="emailOrUsername"
          id="email">
      </app-input-wrapper>

      <button 
        class="button button--primary form_button" 
        type="submit" 
        [disabled]="!startEmailResetForm.valid">
        <span class="regular">{{ 'send' | translate }}</span>
      </button>
    </form>

    <form 
      *ngIf="isPhoneResetMode"
      [formGroup]="startPhoneResetForm" 
      (ngSubmit)="startResetPasswordProcess()">

      <app-input-wrapper 
        [control]="startPhoneResetForm.get('phone')"
        [hasPlaceholderOffset]="true" 
        label="{{ 'phone' | translate }}">
        <ngx-mat-intl-tel-input
          class="form_control"
          [preferredCountries]="['us']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          formControlName="phone"
          name="phone">
        </ngx-mat-intl-tel-input>
      </app-input-wrapper>

      <button 
        class="button button--primary form_button" 
        type="submit" 
        [disabled]="!startPhoneResetForm.valid">
        <span class="regular">{{ 'send' | translate }}</span>
      </button>
    </form>

    <br>
    <input
      type="checkbox"
      class="checkbox_input"
      (change)="onPhoneModeToggle($event)"
      id="use-phone-reset-mode">
    <label 
      for="use-phone-reset-mode"
      class="checkbox_label">
      {{ 'usePhonePasswordReset' | translate | titlecase }}
    </label>
    <br>
    <div class="text--center">    
      <a class="form_link" routerLink="/login">{{ 'goBack' | translate }}</a>
    </div>
  </div>

  <form
    *ngIf="currentStep === 2"
    [formGroup]="phonePinForm" 
    (ngSubmit)="onSubmitPhonePin()">

    <app-input-wrapper 
      [control]="phonePinForm.get('pin')" 
      label="{{ 'pin' | translate }}"
      identifier="pin">
      <input class="form_control" type="text" formControlName='pin' id="pin" name="pin">
    </app-input-wrapper>

    <button class="button button--primary form_button" type="submit" [disabled]="!phonePinForm.valid">
      <span class="regular">{{ 'send' | translate }}</span>
    </button>
 
    <br>
    <br>
    <div class="text--center">    
      <a class="form_link" routerLink="/login">{{ 'goBack' | translate }}</a>
    </div>
  </form>

  <form 
    *ngIf="currentStep === 3"
    [formGroup]="passwordForm" 
    (ngSubmit)="onSubmitPassword()">

    <p *ngIf="shouldForceResetPassword"> {{ 'passwordExpired' | translate }}</p>
    <app-input-wrapper 
      [control]="passwordForm.get('password')" 
      label="{{ 'password' | translate }}"
      identifier="password">
      <input
        autocorrect="off"
        autocapitalize="none"
        class="form_control form_control--indent" 
        [type]="passwordVisible ? 'text' : 'password'"
        formControlName='password' 
        id="password" 
        name="password"
        (input)="onPasswordChange()">
      <i
        (click)="passwordVisible = !passwordVisible"
        class="form_icon fas"
        [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'">
      </i>
    </app-input-wrapper>

    <app-password-validator 
      [password]="passwordForm.get('password').value || ''"
      (onSuggestPassword)="onSuggestPassword($event)">
    </app-password-validator>
    
    <app-input-wrapper
      *ngIf="!passwordSuggestMode"
      [control]="passwordForm.get('confirm_password')" 
      label="{{ 'confirmPassword' | translate }}"
      identifier="confirm_password">
      <input
        autocorrect="off"
        autocapitalize="none"
        class="form_control form_control--indent" 
        [type]="passwordVisible ? 'text' : 'password'"
        formControlName='confirm_password' 
        id="confirm_password"
        name="confirm_password">
      <i
        (click)="passwordVisible = !passwordVisible"
        class="form_icon fas"
        [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'">
      </i>
    </app-input-wrapper>

    <button class="button button--primary form_button" type="submit" [disabled]="!passwordForm.valid">
      <span class="regular">{{ 'reset' | translate }}</span>
    </button>

    <br>
    <br>
    <div class="text--center">    
      <a class="form_link" routerLink="/login">{{ 'goBack' | translate }}</a>
    </div>
  </form>
</app-center-container>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>