<div 
  class="stream_container" 
  [ngClass]="{ 
    'stream_container--single': isSingleImage,
    'stream_container--minimized': !!minimized,
    'stream_container--full': !!full
   }">
  <div class="stream_panel">
    <ng-container *ngIf="!streamPending">
      <span (click)="onFullToggle()" class="stream_full-icon" *ngIf="!minimized">
        <i 
          *ngIf="!full, else compressTemplate"
          class="fas fa-expand-arrows-alt"
          matTooltip="{{ 'fullScreen' | translate | titlecase }}">
        </i>
      </span>
      <span (click)="onMinimizeToggle()" *ngIf="!full">
        <i 
          *ngIf="!minimized, else maximizeTemplate"
          class="far fa-window-minimize"
          matTooltip="{{ 'minimize' | translate | titlecase }}">
        </i>
      </span> 
    </ng-container>
    <span 
      (click)="onClose()"
      matTooltip="{{ 'stopStreaming' | translate | titlecase }}">
      <i class="fas fa-times"></i>
    </span> 
  </div>

  <div *ngIf="streamPending" class="stream_message">
    <div>
      <div>
        {{ 'streamStarting' | translate }}
      </div>
      <app-load
        [isLight]="true"
        class="text--xxs">
      </app-load>
    </div>
  </div>
  
  <img #leftImage [ngClass]="{ 'hidden': streamPending }">
  <img #rightImage [ngClass]="{ 'hidden': streamPending }">
</div>

<ng-template #maximizeTemplate>
  <i class="far fa-window-maximize" matTooltip="{{ 'maximize' | translate | titlecase }}"></i>
</ng-template>

<ng-template #compressTemplate>
  <i class="fas fa-compress-arrows-alt" matTooltip="{{ 'exitFullScreen' | translate | titlecase }}"></i>
</ng-template>
