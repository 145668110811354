// native
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { BaaAgreementService } from '../../core/services/baa-agreement.service';
import { AuthService } from '../../core/services/auth.service';
import { ErrorService } from '../../core/services/error.service';

// constants
import { AUTH_STORAGE_KEYS } from '../../constants';

@Component({
  selector: 'app-baa-agreement',
  templateUrl: './baa-agreement.component.html'
})
export class BaaAgreementComponent {

  isLoading: boolean = false;

  constructor(
    private baaAgreementService: BaaAgreementService,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private errorService: ErrorService
  ) { }

  onAgreementAccept() {
    this.baaAgreementService.sign().pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      response => {
        localStorage.setItem(AUTH_STORAGE_KEYS.SHOW_BAA_AGREEMENT, "false");
        this.router.navigate(['/patients']);
      },
      error => this.errorService.handleError(this.translateService.instant('baa.signingError')),
    );
  }

  onAgreementCancel() {
    this.authService.logout();
  }
}
