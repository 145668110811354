<app-center-container
   [title]="title"
   [showClose]="true"
   (close)="close()">

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit(form)">

    <app-input-wrapper 
      [control]="form.get('first_name')" 
      label="{{ 'firstName' | translate }}"
      identifier="first_name">
      <input class="form_control" type="text" formControlName="first_name" name="first_name" id="first_name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('last_name')" 
      label="{{ 'lastName' | translate }}"
      identifier="last_name">
      <input class="form_control" type="text" formControlName="last_name" name="last_name" id="last_name">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('email')" 
      label="{{ 'email' | translate }}"
      identifier="email">
      <input
        autocorrect="off"
        autocapitalize="none"
        class="form_control" 
        type="email" 
        formControlName="email"
        name="email"
        id="email">
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('offices')" 
      label="{{ 'office' | translate }}">
      <mat-select 
        multiple
        class="form_control"
        formControlName="offices"
        disableOptionCentering 
        panelClass="dropdown dropdown--multiple"
        name="offices"
        aria-label="{{ 'office' | translate }}">
        <mat-option *ngFor="let office of offices" [value]="office.id">
          {{ office.name }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <app-input-wrapper 
      [control]="form.get('employeeGroup')" 
      label="{{ 'employeeGroup' | translate }}">
      <mat-select 
        class="form_control"
        formControlName="employeeGroup" 
        disableOptionCentering 
        panelClass="dropdown"
        name="group"
        aria-label="{{ 'employeeGroup' | translate }}">
        <mat-option *ngFor="let group of employeeGroups" [value]="group.value">
          {{ group.name | translate | titlecase }}
        </mat-option>
      </mat-select>
    </app-input-wrapper>

    <br>

    <button class="button button--primary form_button" type="submit" [disabled]="!form.valid || !form.dirty">
      <span class="regular">{{ 'done' | translate }}</span>
    </button>

  </form>
  
</app-center-container>

<app-loader *ngIf="isLoading"></app-loader>