// native
import { Component, OnInit, OnDestroy } from '@angular/core';
import { fadeTransition } from 'app/animations/router-animations';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { ResetPasswordService } from '../../core/services/reset-password.service';
import { AuthService } from '../../core/services/auth.service';
import { UtilityService } from 'app/core/services/utility.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { CenterContainerTitle, ResetPasswordBody, StartResetPasswordProcessBody, ValidateResetPasswordRequestBody, UserUpdateRequest } from '../../models';

// constants
import { AUTH_STORAGE_KEYS, USER_PASSWORD_REGEX } from '../../constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  animations: [fadeTransition()],
  host: { '[@fadeTransition]': '' }
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  startEmailResetForm: UntypedFormGroup;
  startPhoneResetForm: UntypedFormGroup;
  phonePinForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;

  emailOrPhoneOrUsername: string;
  pin: string;

  isPhoneResetMode: boolean = false;

  currentStep: number = 1;

  shouldForceResetPassword: boolean;
  title: CenterContainerTitle;

  passwordVisible: boolean = false;
  passwordSuggestMode: boolean = false;

  languageSubscription: Subscription;

  isLoading: boolean = false;

  constructor(
    private resetPasswordService: ResetPasswordService,
    private toastService: ToastrService,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.setTranslations();
    this.languageSubscription = this.translateService.onLangChange.subscribe(() => {
      this.setTranslations();
    });
    this.initForms();

    this.shouldForceResetPassword = this.authService.shouldForceResetPassword();

    if (this.shouldForceResetPassword)
      this.currentStep = 3;
    else
      this.validateResetPasswordUrlParams();
  }

  private setTranslations() {
    this.title = {
      thin: this.translateService.instant('reset'),
      bold: this.translateService.instant('password')
    };
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

  initForms() {
    this.startEmailResetForm = this.formBuilder.group({
      emailOrUsername: [null, Validators.compose([Validators.required])]
    });

    this.startPhoneResetForm = this.formBuilder.group({
      phone: [null, Validators.compose([Validators.required])]
    });

    this.phonePinForm = this.formBuilder.group({
      pin: [null, Validators.compose([Validators.required])]
    });

    this.passwordForm = this.formBuilder.group({
      password: [null, Validators.compose([Validators.required, Validators.minLength(15), Validators.pattern(USER_PASSWORD_REGEX)])],
      confirm_password: [null, Validators.compose([Validators.required])]
    }, {
      validator: this.utilityService.checkIfMatchingPasswords('password', 'confirm_password')
    });
  }

  onPhoneModeToggle(event) {
    this.isPhoneResetMode = !!event.target?.checked;
  }

  private validateResetPasswordUrlParams() {
    this.emailOrPhoneOrUsername = this.activatedRoute.snapshot.queryParams.email;
    this.pin = this.activatedRoute.snapshot.queryParams.code;

    if (this.pin && this.emailOrPhoneOrUsername) {
      const body: ValidateResetPasswordRequestBody = {
        pin: this.pin,
        email_phone_username: this.emailOrPhoneOrUsername
      };

      this.resetPasswordService.validateResetPasswordRequest(body).subscribe(res => {
        this.currentStep = 3;
      }, error => {
        this.errorService.handleError(this.translateService.instant('resetPasswordInvalidEmailOrCode'));
        this.router.navigate(['/login']);
      });
    }
  }

  startResetPasswordProcess() {
    this.isLoading = true;

    const body: StartResetPasswordProcessBody = {
      email_phone_username: this.isPhoneResetMode
        ? this.startPhoneResetForm.value['phone']
        : this.startEmailResetForm.value['emailOrUsername']
    };

    this.resetPasswordService.startResetPasswordProcess(body).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(response => {
      this.emailOrPhoneOrUsername = body.email_phone_username;
      this.toastService.info(this.translateService.instant('startResetPasswordSuccess'), '', { closeButton: true });

      if (this.isPhoneResetMode)
        this.currentStep = 2;
      else
        this.router.navigate(['/login']);
    }, error => this.errorService.handleError(this.translateService.instant('startResetPasswordError')));
  }

  onSubmitPhonePin() {
    this.isLoading = true;

    const body: ValidateResetPasswordRequestBody = {
      pin: this.phonePinForm.value.pin,
      email_phone_username: this.emailOrPhoneOrUsername
    };

    this.resetPasswordService.validateResetPasswordRequest(body).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.pin = body.pin;
      this.currentStep = 3;
    }, error => this.errorService.handleError(this.translateService.instant('resetPasswordInvalidPhonePin')));
  }

  onPasswordChange() {
    this.passwordSuggestMode && this.clearSuggestPassword();
  }

  updateExpiredPassword(password: string, confirm_password: string) {
    this.isLoading = true;

    const currentUser = this.authService.getCurrentUser();
    const body: UserUpdateRequest = {
      id: currentUser.id,
      username: currentUser.username,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      phone_number: currentUser.phone_number,
      password,
      confirm_password
    };

    this.authService.updateUserProfile(body).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      localStorage.setItem(AUTH_STORAGE_KEYS.FORCE_PASSWORD_CHANGE, 'false');
      this.toastService.info(this.translateService.instant('resetPasswordSuccess'), this.translateService.instant('success'), {
        closeButton: true
      });
      this.router.navigate(['/patients']);
    }, err => this.errorService.handleError(this.translateService.instant('resetPasswordError')));
  }

  onSubmitPassword() {
    if (this.shouldForceResetPassword) {
      const password = this.passwordForm.value.password;
      const confirm_password = this.passwordSuggestMode ? password : this.passwordForm.value.confirm_password;
      this.updateExpiredPassword(password, confirm_password);
      return;
    }

    this.isLoading = true;

    const body: ResetPasswordBody = {
      email_phone_username: this.emailOrPhoneOrUsername,
      pin: this.pin,
      password: this.passwordForm.value.password,
    };

    this.resetPasswordService.resetPassword(body).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(res => {
      this.toastService.info(this.translateService.instant('resetPasswordSuccess'), this.translateService.instant('success'), {
        closeButton: true
      });
      this.router.navigate(['/login']);
    }, error => this.errorService.handleError(this.translateService.instant('resetPasswordError')));
  }

  onSuggestPassword(password: string) {
    this.passwordForm.controls['password'].setValue(password);
    this.passwordForm.controls['password'].markAsDirty();
    this.passwordForm.controls['confirm_password'].setValue(null);
    this.passwordForm.controls['confirm_password'].disable();
    this.passwordVisible = true;
    this.passwordSuggestMode = true;
  }

  clearSuggestPassword() {
    this.passwordSuggestMode = false;
    this.passwordForm.controls['confirm_password'].enable();
  }
}
