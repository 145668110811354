// native
import { Component, Input } from '@angular/core';

// service
import { AuthService } from 'app/core/services/auth.service';
import { TechallSessionService } from 'app/core/services/techall-session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  @Input() navExpanded;
  @Input() transparentMode;

  constructor(
    public authService: AuthService,
    public techallService: TechallSessionService
  ) {}
}
