// native
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

// service
import { ApiService } from 'app/core/services/api.service';
import { UtilityService } from './utility.service';

// models
import {
  Test, TestPostRequest, TestPutRequest, CorrectionType, PaginatedItems,
  TestListItem, TestGroup, TestStrategy, ControlType, TestMoveRequest, Patient, TestSyncProgress
} from '../../models';

// constants
import {
  API_TESTS_PATH, API_TEST_REPORT_HTML_PATH, API_TEST_REPORT_PDF_PATH, API_TREND_ANALYSIS_REPORT_PATH, API_TEST_GROUPS_PATH,
  API_TEST_REPORT_DICOM_PATH, API_TEST_REPORT_EMAIL_PATH, API_PATIENT_TESTS_PATH, API_IMPORT_GROUPS_PATH,
  API_CORRECTION_TYPES_PATH, API_CONTROL_TYPES_PATH, DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, GROUP, STRATEGY,
  API_STIMULUS_TYPES_PATH, API_TEST_LANGUAGES_PATH, API_TEST_REPORT_IMAGE_PATH, API_TEST_SYNC_PROGRESS_PATH
} from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class TestsService {

  testGroups: TestGroup[] = [];
  controlTypes: ControlType[] = [];
  allTestLanguages: string[] = ['english'];

  constructor(
    private apiService: ApiService,
    private utilityService: UtilityService
  ) { }

  create(body: TestPostRequest): Observable<Test> {
    return this.apiService.post(API_TESTS_PATH, body).pipe(map(res => res as Test));
  }

  getAll(patientId: number, pageSize: number = DEFAULT_PAGE_SIZE, pageIndex: number = DEFAULT_PAGE_INDEX, term: string = null): Observable<PaginatedItems<TestListItem>> {
    let path = `${API_PATIENT_TESTS_PATH}?patient=${patientId}&active=true&limit=${pageSize}`;

    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    if (term)
      path = path + `&search=${term}`;

    return this.apiService.get(path) as Observable<PaginatedItems<TestListItem>>;
  }

  delete(id: number): Observable<any> {
    return this.apiService.delete(`${API_TESTS_PATH}${id}/`);
  }

  getOne(id: number): Observable<Test> {
    return this.apiService.get(`${API_TESTS_PATH}${id}/`).pipe(map(obj => obj as Test));
  }

  update(id: number, body: TestPutRequest): Observable<Test> {
    return this.apiService.put(`${API_TESTS_PATH}${id}/`, body).pipe(map(obj => obj as Test));
  }

  move(id: number, body: TestMoveRequest): Observable<Test> {
    return this.apiService.post(`${API_TESTS_PATH}${id}/move/`, body).pipe(map(obj => obj as Test));
  }

  getFinishedTestsForPatient(patientId: number): Observable<number> {
    return this.apiService.get(`${API_TESTS_PATH}?status__name__in=Finished,Syncing&patient=${patientId}`).pipe(
      map((patients: PaginatedItems<Patient>) => patients.count)
    );
  }

  openHTMLReport(testId: number, age: number, windowRef: Window = null): void {
    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TEST_REPORT_HTML_PATH}?test_id=${testId}&age=${age}&timezone=${timezone}`;
    if (windowRef)
      windowRef.location.href = url;
    else
      window.open(url, '_blank');
  }

  downloadPDFReport(test: TestListItem | Test): Observable<void> {
    if ((<TestListItem>test).is_separate_report_per_eye_supported)
      return this.downloadSeparateEyePDFReport(test.id);

    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TEST_REPORT_PDF_PATH}?test_id=${test.id}&timezone=${timezone}`;

    return this.apiService.downloadFile(url);
  }

  downloadSeparateEyePDFReport(id: number): Observable<void> {
    const timezone = this.utilityService.getLocalTimezone();
    const urlOs = `${API_TEST_REPORT_PDF_PATH}?test_id=${id}&timezone=${timezone}&eye=OS`;
    const urlOd = `${API_TEST_REPORT_PDF_PATH}?test_id=${id}&timezone=${timezone}&eye=OD`;

    return this.apiService.downloadFile(urlOs).pipe(
      mergeMap(() => this.apiService.downloadFile(urlOd))
    );
  }

  downloadDicomReport(id: number): Observable<void> {
    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TEST_REPORT_DICOM_PATH}?test_id=${id}&timezone=${timezone}`;

    return this.apiService.downloadFile(url);
  }

  downloadImageReport(test: TestListItem): Observable<void> {
    if (test.is_separate_report_per_eye_supported)
      return this.downloadSeparateEyeImageReport(test.id);

    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TEST_REPORT_IMAGE_PATH}?test_id=${test.id}&timezone=${timezone}`;

    return this.apiService.downloadFile(url);
  }

  downloadSeparateEyeImageReport(id: number): Observable<void> {
    const timezone = this.utilityService.getLocalTimezone();
    const urlOs = `${API_TEST_REPORT_IMAGE_PATH}?test_id=${id}&timezone=${timezone}&eye=OS`;
    const urlOd = `${API_TEST_REPORT_IMAGE_PATH}?test_id=${id}&timezone=${timezone}&eye=OD`;

    return this.apiService.downloadFile(urlOs).pipe(
      mergeMap(() => this.apiService.downloadFile(urlOd))
    );
  }

  openTrendAnalysisReport(patientId: number, patientAge: number): void {
    const timezone = this.utilityService.getLocalTimezone();
    const url = `${API_TREND_ANALYSIS_REPORT_PATH}?patient_id=${patientId}&timezone=${timezone}&age=${patientAge}`;
    window.open(url, '_blank');
  }

  sendReport(testId: number, patientId: number, isDemo: boolean = false): Observable<any> {
    const timezone = this.utilityService.getLocalTimezone();
    const body = {
      test_id: testId,
      patient_id: patientId,
      timezone,
      demo: isDemo
    };
    return this.apiService.post(`${API_TEST_REPORT_EMAIL_PATH}`, body, true);
  }

  changeStartedState(id: number): Observable<Test> {
    const status = { status: 1 };
    const url = `${API_TESTS_PATH}${id}/`;
    return this.apiService.put(url, status).pipe(map(res => res as Test));
  }

  getTestGroups(customBundleMode: boolean = false, patientId?: number): Observable<TestGroup[]> {
    let url = this.utilityService.appendQueryParam(API_TEST_GROUPS_PATH, 'patient_id', patientId);
    if (customBundleMode) {
      url = this.utilityService.appendQueryParam(url, 'custom_test_group_candidates', 'true');
    }
    return this.apiService.get(url).pipe(
      map(groups => groups as TestGroup[])
    );
  }

  getImportTestGroups(): Observable<TestGroup[]> {
    return this.apiService.get(API_IMPORT_GROUPS_PATH).pipe(
      map(groups => groups as TestGroup[])
    );
  }

  getControlTypes(): Observable<ControlType[]> {
    if (this.controlTypes.length)
      return of(this.controlTypes);
    return this.apiService.get(API_CONTROL_TYPES_PATH).pipe(
      tap((types: ControlType[]) => this.controlTypes = types)
    );
  }

  getCorrectionTypes(): Observable<CorrectionType[]> {
    return this.apiService.get(`${API_CORRECTION_TYPES_PATH}`).pipe(map(obj => obj as CorrectionType[]));
  }

  getStimulusTypes(): Observable<string[]> {
    return this.apiService.get(`${API_STIMULUS_TYPES_PATH}`).pipe(map(obj => obj as string[]));
  }

  getAllTestLanguages(patientId?: number): Observable<void> {
    const url = this.utilityService.appendQueryParam(API_TEST_LANGUAGES_PATH, 'patient_id', patientId);
    return this.apiService.get(url).pipe(
      tap((response: { output_languages: string[], input_languages: string[]; }) => {
        if (response.output_languages?.length)
          this.allTestLanguages = response.output_languages;
      }),
      map(response => null)
    );
  }

  isGroupWithStrategy(group: TestGroup) {
    return [GROUP.PERIMETRY, GROUP.COLOR_VISION, GROUP.VISUAL_ACUITY, GROUP.CSUITE, GROUP.PUPIL].includes(group?.value);
  }

  isGroupWithProtocol(group: TestGroup) {
    return [GROUP.CONTRAST_SENSITIVITY].includes(group?.value);
  }

  isStrategyWithProtocol(strategy: TestStrategy) {
    return [
      STRATEGY.FULL_THRESHOLD,
      STRATEGY.SUPRA_THRESHOLD,
      STRATEGY.AVA_FAST,
      STRATEGY.AVA_STANDARD,
      STRATEGY.PTOSIS,
      STRATEGY.NEAR_VISION,
      STRATEGY.FAR_VISION,
      STRATEGY.LOW_CONTRAST
    ].includes(strategy?.value);
  }

  getSyncProgress(testIds: number[]): Observable<TestSyncProgress[]> {
    const joinedIds: string = testIds.join(',');
    return this.apiService.get(`${API_TEST_SYNC_PROGRESS_PATH}?id__in=${joinedIds}`).pipe(map(res => res as TestSyncProgress[]));
  }
}