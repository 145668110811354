// native
import { Injectable } from '@angular/core';

import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

// service
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AnyLoggedInGuard  {

  constructor(
    private router: Router,
    private authService: AuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/login']);
      return false;
    }

    if (this.authService.shouldForceResetPassword()) {
      this.router.navigate(['/reset-password']);
      return false;
    }

    return true;
  }
}
