<div
  class="monitor-close-button"
  (click)="close()">
</div>

<div #monitorContainer class="monitor_container" (window:resize)="windowChanged.emit(getEvent())">
  <app-monitor-dashboard
    [test]="test"
    [testLoading]="testLoading$ | async"
    [patient]="patient"
    [bundle]="bundle"
    [screen]="currentDeviceScreen"
    [isTestDone]="isTestDone"
    [isTestSyncing]="isTestSyncing"
    [deviceLoading]="deviceLoading$ | async"
    [device]="device"
    [batteryPercentage]="batteryPercentage"
    [streamPopupVisible]="streamPopupVisible"
    [isSingleImage]="isSingleImage"
    [deviceActionLoading]="deviceActionLoading"
    (onStreamOpen)="openStream($event)"
    (onStreamClose)="closeStream()"
    (onActionClicked)="onActionClicked($event)">
  </app-monitor-dashboard>

  <ng-container *ngIf="algorithmVersion === 1, else newVersionTemplate">
    <div class="monitor_container-column monitor_container-column--single">
      <div class="monitor_graph-box monitor_graph-box--single" #plotContainerOS>
        <ng-container *ngTemplateOutlet="leftPlot"></ng-container>
      </div>
    </div>

    <div class="monitor_container-column monitor_container-column--single">
      <div class="monitor_graph-box monitor_graph-box--single" #plotContainerOD>
        <ng-container *ngTemplateOutlet="rightPlot"></ng-container>
      </div>
    </div>
  </ng-container>

</div>

<ng-template #newVersionTemplate>
  <div class="monitor_container-column">
    <div class="monitor_graph-box" #plotContainerOS>
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <ng-container *ngTemplateOutlet="leftPlot"></ng-container>
    </div>
    <div class="monitor_graph-box" #plotContainerOD>
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <ng-container *ngTemplateOutlet="rightPlot"></ng-container>
    </div>
  </div>

  <div class="monitor_container-column">
    <div class="monitor_graph-box monitor_graph-box--single">
      <div *ngIf="isTestSyncing" class="monitor_sync-box"><div class="monitor_overlay-text text--blink">{{'syncing' | translate | titlecase}}...</div></div>
      <div class="monitor_graph-title monitor_graph-title--center">
        <span *ngIf="location && algorithmVersion === 3">{{ 'location' | translate | titlecase }}{{'&nbsp;&#35;' + location}} &nbsp;&nbsp;</span>
        <span *ngIf="attempt">{{ 'attempt' | translate | titlecase }}{{'&nbsp;&#35;' + attempt}}</span>
      </div>
      <app-percentage-circle
        class="monitor_circle-box" 
        [percentage]="percentage">
      </app-percentage-circle>
    </div>
  </div>
</ng-template>


<ng-template #leftPlot>
  <ng-container *ngIf="!isWarmup, else warmupTemplate">
    <div class="monitor_graph-title">
      {{ 'left' | translate | titlecase }}
      <span *ngIf="test?.eye === 'OS'"> <small>({{'movableTarget' | translate}})</small></span>
    </div>
    <plotly-plot [data]="osData" *ngIf="layoutOS" [layout]="layoutOS" [config]="monitorTestService.plotOptions"
      [revision]="count" [debug]="true" class="monitor_plot-box">
    </plotly-plot>
  </ng-container>
</ng-template>

<ng-template #rightPlot>
  <ng-container *ngIf="!isWarmup, else warmupTemplate">
    <div class="monitor_graph-title">
      {{ 'right' | translate | titlecase }}
      <span *ngIf="test?.eye === 'OD'"> <small>({{'movableTarget' | translate}})</small></span>
    </div>
    <plotly-plot [data]="odData" *ngIf="layoutOD" [layout]="layoutOD" [config]="monitorTestService.plotOptions"
      [revision]="count" [debug]="true" class="monitor_plot-box">
    </plotly-plot>
  </ng-container>
</ng-template>

<ng-template #warmupTemplate>
  <div class="monitor_eye-box">
    <app-hourglass [offset]="50"></app-hourglass>
  </div>
</ng-template>

<app-loader *ngIf="isLoading" [transparent]="false"></app-loader>