// native
import { NgModule } from "@angular/core";

// module
import { SharedModule } from "../shared/shared.module";

// component
import { EditPatientComponent } from "./edit-patient/edit-patient.component";
import { NewPatientComponent } from "./new-patient/new-patient.component";
import { PatientFormComponent } from "./patient-form/patient-form.component";
import { PatientsComponent } from "./patients.component";
import { DeviceDashboardComponent } from "./device-dashboard/device-dashboard.component";
import { ViewPatientComponent } from './view-patient/view-patient.component';
import { ReviewPatientsComponent } from './review-patients/review-patients.component';
import { MergePatientComponent } from './merge-patient/merge-patient.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    PatientsComponent,
    NewPatientComponent,
    EditPatientComponent,
    PatientFormComponent,
    DeviceDashboardComponent,
    ViewPatientComponent,
    ReviewPatientsComponent,
    MergePatientComponent
  ]
})
export class PatientsModule { }