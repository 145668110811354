// native
import { map, tap } from 'rxjs/operators';
import { Injectable, isDevMode } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TitleCasePipe } from '@angular/common';

// addon
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';

// service
import { ApiService } from './api.service';
import { UtilityService } from './utility.service';

// models
import {
  WorkItem, WorkItemFilterRequest, WorkItemFilterResponse, WorkItemDefaultConfig, GridItem,
  PaginatedItems, PacsIntegrationRecord, TestBundle, TestListItem
} from '../../models';

// constants
import {
  API_PACS_INTEGRATION_QUERY_PATH, API_PACS_INTEGRATION_CONFIGURATION_PATH, WEBSOCKET_PACS_FILTER,
  API_PACS_INTEGRATION_RECORDS_PATH, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_INDEX, PACS_STATUS
} from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class WorkItemService {
  socket: WebSocket;

  queryStartTime: Date;
  queryTimeToLive = 20 * 1000;
  queryPingInterval: number;
  QUERY_CHECK_INTERVAL = 2 * 1000;
  QUERY_MESSAGE_TYPE = 'mwl_query_status_update';

  resultsStartedAt: Date;
  resultsTimeToLive: number = 86400 * 1000;
  resultsPingInterval: number;
  RESULTS_CHECK_INTERVAL = 300 * 1000;

  workItems$: BehaviorSubject<WorkItem[]> = new BehaviorSubject<WorkItem[]>(null);
  workItemsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  filteringInProgress = false;

  private clientToServerFilterMap = {
    'eq': 'iexact',
    'contains': 'icontains',
    'startswith': 'istartswith',
    'endswith': 'iendswith',
    'gte': 'gte',
    'lte': 'lte'
  };

  constructor(
    private apiService: ApiService,
    private toastService: ToastrService,
    private translateService: TranslateService,
    private utilityService: UtilityService
  ) { }

  getConfig(): Observable<WorkItemDefaultConfig> {
    return this.apiService.get(API_PACS_INTEGRATION_CONFIGURATION_PATH).pipe(
      map(res => res as WorkItemDefaultConfig),
      tap(res => {
        this.queryTimeToLive = res.mwl_query_ttl_seconds * 1000;
        this.resultsTimeToLive = res.mwl_query_results_ttl_seconds * 1000;
      }));
  }

  getRecords(
    pageSize: number = DEFAULT_PAGE_SIZE,
    pageIndex: number = DEFAULT_PAGE_INDEX,
    sort: SortDescriptor[],
    filter: CompositeFilterDescriptor): Observable<GridItem<PacsIntegrationRecord>> {
    let path = this.calcPathUrl(`${API_PACS_INTEGRATION_RECORDS_PATH}?limit=${pageSize}`, pageSize, pageIndex, sort, filter);

    return this.apiService.get(path).pipe(map((res: PaginatedItems<PacsIntegrationRecord>) => {
      return {
        data: res.results,
        total: res.count
      };
    })) as Observable<GridItem<PacsIntegrationRecord>>;
  }

  restartPacsForRecord(id: number): Observable<void> {
    return this.apiService.post(`${API_PACS_INTEGRATION_RECORDS_PATH}${id}/retry/`).pipe(map(res => res as any));
  }

  filter(body: WorkItemFilterRequest): Observable<WorkItemFilterResponse> {
    return this.apiService.post(API_PACS_INTEGRATION_QUERY_PATH, body).pipe(map(res => res as WorkItemFilterResponse));
  }

  listen(id: string): void {
    this.closeSocket();
    this.startSocket(id);

    this.socket.onmessage = event => {
      const message = JSON.parse(event.data);

      if (message.type !== this.QUERY_MESSAGE_TYPE)
        return;

      const data: WorkItemFilterResponse = message.data;

      if (data?.status === PACS_STATUS.AGENT_SUCCESS.value) {
        this.workItems$.next(data.results);
        this.setResultsActivityPing();
        this.closeSocket();
      }

      if (data?.status === PACS_STATUS.AGENT_FAILED.value) {
        this.closeSocket(true);
      }
    };
  }

  startSocket(id: string) {
    const protocol = isDevMode() ? 'ws' : 'wss';
    this.socket = new WebSocket(`${protocol}://${window.location.host}${WEBSOCKET_PACS_FILTER}${id}`);
    this.workItemsLoading$.next(true);
    this.queryStartTime = new Date();
    this.setQueryActivityPing();
  }

  closeSocket(hasError: boolean = false) {
    this.workItemsLoading$.next(false);
    this.socket?.close();
    this.queryStartTime = null;

    if (this.queryPingInterval)
      window.clearInterval(this.queryPingInterval);

    if (hasError) {
      this.workItems$.next([]);
      this.toastService.error(this.translateService.instant('errorFilteringMessage'));
    }
  }

  private setQueryActivityPing() {
    if (this.queryPingInterval)
      window.clearInterval(this.queryPingInterval);

    this.queryPingInterval = window.setInterval(() => {
      if (!this.queryStartTime)
        return;

      const now = new Date().getTime();
      const timeDifference = now - this.queryStartTime.getTime();

      if (timeDifference > this.queryTimeToLive) {
        this.closeSocket(true);
        window.clearInterval(this.queryPingInterval);
      }

      // ping socket for status if timeout is nearing
      if ((timeDifference + 2 * this.QUERY_CHECK_INTERVAL) > this.queryTimeToLive) {
        this.socket.send(JSON.stringify({
          'type': 'mwl_query_status_request'
        }));
      }
    }, this.QUERY_CHECK_INTERVAL);
  }

  private setResultsActivityPing() {
    if (this.resultsPingInterval)
      window.clearInterval(this.resultsPingInterval);

    this.resultsStartedAt = new Date();

    this.resultsPingInterval = window.setInterval(() => {
      if (!this.resultsStartedAt)
        return;

      const now = new Date().getTime();
      const timeDifference = now - this.resultsStartedAt.getTime();
      if (timeDifference > this.resultsTimeToLive) {
        window.clearInterval(this.resultsPingInterval);
        window.location.reload();
      }
    }, this.RESULTS_CHECK_INTERVAL);
  }

  private calcPathUrl(basePath: string, pageSize: number, pageIndex: number, sort: SortDescriptor[], filter: any): string {
    let path = basePath;
    if (pageIndex)
      path = path + `&offset=${pageIndex * pageSize}`;

    filter.filters?.forEach((f: FilterDescriptor) => {
      let value = f.value;

      if (f.field === 'status_changed' && !!f.value) {
        value = `${this.utilityService.convertClientDateToServerDate(f.value)}${f.operator === 'lte' ? '+23:59:59' : ''}`;
        value = value + `&timezone=${this.utilityService.getLocalTimezone()}`;
      }

      path = path + `&${f.field}__${this.clientToServerFilterMap[<string>f.operator]}=${value}`;

      if (f.field == 'patient_name' && !!f.value)
        path = path + `&search=${value}`;
    });

    if (sort[0]?.field && sort[0]?.dir) {
      const prefix = (sort[0].dir === 'asc') ? '' : '-';
      path = path + `&ordering=${prefix}${sort[0].field}`;
    }

    return path;
  }

  isSuccessPacsTest = (item: TestListItem | TestBundle) => !!item.pacs_integration
    && item.pacs_integration.status === PACS_STATUS.AGENT_SUCCESS.value;
  isFailedPacsTest = (item: TestListItem | TestBundle) => !!item.pacs_integration
    && [PACS_STATUS.AGENT_FAILED.value, PACS_STATUS.MESSAGE_FAILED.value].includes(item.pacs_integration.status);
  isPendingPacsTest = (item: TestListItem | TestBundle) => !!item.pacs_integration
    && [PACS_STATUS.MESSAGE_SENT.value, PACS_STATUS.INITIALIZED].includes(item.pacs_integration.status);

  getPacsDetails = (item: TestListItem | TestBundle) => {
    if (!item?.pacs_integration?.status) return '';
    if (!PACS_STATUS[item.pacs_integration.status]) return '';
    let value = '';
    value = value + new TitleCasePipe().transform(this.translateService.instant(PACS_STATUS[item.pacs_integration.status]?.translationKey));
    if (item?.pacs_integration?.details)
      value = value + `: ${item.pacs_integration.details}`;
    return value;
  };
}
