import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// service
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

// models
import { Authenticator } from '../../models';

// constants
import { API_WEBAUTHN_AUTHENTICATOR, API_WEBAUTHN_REGISTER, API_WEBAUTHN_GET_CREDENTIALS, API_WEBAUTHN_VALIDATE_CREDENTIALS } from '../../constants';

// utils
import * as cbor from 'cbor-web';

@Injectable({
  providedIn: 'root'
})
export class WebAuthnService {

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
    private authService: AuthService,
  ) { }

  getAll(): Observable<Authenticator[]> {
    return this.apiService.get(API_WEBAUTHN_AUTHENTICATOR) as Observable<Authenticator[]>;
  }

  getOne(id: number): Observable<Authenticator> {
    return this.apiService.get(`${API_WEBAUTHN_AUTHENTICATOR}${id}/`) as Observable<Authenticator>;
  }

  async create(): Promise<Authenticator> {
    const challenge = await this.httpClient.post(API_WEBAUTHN_REGISTER, null, { responseType: 'arraybuffer' }).toPromise();
    const decoded_data = cbor.decode(challenge);

    const attestation: PublicKeyCredential = await navigator.credentials.create(decoded_data) as PublicKeyCredential;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/cbor'
      })
    };

    const payload = cbor.encode({
      "authenticatorAttachment": (<any>attestation).authenticatorAttachment,
      "attestationObject": (<any>attestation.response).attestationObject,
      "clientDataJSON": attestation.response.clientDataJSON,
    });

    return await this.httpClient.post(API_WEBAUTHN_AUTHENTICATOR, payload.buffer, httpOptions).toPromise() as Authenticator;
  }

  update(id: number, body: Partial<Authenticator>): Observable<Authenticator> {
    return <Observable<Authenticator>>this.apiService.put(`${API_WEBAUTHN_AUTHENTICATOR}${id}/`, body).pipe(
      map(res => res as Authenticator));
  }

  delete(id: number): Observable<void> {
    return this.httpClient.delete(`${API_WEBAUTHN_AUTHENTICATOR}${id}/`).pipe(map(res => null));
  }

  wrapAction(successAction: Function, errorAction: Function): void {
    if (this.authService.isWebAuthnAuthenticationRequired) {
      this.getCredentials().then(credentials => {
        this.validateCredentials(credentials).then(() => {
          successAction();
        }).catch(err => errorAction(err));
      }).catch(err => errorAction(err));
    } else {
      successAction();
    }
  }

  async getCredentials(): Promise<PublicKeyCredential> {
    const challenge = await this.httpClient.post(API_WEBAUTHN_GET_CREDENTIALS, null, { responseType: 'arraybuffer' }).toPromise();
    const decoded_data = cbor.decode(challenge);

    return await navigator.credentials.get(decoded_data) as PublicKeyCredential;
  }

  private async validateCredentials(credentials: PublicKeyCredential): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/cbor'
      }),
      responseType: 'arraybuffer' as 'json'
    };

    const payload = cbor.encode({
      "credentialId": credentials.rawId,
      "authenticatorData": (<any>credentials.response).authenticatorData,
      "clientDataJSON": credentials.response.clientDataJSON,
      "signature": (<any>credentials.response).signature
    });

    return await this.httpClient.post(API_WEBAUTHN_VALIDATE_CREDENTIALS, payload.buffer, httpOptions).toPromise();
  }
}
