<app-breadcrumbs 
  [stepsLabels]="breadcrumbOptions.stepLabels"
  [stepsActions]="breadcrumbOptions.stepActions">
</app-breadcrumbs>

<app-tabs 
  *ngIf="tabs" 
  [tabs]="tabs">
</app-tabs>

<br>

<app-list-header
  name="bundle"
  [canCreate]="!authService.isTechallUserOrAdmin || (authService.isTechallAdmin && isActiveTechallSession)"
  (onCreate)="create()"
  (onSearch)="search($event)">
</app-list-header>

<app-item-list 
  [columns]="columns"
  [actions]="actions"
  [items$]="testBundles$"
  [icons]="[
    { name: 'database', visible: isCustomBundle },
    { name: 'spinner orange', visible: workItemService.isPendingPacsTest, getTooltip: workItemService.getPacsDetails },
    { name: 'exclamation-circle red', visible: workItemService.isFailedPacsTest, getTooltip: workItemService.getPacsDetails },
    { name: 'check color-primary', visible: workItemService.isSuccessPacsTest, getTooltip: workItemService.getPacsDetails }
    ]"
  [orangeMarker]="orangeMarker"
  [greenMarker]="greenMarker"
  [redMarker]="redMarker"
  (onItemClick)="view($event)"  
  (onChangePage)="changePage($event.pageIndex, $event.pageSize)">
</app-item-list>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>
