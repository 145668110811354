<app-center-container
  [title]="title"
  [hasOverlay]="true">

  <br>
  <div>
    {{'ZeroPiiPromptMessage' | translate}}
  </div>
  <br>

  <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmitClick(form)">
    <app-input-wrapper
      [control]="form.get('zero_pii_password')"
      label="{{ 'zeroPiiKey' | translate }}"
      identifier="zero_pii_password">
      <input class="form_control" type="text" formControlName="zero_pii_password" name="zero_pii_password" id="zero_pii_password">
    </app-input-wrapper>
    <br>

    <button class="button button--primary form_button" type="submit">
      <span class="regular">{{ 'next' | translate }}</span>
    </button>
  </form>

</app-center-container>

<app-loader *ngIf="isLoading" [transparent]="true"></app-loader>
