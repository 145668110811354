// native
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// services
import { TestsRecurringService } from './tests-recurring.service';
import { AuthService } from './auth.service';

//  models
import { Tab } from '../../models';

// constants
import { OFFICE_TAB_INDEX, TEST_TAB_INDEX } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  constructor(
    private router: Router,
    private testsRecurringService: TestsRecurringService,
    private authService: AuthService
  ) { }

  getTestTabs(patientId: number, activeIndex: number) {
    const tabs: Tab[] = [
      {
        translationKey: 'tests',
        action: (activeIndex === TEST_TAB_INDEX.TESTS) ? null : () => this.router.navigate([`/tests/${patientId}`], { queryParamsHandling: 'merge' }),
        isActive: activeIndex === TEST_TAB_INDEX.TESTS
      },
      {
        translationKey: 'testBundles',
        action: (activeIndex === TEST_TAB_INDEX.BUNDLES) ? null : () => this.router.navigate([`/tests/bundles/${patientId}`], { queryParamsHandling: 'merge' }),
        isActive: activeIndex === TEST_TAB_INDEX.BUNDLES
      },
      (activeIndex === TEST_TAB_INDEX.RECURRING_TESTS) ? this.getRecurringTestsTab(patientId, activeIndex) : {},
      (activeIndex === TEST_TAB_INDEX.RECURRING_BUNDLES) ? this.getRecurringBundlesTab(patientId, activeIndex) : {},
    ];

    if (activeIndex !== TEST_TAB_INDEX.RECURRING_TESTS)
      this.testsRecurringService.getAllTests(patientId).subscribe(response => {
        if (response?.results?.length)
          tabs[2] = this.getRecurringTestsTab(patientId, activeIndex);
      });

    if (activeIndex !== TEST_TAB_INDEX.RECURRING_BUNDLES)
      this.testsRecurringService.getAllBundles(patientId).subscribe(response => {
        if (response?.results?.length)
          tabs[3] = this.getRecurringBundlesTab(patientId, activeIndex);
      });

    return tabs;
  }

  private getRecurringTestsTab(patientId: number, activeIndex: number): Tab {
    return {
      translationKey: 'tests',
      action: (activeIndex === TEST_TAB_INDEX.RECURRING_TESTS) ? null : () => this.router.navigate([`/tests/recurring/${patientId}`], { queryParamsHandling: 'merge' }),
      isActive: activeIndex === TEST_TAB_INDEX.RECURRING_TESTS,
      iconClass: 'fas fa-clock e2e-rec-test-tab'
    };
  }

  private getRecurringBundlesTab(patientId: number, activeIndex: number): Tab {
    return {
      translationKey: 'testBundles',
      action: (activeIndex === TEST_TAB_INDEX.RECURRING_BUNDLES) ? null : () => this.router.navigate([`/tests/bundles/recurring/${patientId}`], { queryParamsHandling: 'merge' }),
      isActive: activeIndex === TEST_TAB_INDEX.RECURRING_BUNDLES,
      iconClass: 'fas fa-clock e2e-rec-test-bundle-tab'
    };
  }

  getOfficeTabs(activeIndex: number) {
    // no tabs if only user screen is available
    if (this.authService.isOfficeAdmin && !this.authService.isSsoEnabled)
      return [];

    const tabs: Tab[] = [{
      translationKey: 'users',
      action: (activeIndex === OFFICE_TAB_INDEX.USERS) ? null : () => this.router.navigate([`/users`]),
      isActive: activeIndex === OFFICE_TAB_INDEX.USERS
    }];

    if (this.authService.isOfficeGroupAdmin)
      tabs.unshift({
        translationKey: 'offices',
        action: (activeIndex === OFFICE_TAB_INDEX.OFFICES) ? null : () => this.router.navigate([`/offices`]),
        isActive: activeIndex === OFFICE_TAB_INDEX.OFFICES
      });

    if (this.authService.isSsoEnabled)
      tabs.push({
        translationKey: 'invitations',
        action: (activeIndex === OFFICE_TAB_INDEX.INVITATIONS) ? null : () => this.router.navigate([`/invitations`]),
        isActive: activeIndex === OFFICE_TAB_INDEX.INVITATIONS
      });

    return tabs;
  }
}
