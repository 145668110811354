// native
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

// models
import { ConfirmDialogClosePayload, ConfirmDialogConfig } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  visible$ = new Subject<boolean>();
  config$ = new Subject<ConfirmDialogConfig>();
  closed$ = new Subject<ConfirmDialogClosePayload>();

  currentOpenDialogId: string;

  openConfirm(config: ConfirmDialogConfig): Promise<ConfirmDialogClosePayload> {
    this.currentOpenDialogId = config.id;
    return new Promise((resolve, reject) => {

      this.config$.next(config);
      this.visible$.next(true);

      this.closed$.pipe(
        take(1)
      ).subscribe(payload => {
        resolve(payload);
      });
    });
  }

  closeConfirm(payload: ConfirmDialogClosePayload, id?: string) {
    if (id && id !== this.currentOpenDialogId)
      return;
      
    this.currentOpenDialogId = null;
    this.visible$.next(false);
    this.closed$.next(payload);
  }
}
