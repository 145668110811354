// native
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// service
import { TestBundlesService } from 'app/core/services/test-bundles.service';

// models
import { TestBundle, TestDescription } from 'app/models';

@Component({
  selector: 'app-view-bundle',
  templateUrl: './view-bundle.component.html'
})
export class ViewBundleComponent implements OnInit {
  bundledTests: TestDescription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private testBundlesService: TestBundlesService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.testBundlesService.getOne(id).subscribe((bundle: TestBundle) => {
        this.bundledTests = bundle.test_descriptions;
      }, err => {
        // todo revisit both here and in tests (no patient id in url)
        this.router.navigate(['/tests/bundles']);
      });
    });
  }
}
