// native
import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { OfficesService } from 'app/core/services/offices.service';
import { OfflineControllersService } from 'app/core/services/offline-controllers.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { CenterContainerTitle, Office, OfflineController, PaginatedItems } from '../../models';

@Component({
  selector: 'app-offline-controller-form',
  templateUrl: './controller-form.component.html'
})
export class OfflineControllerFormComponent implements OnInit, OnChanges {
  @Input() controller: OfflineController;
  @Input() action: string;

  @Output() close: EventEmitter<OfflineController> = new EventEmitter<OfflineController>();

  public form: UntypedFormGroup;

  offices: Office[] = [];

  title: CenterContainerTitle = {};
  isLoading: boolean = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public officesService: OfficesService,
    public offlineControllersService: OfflineControllersService,
    private translateService: TranslateService,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    this.translateService.get('device').subscribe(() => this.setTranslations());

    this.officesService.getAll().pipe(
      finalize(() => this.isLoading = false)
    ).subscribe((offices: PaginatedItems<Office>) => {
      this.offices = offices.results;
      this.initializeForm();
    }, error => this.errorService.handleError(error));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['action']?.currentValue)
      this.title.thin = changes['action']?.currentValue;
  }

  private initializeForm() {
    const { name, offices } = this.controller || ({} as OfflineController);

    this.form = this.formBuilder.group({
      name: [name, Validators.required],
      offices: [offices, Validators.required],
    });
  }

  private setTranslations() {
    this.title.bold = this.translateService.instant('offlineController');
  }

  onSubmit(form: UntypedFormGroup) {
    if (!form.valid)
      return;

    const { name, offices } = this.form.value;
    const body: OfflineController = { name, offices };

    const request = this.controller?.id ? this.offlineControllersService.update(this.controller.id, body) :
      this.offlineControllersService.create(body);

    this.isLoading = true;
    request.pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      response => this.close.emit(response),
      error => this.errorService.handleDeviceError(error)
    );
  }
}
