// native
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleCasePipe } from '@angular/common';

// addon
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";

// service
import { TestsService } from 'app/core/services/tests.service';
import { DevicesService } from 'app/core/services/devices.service';
import { AuthService } from 'app/core/services/auth.service';
import { PatientsService } from 'app/core/services/patients.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { StreamingService } from 'app/core/services/streaming.service';
import { TestBundlesService } from 'app/core/services/test-bundles.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { DialogService } from 'app/core/services/dialog.service';

// component
import { MonitorBaseComponent } from '../monitor-base.component';

// models
import { MonitorEvent, WaggonerEyeResult, WaggonerEyeState, WaggonerSection, WaggonerState } from '../../models';

// constants
import { MONITOR_EVENT_TYPE, EYE, MONITOR_INACTIVITY_TIMEOUT } from '../../constants';

@Component({
  selector: 'app-monitor-waggoner',
  templateUrl: './monitor-waggoner.component.html'
})
export class MonitorWaggonerComponent extends MonitorBaseComponent {

  odState: WaggonerEyeState;
  osState: WaggonerEyeState;
  bothState: WaggonerEyeState;

  currentOdSection: WaggonerSection;
  currentOsSection: WaggonerSection;
  currentBothSection: WaggonerSection;

  sectionNames: string[] = ['demo', 'general', 'tritan', 'protan', 'deutan'];

  currentSeqNum = null;

  inactivityTimeout = MONITOR_INACTIVITY_TIMEOUT.COLOR_VISION * 1000;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public testService: TestsService,
    public testBundlesService: TestBundlesService,
    public toastService: ToastrService,
    public devicesService: DevicesService,
    public authService: AuthService,
    public patientsService: PatientsService,
    public monitorTestService: MonitorTestService,
    public streamingService: StreamingService,
    public preferencesService: PreferencesService,
    public translateService: TranslateService,
    public dialogService: DialogService) {
    super(route, router, testService, testBundlesService, toastService, devicesService, authService, patientsService,
      monitorTestService, streamingService, preferencesService, translateService, dialogService);
  }

  public getCurrentTestState() {
    this.monitorTestService.getWaggonerState(this.test.id).subscribe(response => {
      this.updateStates(response.eye_states);
      this.currentSeqNum = response.seq_num;
    });
  }

  public handleNewRecordEvent(event: MonitorEvent) {
    if (event.type === MONITOR_EVENT_TYPE.NEW_WAGGONER_STATE) {
      const eventSeqNum = (<WaggonerState>event.data).seq_num;
      if (!eventSeqNum || !this.currentSeqNum || (this.currentSeqNum <= eventSeqNum)) {
        this.currentSeqNum = eventSeqNum;
        this.updateStates((<WaggonerState>event.data).eye_states);
      }
    }
  }

  private updateStates(states: WaggonerEyeState[]) {
    states.forEach(state => {
      switch (state.eye) {
        case EYE.OS:
          this.osState = { ...state };
          this.currentOsSection = state.sections.find(sectionInfo => sectionInfo.section === state.current_section);
          break;
        case EYE.OD:
          this.odState = { ...state };
          this.currentOdSection = state.sections.find(sectionInfo => sectionInfo.section === state.current_section);
          break;
        case EYE.OU:
          this.bothState = { ...state };
          this.currentBothSection = state.sections.find(sectionInfo => sectionInfo.section === state.current_section);
          break;
      }
    });
  }

  getSectionClass(section: WaggonerSection): string {
    if (['Passed', 'Normal'].includes(section.result))
      return 'color-primary';
    if (['Mild', 'Moderate'].includes(section.result))
      return 'lightorange';
    if (['Failed', 'Severe'].includes(section.result))
      return 'red';
  }

  getEyeResultText(result: WaggonerEyeResult) {
    if (!result)
      return '';

    if (!result.tritan_deficiency && !result.protan_deutan_deficiency)
      return new TitleCasePipe().transform(this.translateService.instant('normal'));

    if (result.tritan_deficiency && !result.protan_deutan_deficiency)
      return new TitleCasePipe().transform(this.translateService.instant(result.tritan_deficiency.toLowerCase()))
        + ' Tritan';

    if (!result.tritan_deficiency && result.protan_deutan_deficiency && (result.protan_deutan_deficiency_type === 'Both'))
      return new TitleCasePipe().transform(this.translateService.instant('combination'))
        + ' '
        + new TitleCasePipe().transform(this.translateService.instant(result.protan_deutan_deficiency.toLowerCase()))
        + ' Protan/Deutan';

    if (!result.tritan_deficiency && result.protan_deutan_deficiency && (result.protan_deutan_deficiency_type !== 'Both'))
      return new TitleCasePipe().transform(this.translateService.instant(result.protan_deutan_deficiency.toLowerCase()))
        + ' '
        + result.protan_deutan_deficiency_type;

    if (result.tritan_deficiency && result.protan_deutan_deficiency && (result.tritan_deficiency === result.protan_deutan_deficiency))
      return new TitleCasePipe().transform(this.translateService.instant('combination'))
        + ' '
        + new TitleCasePipe().transform(this.translateService.instant(result.tritan_deficiency.toLowerCase()))
        + ' Tritan/'
        + ((result.protan_deutan_deficiency_type === 'Both') ? 'Protan/Deutan' : result.protan_deutan_deficiency_type);

    if (result.tritan_deficiency && result.protan_deutan_deficiency && (result.tritan_deficiency !== result.protan_deutan_deficiency))
      return new TitleCasePipe().transform(this.translateService.instant('combination'))
        + ' '
        + new TitleCasePipe().transform(this.translateService.instant(result.tritan_deficiency.toLowerCase()))
        + ' Tritan'
        + this.translateService.instant('and ')
        + new TitleCasePipe().transform(this.translateService.instant(result.protan_deutan_deficiency.toLowerCase()))
        + ' '
        + ((result.protan_deutan_deficiency_type === 'Both') ? 'Protan/Deutan' : result.protan_deutan_deficiency_type);
  }

  public resetStateAfterReconnection(): void { }

  public calculateDimensions() { }

  public onToggleGrid() { }
}