// native
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

// addon
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ToastrService } from 'ngx-toastr';

// service
import { SupportService } from 'app/core/services/support.service';
import { AuthService } from 'app/core/services/auth.service';
import { DialogService } from 'app/core/services/dialog.service';
import { ErrorService } from 'app/core/services/error.service';
import { DevicesService } from 'app/core/services/devices.service';

// models
import { SupportDevice, Tab, GridItem } from '../../models';

// constants
import { DEFAULT_SUPPORT_PAGE_INDEX, DEFAULT_SUPPORT_PAGE_SIZE, DEFAULT_SUPPORT_PAGE_SKIP, DEFAULT_SUPPORT_SORT_ORDER } from '../../constants';

@Component({
  selector: 'app-support-devices',
  templateUrl: './support-devices.component.html'
})
export class SupportDevicesComponent implements OnInit {

  tabs: Tab[] = [
    {
      translationKey: 'devices',
      action: null,
      isActive: true
    },
    {
      translationKey: 'users',
      action: () => this.router.navigate(['/support/users']),
      isActive: false
    },
    {
      translationKey: 'offices',
      action: () => this.router.navigate(['/support/offices']),
      isActive: false
    }
  ];

  pageSize = DEFAULT_SUPPORT_PAGE_SIZE;
  pageIndex = DEFAULT_SUPPORT_PAGE_INDEX;
  skip = DEFAULT_SUPPORT_PAGE_SKIP;
  sort: SortDescriptor[] = [{
    field: 'name',
    dir: DEFAULT_SUPPORT_SORT_ORDER,
  }];
  filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  gridData$: BehaviorSubject<GridItem<SupportDevice>> = new BehaviorSubject<GridItem<SupportDevice>>({} as GridItem<SupportDevice>);

  isLoading: boolean = true;

  constructor(
    private supportService: SupportService,
    private router: Router,
    private translateService: TranslateService,
    public authService: AuthService,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private devicesService: DevicesService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getDevices();
  }

  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageIndex = this.skip / this.pageSize;
    this.getDevices();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.getDevices();
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.getDevices();
  }

  private getDevices() {
    this.isLoading = true;
    this.supportService.getDevices(this.pageSize, this.pageIndex, this.sort, this.filter).subscribe(res => {
      this.gridData$.next(res);
      this.isLoading = false;
    });
  }

  unlink(device: SupportDevice) {
    this.dialogService.openConfirm({
      action: this.translateService.instant('unlink'),
      message: this.translateService.instant('areYouSure') + ' ' + this.translateService.instant('unlink')
        + ' ' + this.translateService.instant('device')?.toLowerCase() + '?',
      text: this.translateService.instant('device') + ': ' + device.name,
      confirmText: this.translateService.instant('yes'),
      cancelText: this.translateService.instant('no')
    }).then(result => {
      if (result.confirmed) {
        this.isLoading = true;
        this.devicesService.unlink(device.id).pipe(
          finalize(() => this.isLoading = false)
        ).subscribe(
          res => {
            this.toastService.success(this.translateService.instant('deviceUnlinkSuccess'));
            this.getDevices();
          },
          error => this.errorService.handleDeviceError(error)
        );
      }
    });
  };
}
