<div 
  class="center_container"
  [ngClass]="{ 'center_container--overlay': hasOverlay }">
  <div 
    class="center_content"
    [ngClass]="{ 'center_content--large': isLarge }">
    <div class="center_header">
      <div class="center_icon-container">
        <button 
          class="center_icon"
          attr.aria-label="{{'back' | translate}}"
          *ngIf="showClose"
          [matTooltip]="closeTooltip"
          matTooltipPosition="above"
          (click)="closeContainer($event)">
          <i class="fas fa-arrow-left fa-2x"></i>
        </button>
        <button 
          class="center_icon" 
          *ngIf="icon"
          attr.aria-label="{{icon.class}}" 
          (click)="icon.action()">
          <i class="far fa-{{icon.class}} fa-2x" [ngClass]="{'lightgreen': icon.active}"></i>
        </button>
      </div>
      <h1 class="center_title">
        <span class="thin">{{title?.thin}}</span>{{title?.bold | titlecase}}
      </h1>
    </div>

    <ng-content></ng-content>
  </div>
</div>