// native
import { Component } from '@angular/core';
import { Router } from '@angular/router';

// services
import { AuthService } from 'app/core/services/auth.service';

// models
import { Patient } from 'app/models';

@Component({
  selector: 'app-new-patient',
  templateUrl: './new-patient.component.html'
})
export class NewPatientComponent {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  closeForm(patient: Patient) {
    if (this.authService.isTechallUser) {
      this.router.navigate(['/techall-mode']);
      return;
    }

    this.router.navigate(['/tests/new', patient.id], {
      queryParams: { 'firstTest': true }
    });
  }
}
