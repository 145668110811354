// native
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

// addon
import { TranslateService } from '@ngx-translate/core';

// service
import { DialogService } from 'app/core/services/dialog.service';

// models
import { CenterContainerTitle } from '../../../models';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent implements OnInit {

  visible: boolean = false;

  action: string;
  message: string;
  text: string;
  confirmText: string;
  altConfirmText: string;
  cancelText: string;
  showClose: boolean = true;
  showCancel: boolean = true;
  mediumButtonMode: boolean = false;

  dialogConfigSubscription: Subscription;
  visibilitySubscription: Subscription;

  title: CenterContainerTitle;

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    this.dialogConfigSubscription = this.dialogService.config$.subscribe(config => {
      this.action = config.action;
      this.message = config.message;
      this.text = config.text;
      this.confirmText = config.confirmText;
      this.altConfirmText = config.altConfirmText;
      this.cancelText = config.cancelText;
      this.mediumButtonMode = config.mediumButtonMode || false;

      this.showClose = (config.showClose !== false);
      this.showCancel = (config.showCancel !== false);

      this.title = {
        bold: this.action,
        thin: this.translateService.instant('confirm')
      };

    });

    this.visibilitySubscription = this.dialogService.visible$.subscribe(visible => {
      this.visible = visible;
    });
  }

  onAltConfirm() {
    this.dialogService.closeConfirm({ altConfirmed: true });
  }

  onConfirm() {
    this.dialogService.closeConfirm({ confirmed: true });
  }

  onCancel() {
    this.dialogService.closeConfirm({ canceled: true });
  }

  onClose() {
    this.dialogService.closeConfirm({ closed: true });
  }
}
