<app-header 
  *ngIf="headerShown"
  [navExpanded]="navExpanded" 
  [transparentMode]="headerTransparentMode">
</app-header>

<main
  class="container-main" 
  [ngClass]="{ 'container-with-header': headerShown, 'container-with-footer': footerShown }">
  <router-outlet></router-outlet>
</main>

<app-footer 
  *ngIf="footerShown">
</app-footer>

<app-video-call 
  *ngIf="authService.isTechallUserOrAdmin"
  [hidden]="isVideoHidden()"
  [isVideo]="techallService.isVideo"
  [isSharing]="techallService.isSharing"
  [isMuted]="techallService.isMuted"
  [isEnding]="techallService.isEnding"
  [isWaiting]="techallService.isWaiting"
  [isPortrait]="techallService.isPortraitOrientation"
  [isUser]="authService.isTechallUser"
  [inCall]="techallService.inCall"
  (startVideo)="techallService.startVideo($event)"
  (stopVideo)="techallService.stopVideo($event)"
  (startSharing)="techallService.startSharing($event)"
  (stopSharing)="techallService.stopSharing($event)"
  (mute)="techallService.mute($event)"
  (unmute)="techallService.unmute($event)"
  (endCall)="techallService.endCall($event)">
</app-video-call>

<app-confirm-dialog></app-confirm-dialog>