// native
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

// addon
import { ToastrService } from 'ngx-toastr';

// service
import { PatientsService } from '../../core/services/patients.service';
import { DoctorsService } from 'app/core/services/doctors.service';
import { OperatorsService } from 'app/core/services/operators.service';
import { PreferencesService } from 'app/core/services/preferences.service';
import { TestsService } from 'app/core/services/tests.service';
import { NavigationService } from 'app/core/services/navigation.service';
import { MonitorTestService } from 'app/core/services/monitor-test.service';
import { ErrorService } from 'app/core/services/error.service';

// models
import { DemoPatientRequest, Operator, Doctor, UserPreferences, TestPostRequest, Patient, TestGroup } from '../../models';

// constants
import { EYE, GOLDMAN_SIZES, GROUP, STRATEGY, PROTOCOL, TEST_STATUS_STARTED_ID } from '../../constants';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html'
})
export class DemoComponent implements OnInit, OnDestroy {
  currentStep = 1;
  isLoading: boolean;
  operators: Operator[] = [];
  doctors: Doctor[] = [];
  displayedTestGroups: TestGroup[] = [];
  preferences: UserPreferences;

  patient: Patient;
  testForm: UntypedFormGroup;

  pinForm: UntypedFormGroup;
  isPinPromptVisible: boolean = false;

  navigationOpenedSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private patientsService: PatientsService,
    private testsService: TestsService,
    private monitorTestService: MonitorTestService,
    private doctorsService: DoctorsService,
    private operatorsService: OperatorsService,
    private preferencesService: PreferencesService,
    private toastService: ToastrService,
    private navigationService: NavigationService,
    private router: Router,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    forkJoin([
      this.testsService.getTestGroups(),
      this.operatorsService.getAll(),
      this.doctorsService.getAll(),
      this.preferencesService.get()
    ]).pipe(
      finalize(() => this.isLoading = false)
    ).subscribe(
      ([testGroups, operators, doctors, preferences]) => {
        this.displayedTestGroups = [
          testGroups.find(group => group.value === GROUP.PERIMETRY),
          testGroups.find(group => group.value === GROUP.VISUAL_ACUITY),
        ];
        this.operators = operators;
        this.doctors = doctors;
        this.preferences = preferences;

        if (!this.preferences?.enable_demo_mode)
          this.router.navigate(['patients']);

        this.initializeForm();
      },
      error => this.errorService.handleError(error));

    this.initializePinForm();
    this.navigationOpenedSubscription = this.navigationService.navigationOpened$.subscribe(() => {
      this.isPinPromptVisible = true;
    });
  }

  private initializeForm() {
    this.testForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnDestroy(): void {
    this.navigationOpenedSubscription?.unsubscribe();
  }

  onStepOneComplete() {
    this.currentStep = 2;
  }

  onStepTwoComplete() {
    const body: DemoPatientRequest = {
      name: this.testForm.value.name,
      email: this.testForm.value.email
    };

    this.patientsService.createDemoPatient(body).subscribe(patient => {
      this.patient = patient;
      this.currentStep = 3;
    });
  }

  onTestSelect(group: TestGroup) {
    const body: Partial<TestPostRequest> = {
      patient: this.patient.id,
      status: TEST_STATUS_STARTED_ID,
      device: this.preferences.device.id,
      doctor: this.patient.doctor_id || this.doctors[0]?.id,
      operator: this.preferences.operator?.id || this.operators[0].id,
      eye: EYE.BOTH_SERVER,
      goldman_size: GOLDMAN_SIZES.THREE.value,
      language: null,
      monocular: this.patient.monocular,
      skip_convergence_testing: this.preferences.skip_convergence_testing,
      skip_tutorial: this.preferences.skip_tutorial,
      use_short_tutorial: this.preferences.use_short_tutorial,
      disable_pause: this.preferences.disable_pause,
      test_foveal_sensitivity: false
    };

    switch (group.value) {
      case GROUP.PERIMETRY:
        body.test_group = {
          group: GROUP.PERIMETRY,
          strategy: STRATEGY.AVA_FAST,
          protocol: PROTOCOL.AVA_FAST_30_2
        };
        break;
      case GROUP.VISUAL_ACUITY:
        body.test_group = {
          group: GROUP.VISUAL_ACUITY,
          strategy: STRATEGY.FAR_VISION,
          protocol: PROTOCOL.LANDOLT_C
        };
        break;
    }
    this.isLoading = true;

    this.testsService.create(<TestPostRequest>body).subscribe(test => {
      this.isLoading = false;

      this.monitorTestService.openMonitorScreen(test);
    }, error => {
      this.isLoading = false;
      this.toastService.info(error);
    });
  }

  private initializePinForm() {
    this.pinForm = this.formBuilder.group({
      pin: [null, Validators.pattern(/^8200$/)]
    });
  }

  onSubmitPin() {
    if (this.pinForm.valid) {
      this.pinForm.reset();
      this.isPinPromptVisible = false;
      this.navigationService.navigationHidden$.next(false);
    }
  }

  closePinPrompt() {
    this.router.navigateByUrl('.', { skipLocationChange: true }).then(() => {
      this.router.navigate(['demo-mode']);
    });
  }
}
